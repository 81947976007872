import { BellAlertIcon } from "@heroicons/react/20/solid";
import React, { useState, useEffect } from "react";
import ServerStatusIndicator from "./server";
import ClosingTime from "./closingtime";
import LoadingComponent from "./loading";


const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());

 

  


  useEffect(() => {
    const fetchWeather = async () => {
      setIsLoading(true);
      const apiKey = "1e83f73cc624c5a3fdf20c4eb0b3a261"; // Replace with your API key
      const url = `https://api.openweathermap.org/data/2.5/weather?q=Maidstone,UK&appid=${apiKey}`;
      const response = await fetch(url);
      const data = await response.json();
      setWeatherData(data);
      setIsLoading(false);
    };
    fetchWeather();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!weatherData) {
    return <p>Weather data not found for Maidstone, Kent.</p>;
  }

  const { main, weather } = weatherData;
  const temp = Math.round(main.temp - 273.15); // Convert kelvin to celsius
  const weatherIconCode = weather[0].icon;

  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center space-x-2 border border-blue-50 bg-blue-50 rounded p-2">
        <img
          src={`https://openweathermap.org/img/wn/${weatherIconCode}@2x.png`}
          alt={weather[0].main}
          width={30}
          height={30}
        />
        <span className="text-lg font-bold text-blue-700">{temp}°C</span>
        <span className="text-lg font-bold text-blue-700">{weather[0].main}</span>
      </div>
      <div className="flex items-center space-x-2 border border-blue-50 bg-blue-50 rounded p-2">
        <span className="text-lg font-bold">
          {currentTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
        </span>
      </div>
      <ServerStatusIndicator />
      

  
    </div>
    


  );
};

export default Weather;
