import React, { useState, useEffect } from 'react';
import { format, addHours, parse } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LoadingComponent from './loading';

const App = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSessionData(selectedDate);
  }, [selectedDate]);

  const fetchSessionData = async (date) => {
    setLoading(true);
    const formattedDate = format(date, 'yyyy-MM-dd');
    
    try {
      // Fetch opening and closing time from the API
      const getDayDataResponse = await fetch('https://cheekydino.com/api/admin', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!getDayDataResponse.ok) {
        throw new Error('Failed to fetch day data');
      }

      const getDayData = await getDayDataResponse.json();
      if (!getDayData.success) {
        throw new Error('Failed to fetch day data: ' + getDayData.message);
      }

      const dayData = getDayData.data;
      const getDay = format(date, 'EEEE');
      const filteredData = dayData[0][getDay] || [];
      const parseData = JSON.parse(filteredData);
      
      const { openingTime, closingTime, sessions } = parseData;

      // Convert opening and closing time to date objects
      const openingHour = parse(`${formattedDate} ${openingTime}`, 'yyyy-MM-dd HH:mm', new Date());
      const closingHour = parse(`${formattedDate} ${closingTime}`, 'yyyy-MM-dd HH:mm', new Date());

      // Generate hourly time slots between opening and closing time
      const timeSlots = [];
      let currentSlot = openingHour;

      while (currentSlot <= closingHour) {
        timeSlots.push(format(currentSlot, 'HH:mm')); // Use only hours and minutes for time slots
        currentSlot = addHours(currentSlot, 1); // Increase by 1 hour for each slot
      }

      // Initialize chart data with time slots
      const initialData = timeSlots.reduce((acc, slot) => {
        acc[slot] = { timeSlot: slot, guests: 0 };
        return acc;
      }, {});

      // Fetch session bookings data
      const bookingsResponse = await fetch(`https://cheekydino.com/api/sessionbookings?date=${formattedDate}`);
      if (!bookingsResponse.ok) {
        throw new Error('Failed to fetch bookings data');
      }

      const bookingsJson = await bookingsResponse.json();
      if (!bookingsJson.success) {
        throw new Error('Failed to fetch bookings data: ' + bookingsJson.message);
      }

      const bookingsData = bookingsJson.data;

      // Aggregate guest data into hourly time slots
      bookingsData.forEach(booking => {
        const timeSlotFrom = booking.TimeslotFrom; // Get the TimeslotFrom from the session bookings
        const sessionTime = format(parse(timeSlotFrom, 'HH:mm:ss', new Date()), 'HH:mm');

        // Find the closest time slot in the chart data
        const slot = timeSlots.find(slot => sessionTime >= slot && sessionTime < format(addHours(parse(slot, 'HH:mm', new Date()), 1), 'HH:mm'));

        if (slot && initialData[slot]) {
          initialData[slot].guests += (Number(booking.Adults) || 0) + (Number(booking.Children) || 0);
        }
      });

      // Convert initialData to array format for Recharts
      const chartDataArray = Object.values(initialData);
      setChartData(chartDataArray);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Select Date</label>
        <input
          type="date"
          id="date"
          value={format(selectedDate, 'yyyy-MM-dd')}
          onChange={(e) => setSelectedDate(new Date(e.target.value))}
          className="mt-1 p-2 border border-gray-300 rounded"
        />
      </div>
      <h1 className="text-xl font-semibold mb-4">
        Data for {format(selectedDate, 'MMMM d, yyyy')}
      </h1>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timeSlot" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="guests" fill="#0000ff" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default App;
