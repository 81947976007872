import KitchenStats from "./KitchenStats";
import CafeKitchen from "./CafeKitchen";
import PartyKitchen from "./PartyKitchen";
import { format } from "date-fns";
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CurrencyPoundIcon,
  ChatBubbleBottomCenterIcon,
  TableCellsIcon,
  PencilIcon,
  ArrowLeftIcon,
  ClockIcon,
  TvIcon,
  CogIcon,
  KeyIcon,
  LightBulbIcon,
  CakeIcon,
  BoltIcon,


} from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useEffect } from "react";
import Modal from "./modal";  // import the modal component
import { Link } from "react-router-dom";
import { PhoneIcon } from "@heroicons/react/20/solid";
import { Switch } from '@headlessui/react'
import OrderProgress from './orderprogress'
import NavBar from './staffNav'
import Buzzer from './buzzer'
import AudioChat from './audiochat'
import { useNavigate } from "react-router-dom";
import SlideOver from './slideover'





function classNames(...classes) {
  return classes.filter(Boolean).join(' ')


}


const isElectron = window && window.process && window.process.type;
const ipcRenderer = isElectron ? window.require('electron').ipcRenderer : null;



export default function Kitchen() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [show, setShow] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [chat, setChat] = useState(false);
  const [partyBookings, setPartyBookings] = useState([]);

  const navigate = useNavigate();
  const now = new Date();
  const time = format(now, "h:mm a");
  const date = format(now, "EEEE, MMMM do, yyyy");

  // Fetch today's party bookings
  useEffect(() => {
    const fetchTodaysPartyBookings = async () => {
      try {
        const today = format(new Date(), 'yyyy-MM-dd');
        const response = await fetch(`https://cheekydino.com/api/datepartybookings?date=${today}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setPartyBookings(data.data);

      } catch (error) {
        console.error('Error fetching party bookings:', error);
      }
    };

    fetchTodaysPartyBookings();
  }, []);


  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/messages', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
  
        // Filter messages and customer chat data
        const filteredData = data.data.filter((message) => message.replied === 0);
  
        setMessages(filteredData);

      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
  
       
    

  
    fetchMessages(); // Initial fetch
  
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 10000); // Poll every second
  
    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
    };
  }, []);


  // Fetch cafe orders
  useEffect(() => {
    const fetchCafeOrders = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/cafeorders", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setFilteredOrders(data.data);

      } catch (error) {
        console.error('Error fetching cafe orders:', error);
      }
    };

    fetchCafeOrders();
  }, []);

  // Navigation based on show/chat state
  if (show) navigate('/kitchentables');
  if (chat) return <SlideOver />;


  const latestMessage = messages.length > 0 ? messages[0] : null; // Get the latest message


  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navbar */}
      <nav>
        <NavBar />
      </nav>

      {/* Audio Chat Button */}
      <div className="fixed top-4 right-4 z-50">
        <AudioChat />
      </div>

      {/* Main Content */}
      <main className="py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Kitchen Header */}
          <div className="flex flex-col md:flex-row justify-between items-center text-white text-center py-6 border border-gray-300 shadow-lg bg-gradient-to-tr from-indigo-800 via-purple-500 to-violet-700 rounded-lg">
            {/* Left side: Kitchen time and date */}
            <div className="md:text-left mb-4 md:mb-0">
              <h1 className="ml-0 md:ml-10 text-2xl font-medium">Kitchen</h1>
              <p className="mt-2 ml-0 md:ml-10 text-sm md:text-base">
                {time} | {date}
              </p>
              <p className="mt-2 font-bold ml-0 md:ml-10 text-sm md:text-base">
                {filteredOrders.length} Kitchen Orders Today
              </p>
            </div>

            {/* Right side: Buzzer, Tables, Chat Buttons */}
            <div className="w-full md:w-auto flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-2">
            {latestMessage ? (
  <div className="relative max-w-xs p-4 bg-white text-gray-900 rounded-lg shadow-md mx-auto before:content-[''] before:absolute before:top-full before:left-1/2 before:-translate-x-1/2 before:border-8 before:border-transparent before:border-t-white">
    <p className="font-semibold">{latestMessage.email}</p>
    <p className="mt-1">{latestMessage.content}</p>
    <p className="mt-1 text-gray-500 text-xs">{latestMessage.createdAt}</p>
  </div>
) : (
  <div className="text-gray-300">No new messages</div>
)}

              <Buzzer />
              <button
                onClick={() => setShow(true)}
                className="w-20 sm:w-16 h-12 sm:h-16 bg-white text-xs font-semibold text-blue-700 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center justify-center"
              >
                Tables
              </button>
              <button
                onClick={() => setChat(true)}
                className={classNames(
                  "w-20 sm:w-16 h-12 ml-2 sm:h-16 bg-white text-xs font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center justify-center",
                  messages.length > 0 ? "animate-pulse text-red-700" : "text-blue-700"
                )}
              >
                Chat
              </button>
            </div>
          </div>

          {/* Content Layout Based on Party Bookings */}
          {partyBookings.length > 0 ? (
            <div className="flex flex-col md:flex-row mt-6">
              {/* Orders Column */}
              <div className="w-full md:w-1/2 mb-4 md:mb-0 mt-3">
                <h2 className="text-lg font-medium text-purple-700 text-center">Orders</h2>
                <CafeKitchen />
              </div>

              {/* Party Column */}
              <div className="w-full md:w-1/2 border-l border-gray-300 mt-6 md:mt-0">
                <h2 className="text-lg font-medium text-purple-700 mt-3 text-center">Party</h2>
                <PartyKitchen />
              </div>
            </div>
          ) : (
            <div className="w-full mt-6">
              {/* Full-width Orders Column */}
              <h2 className="text-lg font-medium text-purple-700 text-center">Orders</h2>
              <CafeKitchen />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

  