import { useState } from 'react'
import { Dialog, Switch } from '@headlessui/react'
import { Bars3Icon, CakeIcon } from '@heroicons/react/20/solid'
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { QueueListIcon, SignalIcon } from '@heroicons/react/24/solid'
import Navbar from './staffNav'









function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
    const [staff, setStaff] = useState([])
    const [userGroups, setUserGroups] = useState([]);

    

     
      const secondaryNavigation = [

       
       
            { name: 'Admin', href: '/branchsettings', icon: UserCircleIcon, current: true },
          { name: 'Stock Control ', href: '/stockcontrol', icon: CubeIcon, current: false },
          {name: 'Till Products', href: '/tillproducts', icon: Bars3Icon, current: false},
          {name: 'Party Stock', href: '/partystock', icon: QueueListIcon, current: false},

          { name: 'Add Stock Items ', href: '/buildameal', icon: CakeIcon, current: false },
          {name: 'Error Log', href: '/errorlog', icon: SignalIcon, current: false},
      ]


          const faqs = [
            {
              question: 'Who Do I call if I have a problem with the system?',
              answer:
                'Call Ash on 07960213542',
            },
            // More questions...
          ]

  return (
    <>
      <Navbar/>

      <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-indigo-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-indigo-600' : 'text-indigo-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
        <main className="min-w-0 flex-auto px-4 lg:px-0">
        <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                customer support
              </a>{' '}
              team.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
            </main>

      </div>
    </>
  )
}
