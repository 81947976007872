import { useState, useEffect } from "react";
import { format, isWithinInterval, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";








export default function EditBookings({session}) {

    const navigate = useNavigate();


    const [bookings, setBookings] = useState([]);
    const [childPrice, setChildPrice] = useState([]);
    const [adultPrice, setAdultPrice] = useState([]);
    const [additionalAdultPrice, setAdditionalAdultPrice] = useState([]);
    const [adults, setAdults] = useState(0);    
    const [children, setChildren] = useState(0);
    const [childData, setChildData] = useState([]);
    const [arrival, setArrival] = useState(false);





    useEffect(() => {
        const fetchSessions = async () => {

            const response = await fetch(`https://cheekydino.com/api/getsessionbookings/${session}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            });

            const data = await response.json();

            const sessionData = data.data;

            setBookings(sessionData);

        }

        fetchSessions();

    }, [session]);


    useEffect(() => {
        const fetchPrices = async () => {
          try {
            const response = await fetch('https://cheekydino.com/api/prices', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
    
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            const prices = data.data;

             console.log(prices)
    
            const today = new Date();
            const dayOfWeek = format(today, 'EEEE');
            const currentTime = format(today, 'HH:mm:ss');

            console.log(dayOfWeek)
    
            const convertDaysToArray = (daysString) => {
              if (!daysString) return [];
              return daysString.split(',').map(day => day.trim());
            };

    
            const sessionDataDays = prices.filter((price) => {
              const daysArray = convertDaysToArray(price.Days);
              return (
                price.SetDay === 1 &&
                price.Active === 1 &&
                price.Days &&
                daysArray.includes(dayOfWeek) &&
                price.StartTime <= currentTime &&
                price.EndTime >= currentTime
              );
            });

            console.log(sessionDataDays)
    
            const sessionDataDates = prices.filter((price) => {
              const startDate = price.StartDate ? parseISO(price.StartDate) : null;
              const expiryDate = price.ExpiryDate ? parseISO(price.ExpiryDate) : null;
              return (
                price.SetDay === 0 &&
                price.Active === 1 &&
                price.Category &&
                startDate &&
                expiryDate &&
                isWithinInterval(today, { start: startDate, end: expiryDate }) &&
                price.StartTime <= currentTime &&
                price.EndTime >= currentTime
              );
            });
    
            const allSessionData = [...sessionDataDays, ...sessionDataDates];
    
            setChildPrice(allSessionData.filter(price => price.Category === "Children"));
            setAdultPrice(allSessionData.filter(price => price.Category === "Adults"));
            setAdditionalAdultPrice(allSessionData.filter(price => price.Category === "AdditionalAdults"));
    
          } catch (error) {
            console.error('Failed to fetch prices', error);
          }
        };
    
        fetchPrices();
      }, [session]);


      console.log(childPrice)


      const handleAdultsChange = (value) => {
        setAdults(Math.max(0, value));
      };
    
      const handleChildrenChange = (value) => {
        setChildren(Math.max(0, value));
        setChildData(Array.from({ length: value }, () => ({ age: '' })));
      };

      const handleChildAgeChange = (index, value) => {
        console.log(index, value);
        setChildData((prev) =>
          prev.map((data, i) => (i === index ? { ...data, age: value } : data))
        );
      };


      const calculatePrice = (childData, adults, children) => {
        let totalPrice = 0;
    
        childData.forEach(data => {
          const childPriceValue = parseFloat(data.age) || 0;
          totalPrice += childPriceValue;
        });
    
        const additionalAdults = adults - children;
        if (additionalAdults > 0) {
          const additionalAdultPriceValue = adultPrice.find(price => price.Category === "Adults")?.Price;
          totalPrice += additionalAdults * (parseFloat(additionalAdultPriceValue) || 0);
        }
    
        return totalPrice;
      };

      console.log(calculatePrice(childData, adults, children))
      console.log(childData)

      const handleUpdateBooking = async () => {

        const newTotal = calculatePrice(childData, adults, children);

        console.log(newTotal)

        const combinedTotal = newTotal + parseFloat(bookings[0].TotalSpent);

        console.log(combinedTotal)

        const combinedAdults = parseInt(adults) + parseInt(bookings[0].Adults);

        console.log(combinedAdults)

        const combinedChildren = parseInt(children) + parseInt(bookings[0].Children);

        console.log(combinedChildren)


        console.log(bookings[0].ChildData)

        const combinedChildData = [...bookings[0].ChildData, ...childData];

        console.log(combinedChildData)

        const combinedChildDataString = JSON.stringify(combinedChildData);

        try {

            const response = await fetch(`https://cheekydino.com/api/addguest/${bookings[0].id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Adults: combinedAdults,
                    Children: combinedChildren,
                    TotalSpent: combinedTotal,
                    EntranceFee: combinedTotal,
                    ChildData: combinedChildDataString
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            console.log(data)
            setArrival(true);



            
        } catch (error) {
            console.error('Failed to update booking', error);
            
        }

       

      };

      if (arrival === true) {
        navigate('/prebooktill', {
          state: {
            order: '2 Hour Session',
            total: calculatePrice(childData, adults, children),
            table: bookings[0].Table,
            ChildName: bookings[0].Name,
          },
        });
      }



      return (
        <>
        <button onClick={() => navigate('/dashboard')} className="text-indigo-600 hover:text-indigo-900">Back</button>

          {bookings.map((booking, index) => (
            <div key={index} className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Session Details</h3>
              </div>
              <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Full name</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{booking.Name}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Adults</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{booking.Adults}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Children</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{booking.Children}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <div className="flex flex-col">
                      <label htmlFor={`adults-${index}`} className="text-sm font-medium text-gray-900">
                        Adults
                      </label>
                      <input
                        id={`adults-${index}`}
                        type="number"
                        value={adults}
                        onChange={(e) => handleAdultsChange(e.target.value)}
                        className="mt-1 block w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor={`children-${index}`} className="text-sm font-medium text-gray-900">
                        Children
                      </label>
                      <input
                        id={`children-${index}`}
                        type="number"
                        value={children}
                        onChange={(e) => handleChildrenChange(e.target.value)}
                        className="mt-1 block w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  {children > 0 && (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      {childData.map((data, childIndex) => (
                        <div key={childIndex} className="space-y-2">
                          <label htmlFor={`child-age-${index}-${childIndex}`} className="block text-sm font-medium text-gray-900">
                            Child's Age - Do Not Select Sibling First
                          </label>
                          <select
                            onChange={(e) => handleChildAgeChange(childIndex, e.target.value)}
                            id={`child-age-${index}-${childIndex}`}
                            name={`child-age-${index}-${childIndex}`}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">Select an age</option>
                            {childPrice.map((price) => (
                              <option key={price.id} value={price.Price}>
                                {price.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                  )}
                </dl>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">New Total</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">£{calculatePrice(childData, adults, children)}</dd>
                  </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
                <button
                    type="button"
                    onClick={handleUpdateBooking}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Pay For Extra Guests - £{calculatePrice(childData, adults, children)}
                </button>



            </dt>
          </div>
          </div>

          ))}
        </>
      );
    }      