import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

export default function SessionCalender({ currentDate }) {
  const [occupiedTables, setOccupiedTables] = useState({});

  const timeslots = [
    { start: '09:30', end: '11:30' },
    { start: '10:00', end: '12:00' },
    { start: '10:30', end: '12:30' },
    { start: '11:00', end: '13:00' },
    { start: '11:30', end: '13:30' },
    { start: '12:00', end: '14:00' },
    { start: '12:30', end: '14:30' },
    { start: '13:00', end: '15:00' },
    { start: '13:30', end: '15:30' },
    { start: '14:00', end: '16:00' },
    { start: '14:30', end: '16:30' },
    { start: '15:00', end: '17:00' },
  ];

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${formattedDate}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch availability data');
        }

        const bookingsResponse = await response.json();
        const bookingsForDate = bookingsResponse.data;

        const occupiedTablesData = {};

        timeslots.forEach(timeslot => {
          let occupiedCount = 0;

          bookingsForDate.forEach(booking => {
            if (
              booking.TimeslotFrom < timeslot.end &&
              booking.TimeslotTo > timeslot.start
            ) {
              occupiedCount++;
            }
          });

          occupiedTablesData[timeslot.start] = occupiedCount;
        });

        setOccupiedTables(occupiedTablesData);
      } catch (error) {
        console.error('Failed to fetch availability data', error);
      }
    };

    fetchAvailability();
  }, [currentDate]);

  const getTableColor = numOccupiedTables => {
    if (numOccupiedTables === 0) {
      return 'bg-green-500';
    } else if (numOccupiedTables > 0 && numOccupiedTables <= 5) {
      return 'bg-yellow-500';
    } else {
      return 'bg-red-500';
    }
  };

  return (
    <div>
      <motion.div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timeslot</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Occupied Tables</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {timeslots.map(timeslot => (
              <tr key={timeslot.start} className={`${getTableColor(occupiedTables[timeslot.start] || 0)}`}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {timeslot.start} - {timeslot.end}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {occupiedTables[timeslot.start] || 0}/38
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </motion.div>
    </div>
  );
}
