import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Kitchen() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [parties, setParties] = useState([]);
  const [selectedGuests, setSelectedGuests] = useState({});
  const [fieldsetVisible, setFieldsetVisible] = useState({});
  const [noPartyBookings, setNoPartyBookings] = useState(false);
  const [adultFood, setAdultFood] = useState(false);
  const [clickedGuests, setClickedGuests] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchTodaysPartyBookings();

    const interval = setInterval(() => {
        fetchTodaysPartyBookings();
        }, 60000); // Fetch every minute

    return () => clearInterval(interval);





  }, []);

  async function fetchTodaysPartyBookings() {
    const date = format(new Date(), 'yyyy-MM-dd');
    try {
      // Fetch today's party bookings
      const allBookingsResponse = await fetch(`https://cheekydino.com/api/party?date=${date}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!allBookingsResponse.ok) {
        throw new Error('Failed to fetch bookings data');
      }

      const allBookingsData = await allBookingsResponse.json();
      const partyBookings = allBookingsData.data;

      if (partyBookings.length === 0) {
        setNoPartyBookings(true);
        return;
      }

      // Fetch guests for each party
      const selectedGuests = {};
      for (const party of partyBookings) {
        const response = await fetch(`https://cheekydino.com/api/partyguestsid/${party.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch party guests data');
        }

        const partyGuests = await response.json();
        selectedGuests[party.id] = partyGuests.data;
      }

      setParties(partyBookings);
      setSelectedGuests(selectedGuests);
    } catch (error) {
      console.error('Error fetching party bookings:', error);
    }
  }

  async function handleConfirmClick(party) {
    const awstime = format(new Date(), 'HH:mm:ss');
    try {
      // Mark guests as completed
      

      // Mark the party as food complete and update the preparation time
      await fetch(`https://cheekydino.com/api/partyfood/${party.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          PartyFoodComplete: 1, // Update to 1 (true) instead of true
          PartyFoodPrepared: awstime,
          PartyHost : party.PartyHost,
        }),
      });

    

    } catch (error) {
      console.error('Error confirming party:', error);
    }
  }

  function handleViewOrderClick(partyId) {
    setFieldsetVisible((prevVisible) => ({
      ...prevVisible,
      [partyId]: !prevVisible[partyId],
    }));
  }

  function handleAdultFoodClick() {
    setAdultFood((prevAdultFood) => !prevAdultFood);
  }

  function handleGuestClick(partyId, guestName) {
    setClickedGuests((prevClickedGuests) => ({
      ...prevClickedGuests,
      [partyId]: {
        ...(prevClickedGuests[partyId] || {}),
        [guestName]: !prevClickedGuests[partyId]?.[guestName],
      },
    }));
  }

  const calculateFoodOptions = (guests) => {
    const foodOptions = {};

    guests.forEach((guest) => {
      if (foodOptions[guest.FoodOption]) {
        foodOptions[guest.FoodOption]++;
      } else {
        foodOptions[guest.FoodOption] = 1;
      }
    });

    return foodOptions;
  };

  const calculateAllergies = (guests) => {
    const allergies = {};

    guests.forEach((guest) => {
      if (allergies[guest.Allergies]) {
        allergies[guest.Allergies]++;
      } else {
        allergies[guest.Allergies] = 1;
      }
    });

    return allergies;
  };

  const allGuestsSelected = (partyId) => {
    const selected = selectedGuests[partyId];
    return selected && Object.values(selected).every((guest) => guest.Arrived === 1); // Check if Arrived is 1
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      {parties.filter(party => party.PartyFoodComplete !== 1).map((party) => ( // Filter where PartyFoodComplete is not 1
        <div key={party.id} className="mt-8">
          <div className="flex justify-between gap-x-6 py-5">
            <div className="flex gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-purple-700">
                  Child's Name: {party.ChildName}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-purple-700">
                  Guests: {party.NoOfChildren}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-purple-700">
                  Party Time: {party.PartyTime}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-purple-700">
                  Party Date: {format(new Date(party.PartyDate), 'dd/MM/yyyy')}
                </p>
                <h3 className="mt-1 mb-5 truncate text-xs leading-5 text-purple-700">
                  Party Food Due: {party.PartyFoodTimeDue}
                </h3>
                {Object.entries(calculateFoodOptions(selectedGuests[party.id] || [])).map(([foodOption, count]) => (
                  <p className='mt-1 truncate text-xs leading-5 text-orange-700' key={foodOption}>{`${foodOption}: ${count}`}</p>
                ))}
                {Object.entries(calculateAllergies(selectedGuests[party.id] || [])).map(([allergy, count]) => (
                  <p className='mt-1 truncate text-xs leading-5 text-red-700' key={allergy}>{`Allergies: ${allergy}`}</p>
                ))}
              </div>
            </div>
            <div className="flex flex-col">
              <button
                onClick={() => handleViewOrderClick(party.id)}
                className={classNames(
                  allGuestsSelected(party.id) ? 'bg-purple-500 ' : 'bg-purple-500 animate-pulse',
                  'px-4 py-2 rounded-md text-white mb-2'
                )}
                disabled={!selectedGuests[party.id]}
              >
                View Guests
              </button>
              <button
                onClick={() => handleConfirmClick(party)}
                className={classNames(
                  allGuestsSelected(party.id) ? 'bg-green-500' : 'bg-green-500 hover:bg-blue-700',
                  'px-4 py-2 rounded-md text-white mb-2'
                )}
                disabled={!selectedGuests[party.id]}
              >
                Confirm
              </button>
              <button
                onClick={handleAdultFoodClick}
                className="bg-indigo-500 hover:bg-green-700 px-4 py-2 rounded-md text-white mb-2"
              >
                Adult Party Food
              </button>
            </div>
          </div>
          {fieldsetVisible[party.id] && (
            <fieldset>
              <legend className="sr-only">Party guests</legend>
              <div className="relative rounded-md bg-purple-100 w-full">
                {(selectedGuests[party.id] || []).map((guest, index) => (
                  <div
                    key={guest.ChildName}
                    onClick={() => handleGuestClick(party.id, guest.ChildName)}
                    className={classNames(
                      'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6 w-full shadow-lg',
                      {
                        'bg-green-500': clickedGuests[party.id]?.[guest.ChildName], // Change color based on the click status
                      }
                    )}
                  >
                    <span className="ml-3 w-full font-medium mt-5">
                      <ol>
                        <li>
                          <span
                            className={classNames(
                              'inline-block h-4 w-4 rounded-full mr-2',
                              {
                                'bg-green-500': guest.Arrived === 1, // Check if Arrived is 1
                              }
                            )}
                          ></span>
                          {index + 1}
                        </li>
                        <li className='text-black text-xs'>{guest.ChildName}</li>
                        <li className='text-black text-xs text-bold'>{guest.FoodOption}</li>
                        <li className='text-red-900 text-sm'>Allergies: {guest.Allergies}</li>
                      </ol>
                    </span>
                  </div>
                ))}
              </div>
            </fieldset>
          )}
          {adultFood && (
            <fieldset>
              <legend className="sr-only">Adult Food</legend>
              <div className="relative -space-y-px rounded-md bg-white">
                <li
                  key={party.id}
                  className="relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6"
                >
                  <p>{party.PartyAdultFoodChoices.join(', ')}</p>
                </li>
              </div>
            </fieldset>
          )}
        </div>
      ))}
    </div>
  );
}
