import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


const isElectron = window && window.process && window.process.type;
const ipcRenderer = isElectron ? window.require('electron').ipcRenderer : null;





const Prebooktill = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [amountEntered, setAmountEntered] = useState(0);
  const [isFlashing, setIsFlashing] = useState(false);
  const [changeGiven, setChangeGiven] = useState(0);
  const [input, setInput] = useState("");
  const [cardOptions, setCardOptions] = useState(false);
  const [done, setDone] = useState(false);
  const [discount, setDiscount] = useState(0); // new state for discount percentage

  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;

  const order = state.order;
    const table = state.table;
    const ChildName = state.ChildName;
    const initialTotal = state.total;

    const [total, setTotal] = useState(state ? state.total : 0);


    console.log(state);

    useEffect(() => {
      // Check if ipcRenderer is available before using it
      if (ipcRenderer) {
        ipcRenderer.send('some-electron-event', { data: 'your-data' });
  
        ipcRenderer.on('electron-response', (event, responseData) => {
          console.log('Received response from Electron:', responseData);
        });
      }
    }, []); // Empty dependency array ensures the effect runs once after the initial render
  



  const handleConfirmClick = async () => {
    ipcRenderer.send("entrance");

    console.log("Order confirmed");
    const now = new Date();
const timeString = now.toISOString().split('T')[1];

    // Query the database to update the TotalSpent field
    // const session = await DataStore.query(Sessions);
    // const filter = session.filter(
    //   (session) =>
    //     session.Table === table &&
    //     session.Name === ChildName &&
    //     session.Arrived === true &&
    //     session.LeftCenter === false
    // );

    // // Update the TotalSpent field
    // console.log(filter);
    // const updateSession = await DataStore.save(
    //   Sessions.copyOf(filter[0], (updated) => {
       
    //       updated.TotalSpent = total;
    
    //   })
    // );
    // console.log(updateSession);

    // const message = new Messages({
    //   content: `A New Customer called ${ChildName} has Arrived sitting at table ${table} at ${timeString}`,
    //   createdAt: timeString,
    //   email: 'Front Desk',
    //   group: ['Developer',
    //   'Staff',
    //   'PartyHosts',
    //   'Admin',
    //   'SuperUser',
    //   'TeamLeader',
    //   'Kitchen',
    //   'FrontDesk',
    //   'Cafe',],

    //   orderID: null,
    //   sessionID: filter.id,
      
    // });
  
    // // Save the message to the database
    // const savedMessage = await DataStore.save(message);
    // console.log(savedMessage);

    setDone(true);
    setTotal(0);
    navigate("/till");
  };
  const handleCashClick = async () => {
    setPaymentMethod("cash");
    setAmountEntered(0);
    setChangeGiven(0);
  
    // Check if running in Electron
    if (window && window.process && window.process.type) {
      // We're in Electron, send the IPC event to open the cash drawer
      const { ipcRenderer } = window.require('electron');
      
      ipcRenderer.send('open-drawer');
  
      ipcRenderer.on('electron-response', (event, responseData) => {
        console.log('Received response from Electron:', responseData);
      });
    } else {
      // We're in a browser, make an HTTP request to open the cafe drawer
      console.log('Running in browser, making HTTP request to open cafe drawer');
      
      try {
        const response = await fetch('https://cheekydino.com/api/frontdrawer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}), // Adjust body if necessary
        });
  
        if (!response.ok) {
          throw new Error('Failed to open cafe drawer');
        }
  
        const data = await response.json();
        console.log('Cafe Drawer:', data);
      } catch (error) {
        console.error('Error opening cafe drawer:', error);
      }
    }
  };

  const handleDenominationClick = (amount) => {
    // Calculate the new amount entered by adding the denomination
    const updatedAmount = parseFloat(amountEntered || 0) + amount;
    setAmountEntered(updatedAmount);
    // Calculate the change
    const newChange = updatedAmount - total;
    setChangeGiven(newChange > 0 ? newChange : 0);

    if (ipcRenderer) {
      ipcRenderer.send('open-drawer', { amount });
    } else {
      console.error('ipcRenderer is not available.');
    }
  };

  const handleDecimalClick = () => {
    if (!input.includes(".")) {
      setInput(input + ".");
    }
  };

  const handleNumberClick = (number) => {
    const updatedInput = input + number.toString();
    setInput(updatedInput);
    const updatedAmount = parseFloat(updatedInput);
    setAmountEntered(updatedAmount);
    const newChange = updatedAmount - total;
    setChangeGiven(newChange > 0 ? newChange : 0);
    if (ipcRenderer) {
      ipcRenderer.send('open-drawer', { initialTotal });
    } else {
      console.error('ipcRenderer is not available.');
    }
  };

  const handleCardClick = () => {
    setPaymentMethod("card");
    setCardOptions(true);
    setIsFlashing(true);
    setChangeGiven(0);
  };

  const handleDiscountClick = () => {
    // Display input field for password
    const password = prompt("Please enter your password to apply discount:");
    // Verify password
    if (password === "cheekydino") {
      // Set discount state to true
      setDiscount(true);
    } else {
      console.log("Incorrect password. Please try again.");
    }
  };
  const handleDiscountApply = (percentage) => {
    const discountAmount = initialTotal * (percentage / 100);
    setTotal(initialTotal - discountAmount);
    setDiscount(percentage);
  };

  const buttonVariants = {
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.2,
      },
    },
  };

  

  if (done === true) {
    navigate('/dashboard');
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4">
      {/* Buttons and payment options */}
      <div className="lg:border-r lg:border-gray-300 pr-4 mb-5 lg:mb-0 order-last lg:order-none">
        <motion.button
          variants={buttonVariants}
          whileHover="hover"
          onClick={() => window.location.reload()}
          className="bg-red-500 text-white p-2 lg:p-4 rounded w-full mb-2"
        >
          Cancel
        </motion.button>
  
        <div className="flex flex-col gap-2">
          <motion.button
            className={`${
              paymentMethod === "cash" ? "bg-green-700" : "bg-green-500"
            } text-white p-1 md:p-2 rounded`}
            onClick={handleCashClick}
            variants={buttonVariants}
            whileHover="hover"
          >
            Cash
          </motion.button>
          <motion.button
            className={`${
              paymentMethod === "card" ? "bg-blue-700" : "bg-blue-500"
            } text-white p-1 md:p-2 rounded`}
            onClick={handleCardClick}
            variants={buttonVariants}
            whileHover="hover"
          >
            Card
          </motion.button>
          <motion.button
            className="bg-blue-500 text-white p-1 md:p-2 rounded"
            onClick={handleDiscountClick}
            variants={buttonVariants}
            whileHover="hover"
          >
            Apply Discount
          </motion.button>
  
          {discount && (
            <div className="flex flex-col gap-2 mt-4">
              <motion.button
                className="bg-yellow-200 p-1 md:p-2 rounded"
                onClick={() => handleDiscountApply(10)}
                variants={buttonVariants}
                whileHover="hover"
              >
                10% Discount
              </motion.button>
              <motion.button
                className="bg-yellow-300 p-1 md:p-2 rounded"
                onClick={() => handleDiscountApply(20)}
                variants={buttonVariants}
                whileHover="hover"
              >
                20% Discount
              </motion.button>
              <motion.button
                className="bg-yellow-400 p-1 md:p-2 rounded"
                onClick={() => handleDiscountApply(50)}
                variants={buttonVariants}
                whileHover="hover"
              >
                50% Discount
              </motion.button>
            </div>
          )}
  
          {/* Cash Payment Options */}
          {paymentMethod === "cash" && (
            <div className="flex flex-col gap-2 mt-4">
              <motion.button
                className="bg-cyan-200 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(5)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £5
              </motion.button>
              <motion.button
                className="bg-cyan-300 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(10)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £10
              </motion.button>
              <motion.button
                className="bg-cyan-400 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(20)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £20
              </motion.button>
              <motion.button
                className="bg-cyan-500 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(50)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £50
              </motion.button>
            </div>
          )}
  
          {/* Card Payment Options */}
          {paymentMethod === "card" && (
            <div className="flex flex-col gap-2 mt-4">
              <motion.button
                className="bg-blue-200 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(5)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £5
              </motion.button>
              <motion.button
                className="bg-blue-300 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(10)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £10
              </motion.button>
              <motion.button
                className="bg-blue-400 p-1 md:p-2 rounded"
                onClick={() => handleDenominationClick(20)}
                variants={buttonVariants}
                whileHover="hover"
              >
                £20
              </motion.button>
              <motion.button
                className="bg-blue-500 p-1 md:p-2 rounded"
                onClick={() => handleCardClick()}
                variants={buttonVariants}
                whileHover="hover"
              >
                Full Amount
              </motion.button>
            </div>
          )}
        </div>
      </div>
  
      {/* Number Pad */}
      <div className="grid grid-cols-3 gap-2 border-r lg:border-gray-300 pr-4">
        {[...Array(9)].map((_, i) => (
          <motion.button
            key={i + 1}
            className="bg-purple-200 p-1 md:p-2 rounded"
            onClick={() => handleNumberClick(i + 1)}
            variants={buttonVariants}
            whileHover="hover"
          >
            {i + 1}
          </motion.button>
        ))}
        <motion.button
          className="bg-blue-200 p-1 md:p-2 rounded"
          onClick={() => handleNumberClick(0)}
          variants={buttonVariants}
          whileHover="hover"
        >
          0
        </motion.button>
        <motion.button
          className="bg-yellow-200 p-1 md:p-2 rounded"
          onClick={handleDecimalClick}
          variants={buttonVariants}
          whileHover="hover"
        >
          .
        </motion.button>
      </div>
  
      {/* Order Details, Confirm and Print (at the top for small screens) */}
      <div className="order-first lg:order-none">
        <ul className="mb-4">
          <li className="font-bold">Table: {table}</li>
          <li className="font-bold">Child: {ChildName}</li>
          <li className="font-bold">Total: £{total.toFixed(2)}</li>
          <li className="font-bold">Discount: {discount}%</li>
          <li className="font-bold">Change Given: £{changeGiven.toFixed(2)}</li>
          <li className="font-bold">Amount Entered: £{amountEntered.toFixed(2)}</li>
        </ul>
  
        <input
          type="text"
          placeholder="Enter Amount"
          value={input}
          onChange={(e) => {
            const newInput = e.target.value.replace(/[^0-9.]/g, "");
            setInput(newInput);
            const newChange = parseFloat(newInput) - total;
            setChangeGiven(newChange > 0 ? newChange : 0);
          }}
          className="bg-purple-200 p-1 md:p-2 rounded border border-gray-300 w-full"
        />
  
        <div className="flex flex-col gap-2 mt-4">
          <motion.button
            onClick={handleConfirmClick}
            className="bg-green-500 text-white p-1 md:p-2 rounded w-full"
            variants={buttonVariants}
            whileHover="hover"
          >
            Confirm
          </motion.button>
  
          <motion.button
            onClick={() => {
              const data = {
                product: order.map((item) => item.Name),
                name: ChildName,
                method: paymentMethod,
                table: table,
                change: changeGiven.toFixed(2),
                price: total.toFixed(2),
              };
              // Printing logic
              if (window && window.process && window.process.type) {
                const { ipcRenderer } = window.require('electron');
                ipcRenderer.send('print-receipt', { data });
              } else {
                fetch('https://cheekydino.com/api/frontprinter', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data),
                })
                  .then((response) => response.json())
                  .then((result) => console.log('Success:', result))
                  .catch((error) => console.error('Error:', error));
              }
            }}
            className="bg-purple-500 text-white p-1 md:p-2 rounded w-full mt-2"
            variants={buttonVariants}
            whileHover="hover"
          >
            Print
          </motion.button>
        </div>
      </div>
    </div>
  );
  
};


export default Prebooktill;
