import { useState, useEffect } from "react";
import { format } from 'date-fns';

export default function EstFoodTime() {
  const [estimatedTime, setEstimatedTime] = useState(0);

  const fetchCafeOrders = async () => {
    const today = format(new Date(), 'yyyy-MM-dd');

    try {
      const response = await fetch('https://cheekydino.com/api/cafeorders', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      console.log("Cafe orders", data);

      const dataset = data.data;

      const filteredOrders = dataset.filter(order => 
        order.Delivered === 0 &&
        order.Completed === 1 
      );

      console.log("Filtered orders", filteredOrders.length);

      let time = 10;

      if (filteredOrders.length === 0) {
        time = 10;
        console.log("Time is 10 minutes");
      } else if (filteredOrders.length >= 1 && filteredOrders.length <= 2) {
        time = 15;
        console.log("Time is 15 minutes");
      } else if (filteredOrders.length >= 3 && filteredOrders.length <= 4) {
        time = 20;
        console.log("Time is 20 minutes");
      } else if (filteredOrders.length >= 5 && filteredOrders.length <= 6) {
        time = 25;
        console.log("Time is 25 minutes");
      } else if (filteredOrders.length > 6) {
        time = 30 + (filteredOrders.length - 6) * 5;
        console.log("Time is greater than 30 minutes");
      }

      console.log("Estimated time", time);

      setEstimatedTime(time);
    } catch (error) {
      console.error("Error fetching cafe orders", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchCafeOrders, 10000);
    fetchCafeOrders(); // Initial fetch

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  function getCircleColor(time) {
    if (time > 20) {
      return "red"; // Red color for over 20 mins
    } else if (time >= 10 && time <= 20) {
      return "yellow"; // Yellow color for 10-20 mins
    } else {
      return "green"; // Green color for 5-10 mins
    }
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className="w-12 h-12 flex items-center justify-center rounded-full text-black"
        style={{ backgroundColor: getCircleColor(estimatedTime) }}
      >
        {estimatedTime} mins
      </div>
    </div>
  );
}
