
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import '../src/customer-pages/customerfont.css'
import { Link } from 'react-router-dom'




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src={`${process.env.PUBLIC_URL}/dino-logo.png`}

                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                   src={`${process.env.PUBLIC_URL}/dino-logo.png`}

                    alt="Your Company"
                  />
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <Link
                    to="/sessionlogin"
                    className="inline-flex items-center border-b-2 component-title border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    Book a Session
                  </Link>
                  <Link
                    to="/packages"
                    className="inline-flex items-center border-b-2 component-title border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Party Packages
                  </Link>
                  <Link
                    to="/privatehire"
                    className="inline-flex items-center border-b-2 border-transparent component-title px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Private Hire
                  </Link>
                  
                  
                  <Link
                    to="/login"
                    className="inline-flex items-center border-b-2 border-transparent component-title px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Log In/Register
                  </Link>
                </div>
                
              </div>
             
               
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <button
                  type="button"
                  className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="versa.gif"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
  <div className="space-y-1 pb-3 pt-2">
  <Link
                    to="/sessionlogin"
                    className="inline-flex items-center border-b-2 component-title border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    Book a Session
                  </Link>
                  <Link
                    to="/packages"
                    className="inline-flex items-center border-b-2 component-title border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Party Packages
                  </Link>
                  <Link
                    to="/privatehire"
                    className="inline-flex items-center border-b-2 border-transparent component-title px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Private Hire
                  </Link>
                  <Link
                    to="/events"
                    className="inline-flex items-center border-b-2 border-transparent component-title px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Events
                  </Link>
                  <Link
                    to="/jobs"
                    className="inline-flex items-center border-b-2 border-transparent component-title px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Careers
                  </Link>
                  <Link
                    to="/login"
                    className="inline-flex items-center border-b-2 border-transparent component-title px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Log In
                  </Link>
  </div>
</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
