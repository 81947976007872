import { useState, useEffect } from "react";

export default function DailyReport() {
  const [date, setDate] = useState('');
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    const fetchReport = async () => {
      if (date) { // Only fetch if a date is selected
        try {
          const res = await fetch(`https://cheekydino.com/api/dailyreports/${date}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!res.ok) {
            throw new Error('Failed to fetch report');
          }

          const data = await res.json();
          console.log('Report data:', data);
          setReportData(data.data || []);
        } catch (error) {
          console.error('Error fetching report:', error);
          setReportData([]); // Clear report data on error
        }
      }
    };

    fetchReport();
  }, [date]);

  return (
    <div className="p-4">
      <div>
        <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
          Date
        </label>
        <div className="mt-2">
          <input
            onChange={(e) => setDate(e.target.value)}
            value={date}
            id="date"
            name="date"
            type="date"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="mt-4">
        <h1 className="text-2xl font-bold text-center">Daily Report</h1>
        <p className="text-center">Date: {date}</p>
        
        <p className="text-center text-blue-700">
          {reportData && reportData.length > 0 ? reportData[0].Content : 'No report available for this date.'}
        </p>
      </div>
      
    </div>
  );
}
