import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Example() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [telephone, setTelephone] = useState('');


    const navigate = useNavigate();





  





    const handleSubmit = async (event) => {
      event.preventDefault();
    
      const usergroup = 'customer';
      const LastLogins = new Date().toISOString().slice(0, 19).replace('T', ' ');
    
      const generateRandomCode = () => {
        const min = 100000; // minimum 6-digit number
        const max = 999999; // maximum 6-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
      };
    
      const randomCode = generateRandomCode().toString(); // Convert to string
    
      try {
        const response = await fetch('https://cheekydino.com/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Email: email,
            Password: password,
            Name: name,
            Telephone: telephone,
            Usergroup: usergroup,
            LastLogins,
            LoggedIn: 1,
            RandomCode: randomCode, // Include the random code in your request
          }),
        });
    
        // Check if the response is successful and log it
        if (!response.ok) {
          throw new Error('Failed to register user');
        }
    
        const responseData = await response.json(); // Ensure to parse the response
    
        console.log(responseData); // Log the response for debugging
    
        if (responseData.success) {
          navigate('/auth', { state: { email: email } });
          window.location.reload();
        } else {
          console.error('Registration failed:', responseData.message);
        }
    
      } catch (error) {
        console.error('Failed to register', error);
      }
    };
    

        
        
        
        
    
    




    return (
      <>
       
        <div className="flex min-h-full flex-1">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-10 w-auto"
                  src="versa.gif"
                  alt="Your Company"
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                 Add new Login
                </h2>
               
              </div>
  
              <div className="mt-10">
                <div>
                  <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                        onChange={(event) => setName(event.target.value)}
                          id="name"
                          name="name"
                          type="text"
                          autoComplete="name"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                        onChange={(event) => setEmail(event.target.value)}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
  
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                        onChange={(event) => setPassword(event.target.value)}
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
Telephone                      </label>
                      <div className="mt-2">
                        <input
                        onChange={(event) => setTelephone(event.target.value)}
                          id="telephone"
                          name="telephone"
                          type="text"
                          autoComplete="telephone"
                          
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
  
                    
  
                      
  
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
  
               
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-contain"
              src="dino-logo.png"
              alt=""
            />
          </div>
        </div>
      </>
    )
  }
  