import React from 'react';

export default function LoadingSpinner() {
  return (
    <div className="relative flex items-center justify-center bg-black border border-purple-500 shadow-lg p-4 rounded-md h-full w-full">
      <img
        src={`${process.env.PUBLIC_URL}/kitchen.gif`}
        alt="loading"
        className="absolute inset-0 w-full h-full object-contain" // Full cover styles
      />
    </div>
  );
}
