import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import SessionHistory from './sessionhistory';
import FutureOnlineBookings from './futureonlinebookings';
import ClosingTime from './closingtime';
import TableSlots from './occupiedtables';

export default function StatsComponent() {
  const [currentTablesStat, setSessionStat] = useState(0);
  const [cleanTablesStat, setCleanTablesStat] = useState(0);
  const [ordersModal, setOrdersModal] = useState(false);
  const [futureBookings, setFutureBookings] = useState(false);
  const [later, setLater] = useState(0);


    const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionStat = async () => {
        try {

        // Fetch today's session bookings
        const today = format(new Date(), 'yyyy-MM-dd');
        const response2 = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response2.ok) {
          throw new Error('Failed to fetch session bookings for today');
        }

        const sessionsData = await response2.json();
        const sessions = sessionsData.data;

        console.log(sessions);

        // Filter today's bookings
        const todaysBookings = sessions.filter(
          (session) => session.Arrived === 1 && session.LeftCenter === 1 && session.CleanTable === 1 && session.TableCleaned === 0
        );
        const currentTables = sessions.filter(
          (session) => session.Arrived === 1 && session.LeftCenter === 0
        );

        const currentTime = format(new Date(), 'HH:mm:ss');
        const futureBookings = sessions.filter(session => session.TimeslotFrom > currentTime && session.Date === today && session.Arrived === 0);
        const futureSessionsCount = futureBookings.length;

        setLater(futureSessionsCount);

        setSessionStat(currentTables.length);

        setCleanTablesStat(todaysBookings.length);

      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    fetchSessionStat();


    // Fetch data every second
    const interval = setInterval(() => {
      fetchSessionStat();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const stats = [
    { name: 'Current Tables', value: currentTablesStat },
    { name: 'Clean Tables', value: cleanTablesStat },
    { name: 'Upcoming Bookings', value: later },
  ];

  return (
    <>
              <ClosingTime />


    {ordersModal && (
      <>
      <button onClick={() => setOrdersModal(false)}
        type="button"
        className="rounded bg-red-600 px-2 py-1 text-m font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Close
      </button>


      <SessionHistory />
      </>

    )}
    {futureBookings && (
      <>
      <button onClick={() => setFutureBookings(false)}
        type="button"
        className="rounded mb-10 bg-red-600 px-2 py-1 text-m font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Close
      </button>
      <FutureOnlineBookings />
      </>
    )
      
    
    }
    <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-3 lg:grid-cols-3">
    
      {stats.map((stat) => (
        <div key={stat.name} className="bg-white px-4 py-6 sm:px-6 lg:px-8">
          <p className="text-sm font-medium leading-6 text-blue-700">{stat.name}</p>
          <p className="mt-2 flex items-baseline gap-x-2 bg-white">
            <span className="text-4xl font-semibold tracking-tight text-blue-700">{stat.value}</span>
          </p>
        </div>
      ))}
    </div>
    <div className="min-w-0 flex-1">
      <div className="border-b border-gray-200 bg-green-50 px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          
        </div>
      </div>
      <TableSlots />

      </div>
      </div>
    <div className="flex justify-center mt-4">
    <button onClick={() => navigate('/reservations')}
        type="button"
        className="rounded-md bg-purple-600 w-1/3 mr-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Make a Booking
      </button>
      <button onClick={() => setFutureBookings(true)}
        type="button"
        className="rounded-md animate-pulse bg-indigo-600 w-1/3 mr-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Future Bookings 
      </button>
      <button onClick={() => setOrdersModal(true)}
        type="button"
        className="rounded-md bg-green-600 w-1/3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        View Orders
      </button>
      
      </div>

      
    </>
  );
}
