import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, addHours, parse } from 'date-fns';
import axios from 'axios';

export default function NewComponent({ date, timeSlot, partyType }) {
  const [numberOfChildren, setNumberOfChildren] = useState(1);
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [character, setCharacter] = useState('Elsa');
  const [foodChoice, setFoodChoice] = useState('No Food');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const loginIdResponse = await axios.get('https://cheekydino.com/api/getloginid', {
        params: { email }
      });

      const login_id = loginIdResponse.data.length > 0 ? loginIdResponse.data[0].login_id : null;

      const selectedTimeSlotFood = addHours(parse(timeSlot, 'HH:mm', new Date()), 1.5);
      const selectedTimeSlotWithOffset = addHours(parse(timeSlot, 'HH:mm', new Date()), 2.5);

      let basePrice;
      let childPrice;
      if (partyType === 'Laser') {
        basePrice = 190;
        childPrice = 19.95;
      } else if (partyType === 'T-Rex') {
        basePrice = 145;
        childPrice = 14.50;
      } else if (partyType === 'Character') {
        basePrice = 290;
        childPrice = 19.95;
      } else if (partyType === 'Teddy') {
        basePrice = 215;
        childPrice = 19.95;
      } else if (partyType === 'Football' || partyType === 'Disco') {
        basePrice = 290;
        childPrice = 19.95;
      }

      let totalPrice = basePrice;
      if (numberOfChildren > 10) {
        totalPrice += (numberOfChildren - 10) * childPrice;
      }

      const ageNumber = parseInt(age);
      const children = parseInt(numberOfChildren);

      const bookingData = {
        email,
        telephone,
        childName: name,
        childAge: ageNumber,
        noOfChildren: children,
        foodOptionSelected: foodChoice,
        selectedDate: date,
        selectedTimeSlot: timeSlot,
        partyType,
        character: partyType === 'Character' ? character : null,
        totalPrice,
        partybookingID: null, // You can generate a unique ID or let the backend handle it
        partyFoodComplete: false, // Default value
        partyFinish: format(selectedTimeSlotWithOffset, 'HH:mm'),
        partyFoodTimeDue: format(selectedTimeSlotFood, 'HH:mm'),
        table: null, // You can set the table number or let the backend handle it
        login_id, // Use the login_id retrieved from the API
        bookingreference: null // You can generate a unique reference or let the backend handle it
      };

      const response = await axios.post('https://cheekydino.com/api/addpartybooking', bookingData);
      if (response.data.success) {
        window.location.reload();
        navigate('/partyhistory');
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Failed to add party booking:', error);
    }
  };

  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8 border-2 ">
      <button onClick={() => navigate('/dashboard')} className="text-blue-600 hover:text-blue-700">
        Back to Dashboard
      </button>

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Booking Details</h2>
        <p className="mt-4 text-gray-600">
          Date: {date} - Time Slot: {timeSlot} - Party Type: {partyType}
        </p>
        <form onSubmit={handleSubmit} className="mt-8">
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
              <label htmlFor="number-of-children" className="block text-sm font-medium text-gray-700">
                Number of Children
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  id="number-of-children"
                  name="number-of-children"
                  value={numberOfChildren}
                  onChange={(event) => setNumberOfChildren(event.target.value)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">
                Telephone
              </label>
              <div className="mt-1">
                <input
                  type="tel"
                  id="telephone"
                  name="telephone"
                  value={telephone}
                  onChange={(event) => setTelephone(event.target.value)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                Age
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  id="age"
                  name="age"
                  value={age}
                  onChange={(event) => setAge(event.target.value)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label htmlFor="foodChoice" className="block text-sm font-medium text-gray-700">
                Food Choice
              </label>
              <div className="mt-1">
                <select
                  id="foodChoice"
                  name="foodChoice"
                  value={foodChoice}
                  onChange={(event) => setFoodChoice(event.target.value)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option>No Food</option>
                  <option>Hot Food</option>
                  <option>Cold Food</option>
                  <option>Pizza Buffet</option>
                </select>
              </div>
            </div>
            {partyType === 'Character' && (
              <div>
                <label htmlFor="character" className="block text-sm font-medium text-gray-700">
                  Character
                </label>
                <div className="mt-1">
                  <select
                    id="character"
                    name="character"
                    value={character}
                    onChange={(event) => setCharacter(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>Elsa</option>
                    <option>Spiderman</option>
                    <option>Batman</option>
                    <option>Belle</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
