import React, { useState, useEffect } from 'react';

import { isToday, format, differenceInMinutes, parse } from 'date-fns';

import './progress.css'

function OccupiedTables() {
  const [sessions, setSessions] = useState([]);
  


  




  useEffect(() => {
    async function getTodaysBookings() {
        try {
            const today = format(new Date(), 'yyyy-MM-dd');
        
            const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                }
        
      
          const data = await response.json();
          console.log(data);
          const todaysSessions = data.data;

          // Filter out sessions where Arrived is 1 and LeftCentre is 0

            const sessions = todaysSessions.filter(session => session.Arrived === 1 && session.LeftCenter === 0);



          setSessions(sessions);
      
        } catch (error) {
          console.error('There was an error!', error.message); // Use error.message for more specific info
        }
      }
      

    getTodaysBookings();

    // set a timer to call the getTodaysBookings function every 1 seconds

    const interval = setInterval(() => {
        getTodaysBookings();
    }
    , 1000);

    return () => clearInterval(interval);


  }, []);

  



  const tableInfo = sessions.map((table) => {
    const timeslotFromDate = parse(table.TimeslotFrom, 'HH:mm:ss', new Date());
    const timeslotToDate = parse(table.TimeslotTo, 'HH:mm:ss', new Date());
    const timeArrived = parse(table.TimeArrived, 'HH:mm:ss', new Date());
    const timeRemaining = differenceInMinutes(timeslotToDate, new Date());
    let backgroundColor;
    if (timeRemaining > 60) {
      backgroundColor = 'bg-green-500';
    } else if (timeRemaining > 30) {
      backgroundColor = 'bg-gradient-to-r from-green-500 via-yellow-500 to-red-500';
    } else if (timeRemaining > 10) {
      backgroundColor = 'bg-gradient-to-r from-yellow-500 to-red-500';
    } else {
      backgroundColor = 'bg-red-500';
    }
    const shouldFlashGold = timeRemaining <= 10;
    return {
      number: table.Table,
      name: table.Name,
      guests: table.Adults + table.Children,
      orders: table.Orders,
      totalSpent: table.TotalSpent,
      timeslot: `${format(timeslotFromDate, 'HH:mm')} - ${format(
        timeslotToDate,
        'HH:mm'
      )}`,
      timeRemaining,
      backgroundColor,
      shouldFlashGold,
    };
  });

  
  


  const sortedTableInfo = [...tableInfo].sort((a, b) => {
    // Convert timeArrived strings to Date objects for comparison
    const dateA = new Date(`1970-01-01T${a.timeArrived}:00`);
    const dateB = new Date(`1970-01-01T${b.timeArrived}:00`);
    
    // Compare the Date objects
    return dateA - dateB;
  });

  return (
    <ul>
      {sortedTableInfo.map((table) => (
        <li
          key={table.number}
          className={`p-4 rounded-lg shadow-md mt-5 ${table.backgroundColor} ${
            table.shouldFlashGold ? 'animate-pulse ' : ''
          }`}
        >
          <div className="flex flex-col sm:flex-row items-center">
            <div className="flex-shrink-0 mb-4 sm:mb-0">
              <div className="h-12 w-12 rounded-full bg-white flex items-center justify-center">
                <span className="text-lg font-bold">{table.number}</span>
              </div>
            </div>
            <div className="ml-0 sm:ml-4">
              <p className="text-lg font-semibold text-white">
                Table {table.number}
              </p>
              <h3 className="text-lg font-medium text-white">
                Booked for {table.timeslot}
              </h3>
             
              <h3 className="text-lg font-medium text-white">
                Time Remaining: {table.timeRemaining} minutes
              </h3>
            </div>
           
              
          </div>
        </li>
      ))}
    </ul>
  );
  
}

export default OccupiedTables;
