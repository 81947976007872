import { useState, useEffect, useRef } from 'react';
import { isToday, format, differenceInMinutes, parse, parseISO } from 'date-fns';
import './progress.css'
import { useNavigate } from 'react-router-dom';
import { ArrowDownIcon, CogIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { get, set } from 'lodash';
import FutureOnlineBookings from './futureonlinebookings';
import TablesCleaned from './tablescleaned';
import TableLayout from './tablelayout';
import TableStats from './tablestats';
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Dialog } from '@headlessui/react'
import EditBookings from './editBookings';
import TableSlots from './occupiedtables';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')

}


// check if the app is running in electron

// check if the app is running in electron

let ipcRenderer = null;

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

if (ipcRenderer) {
  console.log('Running in electron');
} else {
  console.log('Running in browser');
}



// Define the function outside of useEffect






function OccupiedTables() {
  const [sessions, setSessions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState({});
  const [moveTable, setMoveTable] = useState([]);
  const [moveState, setMoveState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [till, setTill] = useState(null);
  const [moreGuests, setMoreGuests] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [ordersTable, setOrdersTable] = useState([]);
  const [reload, setReload] = useState(false);
  const [mistake, setMistake] = useState([]);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(true)
  const [duplicateTables, setDuplicateTables] = useState(null);


  const cancelButtonRef = useRef(null)


  // use effect to get mainWindow.webContents.send to call the getTodaysBookings function

  

  let ipcRenderer = null;
  if (window.require) {
    ipcRenderer = window.require('electron').ipcRenderer;
  }
  




  const navigate = useNavigate();

  useEffect(() => {
    if (moveState) {
      const state = { moveTable };
      navigate('/movetable', { state });
    }
  }, [moveState, moveTable, navigate]);
  console.log('moveTable:', moveTable);


  console.log('sessions:', till);
 
// Define the function outside of useEffect
useEffect(() => {
  const getTodaysBookings = async () => {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');

      const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const sessionsData = await response.json();
      const sessions = sessionsData.data;

      // Filter the sessions array to only include sessions for today
      const todaysBookings = sessions.filter(
        (session) => session.Arrived === 1 && session.LeftCenter === 0
      );

      const mistakeBookings = sessions.filter(
        (session) => session.Arrived === 1 && session.LeftCenter === 1
      );

      setMistake(mistakeBookings);

      // Checking for duplicate tables
      const tableNumbers = todaysBookings.map((table) => table.Table);

      // Create a count map to track occurrences of each table number
      const tableCountMap = tableNumbers.reduce((acc, table) => {
        acc[table] = (acc[table] || 0) + 1;
        return acc;
      }, {});

      // Get the duplicated table numbers by filtering those that appear more than once
      const duplicatedTables = Object.keys(tableCountMap).filter(
        (table) => tableCountMap[table] > 1
      );

      // If there are duplicated tables, set the state to the duplicated table numbers
      if (duplicatedTables.length > 0) {
        console.log('Duplicated tables:', duplicatedTables);
        setDuplicateTables(duplicatedTables); // Store duplicated table numbers
      } else {
        console.log('No duplicate tables');
        setDuplicateTables(null); // Set to null if no duplicates
      }

      // set in state the tables that need to leave the center, get the current time and format to HH:mm:ss and then get to timeslot to and if the current time is greater than the timeslot to then the table needs to leave the center


      // Update sessions state
      setSessions(todaysBookings);
      console.log('todaysBookings:', todaysBookings);
    } catch (error) {
      console.error("Error getting today's bookings:", error);
    }
  };

  getTodaysBookings();

  const intervalId = setInterval(() => {
    getTodaysBookings();
  }, 5000);

  // Cleanup function to avoid memory leaks from setInterval
  return () => clearInterval(intervalId);

}, [reload]);






  // Filter the sessions array to only include occupied tables


  const tableInfo = sessions.map((table) => {
    // Parse the TimeslotFrom, TimeslotTo, and TimeArrived values into Date objects
    const timeslotFromDate = parse(table.TimeslotFrom, 'HH:mm:ss', new Date());
    const timeslotToDate = parse(table.TimeslotTo, 'HH:mm:ss', new Date());
    const timeArrived = parse(table.TimeArrived, 'HH:mm:ss', new Date());
  
    // Calculate the time remaining in minutes
    const timeRemaining = differenceInMinutes(timeslotToDate, new Date());
  
    // Determine the background color based on the time remaining
    let backgroundColor;
    if (timeRemaining > 60) {
      backgroundColor = 'bg-green-500';
    } else if (timeRemaining > 30) {
      backgroundColor =
        'bg-gradient-to-r from-green-500 via-yellow-500 to-red-500';
    } else if (timeRemaining > 10) {
      backgroundColor = 'bg-gradient-to-r from-yellow-500 to-red-500';
    } else {
      backgroundColor = 'bg-red-500';
    }
  
    // Determine if the table should flash gold
    const shouldFlashGold = timeRemaining <= 10;
  
    return {
      id: table.id,
      number: table.Table,
      name: table.Name,
      guests: table.Adults + table.Children,
      orders: table.Orders,
      totalSpent: table.TotalSpent,
      sessionSpend: table.EntranceFee,
      TimeslotFrom: format(timeslotFromDate, 'HH:mm:ss'), // Format back to HH:mm:ss
      TimeSlotTo: format(timeslotToDate, 'HH:mm:ss'), // Format back to HH:mm:ss
      timeslot: `${format(timeslotFromDate, 'HH:mm')} - ${format(timeslotToDate, 'HH:mm')}`,
      timeRemaining,
      backgroundColor,
      shouldFlashGold,
    };
  });
  


  


  async function handleLeftCenter(event, table) {
    event.preventDefault();
  
    setLoading(true);
    console.log('table:', table);
  
    try {
      // Post to the API to update the session booking record
      const response = await fetch(`https://cheekydino.com/api/leftcenter/${table.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          LeftCenter: 1,
          TimeLeft: format(new Date(), 'HH:mm:ss'),
          CleanTable: 1,
          TableCleaned: 0,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update session booking');
      }
  
      // Post a new message to the Messages API
      const messageResponse = await fetch('https://cheekydino.com/api/postmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: `Table ${table.number} has left the center, please clean the table.`,
          createdAt: format(new Date(), 'HH:mm:ss.SSS'),
          email: 'Front Desk',
          group: 'Admin',
          sessionID: table.id,
        }),
      });
  
      if (!messageResponse.ok) {
        throw new Error('Failed to post message');
      }
  
    } catch (error) {
      console.error('Error updating record:', error);
    } finally {
      // Set a timer to setLoading(false) after 3 seconds and reload the page
      
        setLoading(false);
        setReload(true);
    }
  }
  
  







    // get current time and format to 


    const handleSelection = async (selectedOption) => {
      try {
        setSelected(selectedOption); // Update selected state
        // Fetch the record from AWS Datastore based on selectedOption.id and perform necessary operations
        const update = await fetch(`https://cheekydino.com/api/mistake/${selectedOption.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({
                LeftCenter: 0,
                TimeLeft: format(new Date(), 'HH:mm:ss'),
                CleanTable: 0,
                TableCleaned: 0,


            }),
        });


      } catch (error) {
        console.error('Error updating record:', error);
      }
    };
  


  



  


console.log('tableInfo:', moreGuests );


const sortedTableInfo = [...tableInfo].sort((a, b) => {
  // Convert `TimeslotFrom` strings (hh:mm:ss) to Date objects for comparison
  const dateA = new Date(`1970-01-01T${a.TimeslotFrom}`);
  const dateB = new Date(`1970-01-01T${b.TimeslotFrom}`);
  
  // Compare the Date objects by subtracting to get the correct order
  return dateA - dateB;
});





  return (
    <div className= "mt-5">

     

    <div className="bg-white">
      <div className="mx-auto max-w-7xl">
        

       <TableStats />
      

        <div className="mt-5 mb-5">
          
        <TableLayout />
        {moreGuests && (
          <EditBookings session= {till.id} />
        )}
        </div>
      </div>
    </div>
    
   
    <div className="min-w-0 flex-1">
      <div className="border-b border-gray-200 bg-purple-50 px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Cleaning</h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          
        </div>
      </div>
      </div>
      </div>
      <div className="mt-5 mb-5">
      <TablesCleaned /> 
      </div>
      
     

      <div className="md:flex md:items-center md:justify-between mb-5">
      
      
      <div className="min-w-0 flex-1">
      <div className="border-b border-gray-200 bg-blue-50 px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold text-center leading-6 text-gray-900">Live Sessions</h3>
          <p className="mt-1 text-sm text-center text-black"> {sessions.length} Tables Occupied</p>
        </div>
        

      </div>
      </div>
        <Listbox value={selected} onChange={handleSelection}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 mt-5 text-gray-900">Bring Back</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">Table: {selected.Table}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {mistake.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person.Table}- {person.Name}- {person.TimeLeft}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    <div className="mt-5">
    <div className="ml-4 mt-2 flex-shrink-0">
  {duplicateTables ? (
    <div className="flex items-center space-x-2">
      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      <span className="text-xl font-medium text-red-500 animate-pulse">
        Table: {duplicateTables.join(', ')} is a duplicate please move guests to a new table
      </span>
    </div>
  ) : null}
</div>
        
      
      </div>
      </div>

     

     
    </div>
    <div className="flex-1 overflow-y-auto">
          <div className="flex">
            <div className="w-full p-4">
              <ul className="mt-5 mb-5">
                {sortedTableInfo.map((table) => (
                  <li
                    key={table.number}
                    className={`p-4 rounded-lg mt-5 shadow-md ${table.backgroundColor} ${
                      table.shouldFlashGold ? "animate-pulse" : ""
                    }`}
                  >
                    <div className="flex flex-col sm:flex-row items-center">
                      <div className="flex-shrink-0 mb-4 sm:mb-0">
                        <div className="h-12 w-12 rounded-full bg-white flex items-center justify-center">
                          <span className="text-lg font-bold">{table.number}</span>
                        </div>
                      </div>
                      <div className="ml-0 sm:ml-4">
                        <p className="text-lg font-semibold text-white">
                          Table {table.number}
                        </p>
                        <p className="text-sm font-medium text-white">Name: {table.name}</p>
                        <p className="text-sm font-medium text-white">Guests: {table.guests}</p>
                        <p className="text-sm font-medium text-white">Orders: {table.orders}</p>
                        <p className="text-sm font-medium text-white">Entrance Fee: £{table.sessionSpend}</p>
                        <p className="text-sm font-medium text-white">
                          Time Slot: {table.TimeslotFrom} - {table.TimeSlotTo}
                        </p>
                        <p className="text-sm font-medium text-white">Total Spent: £{table.totalSpent}</p>
                        <p className="text-sm font-medium text-white">Booked for {table.timeslot}</p>
                        <p className="text-sm font-medium text-white">Time Remaining: {table.timeRemaining} minutes</p>
                      </div>
                      {loading && (
                        <div className="ml-auto flex-shrink-0 flex items-center space-x-4 mt-4 sm:mt-0">
                          <CogIcon className="h-20 w-20 text-white animate-spin" aria-hidden="true" />
                        </div>
                      )}
                      <div className="ml-auto flex-shrink-0 flex items-center space-x-4 mt-4 sm:mt-0">
                        <button
                          type="button"
                          onClick={(event) => {
                            console.log('table:', table);
                            handleLeftCenter(event, table);
                          }}
                          className="inline-flex items-center gap-x-2 rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Left
                        </button>
                        <button
                          type="button"
                          onClick={() => setMoveTable(table) || setMoveState(true)}
                          className="inline-flex items-center gap-x-2 rounded-md bg-purple-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Move Table
                        </button>
                        <button
                          type="button"
                          onClick={() => setMoreGuests(true) || setTill(table)} 
                          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Add Guests
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

          
            </div>
            </div>
</div>
  
 
  

  
  );
}
export default OccupiedTables;