import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CustomerNav from '../customer-pages/customerlogin/customernav';
import JsxParser from 'react-jsx-parser';

export default function FrontPagePreview() {
  const [adverts, setAdverts] = useState([]);
  const [orderInputs, setOrderInputs] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/website", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          const fetchedAdverts = data.data;
          setAdverts(fetchedAdverts);
console.log(fetchedAdverts);
          // Initialize order inputs
          const initialInputs = {};
          fetchedAdverts.forEach((advert, index) => {
            initialInputs[advert.id] = index + 1; // Default to 1-based index
          });
          setOrderInputs(initialInputs);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  
  }, []);

  const handleOrderChange = (id, value) => {
    const numValue = Number(value);
    if (!isNaN(numValue) && numValue > 0 && numValue <= adverts.length) {
      setOrderInputs((prev) => {
        // Ensure no duplicate values in orderInputs
        const newOrderInputs = { ...prev, [id]: numValue };
        const values = Object.values(newOrderInputs);
        const uniqueValues = new Set(values);
        
        // Adjust values to ensure all numbers from 1 to length are present
        if (uniqueValues.size !== adverts.length) {
          let adjusted = [];
          for (let i = 1; i <= adverts.length; i++) {
            if (!uniqueValues.has(i)) adjusted.push(i);
          }
          adjusted.forEach((num) => {
            const idx = values.indexOf(num);
            if (idx !== -1) values[idx] = adjusted.shift();
          });
          uniqueValues.clear();
          values.forEach((num) => uniqueValues.add(num));
        }

        return Object.fromEntries(
          Object.keys(newOrderInputs).map((key) => [key, newOrderInputs[key]])
        );
      });
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const sortedAdverts = adverts.slice().sort((a, b) => {
        const orderA = orderInputs[a.id] || 0;
        const orderB = orderInputs[b.id] || 0;
        return orderA - orderB;
      });

      const response = await fetch("https://cheekydino.com/api/website/updateorder", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sortedAdverts.map((advert, index) => ({
          id: advert.id,
          ComponentOrder: orderInputs[advert.id],
        }))),
      });

      if (response.ok) {
        console.log("Order saved successfully");
      } else {
        console.error("Error saving order:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving order:", error);
    } finally {
      setSaving(false);
    }
  };

  // Sort adverts based on orderInputs
  const sortedAdverts = adverts.slice().sort((a, b) => {
    const orderA = orderInputs[a.id] || 0;
    const orderB = orderInputs[b.id] || 0;
    return orderA - orderB;
  });

  const collections = [
    {
      name: "Book a Session",
      href: '/sessionlogin',
      imageSrc: 'whatsapp1.jpg',
      imageAlt: 'Kids Playing',
    },
    {
      name: "Book a Party",
      href: '/packages',
      imageSrc: 'whatsapp2.jpg',
      imageAlt: 'Man wearing a charcoal gray cotton t-shirt.',
    },
    {
      name: 'Private Hire',
      href: '/privatehire',
      imageSrc: 'whatsapp3.jpg',
      imageAlt: 'Person sitting at a wooden desk with paper note organizer, pencil and tablet.',
    },
  ];

  const navigation = {
    main: [
      { name: 'Home', href: '/' },
      { name: 'Book', href: '/sessions' },
      { name: 'Contact', href: '/customercontact' },
    ],
    social: [
      {
        name: 'Facebook',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            {/* Facebook icon SVG */}
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            {/* Instagram icon SVG */}
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            {/* Twitter icon SVG */}
          </svg>
        ),
      },
    ],
  };

  return (
    <>
      <div className="relative bg-white mb-5">
        <div className="flex justify-end p-4">
          <button
            onClick={handleSave}
            disabled={saving}
            className={`px-4 py-2 text-white bg-blue-600 rounded ${saving ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {saving ? 'Saving...' : 'Save Order'}
          </button>
        </div>

        {sortedAdverts.map((advert) => (
          <div key={advert.id} className="relative bg-white mb-10 mt-5 p-4 border border-gray-200 rounded">
            <JsxParser jsx={advert.Code} />
            <div className="mt-2">
              <label htmlFor={`order-${advert.id}`} className="block text-sm font-medium text-gray-700">
                Order:
              </label>
              <input
                id={`order-${advert.id}`}
                type="number"
                value={orderInputs[advert.id] || ''}
                onChange={(e) => handleOrderChange(advert.id, e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        ))}

        <section
          aria-labelledby="collection-heading"
          className="relative mt-8 sm:mt-5"
        >
          <h2 id="collection-heading" className="sr-only">
            Collections
          </h2>
          <div className="mx-auto grid max-w-md grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:grid-cols-3 sm:gap-x-6 sm:gap-y-0 sm:px-6 lg:gap-x-8 lg:px-8">
            {collections.map((collection) => (
              <div
                key={collection.name}
                className="group relative h-96 rounded-lg bg-white shadow-xl sm:aspect-h-5 sm:aspect-w-4 sm:h-auto"
              >
                <div>
                  <div
                    aria-hidden="true"
                    className="absolute inset-0 overflow-hidden rounded-lg"
                  >
                    <div className="absolute inset-0 overflow-hidden group-hover:opacity-75">
                      <img
                        src={collection.imageSrc}
                        alt={collection.imageAlt}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                  </div>
                  <div className="absolute inset-0 flex items-end rounded-lg p-6">
                    <div>
                      <h3 className="mt-1 font-semibold text-white component-title">
                        <Link to={collection.href}>
                          <span className="absolute inset-0 component-title" />
                          {collection.name}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <footer>
          <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
            <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
              {navigation.main.map((item) => (
                <div key={item.name} className="pb-6">
                  <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                    {item.name}
                  </Link>
                </div>
              ))}
            </nav>
            <div className="mt-10 flex justify-center space-x-10">
              {navigation.social.map((item) => (
                <Link key={item.name} to={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </Link>
              ))}
            </div>
            <p className="mt-10 text-center text-xs leading-5 text-gray-500">
              &copy; 2024, Built by Ashley Cook. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
