import React, { useState, useEffect } from 'react';
import PartyBookingForm from './partybookingform';
import NewPartyBookingForm from './PartyStaffCalendar';
import CustMessages from './custsendmessage';
import { format } from 'date-fns';
import { set } from 'lodash';

export default function OrderHistory() {
  const [partyBookings, setPartyBookings] = useState([]);
  const [timeRange, setTimeRange] = useState('Hour');
  const [showFuturePartyBookings, setShowFuturePartyBookings] = useState(false);
  const [showMoreInformation, setShowMoreInformation] = useState(false);
  const [partyId, setPartyId] = useState('');
  const [newBookings, setNewBookings] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partyGuests, setPartyGuests] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [currentPartyId, setCurrentPartyId] = useState(null);
  const [addGuest, setAddGuest] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [allergyDetails, setAllergyDetails] = useState('');
  const [activate, setActivate] = useState('No');
  const [foodOptions, setFoodOptions] = useState([]);
  const [food, setFood] = useState('');
  const [payment, setPayment] = useState(null);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const loadPartyBookings = async () => {
      setLoading(true);
      setError(null);
      try {
        const bookings = await fetchPartyBookings(timeRange, showFuturePartyBookings);
        setPartyBookings(bookings);
      } catch (e) {
        setError('Failed to load party bookings.');
      } finally {
        setLoading(false);
      }
    };

    loadPartyBookings();



  }, [timeRange, showFuturePartyBookings]);


   

  const fetchPartyBookings = async (timeRange, showFuturePartyBookings) => {
    const now = new Date();

    const getDateBoundaries = () => {
      const oneHourAgo = new Date(now);
      oneHourAgo.setHours(oneHourAgo.getHours() - 1);

      const startOfToday = new Date(now);
      startOfToday.setHours(0, 0, 0, 0);

      const startOfWeek = new Date(now);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      startOfWeek.setHours(0, 0, 0, 0);

      const startOfMonth = new Date(now);
      startOfMonth.setDate(1);
      startOfMonth.setHours(0, 0, 0, 0);

      switch (timeRange) {
        case 'Hour':
          return oneHourAgo;
        case 'Current Day':
          return startOfToday;
        case 'This Week':
          return startOfWeek;
        case 'This Month':
          return startOfMonth;
        case 'All':
          return null;
        default:
          return now;
      }
    };


    const startDate = getDateBoundaries();

    try {
      const response = await fetch('https://cheekydino.com/api/allpartybookings', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data: allPartyBookings } = await response.json();

      return allPartyBookings.filter(session =>
        timeRange === 'All'
          ? true
          : showFuturePartyBookings
            ? new Date(session.PartyDate) >= now
            : new Date(session.PartyDate) >= startDate && new Date(session.PartyDate) <= now
      );
    } catch (error) {
      console.error('Failed to fetch party bookings:', error);
      return [];
    }
  };

  const calculateTotals = (partyBookings) => {
    const numberOfOrders = partyBookings.length;
    const totalAmount = partyBookings.reduce((acc, order) => acc + order.Total, 0);
    const totalAmountMinusVAT = totalAmount * 0.8;

    return { numberOfOrders, totalAmount, totalAmountMinusVAT };
  };


useEffect(() => {
  const getFoodOptions = async () => {
    try {
      const response = await fetch('https://cheekydino.com/api/foodoptions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setFoodOptions(data.data);
      
    } catch (error) {
      console.error('Failed to fetch food options', error);
    }
  };

  getFoodOptions();
}, []);




  const { numberOfOrders, totalAmount, totalAmountMinusVAT } = calculateTotals(partyBookings);

  const showPartyGuests = async (id) => {
    if (!id) {
      console.error('Party ID is undefined');
      return;
    }

    try {
      const response = await fetch(`https://cheekydino.com/api/partyguestsid/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const partyGuests = data.data;

      setPartyGuests(partyGuests);
      setCurrentPartyId(id);
      setShowTable(true);

    } catch (error) {
      console.error('Failed to fetch party guests:', error);
    }
  };

  const handleAddGuest = async (e) => {
    e.preventDefault();

    try {
        // Ensure `food` contains both `id` and `name`
        if (!food.id || !food.name) {
            console.error('Food option is not selected properly.');
            return;
        }

        // Ensure all required fields are present
        if (!name || !email || !currentPartyId) {
            console.error('Required fields are missing.');
            return;
        }

        // Make the API request to add a guest
        const response = await fetch('https://cheekydino.com/api/addpartyguest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ChildName: name,
                FoodOption: food.name,
                FoodOptionId: food.id,
                Allergies: activate === 'Yes' ? allergyDetails : 'No', // Conditionally handle allergy details
                ContactInfoEmail: email,
                guest_id: currentPartyId // Add the guest to the currently selected party
            })
        });

        // Check if the response is okay
        if (!response.ok) {
            throw new Error(`Failed to add guest. Status: ${response.status}`);
        }

      const data = await response.json();
      console.log(data);

      // Refresh the guest list after adding a new guest
      showPartyGuests(currentPartyId);
      setAddGuest(false);

    } catch (error) {
      console.error('Failed to add party guest:', error);
    }
  };

  if (newBookings) {
    return <NewPartyBookingForm />;
  }

  if (message) {
    return <CustMessages reply={partyId} />;
  }


  const handleDeleteGuest = async (e, guestId) => {
    e.preventDefault();

    console.log('Deleting guest:', guestId);
  
    try {
      const response = await fetch(`https://cheekydino.com/api/deletepartyguest/${guestId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Guest deleted:', data);
  
      // Refresh the guest list after deleting a guest
      showPartyGuests(currentPartyId);
    } catch (error) {
      console.error('Failed to delete party guest:', error);
    }
  };


  const handleChange = (e) => {
    // Extract the selected value
    const [selectedId, selectedName] = e.target.value.split('|');
    setFood({ id: selectedId, name: selectedName });
};

const handlePayment = (id) => async (e) => {
  console.log('Payment:', id);
  e.preventDefault()

  try {
    const response = await fetch(`https://cheekydino.com/api/addpayment/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        AmountPaid: price
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Payment added:', data);

    setPayment(null);
  } catch (error) {
    console.error('Failed to add payment:', error);
  }

};



  return (
    <>

      <div className="flex flex-col items-start sm:items-center sm:flex-row sm:justify-between">
        <label htmlFor="timeRange" className="block text-sm font-medium leading-6 text-gray-900">
          Time Range
        </label>
        <select
          id="timeRange"
          name="timeRange"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 sm:w-auto"
          value={timeRange}
          onChange={e => setTimeRange(e.target.value)}
        >
          <option>Hour</option>
          <option>Current Day</option>
          <option>This Week</option>
          <option>This Month</option>
          <option>All</option>
        </select>
        <button
          className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0"
          onClick={() => setShowFuturePartyBookings(!showFuturePartyBookings)}
        >
          {showFuturePartyBookings ? 'Hide Future Party Bookings' : 'Show Future Party Bookings'}
        </button>
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Party Bookings</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the party bookings in your account including their name, age, party type, date, time, number of children, and total cost.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              onClick={() => setNewBookings(true)}
            >
              Add Booking
            </button>
            {showTable && currentPartyId && (
            <div className="mt-4">
            <button onClick={() => setShowTable(false)}
        type="button"
        className="rounded-md bg-red-600 mt-3 mb-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Close Guest Table
      </button>

            </div>
            )}
          </div>
        </div>

        <div className="mt-8">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Order Summary</h2>
          <p>Total Bookings: {numberOfOrders}</p>
          <p>Total Amount (including VAT): £{totalAmount.toFixed(2)}</p>
          <p>Total Amount (excluding VAT): £{totalAmountMinusVAT.toFixed(2)}</p>
        </div>

        {loading && <p className="text-gray-600">Loading party bookings...</p>}
        {error && <p className="text-red-600">{error}</p>}

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900">
                      Party ID
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                       Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Telephone 
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Age
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Party Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Party Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Party Time
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      No. of Children
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Amount Paid (£)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Total (£)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Amount to Pay (£)
                    </th>
                    
                    
                    <th scope="col" className="relative py-3.5 pl-3 pr-4">
                      <span className="sr-only">Show Guests</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {partyBookings.map((party) => (
                    <tr key={party.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                        {party.id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{party.ChildName}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{party.Email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{party.Telephone}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{party.ChildAge}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {party.PartyType}
  {party.PartyType === "Character" && <span> - {party.CharacterSelected}</span>}
</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {format(new Date(party.PartyDate), 'dd/MM/yyyy')}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{party.PartyTime}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{party.NoOfChildren}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">£{party.AmountPaid}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">£{party.Total}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  £{(Number(party.Total) - Number(party.AmountPaid)).toFixed(2)}
</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => showPartyGuests(party.id)}
                        >
                          Show Guests
                        </button>
                        <button
                          className="ml-4 text-green-600 hover:text-green-900"
                          onClick={() => {
                            setAddGuest(true);
                            setCurrentPartyId(party.id);
                          }}
                        >
                          Add Guest
                        </button>
                        <button className="ml-4 text-blue-600 hover:text-blue-900" onClick={() => setPayment(party.id)}>
                          Add Payment 
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {payment && (
          <div className="mt-8">
<div>
      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
        Amount Paid
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">£</span>
        </div>
        <input onChange={(e) => setPrice(e.target.value)} 
          id="price"
          name="price"
          type="text"
          value = {price}
          placeholder="0.00"
          aria-describedby="price-currency"
          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span id="price-currency" className="text-gray-500 sm:text-sm">
            GBP
          </span>
        </div>
      </div>
      <div className="mt-2">
      <button type='submit' onClick={handlePayment(payment)} className="rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          
          Add Payment

        </button>

    </div>
          
        
    </div>  
          </div>)}

        {showTable && currentPartyId && (
  <div className="mt-8">
    <h2 className="text-base font-semibold leading-6 text-gray-900">
      Guests for Party ID: {currentPartyId}
    </h2>
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900">
                Child Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Food Option
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Allergies
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Contact Email
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {partyGuests.map((guest) => (
              <tr key={guest.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-900">
                  {guest.ChildName}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {guest.FoodOption}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {guest.Allergies}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {guest.ContactInfoEmail}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={(e) => handleDeleteGuest(e, guest.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}

        {addGuest && currentPartyId && (
          <div className="mt-8">
            
            
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Add Guest for Party ID: {currentPartyId}
            </h2>
            
           
           
            <form onSubmit={handleAddGuest} className="mt-4 space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Child's Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Contact Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
            <label htmlFor="food" className="block text-sm font-medium leading-6 text-gray-900">
                Food Option
            </label>
            <select
                id="food"
                name="food"
                value={`${food.id || ''}|${food.name || ''}`} // Set value to the combined id and name
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
                <option value="" disabled>
                    Please select
                </option>
                {foodOptions.map((option) => (
                    <option key={option.id} value={`${option.id}|${option.Name}`}>
                        {option.Name}
                    </option>
                ))}
            </select>
        </div>

              <div>
                <label htmlFor="activate" className="block text-sm font-medium text-gray-700">
                  Any Allergies?
                </label>
                <select
                  id="activate"
                  name="activate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={activate}
                  onChange={(e) => setActivate(e.target.value)}
                  required
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>

              {activate === 'Yes' && (
                <div>
                  <label htmlFor="allergyDetails" className="block text-sm font-medium text-gray-700">
                    Allergy Details
                  </label>
                  <input
                    type="text"
                    id="allergyDetails"
                    name="allergyDetails"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={allergyDetails}
                    onChange={(e) => setAllergyDetails(e.target.value)}
                    required={activate === 'Yes'}
                  />
                </div>
              )}

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Add Guest
                </button>
              </div>
            </form>
          </div>
        )}
</div>
        
    </>
  );
}
