import { useEffect, useState } from "react";

export default function OpeningOverview() {
  const [openingTimes, setOpeningTimes] = useState([]);

  useEffect(() => {
    const fetchOpeningTimes = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/admin', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Log the raw data to understand its structure
        console.log('Fetched data:', data);

        // Check if the data contains the expected structure
        if (!data.data || !data.data[0]) {
          throw new Error('Unexpected data format');
        }

        const dayData = data.data[0];

        // Days of the week for display
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        // Transform the fetched data into a format suitable for display
        const fetchedTimes = daysOfWeek.map(day => {
          let dayDataContent = dayData[day];

          // Handle case where dayDataContent might be undefined
          if (!dayDataContent) {
            console.error(`No data found for ${day}`);
            dayDataContent = "{}"; // Use an empty JSON object as a fallback
          }

          // If dayDataContent is a string, parse it
          if (typeof dayDataContent === 'string') {
            try {
              dayDataContent = JSON.parse(dayDataContent);
            } catch (error) {
              console.error(`Error parsing JSON for ${day}:`, error);
              dayDataContent = {};
            }
          } else if (typeof dayDataContent !== 'object') {
            console.error(`Unexpected data format for ${day}:`, dayDataContent);
            dayDataContent = {};
          }

          return {
            day,
            openingTime: dayDataContent.openingTime || '',
            closingTime: dayDataContent.closingTime || '',
            sessions: dayDataContent.sessions || [],
          };
        });

        setOpeningTimes(fetchedTimes);
      } catch (error) {
        console.error('Error fetching opening times:', error);
      }
    };

    fetchOpeningTimes();


// call fetchOpeningTimes every 10 seconds

    const interval = setInterval(fetchOpeningTimes, 10000);
    return () => clearInterval(interval
    );
    


  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Opening Times Overview</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of opening and closing times for each day of the week.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Day
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Open Time
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Close Time
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Sessions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {openingTimes.map(({ day, openingTime, closingTime, sessions }) => (
                  <tr key={day}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {day}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{openingTime}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{closingTime}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {sessions.map((session, index) => (
                        <div key={index}>
                          {session.start} - {session.end}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
