import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { AuthContext } from './AuthContext';
import LoadingComponent from './staffpages/loading';

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post('https://cheekydino.com/api/auth', { token });
          if (response.data.success) {
            const decodedToken = jwtDecode(token);
            setUser(decodedToken);
          } else {
            localStorage.removeItem('token');
          }
        } catch (error) {
          localStorage.removeItem('token');
        }
      }
      setIsVerifying(false);
    };

    verifyToken();
  }, []);

  if (isVerifying) {
    return <LoadingComponent/>; // Or your own loading component
  }

  return (
    <AuthContext.Provider value={user}>
      {children}
    </AuthContext.Provider>
  );
}
