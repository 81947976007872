import { format, addDays } from 'date-fns';

export async function generateAIReport() {
  const today = format(new Date(), 'yyyy-MM-dd');
  const tomorrow = format(addDays(new Date(), 1), 'yyyy-MM-dd');

  try {
    const [
      sessionsRes, 
      partyRes, 
      clockInsRes, 
      cafeOrdersRes, 
      tomorrowSessionsRes, 
      tomorrowPartyRes, 
      tomorrowShiftsRes
    ] = await Promise.all([
      fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`),
      fetch(`https://cheekydino.com/api/party?date=${today}`),
      fetch('https://cheekydino.com/api/clockins'),
      fetch(`https://cheekydino.com/api/cafeordersstats?date=${today}`),
      fetch(`https://cheekydino.com/api/sessionbookings?date=${tomorrow}`),
      fetch(`https://cheekydino.com/api/party?date=${tomorrow}`),
      fetch(`https://cheekydino.com/api/shiftstomorrow/${tomorrow}`)
    ]);

    if (!sessionsRes.ok || !partyRes.ok || !clockInsRes.ok || !cafeOrdersRes.ok || 
        !tomorrowSessionsRes.ok || !tomorrowPartyRes.ok || !tomorrowShiftsRes.ok) {
      throw new Error('Failed to fetch data');
    }

    const [
      sessionsData, 
      partyData, 
      clockInsData, 
      cafeOrdersData, 
      tomorrowSessionsData, 
      tomorrowPartyData, 
      tomorrowShiftsData
    ] = await Promise.all([
      sessionsRes.json(),
      partyRes.json(),
      clockInsRes.json(),
      cafeOrdersRes.json(),
      tomorrowSessionsRes.json(),
      tomorrowPartyRes.json(),
      tomorrowShiftsRes.json(),
    ]);

    const sessions = sessionsData.data;
    const partyBookings = partyData.data;
    const staff = clockInsData.data;
    const cafeOrders = cafeOrdersData.data;
    const tomorrowSessions = tomorrowSessionsData.data;
    const tomorrowPartyBookings = tomorrowPartyData.data;
    const tomorrowShifts = tomorrowShiftsData.data;

    const todayTotalSpend = cafeOrders.reduce((total, order) => total + (order.Total || 0), 0);
    const totalSpendAllSessions = sessions.reduce((total, session) => total + (session.TotalSpent || 0), 0);
    const averageSpendPerSession = totalSpendAllSessions / sessions.length || 0;
    const totalSpendAllParties = partyBookings.reduce((total, party) => total + (party.Total || 0), 0);
    const totalSpendAllCafeOrders = cafeOrders.reduce((total, order) => total + (order.Total || 0), 0);

    const variableLabourPercentage = () => {
      const currentTime = format(new Date(), 'HH:mm:ss');
      const staffClockedIn = staff.filter(staff => staff.ClockOut === null);

      const totalLabourCost = staffClockedIn.reduce((acc, staff) => {
        const clockInTime = new Date(`1970-01-01T${staff.ClockIn}Z`);
        const clockOutTime = new Date(`1970-01-01T${currentTime}Z`);
        const hoursWorked = (clockOutTime - clockInTime) / 1000 / 60 / 60;
        const labourCost = hoursWorked * staff.HourlyRate;
        return acc + labourCost;
      }, 0);

      return todayTotalSpend > 0 ? (totalLabourCost / todayTotalSpend) * 100 : 0;
    };

    // Data to send to the backend
    const reportData = {
      todayTotalSpend,
      averageSpendPerSession,
      totalSpendAllSessions,
      totalSpendAllParties,
      totalSpendAllCafeOrders,
      variableLabourPercentage: variableLabourPercentage().toFixed(2),
      staff,
      sessions,
      partyBookings,
      cafeOrders,
      tomorrowSessions,
      tomorrowPartyBookings,
      tomorrowShifts,
    };

    // Send data to the backend to generate the AI report
    const response = await fetch('https://cheekydino.com/api/generate-ai-report', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reportData),
    });

    if (!response.ok) {
      throw new Error('Failed to generate AI report');
    }

    const result = await response.json();
    if (result.success) {
      console.log('AI Report:', result.message);
    } else {
      throw new Error(result.message || 'Failed to generate AI report');
    }

  } catch (error) {
    console.error('Error generating or saving AI response:', error);
    throw new Error('Failed to generate AI report');
  }
}
