import { useEffect, useState, useRef } from "react";

export default function TillStaff({ onSelectChange }) {
  const defaultStaff = { StaffId: 'Barry@Cheekydino.co.uk' /* other properties */ };
  const overridePin = "8568"; // Hardcoded PIN for override

  const [selected, setSelected] = useState(null); // Start with no selected staff
  const [clockedInStaff, setClockedInStaff] = useState([]);
  const [pin, setPin] = useState('');
  const [inputVisible, setInputVisible] = useState(true);
  const inputRef = useRef(null); // Create a ref for the input

  useEffect(() => {
    // Automatically focus on the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Update parent component when selected staff changes
  useEffect(() => {
    if (selected) {
      onSelectChange(selected);
    }
  }, [selected]);

  // Fetch clocked-in staff data on component mount
  useEffect(() => {
    const fetchClockIn = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/clockins', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        setClockedInStaff(data.data);
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    fetchClockIn();
  }, []);

  // Handle pin input and check for override or clocked-in staff
  const handlePinChange = (event) => {
    const enteredPin = event.target.value;
    setPin(enteredPin);

    // Check for override PIN
    if (enteredPin === overridePin) {
      setSelected(defaultStaff); // Select Barry as the default staff
      setInputVisible(false);     // Hide input on successful match
      return; // Exit function to prevent further checks
    }

    // Check for matching staff based on entered PIN
    const matchingStaff = clockedInStaff.find(staff => staff.TillPin === enteredPin);

    if (matchingStaff) {
      setSelected(matchingStaff); // Select the matching staff
      setInputVisible(false);      // Hide input and component on successful match
    }
  };

  // Render component only if inputVisible is true
  return (
    inputVisible && (
      <div className="flex justify-center items-center h-screen"
           style={{ backgroundImage: "url('/loading.gif')" }}
      >
        <div className="w-1/3">
          <label htmlFor="pin" className="block text-lg font-bold leading-8 text-white text-center mb-4">
            Enter Till Pin
          </label>
          <div className="mt-2">
            <input
              ref={inputRef} // Attach the ref to the input
              onChange={handlePinChange}
              value={pin}
              id="pin"
              name="pin"
              type="password"
              placeholder="Enter your PIN"
              className="block w-full text-center rounded-md border-2 border-gray-300 py-4 text-2xl text-purple-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
            />
          </div>
        </div>
      </div>
    )
  );
}
