import React, { useEffect, useState } from 'react';


import { format, addHours } from 'date-fns';
import Till from './Till';
import SessionTill from './SessionTill';
import { motion } from 'framer-motion';




let ipcRenderer = null;
if (window && window.process && window.process.type) {
  ipcRenderer = window.require('electron').ipcRenderer;
}





export default function TableSelect({ availableTables, onSelect, details, handleBack }) {
  const [selectedTables, setSelectedTables] = useState([]);
  const [savedDetails, setDetails] = useState(details);
  const [truee, setTrue] = useState(false);
  const [pay, setPay] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [clean, setCleanTables] = useState([]);
  const [note, setNote] = useState(false);
  const [exName, setExName] = useState('');
  const [exGuests, setExGuests] = useState('');
  const [exTime, setExTime] = useState('');
  const [exPrepaid, setExPrepaid] = useState('');
  const [noteString, setNoteString] = useState('');


 


  console.log(clean)
console.log(savedDetails)

  useEffect(() => {
    // Feth admin data
    const fetchAdminData = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/admin');
        const data = await response.json();
        const parsedTableData = JSON.parse(data.data[0].TableData); // JSON.parse here
        setTableData(parsedTableData);
    }
    catch (error) {
      console.error('There was an error!', error);
    }
  };


    fetchAdminData();
  }, []);

  useEffect(() => {
    // Fetch sessions data for today's date
    const fetchSessions = async () => {
      try {
        const date = format(new Date(), 'yyyy-MM-dd'); // Get today's date
        
        const allBookingsResponse = await fetch(`https://cheekydino.com/api/sessionbookings?date=${date}`);
        const sessionsData = await allBookingsResponse.json();

        // its data.data because the response is an object with a data property

        const sessions = sessionsData.data;
        
        const tablesToBeCleaned = sessions.filter(session => 
          session.Arrived === 1 && 
          session.LeftCenter === 1 &&
          session.CleanTable === 1 &&
          session.TableCleaned === 0
        );
        setCleanTables(tablesToBeCleaned); // Set the cleanTables state
      } catch (error) {
        console.error('Failed to fetch sessions data', error);
      }
    };
  
    fetchSessions();

    // fetch every second
    const interval = setInterval(() => {
      fetchSessions();
    }, 1000);

    return () => clearInterval(interval);

  }, [tableData]);
  


  if (truee === true) {
    return <Till />;
  }

  console.log(savedDetails.Name[0].name);
  console.log(savedDetails);

  const handleTableClick = (table) => {
    setSelectedTable(table);

    if (selectedTables.includes(table)) {
      setSelectedTables(selectedTables.filter((t) => t !== table));
      setDetails(details);
    } else {
      setSelectedTables([...selectedTables, table]);
    }
  };

  const handleConfirmClick = async () => {
    try {
      onSelect(selectedTables);
  
      // Get current date and format it
      const date = new Date();
      const awsDate = format(date, 'yyyy-MM-dd');
      const nowString = format(date, 'HH:mm:ss.SSS');
  
      // Calculate two hours later
      const twoHoursLater = addHours(date, 2);
      const twoHoursLaterString = format(twoHoursLater, 'HH:mm');
  
      const children = parseInt(savedDetails.Children);
      const adults = parseInt(savedDetails.Adults);
  
      const emailTime = format(date, 'HH:mm');
      const emailTime2 = format(twoHoursLater, 'HH:mm');
  
      const name = savedDetails.Name;
  
      const data = {
        name: name,
        email: savedDetails.Email,
        telephone: savedDetails.Telephone,
        carReg: savedDetails.CarReg,
        table: selectedTables[0].table,
        time: emailTime,
        time2: emailTime2,
        date: awsDate,
        children: children,
        adults: adults,
        total: savedDetails.Total,
      }
  
      // Send data to IPC renderer if available
      if (ipcRenderer) {
        ipcRenderer.send('show-data', data);
        console.log('Sent data to IPC renderer');
      }
  
      const bookingData = {
        Email: savedDetails.Email,
        Number: savedDetails.Number,
        Date: awsDate,
        Name: savedDetails.Name,
        Children: children,
        Adults: adults,
        Table: selectedTables[0].table,
        TimeslotFrom: savedDetails.TimeSlotFrom,
        TimeslotTo: savedDetails.TimeSlotTo,
        TimeArrived: nowString,
        Arrived: 1,
        LeftCenter: 0,
        TimeArrived: nowString,
        Telephone: savedDetails.Telephone,
        TotalSpent: savedDetails.Total,
        Staff: savedDetails.Staff,
        EntranceFee: savedDetails.Total,
        CarReg: savedDetails.CarReg,
        ChildData: savedDetails.ChildData,
        GuestNotes : noteString,
        createdAt : new Date().toISOString(),
      }
  
      try {
        // get token `token` from localStorage
        const token = localStorage.getItem('token');

        const response = await fetch('https://cheekydino.com/api/postsession', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bookingData),
        });
  
        const responseData = await response.json();
        console.log(responseData);
        setPay(true);
      } catch (error) {
        console.error('Failed to post booking data', error);
      }
    } catch (error) {
      console.error('Error during confirmation', error);
    }
  }


  const handleLater = async () => {
setNote(false);

const NoteString = `${exName} is expecting ${exGuests} guests at ${exTime}: Prepaid: ${exPrepaid}`;

console.log(NoteString);


setNoteString(NoteString);


  }





  




   

   

  let guests = parseInt(savedDetails.Children) + parseInt(savedDetails.Adults);

  if (pay === true) {
    return (
      <SessionTill order={'2 Hour Session'} total={savedDetails.Total} table={selectedTables[0].table} ChildName={savedDetails.Name} />
    );
  }

  const notificationMethods = [

  { id: 'Yes', title: 'Yes' },
  { id: 'No', title: 'No' },
  ];

  return (
    <div className="p-4 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="bg-white shadow-lg rounded-lg p-4 sm:p-6 w-full max-w-3xl lg:max-w-4xl">
        <div className="flex flex-col md:flex-row justify-between mb-6 space-y-4 md:space-y-0 md:space-x-4">
          <motion.button
            onClick={handleBack}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Back
          </motion.button>
          <motion.button
            onClick={handleConfirmClick}
            className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded animate-pulse"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Confirm
          </motion.button>
          <motion.button
            onClick={() => setNote(true)}
            className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            More Guests Arriving Later?
          </motion.button>
        </div>
  
        <div className="text-center mb-6">
        <p className="text-xl sm:text-2xl font-semibold text-purple-500">Staff Member: {savedDetails.Staff}</p>
          <p className="text-xl sm:text-2xl font-semibold">Select A Table</p>
          <p className="text-md mt-2">Party Size: {guests}</p>
          <p className="text-md mt-1">Name: {savedDetails.Name}</p>
          <p className="text-md mb-4 mt-1">Price: £{savedDetails.Total.toFixed(2)}</p>
  
          {note && (
            <div className="mb-6 p-4 bg-gray-50 rounded-md shadow-sm">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                Name
              </label>
              <input
                onChange={(e) => setExName(e.target.value)}
                id="name"
                name="name"
                type="text"
                placeholder="Jenny"
                className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              />
              <div className="mt-4">
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900">Prepaid</legend>
                  <p className="mt-1 text-sm leading-6 text-gray-600">Is the Customer Paying for The Guests That Are Arriving Later Now</p>
                  <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:space-x-10">
                    {notificationMethods.map((notificationMethod) => (
                      <div key={notificationMethod.id} className="flex items-center">
                        <input
                          onChange={(e) => setExPrepaid(e.target.id)}
                          defaultChecked={notificationMethod.id === 'Yes'}
                          id={notificationMethod.id}
                          name="notification-method"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          {notificationMethod.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-4">
                <label htmlFor="guests" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                  Expected Guests
                </label>
                <input
                  onChange={(e) => setExGuests(e.target.value)}
                  id="guests"
                  name="guests"
                  type="number"
                  value={exGuests}
                  placeholder="2"
                  className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                />
              </div>
              <div className="mt-4">
                <label htmlFor="time" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                  Expected Time
                </label>
                <input
                  onChange={(e) => setExTime(e.target.value)}
                  id="time"
                  name="time"
                  type="time"
                  value={exTime}
                  placeholder="16:00"
                  className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                />
              </div>
              <div className="mt-4">
                <button
                  onClick={() => handleLater()}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
  
        {selectedTable && (
          <div className="text-center mb-6">
            <p className="font-semibold">You have selected table number:</p>
            <div className="inline-block bg-blue-500 text-white font-bold rounded-full w-12 h-12 flex items-center justify-center text-lg">
              {selectedTable.table}
            </div>
          </div>
        )}
  
        {!note && (
          <motion.div
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 border-2 border-gray-300 bg-gray-900 rounded-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {tableData.map((table) => {
              const isAvailable = availableTables.some((t) => t.table === table.table);
              const isSelected = selectedTables.some((t) => t.table === table.table);
              const needsCleaning = clean.some(cleanTable => cleanTable.Table === table.table && !cleanTable.TableCleaned);
  
              let tableRow = table.location.y + 1;
              let tableCols = `${table.location.x + 1} / span 1`;
  
              if (tableRow === 2 || tableRow === 4) {
                const tablesInRow = tableData.filter((t) => t.location.y === tableRow - 1);
                tablesInRow.reverse();
                tableCols = `${tablesInRow[table.location.x].location.x + 1} / span 1`;
              }
  
              return (
                <motion.button
                  key={table.table}
                  className={`p-2 ${table.shape === 'square' ? 'w-12 h-12' : 'w-10 h-10 rounded-full'} 
                  ${isAvailable ? (needsCleaning ? 'bg-purple-500' : 'bg-green-500') : 'bg-red-500'} 
                  ${isSelected ? 'ring-2 ring-white' : 'ring-2 ring-transparent'} mt-4 mb-4`}
                  style={{
                    gridColumn: tableCols,
                    gridRow: `${tableRow} / span 1`,
                    border: '2px solid gray-300',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                  }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleTableClick(table)}
                >
                  <span className="text-white">{table.table}</span>
                  <span className="text-xs text-gray-200 block mt-2">{table.capacity} seats</span>
                </motion.button>
              );
            })}
          </motion.div>
        )}
      </div>
    </div>
  );
  
  

}





