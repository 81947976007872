import React, { useState, useEffect, useCallback } from 'react';
import { format, parseISO, isWithinInterval, isBefore } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Weather from './weatherdata';
import { BellIcon } from '@heroicons/react/20/solid';
import CustomerTables from './customertables';
import CustOrderProgress from './custOrderProgress';
import { motion } from 'framer-motion';
import JsxParser from 'react-jsx-parser'; // Ensure JsxParser is imported
import Leave from './Leave'; // Ensure Leave component is imported

export default function Example() {
  const [announcementMessage, setAnnouncementMessage] = useState('');
  const [tableMessage, setTableMessage] = useState('');
  const [sessions, setSessions] = useState([]);
  const [dash, setDash] = useState(false);
  const [adverts, setAdverts] = useState([]);
  const [currentAdvert, setCurrentAdvert] = useState(null);
  const [showAdvert, setShowAdvert] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (dash) {
      navigate('/dashboard');
    }
  }, [dash, navigate]);

  // Fetch announcements data
  const fetchAnnouncements = useCallback(async () => {
    try {
      const response = await fetch("https://cheekydino.com/api/announcements", {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      const now = new Date();
      const todayDate = format(now, "yyyy-MM-dd");
      const currentTime = format(now, "HH:mm:ss");
      const todayDay = format(now, "EEEE"); // 'Monday', 'Tuesday', etc.

      const filteredData = data.data.filter(item => {
        const { SetDay, Days, StartTime, EndTime, Activate, StartDate, EndDate } = item;

        if (Activate !== 1) return false; // Skip inactive announcements

        if (SetDay === 1) {
          const daysArray = Days ? Days.split(',') : [];
          return daysArray.includes(todayDay) && (currentTime >= StartTime && currentTime <= EndTime);
        } else if (SetDay === 0) {
          const startDate = format(parseISO(StartDate), "yyyy-MM-dd");
          const endDate = format(parseISO(EndDate), "yyyy-MM-dd");
          const dateInRange = todayDate >= startDate && todayDate <= endDate;
          return dateInRange && (currentTime >= StartTime && currentTime <= EndTime);
        }
        return false;
      });

      if (filteredData.length > 0) {
        const newAnnouncementMessage = filteredData[0].Content;
        setAnnouncementMessage(prev => {
          if (prev !== newAnnouncementMessage) {
            return newAnnouncementMessage;
          }
          return prev;
        });
      } else {
        setAnnouncementMessage('');
      }
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  }, []);


  useEffect(() => {
    fetchAnnouncements();
    const interval = setInterval(() => {
      fetchAnnouncements();
    }, 30000); // Adjusted to 30 seconds for testing

    return () => clearInterval(interval);
  }, [fetchAnnouncements]);

  // Fetch table messages data

  useEffect(() => {
    const fetchTableMessages = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/tablemessages", {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        const now = new Date();
        const todayDate = format(now, "yyyy-MM-dd");
        const currentTime = format(now, "HH:mm:ss");
        const todayDay = format(now, "EEEE"); // 'Monday', 'Tuesday', etc.

        const filteredData = data.data.filter(item => {
          const { SetDay, Days, StartTime, EndTime, Activate, StartDate, EndDate } = item;

          if (Activate !== 1) return false; // Skip inactive table messages

          if (SetDay === 1) {
            const daysArray = Days ? Days.split(',') : [];
            return daysArray.includes(todayDay) && (currentTime >= StartTime && currentTime <= EndTime);
          } else if (SetDay === 0) {
            const startDate = format(parseISO(StartDate), "yyyy-MM-dd");
            const endDate = format(parseISO(EndDate), "yyyy-MM-dd");
            const dateInRange = todayDate >= startDate && todayDate <= endDate;
            return dateInRange && (currentTime >= StartTime && currentTime <= EndTime);
          }
          return false;
        });

        if (filteredData.length > 0) {
          const newTableMessage = filteredData[0].Content;
          setTableMessage(prev => {
            if (prev !== newTableMessage) {
              return newTableMessage;
            }
            return prev;
          });
        } else {
          setTableMessage('');
        }
      } catch (error) {
        console.error('Error fetching table messages:', error);
      }
    }

    fetchTableMessages();

    const interval = setInterval(() => {
      fetchTableMessages();
    }, 30000); // Adjusted to 30 seconds for testing

    return () => clearInterval(interval);
  }, []);

  

  useEffect(() => {
    const fetchAdverts = async () => {
        try {
            const response = await fetch("https://cheekydino.com/api/adverts", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log("Fetched data:", data);

            let advertsData = data.data;

            const todaysDate = format(new Date(), 'yyyy-MM-dd');
            const dayOfWeek = format(new Date(), 'EEEE');
            let currentTime = format(new Date(), 'HH:mm:ss');

            const filteredAdverts = advertsData.filter(advert => {
                const websiteMatch = advert.FrontScreen === 1 && advert.Activate === 1;
                return websiteMatch;
            });

            const finalFilteredAdverts = filteredAdverts.filter(advert => {
                try {
                    if (advert.SetDay === 1) {
                        const advertDaysArray = advert.Days.split(',').map(day => day.trim());
                        const dayMatch = advertDaysArray.includes(dayOfWeek);

                        if (dayMatch) {
                            const timeMatch = currentTime >= advert.StartTime && currentTime <= advert.EndTime;
                            return timeMatch;
                        }
                    } else if (advert.SetDay === 0) {
                        const startDate = parseISO(advert.StartDate);
                        const endDate = parseISO(advert.EndDate);

                        const validDates = !isNaN(startDate) && !isNaN(endDate) && isBefore(startDate, endDate);

                        if (validDates) {
                            const dateMatch = isWithinInterval(new Date(), { start: startDate, end: endDate });

                            if (dateMatch) {
                                const timeMatch = currentTime >= advert.StartTime && currentTime <= advert.EndTime;
                                return timeMatch;
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error processing advert:', advert, error);
                }
                return false;
            });

            finalFilteredAdverts.sort((a, b) => a.ComponentOrder - b.ComponentOrder);

            setAdverts(finalFilteredAdverts);

        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    }

    fetchAdverts();
    const interval = setInterval(() => {
        fetchAdverts();
    }, 30000); // Adjusted to 30 seconds for testing

    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    if (adverts.length > 0) {
      // Pick the first advert to display (or implement logic to select a specific one)
      console.log("Adverts:", adverts);
      setCurrentAdvert(adverts[0]);

      const showAdvertInterval = setInterval(() => {
        setShowAdvert(true);

        // Hide advert after 1 minute
        setTimeout(() => {
          setShowAdvert(false);
        }, 60000); // 1 minute

      }, 120000); // Every 2 minutes

      return () => clearInterval(showAdvertInterval);
    }
  }, [adverts]);

  return (
    <>
      <div className={`fixed top-0 left-0 w-full h-24 flex flex-col items-center px-4 z-50 transition-transform duration-300 ${announcementMessage || tableMessage ? 'bg-red-500 text-white' : 'bg-transparent'}`}>
        {announcementMessage && (
          <div className={`w-full h-12 flex items-center justify-center text-2xl ${announcementMessage ? 'block' : 'hidden'}`}>
            {announcementMessage}
          </div>
        )}
        
         
        {(announcementMessage || tableMessage) && <BellIcon className="h-8 w-8 mt-2" />}
      </div>

      {!announcementMessage && !tableMessage && (
        <div className="fixed top-0 left-0 w-full h-24 flex items-center px-4 bg-white shadow-md z-40">
          <div className="text-orange-500 font-bold text-xl mr-auto">
            {format(new Date(), "HH:mm")}
          </div>
          <div className="flex-grow flex items-center justify-center">
            <div className="text-white font-bold">
              <Weather />
            </div>
          </div>
          <button
            className="text-orange-500 font-bold bg-transparent border border-white rounded-md px-4 py-2 ml-auto"
            onClick={() => setDash(true)}
          >
            Close
          </button>
        </div>
      )}

      {/* Full-screen advert container */}
      {showAdvert && currentAdvert && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-30">
          <motion.div
            key={currentAdvert.id}
            className="w-full h-full flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="w-full h-full flex items-center justify-center p-4 bg-white rounded">
              {currentAdvert.Code ? (
                <JsxParser jsx={currentAdvert.Code} />
              ) : (
                <p>No content available</p> // Fallback if no content
              )}
            </div>
          </motion.div>
        </div>
      )}

      <div className={`flex ${announcementMessage || tableMessage ? 'pt-24' : 'pt-16'} bg-fixed bg-center bg-white bg-no-repeat bg-cover`}>
        <div className="w-1/2 p-4">
          <div className={`overflow-hidden h-full ${sessions.length > 5 ? "animate-moveUp" : ""}`}>
            <Leave />
            <CustomerTables />
          </div>
        </div>
        <div className="w-1/2 p-4 flex flex-col">
          <div className="flex-1">
            <CustOrderProgress />
          </div>
        </div>
      </div>
    </>
  );
}
