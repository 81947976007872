import React, { useEffect, useRef, useState } from 'react';
import {
  format,
  parse,
  differenceInMinutes,
  startOfMonth,
  subDays,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  addMonths,
  subMonths,
  isSameMonth,
  isToday,
} from 'date-fns';
import { get } from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Timeslots from './tableslots';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Calender() {

  const navigate = useNavigate();


  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [bookingsData, setBookingsData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [startTime, setStartTime] = useState([]);

  useEffect(() => {
    fetchBookingsAndParties(format(currentDate, 'yyyy-MM-dd'));
    generateDays();
  }, [currentDate]);

  const startDate = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 });
  const endDate = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 });

  const generateDays = () => {
    const daysArray = [];
    let day = startDate;

    while (day <= endDate) {
      daysArray.push(day);
      day = addDays(day, 1);
    }

    setDays(daysArray);
  };

  const fetchBookingsAndParties = async (date) => {
    try {
      // Fetch bookings data based on date
      const allBookingsResponse = await fetch(`https://cheekydino.com/api/sessionbookings?date=${date}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!allBookingsResponse.ok) {
        throw new Error('Failed to fetch bookings data');
      }

      const responseJson = await allBookingsResponse.json();
      if (!responseJson.success) {
        throw new Error('Failed to fetch bookings data: ' + responseJson.message);
      }

      const bookingsData = responseJson.data;

      // Fetch party data based on date
      const partyResponse = await fetch(`https://cheekydino.com/api/party?date=${date}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!partyResponse.ok) {
        throw new Error('Failed to fetch party data');
      }

      const partyData = await partyResponse.json();
      if (!partyData.success) {
        throw new Error('Failed to fetch party data: ' + partyData.message);
      }

      const partyDat = partyData.data;

      // Merge party data into bookings data
      partyDat.forEach((party) => {
        const matchingBooking = bookingsData.find(
          (booking) => booking.Date === party.PartyDate && booking.TimeslotFrom === party.PartyTime
        );

        if (matchingBooking) {
          matchingBooking.PartyType = party.PartyType;
          matchingBooking.NoOfChildren = party.NoOfChildren;
          matchingBooking.isParty = true;
        } else {
          bookingsData.push({
            ...party,
            TimeslotFrom: party.PartyTime,
            TimeslotTo: party.PartyEndTime,
            isParty: true,
          });
        }
      });

      setBookingsData(bookingsData);
      setPartyData(partyDat);

      // Fetch additional data based on day of the week
      const getDay = format(currentDate, 'EEEE');

      const getDayDataResponse = await fetch('https://cheekydino.com/api/admin', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!getDayDataResponse.ok) {
        throw new Error('Failed to fetch day data');
      }

      const getDayData = await getDayDataResponse.json();
      if (!getDayData.success) {
        throw new Error('Failed to fetch day data: ' + getDayData.message);
      }

      const dayData = getDayData.data;

      const filteredData = get(dayData[0], getDay, []);
      const parseData = JSON.parse(filteredData);

      const timings = parseData.sessions;
      const startTimes = timings.map((session) => session.start);

      setStartTime(startTimes);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const baseTime = parse(startTime[0], 'HH:mm', new Date());

  const navigatePreviousDay = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1));
  };

  const navigateNextDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  const handleDayClick = (day) => {
    setCurrentDate(day);
  };

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => subMonths(prevDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => addMonths(prevDate, 1));
  };

  const next7Days = days.slice(0, 7);

  return (
    <div className="flex h-full flex-col">
      <button onClick={() => window.location.reload()}
        type="button"
        className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
Back      </button>
      <header className="flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
        <div>
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time>{format(currentDate, 'MMMM d, yyyy')}</time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">{format(currentDate, 'EEEE')}</p>
        </div>
        <div className="flex items-center">
        
            
          {/* Navigation buttons */}
        </div>
      </header>

      <div className="isolate flex flex-auto overflow-hidden bg-white">
        <div ref={container} className="flex flex-auto flex-col overflow-auto">
          <div className="isolate flex flex-auto overflow-hidden bg-white">
            <div className="flex flex-auto flex-col overflow-auto">
              <div className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden">
                {/* Day buttons */}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-auto overflow-auto">
            <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1 mt-5">
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
              >
                {startTime.map((time, index) => (
                  <div key={index}>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      {time}
                    </div>
                  </div>
                ))}
              </div>

              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                style={{ gridTemplateRows: `repeat(${startTime.length * 4}, minmax(0, 1fr))` }}
              >
                {bookingsData.map((booking, index) => {
                  const bookingTime = parse(booking.TimeslotFrom, 'HH:mm:ss', new Date());
                  const minutesFromBase = differenceInMinutes(bookingTime, baseTime);
                  const startRow = Math.floor(minutesFromBase / 30) + 3;

                  if (startRow < 1) return null;

                  let colorClass = 'bg-blue-500 hover:bg-blue-100 text-white';
                  if (booking.isParty) {
                    colorClass = 'bg-orange-500 hover:bg-orange-100 text-white';
                  } else if (booking.Arrived === 1 && booking.LeftCenter === 1) {
                    colorClass = 'bg-red-500 hover:bg-red-100 text-white';
                  } else if (booking.Arrived === 1 || booking.LeftCenter === 1) {
                    colorClass = 'bg-green-500 hover:bg-green-100 text-white animate-pulse';
                  }

                  return (
                    <li
                      key={index}
                      className="relative mt-px flex"
                      style={{ gridRow: `${startRow} / span 1` }}
                    >
                      <a
                        href="#"
                        className={`group absolute inset-1 flex flex-col overflow-y-auto rounded-lg ${colorClass}`}
                      >
                        <p className="order-1 font-semibold">{booking.Name || booking.PartyType}</p>
                        <p className="group-hover:text-blue-700"> Table:{booking.Table}</p>
                        <p className="group-hover:text-blue-700"> {booking.Email}</p>
                        {booking.isParty && (
                          <p className="group-hover:text-blue-700">
                            {booking.NoOfChildren} children
                          </p>
                        )}
                        {/* <p className="group-hover:text-blue-700">
                          <time dateTime={booking.TimeslotFrom}>{format(bookingTime, 'HH:mm')}</time>
                          <time dateTime={booking.TimeslotTo}>
                            - {format(parse(booking.TimeslotTo, 'HH:mm:ss', new Date()), 'HH:mm')}
                          </time>
                        </p> */}
                      </a>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
        <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block overflow-auto">
          <div className="flex items-center text-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={handlePrevMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {format(currentDate, 'MMMM yyyy')}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={handleNextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => (
              <button
                key={day}
                type="button"
                className={`py-1.5 hover:bg-gray-100 focus:z-10 ${
                  isSameMonth(day, currentDate) ? 'bg-white' : 'bg-gray-50'
                } ${isToday(day) || isSameMonth(day, currentDate) ? 'font-semibold' : ''} ${
                  isToday(day) ? 'text-indigo-600' : ''
                } ${!isSameMonth(day, currentDate) ? 'text-gray-400' : ''} ${
                  isSameMonth(day, currentDate) && !isToday(day) ? 'text-gray-900' : ''
                } ${dayIdx === 0 ? 'rounded-tl-lg' : ''} ${
                  dayIdx === 6 ? 'rounded-tr-lg' : ''
                } ${dayIdx === days.length - 7 ? 'rounded-bl-lg' : ''} ${
                  dayIdx === days.length - 1 ? 'rounded-br-lg' : ''
                }`}
                onClick={() => handleDayClick(day)}
              >
                <time
                  dateTime={format(day, 'yyyy-MM-dd')}
                  className={`mx-auto flex h-7 w-7 items-center justify-center rounded-full ${
                    isToday(day) ? 'bg-indigo-600 text-white' : ''
                  }`}
                >
                  {format(day, 'd')}
                </time>
              </button>
            ))}
          </div>
          <Timeslots currentDate={currentDate} />
        </div>
      </div>
    </div>
  );
}
