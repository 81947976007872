import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Online from './online'; // Assuming Online component is in the same directory
import ClockIn from './staffactions'; // Assuming ClockIn component is in the same directory
import ClockInData from './clockindata'; // Assuming ClockInData component is in the same directory
import Tasks from './stafftasks'; // Assuming Tasks component is in the same directory
import StaffDetails from './staffdetails'; // Assuming StaffDetails component is in the same directory
import BookShifts from './bookshifts'; // Assuming BookShifts component is in the same directory
import Shifts from './shifts'; // Assuming Shifts component is in the same directory
import SessionHistory from './sessionhistory'; // Assuming SessionHistory component is in the same directory
import Financials from './financials'; // Assuming Financials component is in the same directory
import CustomerAnalytics from './consumeranayltics'; // Assuming CustomerAnalytics component is in the same directory
import TillHistory from './tillhistory'; // Assuming TillHistory component is in the same directory
import Graph from './graph';
import SpeedTest from './demographics';

 // Assuming Graph component is in the same directory

const MyAccount = () => <SessionHistory/>;
const Company = () =>  <CustomerAnalytics />; // Import ClockIn component
const TeamMembers = () => <Financials />; // Default to Online component
const Task = () => <TillHistory/>;
const GraphSlot = () => <Graph/>;
const Speed = () => <SpeedTest/>;

const tabs = [
  { name: 'Customer CRM', component: MyAccount, current: false },
  { name: 'Analytics', component: Company, current: false },
  { name: 'Financial', component: TeamMembers, current: true },
  { name: 'Graph', component: GraphSlot, current: false}, // Set this as the default tab


  {name: 'Till Analytics', component: Task, current: false}, // Set this as the default tab
  {name: 'AI Analytics Reports', component: Speed, current: false}, // Set this as the default tab
  
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [currentTab, setCurrentTab] = useState(tabs.find((tab) => tab.current));

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to update the selected tab */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={currentTab.name}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) =>
            setCurrentTab(tabs.find((tab) => tab.name === e.target.value))
          }
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setCurrentTab(tab)}
              className={classNames(
                tab.name === currentTab.name
                  ? 'bg-gray-100 text-gray-700'
                  : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {React.createElement(currentTab.component)}
      </div>
    </div>
  );
}
