import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

export default function Example({ reply }) {
  const [open, setOpen] = useState(true);
  const [replys, setReply] = useState([]);
  const [newReply, setNewReply] = useState('');
  const [loginId, setLoginId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    if (reply) {
      setReply(reply);
    }
  }, [reply]);

  useEffect(() => {
    const fetchLoginId = async () => {
      if (replys.To) {
        try {
          setLoading(true);
          console.log('Fetching login ID for:', replys.To);

          const response = await fetch(`https://cheekydino.com/api/getloginid/${replys.To}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch login ID');
          }

          const data = await response.json();
          console.log('Login ID Data:', data);

          // Assuming data.data is an array and the first element contains the login_id
          if (data.data && data.data.length > 0) {
            setLoginId(data.data[0].login_id); // Adjust according to the actual response structure
          } else {
            throw new Error('No login ID found in response');
          }
        } catch (error) {
          console.error('Error fetching login ID:', error);
          setError('Failed to fetch login ID.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLoginId();
  }, [replys.To]);

  const handleSendReply = async () => {
    console.log('Sending reply:', newReply);

    console.log('Login ID:', loginId);

    if (!loginId) {
      console.error('Login ID is not available');
      setError('Login ID is not available.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('https://cheekydino.com/api/sendcustomerenquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          To: replys.To,
          From: replys.From,
          Content: newReply,
          login_id: loginId,
          Marketing: 0,
          ToMarketing: 0,
          Replied: 0,
          createdAt: new Date().toISOString(),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const data = await response.json();
      console.log('Message sent:', data);

      // Optionally handle success feedback
      setNewReply('');
    } catch (error) {
      console.error('Error sending reply:', error);
      setError('Failed to send message.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  alt="User Avatar"
                  src="versa.gif"
                  className="h-12 w-12 rounded-full"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">New Message To {replys.To}</h3>
                <p className="text-sm text-gray-500">
                  <a href={`mailto:${replys.To}`}>{replys.To}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
          Send Message
        </label>
        <div className="mt-2">
          <textarea
            onChange={(e) => setNewReply(e.target.value)}
            id="comment"
            name="comment"
            rows={4}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={newReply}
          />
        </div>
        {error && (
          <p className="mt-2 text-red-600">{error}</p>
        )}
        <div className="mt-4 flex justify-end">
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={handleSendReply}
            disabled={loading} // Disable button while loading
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </>
  );
}
