import React, { useState, useEffect } from 'react';

function App() {
  const [errors, setErrors] = useState([]);

  // Function to send errors to an endpoint
  const sendErrors = async (errorsToSend) => {
    try {
      await fetch('https://cheekydino.com/api/errorlog', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(errorsToSend),
      });
      console.log('Errors sent successfully');
    } catch (error) {
      console.error('Error sending errors:', error);
    }
  };

  useEffect(() => {
    const originalConsoleError = console.error;

    // Override console.error to capture errors
    console.error = (...args) => {
      setErrors(prevErrors => [...prevErrors, { message: args.join(' '), type: 'console' }]);
      originalConsoleError(...args);
    };

    // Global error handler for uncaught exceptions
    window.onerror = (message, source, lineno, colno, error) => {
      setErrors(prevErrors => [
        ...prevErrors,
        { message: `${message} at ${source}:${lineno}:${colno}`, type: 'uncaught' },
      ]);
      return false; // Prevents default logging
    };

    // Global handler for unhandled promise rejections
    window.onunhandledrejection = (event) => {
      setErrors(prevErrors => [
        ...prevErrors,
        { message: `Unhandled promise rejection: ${event.reason}`, type: 'promise' },
      ]);
    };

    // Set up interval to send errors every hour
    const intervalId = setInterval(() => {
      if (errors.length > 0) {
        sendErrors(errors)
          .then(() => setErrors([]))  // Clear errors after sending
          .catch(error => console.error('Failed to send errors:', error));
      }
    }, 3600000); // 3600000 milliseconds = 1 hour

    // Cleanup
    return () => {
      console.error = originalConsoleError;
      window.onerror = null;
      window.onunhandledrejection = null;
      clearInterval(intervalId);
    };
  }, [errors]);

  return (
    <div>
      <h1>React Error Logger</h1>
      <button onClick={() => setErrors([])}>Clear Errors</button>
      <ul>
        {errors.map((error, index) => (
          <li key={index} style={{ color: 'red' }}>
            [{error.type}] {error.message}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
