import { useEffect, useState } from "react";

export default function LoginCode() {
  const [people, setPeople] = useState([]);
  const [edit, setEdit] = useState(false);
  const [pin, setPin] = useState(''); // State for 'pin'
  const [id, setId] = useState(null); // State for 'id'

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/staff", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setPeople(data.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers(); // Initial fetch
  }, []);

  // Handle when the Edit button is clicked
  const handleEdit = (id) => {
    setEdit(true);
    setId(id);
    const person = people.find((p) => p.id === id);
    if (person) {
      setPin(person.TillPin); // Populate pin with the TillPin value from the selected person
    }
  };

  // Handle changes in the input field
  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  // Handle saving the updated pin and posting to /api/tillpin
  const handleSave = async (id) => {

    console.log("Saving pin:", pin);
    console.log("For ID:", id);
    try {
      // Send a POST request to save the new pin
      const response = await fetch("https://cheekydino.com/api/tillpin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,    // Send the ID of the staff member
          pin,   // Send the new TillPin
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // If successful, update the person's TillPin in the local state
      const updatedPeople = people.map((person) =>
        person.id === id ? { ...person, TillPin: pin } : person
      );
      setPeople(updatedPeople);
      setEdit(false); // Close the edit form after saving

    } catch (error) {
      console.error("Failed to save new pin:", error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Users
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>

      {edit && (
        <div>
          <label
            htmlFor="pin"
            className="block text-sm font-medium leading-6 text-gray-900 mt-5"
          >
            Pin Number
          </label>
          <div className="mt-2">
            <input
              id="pin"
              name="pin"
              type="text"
              value={pin} // Use 'pin' state for the input value
              onChange={handlePinChange} // Handle changes in the input
              placeholder="1234"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <button
            onClick={() => handleSave(id)} // Call handleSave with the id
            type="button"
            className="flex w-full mt-5 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Pin
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {people.map((person, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {person.Name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person.Email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person.TillPin}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => handleEdit(person.id)} // Wrap in an arrow function
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
