import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

export default function Labor() {
    const [staff, setStaff] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [cafeOrders, setCafeOrders] = useState([]);
    const [revenueStatus, setRevenueStatus] = useState('');
    const [estimatedTime, setEstimatedTime] = useState(null);
    const [staffToSendHome, setStaffToSendHome] = useState(null);
    const [messageSent, setMessageSent] = useState(false);

    const closingTime = '17:00';
    const targetLaborPercentage = 20;

    // Fetch cafe orders
    useEffect(() => {
        const fetchCafeOrders = async () => {
            const today = format(new Date(), 'yyyy-MM-dd');
            try {
                const response = await fetch(`https://cheekydino.com/api/cafeordersstats?date=${today}`);
                const data = await response.json();
                setCafeOrders(data.data);
            } catch (error) {
                console.error('Failed to fetch cafe orders:', error);
            }
        };
        fetchCafeOrders();
        const intervalId = setInterval(fetchCafeOrders, 60000);
        return () => clearInterval(intervalId);
    }, []);

    // Fetch sessions
    useEffect(() => {
        const fetchSessions = async () => {
            const today = format(new Date(), 'yyyy-MM-dd');
            try {
                const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`);
                const data = await response.json();
                setSessions(data.data);
            } catch (error) {
                console.error('Failed to fetch sessions:', error);
            }
        };
        fetchSessions();
        const intervalId = setInterval(fetchSessions, 60000);
        return () => clearInterval(intervalId);
    }, []);

    // Calculate total spend
    const todayTotalSpend = sessions.reduce((total, session) => total + (session.TotalSpent || 0), 0)
        + cafeOrders.reduce((total, order) => total + (order.Total || 0), 0);

    // Fetch clock-ins
    useEffect(() => {
        const fetchClockIns = async () => {
            try {
                const response = await fetch('https://cheekydino.com/api/clockins');
                const data = await response.json();
                setStaff(data.data);
            } catch (error) {
                console.error('Failed to fetch clock ins:', error);
            }
        };
        fetchClockIns();
        const intervalId = setInterval(fetchClockIns, 600000);
        return () => clearInterval(intervalId);
    }, []);

    // Calculate hours until closing
    const calculateHoursUntilClosing = (currentTime) => {
        const [closingHours, closingMinutes] = closingTime.split(':').map(Number);
        const closingDateTime = new Date();
        closingDateTime.setHours(closingHours, closingMinutes, 0);
        return (closingDateTime - currentTime) / (1000 * 60 * 60); // Return hours remaining
    };

    // Calculate variable labor percentage
    const variableLabourPercentage = (projectedRevenue) => {
        const currentTime = format(new Date(), 'HH:mm:ss');
        const staffClockedIn = staff.filter(member => member.ClockOut === null);

        const totalLabourCost = staffClockedIn.reduce((acc, member) => {
            const clockInTime = new Date(`1970-01-01T${member.ClockIn}Z`);
            const clockOutTime = new Date(`1970-01-01T${currentTime}Z`);
            const hoursWorked = (clockOutTime - clockInTime) / (1000 * 60 * 60);
            return acc + hoursWorked * (member.HourlyRate || 0);
        }, 0);

        return projectedRevenue > 0 ? (totalLabourCost / projectedRevenue) * 100 : 0;
    };

    // Evaluate staffing based on projected revenue and labor costs
    const evaluateStaffing = async () => {
        const currentTime = new Date();

        const futureBookings = sessions.filter(session =>
            session.TimeslotFrom > format(currentTime, 'HH:mm') &&
            session.Date === format(currentTime, 'yyyy-MM-dd') &&
            session.Arrived === 0
        );
        
        const potentialFutureRevenue = futureBookings.length * 20;
        const projectedRevenue = todayTotalSpend + potentialFutureRevenue;

        const currentLabourPercentage = variableLabourPercentage(projectedRevenue);
        const staffClockedIn = staff.filter(member => member.ClockOut === null);

        if (currentLabourPercentage > targetLaborPercentage && !messageSent && staffClockedIn.length > 1) {
            const message = {
                content: "Variable labor has now exceeded the 20% threshold", 
                email: "System", 
                createdAt: new Date().toISOString(),
                replied: 0
            };

            try {
                await fetch('https://cheekydino.com/api/addmessages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(message)
                });
                setMessageSent(true);
            } catch (error) {
                console.error('Failed to send message:', error);
            }
        }

        if (currentLabourPercentage > 22 && staffClockedIn.length > 1) {
            const mostExpensiveStaff = staffClockedIn.reduce((prev, current) => {
                return (!prev || (current.HourlyRate > prev.HourlyRate)) ? current : prev;
            }, null);

            setStaffToSendHome(mostExpensiveStaff ? mostExpensiveStaff.StaffName : null);
            setRevenueStatus(`Projected labor costs exceed revenue. Recommend sending home: ${mostExpensiveStaff ? mostExpensiveStaff.StaffName : 'No available staff'}`);
            setEstimatedTime(calculateHoursUntilClosing(currentTime));
        } else {
            setRevenueStatus('Keep staff. Revenue is projected to be sufficient or essential staff only.');
            setStaffToSendHome(null);
            setEstimatedTime(null);
        }
    };

    useEffect(() => {
        evaluateStaffing();
    }, [staff, sessions, cafeOrders]);

    return (
        <div className={staffToSendHome ? "bg-red-500 p-5 rounded-lg shadow-lg" : "bg-green-500 p-5 rounded-lg shadow-lg"}>
            <h1 className="text-white text-xl font-bold">Labor Management</h1>
            <h2 className="text-white">{revenueStatus}</h2>
            {estimatedTime !== null && (
                <div>
                    <h3 className="text-white">Estimated Time Until Revenue Exceeds Labor Costs: {estimatedTime.toFixed(2)} hours</h3>
                </div>
            )}
            {staffToSendHome && <div className="text-white">Recommended to send home: {staffToSendHome}</div>}
        </div>
    );
}
