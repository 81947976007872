import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditBooking from "./editbooking";
import SessionBooker from "./sessionbooker";
import StaffCalenderParty from './PartyStaffCalendar';
import { motion } from "framer-motion";
import { format } from "date-fns";
import EditBookings from './editBookings';


let ipcRenderer = null;
if (window && window.process && window.process.type) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

export default function MakeReservation() {
    const navigate = useNavigate();

    const [sessions, setSessions] = useState([]);
    const [addGuests, setAddGuests] = useState(null);

    console.log(sessions);

    useEffect(() => {

        const today = new Date();
        const formattedDate = format(today, 'yyyy-MM-dd');

        const fetchSessions = async () => {

            const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${formattedDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch session data');
            }

            const data = await response.json();
            console.log(data.data);

            const sessions = data.data

// filter data if GuestNotes is not null or empty
            const filterData = sessions.filter((session) => {
                return session.GuestNotes !== null && session.GuestNotes !== '';
            });

            console.log(filterData);
            setSessions(filterData);




        };

        fetchSessions();

    }, []);


if (addGuests) {
    return <EditBookings session= {addGuests} />;
}







   

   



return (
    <>
        <div className="fixed inset-0 flex items-center justify-center bg-white py-24 sm:py-32 text-center">
            <div className="flex w-full max-w-7xl mx-auto">
                {/* Left side: Sessions list */}
                <div className="w-1/3 bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Session List</h3>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        {sessions.length === 0 && (
                            <p className="text-sm text-gray-500">No Extra Guests Expected</p>
                        )}
                        {sessions.map((session, index) => (
                            <div key={session.id} className={`flex items-center justify-between ${index === 0 ? 'mt-0' : 'mt-4'}`}>
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="text-sm font-medium text-gray-900 truncate">{session.Name}</h3>
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            {session.Table}
                                        </span>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500 truncate">{session.GuestNotes}</p>
                                    <p className="mt-1 text-sm text-gray-500 truncate">{session.TimeslotFrom} - {session.TimeslotTo}</p>
                                </div>
                                <div className="flex-shrink-0 ml-5">
                                    <motion.button 
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        onClick={() => setAddGuests(session.id)} 
                                    >
                                        Add Guests
                                    </motion.button>
                                    <motion.button 
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        onClick={() => setDelete(session.id)} 
                                    >
                                        Delete Note
                                    </motion.button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Right side: Reservation actions */}
                <div className="w-2/3 ml-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            Make A Reservation
                        </h2>
                        <div className="mt-8 space-y-4">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-green-700 md:py-4 md:text-lg md:px-10 rounded-lg shadow-md"
                                onClick={() => navigate('/dashboard')}
                            >
                                Back
                            </motion.button>

                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-4 md:text-lg md:px-10 rounded-lg shadow-md"
                                onClick={() => {
                                    navigate('/booknow');
                                    if (ipcRenderer) {
                                        ipcRenderer.send('show-form');
                                    }
                                }}
                            >
                                Book For Now
                            </motion.button>

                            
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 md:py-4 md:text-lg md:px-10 rounded-lg shadow-md"
                                onClick={() => navigate('/partybooking')}
                            >
                                New Party Booking
                            </motion.button>

                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10 rounded-lg shadow-md"
                                onClick={() => navigate('/booklater')}
                            >
                                Book For Later
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

}
