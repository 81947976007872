import React, { useState, useEffect } from 'react';
import './customerfont.css';
import { format, parseISO, isAfter, isBefore, isWithinInterval } from 'date-fns';
import JsxParser from 'react-jsx-parser';


export default function HeroSection() {

    const [adverts, setAdverts] = useState([]);
  


    useEffect(() => {
        const fetchAdverts = async () => {
            try {
                const response = await fetch("https://cheekydino.com/api/adverts", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log("Fetched data:", data);

                let adverts = data.data;

                const todaysDate = format(new Date(), 'yyyy-MM-dd');
                console.log("Today's date:", todaysDate);

                const dayOfWeek = format(new Date(), 'EEEE');
                console.log("Day of the week:", dayOfWeek);

                let currentTime = format(new Date(), 'HH:mm:ss');
                console.log("Current time:", currentTime);

                // Filter adverts based on Website === 1
                const filteredAdverts = adverts.filter(advert => {
                    const websiteMatch = advert.Website === 1 && advert.Activate === 1;
                    console.log(`Advert ${advert.ID} Website check (Website === 1):`, websiteMatch);
                    return websiteMatch;
                });

                console.log("Adverts after Website filter:", filteredAdverts);

                // Further filter adverts based on SetDay, day, date, and time
                const finalFilteredAdverts = filteredAdverts.filter(advert => {
                    try {
                        console.log(`Processing Advert ID: ${advert.ID}, SetDay: ${advert.SetDay}`);

                        if (advert.SetDay === 1) {
                            // Convert advert.Days into an array
                            const advertDaysArray = advert.Days.split(',').map(day => day.trim());
                            console.log(`Advert ${advert.ID} Days Array:`, advertDaysArray);

                            // Check if the current day is in the array
                            const dayMatch = advertDaysArray.includes(dayOfWeek);
                            console.log(`Advert ${advert.ID} Day of Week Match:`, dayMatch);

                            if (dayMatch) {
                                // Check if the current time is between StartTime and EndTime
                                const timeMatch = currentTime >= advert.StartTime && currentTime <= advert.EndTime;
                                console.log(`Advert ${advert.ID} Time Match (${advert.StartTime} - ${advert.EndTime}):`, timeMatch);
                                return timeMatch;
                            }
                        } else if (advert.SetDay === 0) {
                            // Parse StartDate and EndDate
                            const startDate = parseISO(advert.StartDate);
                            const endDate = parseISO(advert.EndDate);

                            console.log(`Advert ${advert.ID} StartDate:`, startDate);
                            console.log(`Advert ${advert.ID} EndDate:`, endDate);

                            // Ensure valid dates and check order
                            const validDates = !isNaN(startDate) && !isNaN(endDate) && isBefore(startDate, endDate);
                            console.log(`Advert ${advert.ID} Valid Dates:`, validDates);

                            if (validDates) {
                                // Check if the current date is between StartDate and EndDate
                                const dateMatch = isWithinInterval(new Date(), { start: startDate, end: endDate });
                                console.log(`Advert ${advert.ID} Date Match:`, dateMatch);

                                if (dateMatch) {
                                    // Check if the current time is between StartTime and EndTime
                                    const timeMatch = currentTime >= advert.StartTime && currentTime <= advert.EndTime;
                                    console.log(`Advert ${advert.ID} Time Match (${advert.StartTime} - ${advert.EndTime}):`, timeMatch);
                                    return timeMatch;
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Error processing advert:', advert, error);
                    }

                    console.log(`Advert ${advert.ID} Excluded.`);
                    return false; // Exclude advert if it doesn't meet criteria or if there's an error
                });

                console.log("Final filtered adverts:", finalFilteredAdverts);

                // Do something with finalFilteredAdverts, such as setting state
                // setAdverts(finalFilteredAdverts);

                // now final filter is .ComponentOrder i want the adverts to be ordered by this as they are intergers

                finalFilteredAdverts.sort((a, b) => a.ComponentOrder - b.ComponentOrder);

                setAdverts(finalFilteredAdverts);

            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        }

        fetchAdverts();

        // Fetch adverts every 30 seconds
        const interval = setInterval(() => {
            fetchAdverts();
        }, 3000);

        return () => clearInterval(interval);

    }, []);


 



    return (
        <div>
            {adverts.length > 0 ? (
                adverts.map((advert) => (
                    <div key={advert.id} className="relative bg-white mb-10 mt-5 p-4 border border-gray-200 rounded">
                        <JsxParser jsx={advert.Code} />
                    </div>
                ))
            ) : (
                <div className="text-center">No adverts found</div>
            )}

        </div>
    );
}
