import React, { useState, useEffect } from 'react';
import { ClockIcon, MoonIcon, SunIcon } from '@heroicons/react/20/solid';
import ClockInData from './clockindata';
import Modal from './modal';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { set } from 'lodash';
import Online from './online';
import LoadingComponent from './loading';

export default function StaffActions() {
  const [staffList, setStaffList] = useState([]);
  const [clock, setClock] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchStaff = async () => {
    try {
      // Fetch staff data
      const staffResponse = await fetch('https://cheekydino.com/api/staff', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const staffData = await staffResponse.json();

      if (!staffResponse.ok) {
        throw new Error(staffData.message || 'Failed to fetch staff data.');
      }

      const staff = staffData.data;

      // Fetch clock-in data
      const clockInResponse = await fetch('https://cheekydino.com/api/clockin', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const clockInData = await clockInResponse.json();

      if (!clockInResponse.ok) {
        throw new Error(clockInData.message || 'Failed to fetch clock-in data.');
      }

      const clockInDataAll = clockInData.data;

      // Debug logging
      console.log('ClockIn Data:', clockInDataAll);

      // Process and merge data
      const staffWithClockInStatus = staff.map((staffMember) => {
        const id = staffMember.id;
        const today = format(new Date(), 'yyyy-MM-dd'); // Format the date to 'YYYY-MM-DD'

        const clockInDataForUser = clockInDataAll.find(
          (c) => {
            // Convert ISO date to comparable format
            const clockInDate = parseISO(c.Date);
            const clockInDateFormatted = format(clockInDate, 'yyyy-MM-dd');
            
            return c.StaffId === id && clockInDateFormatted === today;
          }
        );

        // Debug logging
        console.log(`Staff Member: ${staffMember.Name}`);
        console.log(`ClockInDataForUser:`, clockInDataForUser);

        return {
          ...staffMember,
          isClockedIn: clockInDataForUser ? clockInDataForUser.ClockedIn === 1 && clockInDataForUser.ClockedOut === 0 : false,
          isOnBreak: clockInDataForUser ? clockInDataForUser.Break === 1 : false,
          clockInTime: clockInDataForUser ? clockInDataForUser.ClockIn : null, // Add ClockIn field here
        };
      });

      // Debug logging
      console.log('Staff with Clock-In Status:', staffWithClockInStatus);

      setStaffList(staffWithClockInStatus);
    } catch (error) {
      console.error('Error fetching staff data:', error);
    }
  };

  useEffect(() => {
    fetchStaff();
  }, [reload]);


  

  console.log('Staff List:', staffList);


  const handleClockInOut = async (staff) => {
    setIsLoading(true); // Start loading

    const id = staff.id;
    const now = new Date();
    const timeOnly = format(now, 'HH:mm:ss');
    const dateOnly = format(now, 'yyyy-MM-dd');

    try {
        const clockInResponse = await fetch('https://cheekydino.com/api/clockin', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const clockInData = await clockInResponse.json();
        const clockInDataAll = clockInData.data;

        const clockInDataFiltered = clockInDataAll.find((c) => {
            const clockInDate = parseISO(c.Date);
            const clockInDateFormatted = format(clockInDate, 'yyyy-MM-dd');
            return c.StaffId === id && clockInDateFormatted === dateOnly;
        });

        // Debug logging
        console.log(`ClockInDataForUser:`, clockInDataFiltered);

        if (clockInDataFiltered && clockInDataFiltered.ClockedIn === 1 && clockInDataFiltered.ClockedOut === 0) {
            // If the staff member is already clocked in but not clocked out, update the ClockOut time and ClockedOut status
            const clockInTime = new Date(`${dateOnly}T${clockInDataFiltered.ClockIn}`);
            const clockOutTime = now;

            // Calculate the shift length in minutes
            const shiftLengthInMinutes = (clockOutTime - clockInTime) / 60000;
            const totalPay = shiftLengthInMinutes * (staff.HourlyRate / 60); // Convert hourly rate to per minute rate

            await fetch(`https://cheekydino.com/api/clockin/addput/${clockInDataFiltered.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ClockOut: timeOnly,
                    ClockedOut: 1,
                    ClockedIn: 0,
                    HoursWorked: parseFloat(shiftLengthInMinutes) / 60, // Convert minutes to hours
                    TotalPay: parseFloat(totalPay),
                }),
            });

            console.log(`${staff.Name} clocked out`);
            setReload(true);
        } else {
            // Fetch today's shifts
            const todaysshiftResponse = await fetch('https://cheekydino.com/api/todaysshift', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!todaysshiftResponse.ok) {
                throw new Error('Failed to fetch todaysshift data.');
            }

            const todaysshiftData = await todaysshiftResponse.json();
            const todaysshift = todaysshiftData.data;

            // Check the StaffId of the staff member and match it with the StaffId of the shift
            const shift = todaysshift.find((shift) => shift.StaffId === id);

            if (!shift) {
                throw new Error('No shift found for the staff member.');
            }

            // Find the shift start time
            const shiftStartTime = shift.StartTime;

            // Check if the staff member is late
            const late = shiftStartTime < timeOnly ? 1 : 0;

            // If the staff member is not clocked in, save a new ClockIn entry
            await fetch('https://cheekydino.com/api/clockin/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ClockIn: timeOnly,
                    ClockedIn: 1,
                    ClockedOut: 0,
                    HourlyRate: staff.HourlyRate,
                    StaffId: staff.id,
                    Date: dateOnly,
                    StaffName: staff.Name,
                    Late: late,
                    TillPin : staff.TillPin,
                }),
            });

            console.log(`${staff.Name} clocked in`);
        }
    } catch (error) {
        console.error('Error handling clock in/out:', error);
        setError("No Shift Found For This Staff Member, Cannot Clock In");
    }

    setIsLoading(false);
    setReload(true);
};

  

  const handleBreakStartEnd = async (staff) => {
    setIsLoading(true); // Start loading
    const id = staff.id;
    const now = new Date();
    const timeOnly = format(now, 'HH:mm:ss');
    const dateOnly = format(now, 'yyyy-MM-dd');
  
    try {
      const clockInResponse = await fetch('https://cheekydino.com/api/clockin', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const clockInData = await clockInResponse.json();
      const clockInDataAll = clockInData.data;
  
      const clockInDataFiltered = clockInDataAll.filter(c => {
        const clockInDate = parseISO(c.Date);
        const clockInDateFormatted = format(clockInDate, 'yyyy-MM-dd');
        return c.StaffId === id && clockInDateFormatted === dateOnly;
      });
  
      if (clockInDataFiltered.length > 0 && clockInDataFiltered[0].Break === 1) {
        // If the staff member is on break, update the BreakEnd time and Break status
        const breakStart = new Date(`${dateOnly}T${clockInDataFiltered[0].BreakStart}`);
        const breakEnd = now;
        const breakLength = (breakEnd - breakStart) / 60000; // Calculate break length in minutes
  
        await fetch(`https://cheekydino.com/api/clockin/breakend/${clockInDataFiltered[0].id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            BreakEnd: timeOnly,
            Break: 0,
            StaffBreak: parseFloat(breakLength),
          }),
        });
  
        console.log(`${staff.Name} ended break`);
        setReload(true);
      } else if (clockInDataFiltered.length > 0) {
        // If the staff member is not on break, update the BreakStart time and Break status
        await fetch(`https://cheekydino.com/api/clockin/breakstart/${clockInDataFiltered[0].id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            BreakStart: timeOnly,
            Break: 1,
          }),
        });
  
        console.log(`${staff.Name} started break`);
        setReload(true);
      } else {
        console.log('No clock-in record found for today.');
      }
    } catch (error) {
      console.error('Error handling break start/end:', error);
    }
  
    setIsLoading(false);
setReload(true);  };
  
  if (clock === true) {
    return <ClockInData />;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Clock Ins</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {error && <div className="text-red-500">{error}</div>}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => setClock(true)}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Clock In Data
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      ClockIn/Out
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Break
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {staffList.map((staff) => (
                    <tr key={staff.Name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {staff.Name} - {staff.clockInTime}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button
                          className={`bg-purple-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-md ${staff.isClockedIn ? 'bg-red-500' : 'bg-blue-500'}`}
                          onClick={() => handleClockInOut(staff)}
                        >
                          {staff.isClockedIn ? <ClockIcon className="h-5 w-5 mr-2" /> : <MoonIcon className="h-5 w-5 mr-2" />}
                          {staff.isClockedIn ? 'Clock Out' : 'Clock In'}
                        </button>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button
                          className={`bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-md ${staff.isOnBreak ? 'bg-green-500' : 'bg-blue-500'}`}
                          onClick={() => handleBreakStartEnd(staff)}
                        >
                          {staff.isOnBreak ? <MoonIcon className="h-5 w-5 mr-2" /> : <SunIcon className="h-5 w-5 mr-2" />}
                          {staff.isOnBreak ? 'Back From Break' : 'Start Break'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
