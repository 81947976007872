import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'; // Make sure to have a context for Auth
import { parse, format, addHours } from 'date-fns';
import '../customerfont.css';
import { gu } from 'date-fns/locale';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function CustomerDashboard() {
  const [childName, setChildName] = useState('');
  const [childAge, setChildAge] = useState('');
  const [noOfChildren, setNoOfChildren] = useState('');
  const [foodOptionSelected, setFoodOptionSelected] = useState('');
  const [telephone, setTelephone] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  
  const { selectedDate, selectedTimeSlot, partyType } = location.state;

  const user = useContext(AuthContext);

  




  console.log(selectedDate);
  console.log(selectedTimeSlot);
  console.log(partyType);
  console.log(user);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    // create a unique booking reference thats a number and random 10 digit number

    const bookingReference = Math.floor(1000000000 + Math.random() * 9000000000);





    console.log('User:', user);

    const name = partyType[0].name;
    const character = partyType[0].character;

    const email = user.email;
    const selectedTimeSlotWithOffset = addHours(parse(selectedTimeSlot, 'HH:mm', new Date()), 2.5);
    const selectedTimeSlotFood = addHours(parse(selectedTimeSlot, 'HH:mm', new Date()), 1.5);

    // create 10 digit booking reference
    const guest_id = Math.floor(1000000000 + Math.random() * 9000000000);


    // Calculate table recommendation logic here
    let recommendedTable = 40; // Simplified for example
    let basePrice = 0;
    let childPrice = 0;

    if (partyType[0].name === 'Laser') {
      basePrice = 190;
      childPrice = 19.95;
    } else if (partyType[0].name === 'T-Rex') {1
      basePrice = 145;
      childPrice = 14.50;
    } else if (partyType[0].name === 'Character') {
      basePrice = 290;
      childPrice = 19.95;
    } else if (partyType[0].name === 'Teddy') {
      basePrice = 215;
      childPrice = 19.95;
    } else if (partyType[0].name === 'Football') {
      basePrice = 290;
      childPrice = 19.95;
    } else if (partyType[0].name === 'Disco') {
      basePrice = 290;
      childPrice = 19.95;
    }

    let totalPrice = basePrice;
    if (noOfChildren > 10) {
      totalPrice += (noOfChildren - 10) * childPrice;
    }

    try {
        const response = await fetch('https://cheekydino.com/api/addpartybooking', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            telephone,
            childName,
            childAge,
            noOfChildren,
            foodOptionSelected,
            selectedDate,
            selectedTimeSlot,
            partyType: name,
            character: character,
            totalPrice,
            partyFoodComplete: false,
            partyFinish: format(selectedTimeSlotWithOffset, 'HH:mm'),
            partyFoodTimeDue: format(selectedTimeSlotFood, 'HH:mm'),
            table: recommendedTable,
            login_id: user.login_id,
            bookingReference,

          }),
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('New booking added:', result);


      const postMessage = await fetch('https://cheekydino.com/api/addmessages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: `New Party Booking: ${name} for ${childName} on ${selectedDate} at ${selectedTimeSlot}`,
          email: user.email,
          createdAt : new Date(),
          replied: 0
        }),
      });

      if (!postMessage.ok) {
        throw new Error('Network response was not ok');
      }

      const messageResult = await postMessage.json();
      console.log('New message added:', messageResult);

      

      setLoading(false);
      navigate('/my-booking', { state: { selectedDate, selectedTimeSlot, partyType } });
      window.location.reload();
    } catch (error) {
      console.error('Error adding new booking:', error);
      setLoading(false);
    }
  }

  const backgroundImage= 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzZhYTBkZjVxbjlrd3dqb3IzeGJ2eHN3NWlkMHd2cGtqc3JwM3R3ciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/4njbG9gEe2c5j2RIWD/giphy.gif';

  return (
    <div
      className="bg-cover bg-center py-24 sm:py-32 flex items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="w-full max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold component-title tracking-tight text-gray-900 sm:text-6xl text-center">
            Your Booking
          </h2>
          <div className='mt-5 mb-5 border-solid'>
            {partyType.name === "Laser" && (
              <p className="component-title text-center">
                Any Number of Children over 10 - £19.95 per child
              </p>
            )}
            {partyType.name === "Character" && (
              <p className="component-title text-center">
                Any Number of Children over 10 - £19.95 per child
              </p>
            )}
            {partyType.name === "Football" && (
              <p className="component-title text-center">
                Any Number of Children over 10 - £19.95 per child
              </p>
            )}
            {partyType.name === "T-Rex" && (
              <p className="component-title text-center">
                Any Number of Children over 10 - £14.50 per child
              </p>
            )}
            {partyType.name === "Teddy" && (
              <p className="component-title text-center">
                Any Number of Children over 10 - £19.95 per child
              </p>
            )}
            {partyType[0].name === "Disco" && (
              <p className="component-title text-center">
                Any Number of Children over 10 - £19.95 per child
              </p>
            )}
          </div>

          {selectedDate && (
            <p className="component-title text-center">Date: {selectedDate}</p>
          )}
          {selectedTimeSlot && (
            <p className="component-title text-center">
              Time Slot: {selectedTimeSlot}
            </p>
          )}
          {partyType && (
            <p className="component-title text-center">
              Party Type: {partyType[0].name}
            </p>
          )}
          {partyType && (
            <p className="component-title text-center">
              Price: £{partyType[0].price}
            </p>
          )}
          {partyType[0].character && (
            <p className="component-title text-center">
              Character {partyType[0].character}
            </p>
          )}

          <form onSubmit={handleSubmit} className="mt-6 space-y-4">
           
            <input
              type="text"
              value={childName}
              onChange={(e) => setChildName(e.target.value)}
              placeholder="Child Name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
            />
            <input
              type="number"
              value={childAge}
              onChange={(e) => setChildAge(Number(e.target.value))}
              placeholder="Child Age"
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
            />

            <input
              type="number"
              value={noOfChildren}
              onChange={(e) => setNoOfChildren(Number(e.target.value))}
              placeholder="Number of Children"
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
            />
            <select
              value={foodOptionSelected}
              onChange={(e) => setFoodOptionSelected(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Food Option</option>
              <option value="Hot">Hot Food</option>
              <option value="Cold">Cold Food</option>
              <option value="Pizza">Pizza Buffet</option>
            </select>

            {loading && (
              <div className="flex items-center justify-center">
                <p className="mr-2">Loading...</p>
              </div>
            )}
            <button
              type="submit"
              className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
