import React, { useEffect, useState } from 'react';
import { format, addDays, startOfWeek } from 'date-fns';
import Shifts from './shifts';
import { CheckIcon } from '@heroicons/react/24/solid';

const ShiftBooking = () => {
  const [staff, setStaff] = useState([]);
  const [shiftDetails, setShiftDetails] = useState([]);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [holiday, setHoliday] = useState([]);
  const [holidayShifts, setHolidayShifts] = useState([]);

  console.log(holiday);


  useEffect(() => {
    const fetchHoliday = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/holiday', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        const holidayData = data.data;
        console.log(holidayData);


        // match the StaffId to the state of staff to get StaffName

        const holidayDataWithNames = holidayData.map(holiday => {
          const staffMember = staff.find(staffMember => staffMember.id === holiday.StaffId);
          return {
            ...holiday,
            Name: staffMember ? staffMember.Name : 'Unknown',
            Role: staffMember ? staffMember.Role : 'Unknown'
          };

        });



        setHoliday(holidayDataWithNames);
      } catch (error) {
        console.error('Failed to fetch holiday:', error);
      }
    };

    fetchHoliday();
  }, [staff]);


  useEffect(() => {
    // Fetch staff members from the server
    const fetchStaff = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/staff', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch staff');
        }

        const data = await response.json();
        setStaff(data.data);
      } catch (error) {
        console.error('Error fetching staff:', error);
      }
    };

    fetchStaff();
  }, []);




  useEffect(() => {
    // Initialize shift details for the next week starting from next Monday
    if (staff.length > 0) {
      // Get the start of the next week (next Monday)
      const startOfNextWeek = addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7);
      
      const initialShiftDetails = staff.map(staffMember => ({
        staffId: staffMember.id, // Assuming each staff member has an ID
        name: staffMember.Name,
        role: staffMember.Role,

        shifts: Array.from({ length: 7 }, (_, dayIndex) => ({
          date: format(addDays(startOfNextWeek, dayIndex), 'yyyy-MM-dd'),
          startTime: '',
          endTime: '',
        })),
      }));
      
      setShiftDetails(initialShiftDetails);
    }
  }, [staff, holidayShifts]);
  
  // Handle changes to shift details
  const handleChange = (staffIndex, dayIndex, e) => {
    const { name, value } = e.target;
    const updatedShifts = [...shiftDetails];
    updatedShifts[staffIndex].shifts[dayIndex][name] = value; // Update the specific input value
    setShiftDetails(updatedShifts); // Update state to trigger re-render
  };

  // Handle publish for a particular staff member's shifts
  // Handle publish for a particular staff member's shifts
const handlePublish = async (staffMember) => {
  for (const shift of staffMember.shifts) {
    if (shift.startTime && shift.endTime) { // Only save if times are provided
      try {
        const response = await fetch('https://cheekydino.com/api/shifts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            StaffId: staffMember.staffId, // Use the staff ID
            Name: staffMember.name, // Make sure to pass the staff member's name
            ShiftDate: shift.date,
            StartTime: shift.startTime,
            EndTime: shift.endTime
          })
        });

        if (!response.ok) {
          throw new Error('Failed to save shift');
        }
      } catch (error) {
        console.error('Error saving shift:', error);
      }
    }
  }

  console.log(`Shifts saved successfully for ${staffMember.name}`);
};


  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const correctPassword = '8568'; // Change this to your desired password
    
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      setError('Incorrect password');
    }
  };

  useEffect(() => {

    const fetchShifts = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/shifts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }

        });

        if (!response.ok) {
          throw new Error('Failed to fetch shifts');
        }

        const data = await response.json();

        const shifts = data.data;

        console.log(shifts);
        setHolidayShifts(shifts);

       


      }

      catch (error) {
        console.error('Error fetching shifts:', error);
      }

    };

    fetchShifts();

  }, []);


  const handleApprove = async (person) => {

    try {

      const response = await fetch(`https://cheekydino.com/api/approve/holiday/${person.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({
          Holiday: 1,
          FromDate: person.FromDate,
          ToDate: person.ToDate,
          Name: person.Name,
          StaffId: person.StaffId,

        })

      });

      if (!response.ok) {
        throw new Error('Failed to approve holiday');
      }

      const updatedHoliday = holiday.map(holiday => {
        if (holiday.id === person.id) {
          return { ...holiday, Approved: 1 };

        }

        return holiday;

      });

      setHoliday(updatedHoliday);

    } catch (error) {
      console.error('Error approving holiday:', error);
    }

  };


  // find out based on staff.Role how many of each role there are

  const roles = staff.reduce((acc, staffMember) => {
    const role = staffMember.Role;
    if (acc[role]) {
      acc[role] += 1;
    } else {
      acc[role] = 1;
    }
    return acc;
  }, {});

  console.log(roles);






          


  

  if (!isAuthenticated) {
    return (
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="/versa.gif"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Management Access Only
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleLoginSubmit} method="POST" className="space-y-6">
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          {error && <p className="mt-4 text-red-600 text-sm text-center">{error}</p>}
        </div>
      </div>
    );
  }

  return (
    <>
      <Shifts />



      <div className="mt-8">
            <h2 className="text-lg font-semibold text-blue-500 mt-5 mb-5">Holiday Table</h2>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
               
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Name</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Role</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">From</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">To</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Reason</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Approved</th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {holiday.map(person => (
                      <tr key={person.id}>
                        <td className="py-4 text-sm font-medium text-gray-900">{person.Name}</td>
                        <td className="py-4 text-sm text-green-500">{person.Role}</td>
                        <td className="py-4 text-sm text-gray-900">{person.FromDate}</td>
                        <td className="px-3 text-sm text-gray-500">{person.ToDate}</td>
                        <td className="px-3 text-sm text-gray-500">{person.Notes}</td>
<td className="px-3 text-sm text-gray-500">
  {person.Approved === 1 ? <CheckIcon className='h-5 w-5 text-green-500'/> : "No"}
  {person.Approved === 0 && (
  <button onClick={() => handleApprove(person)} className="bg-green-500 text-white rounded-md px-2 py-1 hover:bg-blue-600 ml-2">
    Approve
  </button>
  )}

</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

   




      <div className="mt-8 flow-root mt-5 mb-5">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <ul className="flex">
                  {Object.entries(roles).map(([role, count]) => (
                    <li key={role} className="mr-4">
                      <span className="text-sm font-semibold text-green-500">{role}: {count}</span>
                    </li>
                  ))}
                </ul>
            <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-purple-700 sm:pl-0">
                    Name
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-purple-700 sm:pl-0">
                    Role
                  </th>
                              {shiftDetails[0]?.shifts.map((shift, dayIndex) => (


              <th key={dayIndex} scope="col" className="py-3.5 pl-4 pr-3 mr-5 text-left text-sm font-semibold text-blue-500 sm:pl-0">
                {format(new Date(shift.date), 'EEEE, MMM d')}
              </th>
            ))}
             <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Publish</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
        {shiftDetails.map((staffMember, staffIndex) => (
            <tr key={staffIndex}>
  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">                {staffMember.name}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">                {staffMember.role}
              </td>
              {staffMember.shifts.map((shift, dayIndex) => (
                <td key={dayIndex} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"> 
                  <div>
                    <input
                      type="time"
                      name="startTime"
                      className="border border-gray-300 rounded-md py-1 px-2"
                      value={shift.startTime}
                      onChange={(e) => handleChange(staffIndex, dayIndex, e)}
                    />
                    <input
                      type="time"
                      name="endTime"
                      className="border border-gray-300 rounded-md py-1 px-2 ml-2"
                      value={shift.endTime}
                      onChange={(e) => handleChange(staffIndex, dayIndex, e)}
                    />
                  </div>
                    
                </td>
              ))}
             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <button
                  onClick={() => handlePublish(staffMember)}
                  className="bg-green-500 text-white rounded-md px-2 py-1 hover:bg-blue-600"
                >
                  Publish
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>
    </>
  );
};

export default ShiftBooking;
