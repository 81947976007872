import { useState, useEffect } from 'react';

export default function Announcements() {
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
  const [selectedOption, setSelectedOption] = useState('date'); // State to track selected option ('day' or 'date')
  const [content, setContent] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedDays, setSelectedDays] = useState([]); // To handle selected days in 'day' mode
  const [reload, setReload] = useState(false);

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  useEffect(() => {
    async function fetchAnnouncements() {
      try {
        const response = await fetch("https://cheekydino.com/api/announcements", {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data);
        setFilteredAnnouncements(data.data);
      } catch (error) {
        console.error('There was an error!', error);
      }
    }

    fetchAnnouncements();
  }, [reload]);

  const notificationMethods = [
    { id: 1, title: 'Day' },
    { id: 0, title: 'Date' },
  ];

  const [open, setOpen] = useState(false);

  const handleSaveAnnouncements = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://cheekydino.com/api/announcements", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Content: content,
          SetDay: selectedOption === 'day' ? 1 : 0,
          StartDate: selectedOption === 'day' ? null : startDate,
          EndDate: selectedOption === 'day' ? null : endDate,
          StartTime: startTime,
          EndTime: endTime,
          Days: selectedOption === 'day' ? selectedDays : null,
          Activate: 1,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      setReload(!reload); // Toggle reload to refresh data
      setOpen(false);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://cheekydino.com/api/announcements/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setReload(!reload); // Toggle reload to refresh data
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title, email, and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add Announcement
          </button>
        </div>
      </div>

      {open && (
        <div className="mt-4">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Add Announcement</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Add a new announcement to the list of announcements.</p>
              </div>
              <form onSubmit={handleSaveAnnouncements} className="mt-5 space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Content
                  </label>
                  <textarea
                    onChange={(event) => setContent(event.target.value)}
                    type="text"
                    name="name"
                    id="name"
                    rows={3}
                    value={content}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Announcement content"
                  />
                </div>

                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900">Set for Day or Date</legend>
                  <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    <div className="flex items-center">
                      <input
                        id="date"
                        name="schedule-option"
                        type="radio"
                        value="date"
                        checked={selectedOption === 'date'}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="date" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Date
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="day"
                        name="schedule-option"
                        type="radio"
                        value="day"
                        checked={selectedOption === 'day'}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="day" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Day
                      </label>
                    </div>
                  </div>
                </fieldset>

                {selectedOption === 'date' ? (
                  <>
                    <div>
                      <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <input
                        onChange={(event) => setStartDate(event.target.value)}
                        type="date"
                        name="startDate"
                        id="startDate"
                        value={startDate}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Start Date"
                      />
                    </div>
                    <div>
                      <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                        End Date
                      </label>
                      <input
                        onChange={(event) => setEndDate(event.target.value)}
                        type="date"
                        name="endDate"
                        id="endDate"
                        value={endDate}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="End Date"
                      />
                    </div>
                    <div>
                      <label htmlFor="startTime" className="block text-sm font-medium text-gray-700">
                        Start Time
                      </label>
                      <input
                        onChange={(event) => setStartTime(event.target.value)}
                        type="time"
                        name="startTime"
                        id="startTime"
                        value={startTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Start Time"
                      />
                    </div>
                    <div>
                      <label htmlFor="endTime" className="block text-sm font-medium text-gray-700">
                        End Time
                      </label>
                      <input
                        onChange={(event) => setEndTime(event.target.value)}
                        type="time"
                        name="endTime"
                        id="endTime"
                        value={endTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="End Time"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Select Days of the Week</label>
                      <div className="mt-2 grid grid-cols-3 gap-2">
                        {daysOfWeek.map((day) => (
                          <div key={day} className="flex items-center">
                            <input
                              id={day}
                              name="days"
                              type="checkbox"
                              value={day}
                              onChange={(event) => {
                                const { checked, value } = event.target;
                                setSelectedDays((prev) =>
                                  checked ? [...prev, value] : prev.filter((day) => day !== value)
                                );
                              }}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor={day} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              {day}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="startTime" className="block text-sm font-medium text-gray-700">
                        Start Time
                      </label>
                      <input
                        onChange={(event) => setStartTime(event.target.value)}
                        type="time"
                        name="startTime"
                        id="startTime"
                        value={startTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Start Time"
                      />
                    </div>
                    <div>
                      <label htmlFor="endTime" className="block text-sm font-medium text-gray-700">
                        End Time
                      </label>
                      <input
                        onChange={(event) => setEndTime(event.target.value)}
                        type="time"
                        name="endTime"
                        id="endTime"
                        value={endTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="End Time"
                      />
                    </div>
                  </>
                )}

                <div className="mt-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Content
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Set Day
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Dates
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Times
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Days
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Active
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredAnnouncements.map((announcement) => (
                  <tr key={announcement.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {announcement.Content}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{announcement.SetDay}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {announcement.StartDate && announcement.EndDate ? (
    <>
      {new Date(announcement.StartDate).toISOString().slice(0, 10)} - {new Date(announcement.EndDate).toISOString().slice(0, 10)}
    </>
  ) : (
    "Not Applicable"
  )}
</td>


                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {announcement.StartTime} - {announcement.EndTime}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {announcement.Days ? announcement.Days : "Not Applicable"}
</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {announcement.Activate === 1 ? 'Active' : 'Inactive'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium">
                      <button
                        onClick={() => {
                          const updateAnnouncement = async () => {
                            try {
                              const response = await fetch(`https://cheekydino.com/api/announcementactivate/${announcement.id}`, {
                                method: 'PUT',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                  Activate: announcement.Activate === 1 ? 0 : 1,
                                }),
                              });

                              if (response.ok) {
                                const data = await response.json();
                                console.log(data);
                                setReload(!reload); // Toggle reload to refresh data
                              } else {
                                console.error('Failed to update announcement');
                              }
                            } catch (error) {
                              console.error('Error:', error);
                            }
                          };

                          updateAnnouncement();
                        }}
                        type="button"
                        className={`block rounded-md mr-3 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${announcement.Activate === 1 ? 'bg-purple-500 hover:bg-green-400 focus-visible:outline-red-600' : 'bg-green-500 hover:bg-red-400 focus-visible:outline-green-600'}`}
                      >
                        {announcement.Activate === 1 ? 'Deactivate' : 'Activate'}
                      </button>
                      <button
                        onClick={() => handleDelete(announcement.id)}
                        type="button"
                        className="block rounded-md px-3 mt-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-red-600 hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
