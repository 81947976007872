import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const TillParty = ({ selectedParty }) => {
  const [partyDetails, setPartyDetails] = useState([]);
  const [partyGuests, setPartyGuests] = useState([]);
  const [clientArrived, setClientArrived] = useState(false);
  const [partyFinished, setPartyFinished] = useState(false);
  const [partyTimeRemaining, setPartyTimeRemaining] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedParty) {
      fetchPartyBooking();
      fetchPartyGuests();
    }
  }, [selectedParty]);

  useEffect(() => {
    if (partyDetails.length > 0) {
      const interval = setInterval(() => {
        const now = new Date();
        const [hours, minutes, seconds] = partyDetails[0].PartyTime.split(':');
        const partyStartTime = new Date();
        partyStartTime.setHours(hours, minutes, seconds, 0);
        
        const timeRemaining = partyStartTime - now;

        if (timeRemaining > 0) {
          const hoursRemaining = Math.floor(timeRemaining / (1000 * 60 * 60));
          const minutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
          const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);
          setPartyTimeRemaining(`${hoursRemaining}h ${minutesRemaining}m`);
        } else {
          setPartyTimeRemaining('Party Started');
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [partyDetails]);


  

  // Fetch Party Booking details
  const fetchPartyBooking = async () => {
    try {
      const response = await fetch(`https://cheekydino.com/api/partybookingid/${selectedParty}`);
      if (!response.ok) throw new Error('Failed to fetch party booking');
      const data = await response.json();

      setPartyDetails(data.data);
      setClientArrived(data.data[0].Arrived === 1);
    } catch (error) {
      console.error('Error fetching party booking:', error);
    }
  };

  // Fetch Party Guests
  const fetchPartyGuests = async () => {
    try {
      const response = await fetch(`https://cheekydino.com/api/partyguestsid/${selectedParty}`);
      if (!response.ok) throw new Error('Failed to fetch party guests');
      const data = await response.json();
      setPartyGuests(data.data);
    } catch (error) {
      console.error('Error fetching party guests:', error);
    }
  };

  // Handle Guest Arrival
  const handleGuestArrival = async (guestId) => {
    try {
      await fetch(`https://cheekydino.com/api/partyguest/arrived/${guestId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Arrived: 1, ArrivalTime: new Date().toISOString(), guest_id: selectedParty }),
      });
      fetchPartyGuests();
    } catch (error) {
      console.error('Error updating guest arrival:', error);
    }
  };

  // Handle Mistake in Guest Arrival
  const handleMistake = async (guestId) => {
    try {
      await fetch(`https://cheekydino.com/api/partyguest/mistake/${guestId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Arrived: 0, ArrivalTime: null, guest_id: selectedParty }),
      });
      fetchPartyGuests();
    } catch (error) {
      console.error('Error updating guest mistake:', error);
    }
  };

  // Handle Client Arrival
  const handleClientArrival = async () => {

    const partyHost = partyDetails[0].PartyHost;
    try {
      await fetch(`https://cheekydino.com/api/clientarrival/${selectedParty}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ PartyChildMumArrived: new Date().toISOString(), PartyHost: partyHost }),
      });
      setClientArrived(true);
      fetchPartyBooking();
    } catch (error) {
      console.error('Error handling client arrival:', error);
    }
  };

  // Handle Party Finish
  const handlePartyFinish = async () => {
    try {
      const date = new Date();
      const awsTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

      await fetch(`https://cheekydino.com/api/partyfinished/${selectedParty}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          PartyChildLeft: awsTime,
          LeftBranch: 1,
        }),
      });

      setPartyFinished(true);
    } catch (error) {
      console.error('Error handling party finish:', error);
    }
  };



  const arrivedGuestsCount = partyGuests.filter((guest) => guest.Arrived).length;

  return (
    <div className={`flex flex-wrap ${clientArrived ? 'bg-green-50' : 'bg-red-50'} p-6`}>
      {/* Left Section - Party Details and Guests */}
      <div className="w-full md:w-1/2 mb-6 md:mb-0">
        <div className="mb-10">
          <h3 className="text-lg font-semibold text-black">Party Details</h3>
          <div className="mt-4 grid grid-cols-1 gap-4">
            {partyDetails.map((party) => (
              <div key={party.id} className="p-4 rounded-lg bg-white shadow-md">
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Child Name:</span>
                  <span className="ml-2 text-black">{party.ChildName}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Email:</span>
                  <span className="ml-2 text-black">{party.Email}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Phone:</span>
                  <span className="ml-2 text-black">{party.Telephone}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Party Date:</span>
                  <span className="ml-2 text-black">{format(new Date(party.PartyDate), "EEEE MMMM do, yyyy")}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Party Time:</span>
                  <span className="ml-2 text-black">{party.PartyTime} - {party.PartyFinish}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Party Type:</span>
                  <span className="ml-2 text-black">{party.PartyType}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Number of Guests:</span>
                  <span className="ml-2 text-black">{party.NoOfChildren}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Food Option:</span>
                  <span className="ml-2 text-black">{party.FoodOptionSelected}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Child Age:</span>
                  <span className="ml-2 text-black">{party.ChildAge}</span>
                </div>
                <div className="mb-2">
                  <span className="font-medium text-gray-900">Client Arrived:</span>
                  <span className="ml-2 text-black">{clientArrived ? 'Yes' : 'No'}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-black">Party Guests</h3>
          <ul className="mt-4 grid grid-cols-1 gap-4">
            {partyGuests.map((guest) => (
              <li
                key={guest.id}
                className="flex items-center justify-between p-3 border border-gray-300 rounded-md shadow-sm"
              >
                <div className="text-sm font-medium text-gray-900">
                  {guest.ChildName}: {guest.FoodOption}
                </div>
                <div className="flex items-center space-x-2">
                  {guest.Arrived === 0 ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleGuestArrival(guest.id)}
                        className="px-2 py-1 text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                      >
                        Mark as Arrived
                      </button>
                      <button
                        type="button"
                        onClick={() => handleMistake(guest.id)}
                        className="px-2 py-1 text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleMistake(guest.id)}
                      className="px-2 py-1 text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                    >
                      Cancel
                    </button>
                  )}
                  {guest.Arrived === 1 && (
                    <span className="text-green-600">&#10003;</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Center Section - Time Until Party and Guests Arrived */}
      <div className="w-full md:w-1/2 flex flex-col items-center justify-center space-y-6">
        <div className="text-center">
  <h3 className="text-xl font-bold">Time Until Party:</h3>
  <p className="inline-block px-6 py-2 mt-2 text-lg font-medium text-white bg-blue-600 rounded-full">
    {partyTimeRemaining}
  </p>
</div>

        <div className="flex flex-col items-center">
          <div className="w-24 h-24 flex items-center justify-center rounded-full bg-blue-100 text-2xl font-bold text-blue-700">
            {arrivedGuestsCount}/{partyGuests.length}
          </div>
          <div className="text-lg font-semibold text-gray-700 mt-2">
            Guests Arrived
          </div>
        </div>
        <div className="mt-6 flex space-x-4">
          {!clientArrived && (
            <button
              type="button"
              onClick={handleClientArrival}
              className="px-4 py-2 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
            >
              Mark Client as Arrived
            </button>
          )}
          {!partyFinished && (
            <button
              type="button"
              onClick={handlePartyFinish}
              className="px-4 py-2 text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none"
            >
              Finish Party
            </button>
          )}
        </div>
      </div>

      
    </div>
  );
};

export default TillParty;
