import React, { useState, useEffect } from 'react';
import { Howl } from 'howler';
import { useNavigate } from 'react-router-dom';
import Weather from './weatherdata'
import Leave from './Leave';


export default function Orders() {
  const [completedOrders, setCompletedOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const prevOrdersRef = React.useRef([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await fetch("https://cheekydino.com/api/cafeorders", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Filter orders based on conditions
        const newPendingOrders = data.data.filter(order => order.Completed === 0 && order.Delivered === 0);
        const newCompletedOrders = data.data.filter(order => order.Completed === 1 && order.Delivered === 0);

        // Sort orders by most recent first (assuming CreatedTime is a valid timestamp field)
        newPendingOrders.sort((a, b) => new Date(b.CreatedTime) - new Date(a.CreatedTime));
        newCompletedOrders.sort((a, b) => new Date(b.CreatedTime) - new Date(a.CreatedTime));

        // Check if any order changed from Completed 0 to Completed 1
        const newlyCompletedOrders = newCompletedOrders.filter(order =>
          !prevOrdersRef.current.some(prevOrder => prevOrder.id === order.id && prevOrder.Completed === 1)
        );

        if (newlyCompletedOrders.length > 0) {
          playNotificationSound();
        }

        // Update prevOrdersRef to current orders
        prevOrdersRef.current = data.data;

        setPendingOrders(newPendingOrders);
        setCompletedOrders(newCompletedOrders);
      } catch (error) {
        console.error('There was an error fetching orders!', error);
      }
    }

    // Initial fetch
    fetchOrders();

    // Polling for new orders every 5 seconds
    const interval = setInterval(fetchOrders, 5000);

    return () => clearInterval(interval);
  }, []);

  // Function to play notification sound
  const playNotificationSound = () => {
    const sound = new Howl({
      src: [`${process.env.PUBLIC_URL}/message.mp3`],
      volume: 0.5, // Adjust volume as needed
    });

    sound.play();
  };

  const toggleHotItems = (orderIndex, isCompleted) => {
    if (isCompleted) {
      const updatedOrders = [...completedOrders];
      updatedOrders[orderIndex].showHotItems = !updatedOrders[orderIndex].showHotItems;
      setCompletedOrders(updatedOrders);
    } else {
      const updatedOrders = [...pendingOrders];
      updatedOrders[orderIndex].showHotItems = !updatedOrders[orderIndex].showHotItems;
      setPendingOrders(updatedOrders);
    }
  };

  const fetchOrders = async () => {
    // Your fetchOrders function definition here
  };

  const Deliver = async (order) => {





    try {
      const response = await fetch(`https://cheekydino.com/api/cafeordersdeliver`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: order, Delivered: 1 }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Order delivered successfully!', data);
        // Fetch the updated orders
        fetchOrders();
      } else {
        console.error('There was an error!', data.error);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const handleAllDelivered = async () => {
    // Loop through all completed orders and deliver each one
    for (const order of completedOrders) {
      try {
        const response = await fetch(`https://cheekydino.com/api/cafeordersdeliver`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: order.id, Delivered: 1 }),
        });
  
        const data = await response.json();
  
        if (!response.ok) {
          console.error('Error delivering order:', data.error);
        } else {
          console.log('Order delivered successfully!', data);
        }
      } catch (error) {
        console.error('There was an error delivering the order!', error);
      }
    }
  
    // After processing all orders, fetch the updated orders
    fetchOrders();
  };
  




  return (
    <>
     <Leave />
              <Weather />

    <div>
     
      <div>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Live Orders</h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          <button onClick={() => navigate('/kitchen')}
            type="button"
            className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
          Kitchen
          </button>
          <button onClick={() => navigate('/till')}
            type="button"
            className="relative inline-flex ml-2 items-center rounded-full bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
Till          </button>
<button onClick={() => navigate('/reservations')}
            type="button"
            className="relative inline-flex ml-2 items-center rounded-full bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
Book          </button>


        </div>
      </div>
    </div>
    <div>

    <div className="border-b border-gray-200 bg-green-50 px-4 py-5 sm:px-6">
  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
    <div className="ml-4 mt-2">
      <h3 className="text-base font-semibold leading-6 text-gray-900">Preparing</h3>
      <p className="text-sm leading-5 text-black">{pendingOrders.length} In Kitchen</p>
    </div>
  </div>
</div>

<ul role="list" className="divide-y divide-gray-100 mt-5">
  {pendingOrders.map((order, index) => (
    <li 
      key={order.id} 
      className="flex justify-between gap-x-6 py-5 rounded-lg shadow-md bg-green-500"
    >
      <div className="flex min-w-0 gap-x-4 items-center">
        <div className="h-12 w-12 ml-2 flex items-center justify-center rounded-full bg-white shadow-sm">
          <span className="text-lg font-bold text-black">{order.TableNum}</span>
        </div>
        <div className="min-w-0 flex-auto">
          {order.showHotItems && (
            <ol className="mt-1 text-xs leading-5 text-white">
              {order.HotItems && JSON.parse(order.HotItems).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          )}
          <button
            onClick={() => toggleHotItems(index, false)}
            className="mt-2 bg-gray-200 text-gray-800 text-xs py-1 px-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            {order.showHotItems ? 'Hide Hot Items' : 'Show Hot Items'}
          </button>
          <p className="mt-1 flex text-xs leading-5 text-gray-500">
            <a href={`mailto:${order.email}`} className="relative truncate hover:underline">
              {order.Email}
            </a>
          </p>
        </div>
      </div>
      <div className="hidden sm:flex sm:flex-col sm:items-end mr-2">
        <p className="text-xs leading-5 text-white font-bold">OT: {order.CreatedTime}</p>
        <p className="text-xs leading-5 text-white font-bold">Total: £{order.Total.toFixed(2)}</p>
        <p className="text-xs leading-5 text-white font-bold animate-pulse">Status: Kitchen</p>
      </div>
    </li>
  ))}
</ul>

<div className='mt-10 mb-5'> {/* Added margin-top here */}
  <div className="border-b border-gray-200 bg-red-50 px-4 py-5 sm:px-6">
    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
      <div className="ml-4 mt-2">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Deliver</h3>
        <p className="text-sm leading-5 text-black">{completedOrders.length} To Deliver</p>
        {completedOrders.length > 0 && (
          <button
            onClick={() => handleAllDelivered(completedOrders.map(order => order.id))}
            className="mt-2 bg-red-500 text-gray-800 text-xs py-1 px-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  </div>
  
  <ul role="list" className="divide-y divide-gray-100 mt-5">
    {completedOrders.map((order, index) => (
      <li 
        key={order.id} 
        className="flex justify-between gap-x-6 py-5 rounded-lg shadow-md bg-red-500"
      >
        <div className="flex min-w-0 gap-x-4 items-center mt-5">
          <div className="h-12 w-12 ml-2 flex items-center justify-center rounded-full bg-white shadow-sm">
            <span className="text-lg font-bold text-black">{order.TableNum}</span>
          </div>
          <div className="min-w-0 flex-auto">
            {order.showHotItems && (
              <ol className="mt-1 text-xs leading-5 text-white">
                {order.HotItems && JSON.parse(order.HotItems).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            )}
            <button
              onClick={() => toggleHotItems(index, true)}
              className="mt-2 bg-gray-200 text-gray-800 text-xs py-1 px-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              {order.showHotItems ? 'Hide Hot Items' : 'Show Hot Items'}
            </button>
            <p className="mt-1 flex text-xs leading-5 text-gray-500">
              <a href={`mailto:${order.email}`} className="relative truncate hover:underline">
                {order.Email}
              </a>
            </p>
          </div>
        </div>
        <div className="hidden sm:flex sm:flex-col sm:items-end mr-2">
          <p className="text-xs leading-5 text-white font-bold">OT: {order.CreatedTime}</p>
          <p className="text-xs leading-5 text-white font-bold">Total: £{order.Total.toFixed(2)}</p>
          <p className="text-xs leading-5 text-white font-bold animate-pulse">Status: Ready for Delivery</p>
          <button
            onClick={() => Deliver(order.id)}
            className="mt-2 bg-green-500 text-white text-xs py-1 px-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            Deliver
          </button>
        </div>
      </li>
    ))}
  </ul>
</div>
</div>
</div>
</div>

    </>
  );
}
