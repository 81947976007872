import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Online from './online'; // Assuming Online component is in the same directory
import ClockIn from './staffactions'; // Assuming ClockIn component is in the same directory
import ClockInData from './clockindata'; // Assuming ClockInData component is in the same directory
import Tasks from './stafftasks';
import BranchSettings from './branchsettings'; // Assuming BranchSettings component is in the same directory
import Overview from './overview'; // Assuming Overview component is in the same directory
import TillProducts from './tillproducts';
import Logins from './logins';
import Prices from './prices'; // Assuming Prices component is in the same directory
import Announcements from './Announcement'; // Assuming Announcements component is in the same directory
import EditHome from './edithome';
import { useNavigate } from 'react-router-dom';
import ClosedDates from './closeddates';




const MyAccount = () => <Overview />; // Import Overview component
const Company = () =>  <BranchSettings/>; // Import ClockIn component
const ClosingDates = () => <ClosedDates />; // Import ClockInData component
const TeamMembers = () => <Prices />; // Default to Online component
const Billing = () => <TillProducts/>;
const Task = () => <Logins/>;
const Screen = () => <Announcements/>;



const tabs = [
  { name: 'Overview', component: MyAccount, current: true },
  { name: 'Open Times', component: Company, current: false },
  { name: 'Closed Dates', component: ClosingDates, current: false },
  { name: 'Prices', component: TeamMembers, current: false },
  {name: 'Announcements', component: Screen, current: false},
   // Set this as the default tab
  {name: 'Log Ins', component: Task, current: false}, // Set this as the default tab
  { name: 'Till Products', component: Billing, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [currentTab, setCurrentTab] = useState(tabs.find((tab) => tab.current));


  const navigate = useNavigate();


  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to update the selected tab */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={currentTab.name}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) =>
            setCurrentTab(tabs.find((tab) => tab.name === e.target.value))
          }
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setCurrentTab(tab)}
              className={classNames(
                tab.name === currentTab.name
                  ? 'bg-gray-100 text-gray-700'
                  : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {React.createElement(currentTab.component)}
      </div>
    </div>
  );
}
