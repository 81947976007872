import { useState, useEffect, useRef } from 'react';
import { format, parse, set } from 'date-fns';
import { CheckCircleIcon, CogIcon, ClockIcon } from '@heroicons/react/20/solid';
import Countdown from 'react-countdown';
import { Howl } from 'howler';


const isElectron = typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer';
const { ipcRenderer } = isElectron ? window.require('electron') : {};

// Replace with your sound file path
const sound = new Howl({
  src: ['sound.mp3'],
});

export default function CafeKitchen() {
  const [orders, setOrders] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [snooze, setSnooze] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [ingredients, setIngredients] = useState([]);


  


  
  const prevOrdersRef = useRef([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/cafeorders", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const fetchedOrders = data.data.filter(order => order.Completed === 0 && order.Delivered === 0);

        // Filter out orders that are already in prevOrdersRef.current
        const newOrders = fetchedOrders.filter(order =>
          !prevOrdersRef.current.some(prevOrder => prevOrder.id === order.id)
        );

        if (newOrders.length > 0) {
          sound.play(); // Play sound for new orders
        }

        // Update prevOrdersRef.current to current fetched orders
        prevOrdersRef.current = fetchedOrders;

        // Update state with new orders combined with existing orders
        setOrders(prevOrders => [...prevOrders, ...newOrders]);
      } catch (error) {
        console.error('There was an error fetching orders!', error);
      }
    };

    const interval = setInterval(() => {
      fetchOrders();
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const kitchenItemIds = orders.map(order => order.KitchenMenuId).flat();

        const response = await fetch(`https://cheekydino.com/ingredients?productIds=${kitchenItemIds.join(',')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Fetched Ingredients:', data);

        // Match ingredients to each order
        const matchedIngredients = orders.map(order => {
          const orderIngredients = data.data.filter(ingredient =>
            order.KitchenMenuId.includes(ingredient.productId)
          );
          return {
            ...order,
            ingredients: orderIngredients,
          };
        });

        console.log('Matched Ingredients:', matchedIngredients);
        setIngredients(matchedIngredients);

      } catch (error) {
        console.error('There was an error fetching ingredients!', error);
      }
    };

    fetchIngredients();

  }, [orders]);

  const renderer = ({ minutes, completed }) => {
    if (completed) {
      return <ClockIcon className='w-12 h-12 animate-pulse text-white' />;
    } else {
      return <span className='text-white'>{minutes} mins </span>;
    }
  };

  const handleOrderConfirmed = async (order) => {
    try {
      // Get current time formatted
      const currentTime = new Date();
      const formattedTime = format(currentTime, 'HH:mm:ss');
      
      console.log('Current time formatted:', formattedTime);
  
      // Initialize an array to store the number of portions remaining for each KitchenMenu item
      const portionsRemaining = {};
  
      // Array to store promises for updating StockControl
      const updateStockPromises = [];
  
      // Debugging: Log the KitchenMenuId
      console.log('order.KitchenMenuId:', order.KitchenMenuId);
  
      // Extract KitchenMenuIds from the order object
      const kitchenMenuIds = order.KitchenMenuId;
  
      // Debugging: Log parsed KitchenMenuIds
      console.log('KitchenMenuIds:', kitchenMenuIds);
  
      // Loop through each KitchenMenuId in the order
      for (const kitchenMenuId of kitchenMenuIds) {
        console.log(`Processing KitchenMenuId: ${kitchenMenuId}`);
  
        // Find the corresponding ingredients from the state
        const relevantIngredients = ingredients.filter(ingredient => ingredient.productid === kitchenMenuId);
  
        console.log(`Relevant ingredients for KitchenMenuId ${kitchenMenuId}:`, relevantIngredients);
  
        // Loop through ingredients of the KitchenMenu item
        for (const ingredient of relevantIngredients) {
          const requiredStockLevel = ingredient.weight > 0 ? ingredient.weight : ingredient.quantity;
  
          console.log(`Ingredient ${ingredient.id}: required stock level:`, requiredStockLevel);
  
          // Fetch StockControl item by stockId
          const stockControlResponse = await fetch(`https://cheekydino.com/stockcontrol?id=${ingredient.stockid}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (!stockControlResponse.ok) {
            throw new Error(`Failed to fetch StockControl item with id ${ingredient.stockid}`);
          }
  
          const stockControlData = await stockControlResponse.json();
          const stockControl = stockControlData.data[0];
  
          console.log(`Fetched StockControl for ingredient ${ingredient.id}:`, stockControl);
  
          if (stockControl) {
            // Calculate new stock levels
            const newCurrentStockLevel = stockControl.CurrentStockLevel - requiredStockLevel;
  
            console.log(`New stock level for StockControl ${stockControl.id}:`, newCurrentStockLevel);
  
            // Update StockControl with new stock level
            const updatedStockControl = {
              ...stockControl,
              CurrentStockLevel: newCurrentStockLevel,
            };
  
            const updateStockPromise = fetch(`https://cheekydino.com/stockcontrol/${stockControl.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(updatedStockControl),
            });
  
            updateStockPromises.push(updateStockPromise);
  
            // Calculate portions remaining for the ingredient
            const portions = Math.floor(stockControl.CurrentStockLevel / requiredStockLevel);
            if (!portionsRemaining[kitchenMenuId]) {
              portionsRemaining[kitchenMenuId] = [];
            }
            portionsRemaining[kitchenMenuId].push(portions);
  
            console.log(`Portions remaining for ingredient ${ingredient.id}:`, portions);
          }
        }
      }
  
      // Wait for all StockControl updates to complete
      await Promise.all(updateStockPromises);
  
      console.log('All StockControl updates completed');
  
      // Update KitchenMenu items with remaining stock levels
      const updateKitchenPromises = Object.keys(portionsRemaining).map(async (kitchenMenuId) => {
        const minPortionsRemaining = Math.min(...portionsRemaining[kitchenMenuId]);
  
        console.log(`Minimum portions remaining for KitchenMenuId ${kitchenMenuId}:`, minPortionsRemaining);
  
        // Fetch the KitchenMenu item to update it
        const response = await fetch(`https://cheekydino.com/api/kitchenmenu/${kitchenMenuId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch KitchenMenu item with id ${kitchenMenuId}`);
        }
  
        const kitchenMenuItem = await response.json();
  
        console.log(`Fetched KitchenMenu item ${kitchenMenuId}:`, kitchenMenuItem);
  
        // Update the stock level of the KitchenMenu item
        const updatedKitchenMenuItem = { ...kitchenMenuItem, StockLevel: minPortionsRemaining };
  
        await fetch(`https://cheekydino.com/api/kitchenmenu/${kitchenMenuId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedKitchenMenuItem),
        });
  
        console.log(`Updated KitchenMenu item ${kitchenMenuId} with new stock level:`, minPortionsRemaining);
      });
  
      await Promise.all(updateKitchenPromises);
  
      console.log('All KitchenMenu updates completed');

      // get time between CreatedTime and formattedTime

      const orderTime = parse(order.CreatedTime, 'HH:mm:ss', new Date());

      console.log('Order time:', orderTime);

      // get the time difference between orderTime and formattedTime there both formatted hh:mm:ss

      const timeDifference = Math.abs(currentTime - orderTime) / 1000 / 60;

      
      console.log('Time difference:', timeDifference);

      console.log(order)

  
      // Update the CafeOrder status
      await fetch(`https://cheekydino.com/api/cafeorderkitchen/${order.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Completed: 1, CompletedTime: formattedTime, TimeTaken: timeDifference }),
      });
  
      console.log(`Updated CafeOrder ${order.id} status to Completed`);
  
      // Update the orders state to remove the confirmed order
      setOrders(prevOrders => prevOrders.filter(o => o.id !== order.id));
      
      // Update the prevOrdersRef to remove the confirmed order
      prevOrdersRef.current = prevOrdersRef.current.filter(o => o.id !== order.id);
  
      // Fetch new orders after confirming the order


      const printResponse = await fetch(`https://cheekydino.com/api/kitchenprint`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          Product: order.HotItems,
          Name: order.SessionEmail,
          TableNum: order.TableNum,
        }),

      });

      if (!printResponse.ok) {
        throw new Error('Failed to print order');
      }

      console.log('Order printed successfully');

      const printData = await printResponse.json();

      console.log('Print data:', printData);






  
      const data = {
        product: order.HotItems,
        name: order.SessionEmail,
        table: order.TableNum,
      };

      console.log('Sending data to kitchen-print channel:', data);

  
      // Send the data to the kitchen-print channel
      if (isElectron) {
        // Introduce a 3-second delay before sending
        setTimeout(() => {
          ipcRenderer.send('kitchen-print', { data });
          // Set printing to false after sending
          setPrinting(false);

        }, 3000);
      }





      else {
        console.log('Not running in Electron, skipping printing');
      }
    } catch (error) {
      console.error('Error handling confirmed order:', error);
    }
  };
  
  
  
  
  
  
  

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <p className="text-purple-700 text-center">Number of Orders: {orders.length}</p>
      {snooze && (
        <button onClick={() => setSnooze(false)} className="bg-red-500 text-white px-4 py-2 rounded-md">
          Snooze
        </button>
      )}
      <ul role="list" className="divide-y divide-gray-100 mt-2 mb-2">
        {ingredients.map((order, index) => {
          const orderTime = parse(order.CreatedTime, 'HH:mm:ss', new Date());
          const orderTimePlus20 = set(orderTime, { minutes: orderTime.getMinutes() + 20 });
          const remainingTime = (orderTimePlus20.getTime() - new Date().getTime()) / 1000 / 60;
          const readyBy = format(orderTimePlus20, 'h:mm a');
  
          let backgroundColor = 'bg-green-500';
          if (order.timerFinished) {
            backgroundColor = 'bg-red-500 animate-pulse';
          } else if (remainingTime <= 20 && remainingTime > 10) {
            backgroundColor = 'bg-green-500';
          } else if (remainingTime <= 10 && remainingTime > 5) {
            backgroundColor = 'bg-yellow-500';
          } else if (remainingTime <= 5) {
            backgroundColor = 'bg-red-500';
          }
  
          let hotItems = [];
          try {
            hotItems = JSON.parse(order.HotItems);
          } catch (e) {
            console.error('Failed to parse HotItems', e);
          }
  
          return (
            <li
              key={order.id}
              className={`relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 lg:px-8 ${backgroundColor} rounded-lg mt-2 mb-2 border border-gray-200 shadow-lg `}
            >
              <div className="flex gap-x-4">
                <CheckCircleIcon className="w-6 h-6 animate-pulse text-white" />
                <div className="min-w-0 flex-auto">
                  <h3 className="text-lg font-medium leading-6 text-white">Table:{order.TableNum}</h3>
                  {printing && <CogIcon className="w-10 h-10 animate-spin text-white" />}
                  <ul className="ml-3 font-medium text-white">
                    {hotItems.map((item, index) => (
                      <li key={`${item}-${index}`}>
                        <input id={item} name={item} type="checkbox" className="mr-1" />
                        <label htmlFor={item}>{item}</label>
                      </li>
                    ))}
                  </ul>
                  <ul className="ml-3 font-medium text-white mt-2">
                    {order.ingredients.map(ingredient => (
                      <li key={ingredient.id} className="text-xs font-italic">
                        {ingredient.name} - {ingredient.quantity} {ingredient.weight > 0 ? 'g' : 'pcs'}
                      </li>
                    ))}
                  </ul>
                  <p className="text-sm font-semibold leading-6 text-white mt-2">
                    {order.Notes ? order.Notes : 'No Order Notes'}
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <a href={`mailto:${order.email}`} className="relative truncate hover:underline">
                      {order.email}
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-x-4 mb-1 mt-1">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">{order.role}</p>
                  <p className="mt-1 text-xs leading-5 text-white">
                    Order Time: {format(parse(order.CreatedTime, 'HH:mm:ss', new Date()), 'h:mm a')}
                  </p>
                  <p className="text-white text-xs">Ready By: {readyBy}</p>
                  <Countdown date={orderTimePlus20} renderer={renderer} />
                </div>
                <button
                  onClick={() => handleOrderConfirmed(order)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Ready
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
