import React, { useEffect, useState, useContext } from 'react';
import { addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import { useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ThemedCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [partyDetails, setPartyDetails] = useState([]);
  const [selectedPartyDetails, setSelectedPartyDetails] = useState([]);
  const [daysAvailability, setDaysAvailability] = useState([]);
  const [partyType, setDetails] = useState([]);

  const user = useContext(AuthContext);

  const location = useLocation();
  const { details } = location.state;

  console.log(user);
    console.log(details);

  useEffect(() => {
    setDetails(details);
  }, [details]);

  const navigate = useNavigate();

  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);

  const handleDayClick = async date => {
    setSelectedDate(date);
    const availability = getPartyDetailsForDate(date, partyDetails);
    setSelectedPartyDetails(availability);
  };

  const getPartyDetailsForDate = (date, partyDetails) => {
    const filteredBookings = partyDetails.filter(
      booking => 
        booking.PartyDate === date &&
        booking.PartyType === "Character" &&
        (booking.PartyName === "Disco" || booking.PartyName === "Football")
    );
    const maxAppointments = 1;
    const timeSlots = ['14:30:00'];
    let availability = [];
    const dayOfWeek = new Date(date).getDay();

    if (dayOfWeek === 6 || dayOfWeek === 0) {
      timeSlots.forEach(timeSlot => {
        const bookingsForTimeSlot = filteredBookings.filter(booking => booking.PartyTime === timeSlot);
        const availableAppointments = maxAppointments - bookingsForTimeSlot.length;
        if (availableAppointments > 0) { // Only add time slots that are not fully booked
          availability.push({
            timeSlot: timeSlot.substring(0, 5),
            availableAppointments
          });
        } else {
          console.log(`Blocked appointment: ${date} ${timeSlot}`); // Log blocked appointments
        }
      });
    }
    return availability;
  };

  const getDaysAvailability = () => {
    const daysAvailability = eachDayOfInterval({ start: startDate, end: endDate }).map(date => {
      const availability = getPartyDetailsForDate(date.toISOString().slice(0, 10), partyDetails);
      const hasAvailableAppointments = availability.some(timeSlot => timeSlot.availableAppointments > 0);
      const isPast = date < new Date();
      return {
        date: date.toISOString().slice(0, 10),
        isCurrentMonth: true,
        isToday: date.toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10),
        hasAvailableAppointments,
        isPast
      };
    });
    setDaysAvailability(daysAvailability);
  };

  useEffect(() => {
    const fetchPartyBookings = async () => {
      try {
        const response = await axios.get('https://cheekydino.com/api/partybookings');

        // format PartyDate to match the date format of the calendar
        response.data.data.forEach(booking => {
          booking.PartyDate = new Date(booking.PartyDate).toISOString().slice(0, 10);
        });
console.log(response.data.data);
        setPartyDetails(response.data.data);
        getDaysAvailability(); // Call getDaysAvailability here
      } catch (error) {
        console.error('Failed to fetch party bookings', error);
      }
    };

    fetchPartyBookings();
  }, []);

  // Add useEffect for updating availability on month change
  useEffect(() => {
    getDaysAvailability();
  }, [currentDate, partyDetails]);

  const handlePrevMonthClick = () => {
    setCurrentDate(current => subMonths(current, 1));
  };

  const handleNextMonthClick = () => {
    setCurrentDate(current => addMonths(current, 1));
  };

  const handleTodayClick = () => {
    setCurrentDate(new Date());
  };

  const handleBookAppointment = timeSlot => {


// if user is null then navigate to register page, if user has a value then render CustomerDashboard

   
        navigate('/customer-dashboard', {
            state: {
                selectedDate,
                selectedTimeSlot: timeSlot,
                partyDetails,
                partyType
            }
        });
    }
  


  const imageURL = "https://media.giphy.com/media/4njbG9gEe2c5j2RIWD/giphy.gif";

  return (
    <div className="flex flex-col h-full">
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
        <div className="flex flex-wrap justify-center">
          {partyType.map((party, index) => (
            <div key={index} className="m-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700">
              <p>{party.name} Party</p>
              <p>£{party.price}</p>
              <p>{party.character}</p>
            </div>
          ))}
        </div>
        <button onClick={handlePrevMonthClick} className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime={format(currentDate, 'yyyy-MM-dd')}>
            {format(currentDate, 'MMMM yyyy')}
          </time>
        </h1>
        <button onClick={handleNextMonthClick} className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </header>
      <section className="bg-cover bg-center min-h-screen" style={{ backgroundImage: `url(${imageURL})` }}>
        <div className="flex flex-auto justify-center">
          <div className="w-full max-w-md px-8 py-10">
            {selectedPartyDetails.length > 0 && (
              <div className="p-4 border rounded-lg mb-4 shadow-md">
                <h2 className="text-lg font-semi-bold text-center mb-4">
                  Date: {format(new Date(selectedDate), 'dd/MM/yyyy')}
                </h2>

                {selectedPartyDetails.map(timeSlot => timeSlot.availableAppointments > 0 && (
                  <div key={timeSlot.timeSlot} className="mb-4 p-4 border rounded-lg shadow-sm">
                    <h3 className="text-center mb-2">Time: {timeSlot.timeSlot}</h3>
                    <button
                      className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                      onClick={() => handleBookAppointment(timeSlot.timeSlot)}
                    >
                      Book Party
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="justify-center">
          <div className="flex flex-wrap justify-center">
            <p className="text-2xl font-semibold text-center mt-10 mb-10">Select a date to book a party</p>
          </div>
          <div className="flex flex-wrap justify-center">
            {daysAvailability.map(day => day.hasAvailableAppointments && (
              <button
                key={day.date}
                onClick={() => handleDayClick(day.date)}
                className="m-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
              >
                {format(new Date(day.date), 'EEEE, MMMM do, yyyy')}
              </button>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
