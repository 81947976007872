import { WifiIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

const ServerStatusIndicator = () => {
  const [serverRunning, setServerRunning] = useState(false);
  const [isElectron, setIsElectron] = useState(false);

  useEffect(() => {
    // Check if running in Electron
    if (window && window.process && window.process.type) {
      setIsElectron(true);

      const { ipcRenderer } = window.require('electron');

      ipcRenderer.send('get-server-status');

      ipcRenderer.on('server-status', (event, isRunning) => {
        setServerRunning(isRunning);
      });

      return () => {
        ipcRenderer.removeAllListeners('server-status');
      };
    }
  }, []);

  if (!isElectron) {
    return <div>
      <WifiIcon className="h-10 w-10 text-green-500" />

    </div>;
  }

  return (
    <div>
      <span
        style={{
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: serverRunning ? 'green' : 'red',
          display: 'inline-block',
        }}
      ></span>
    </div>
  );
};

export default ServerStatusIndicator;
