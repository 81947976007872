import { useState, useEffect } from 'react';
import axios from 'axios';
import { format, parseISO, isValid, startOfDay } from 'date-fns';
import OpenAI from 'openai';
import config from '../openai'; // Adjust the path if necessary
import LoadingComponent from './loading';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Demographics from './demographics';

export default function ConsumerAnalytics() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [openAI, setOpenAI] = useState(null);
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [saved, setSaved] = useState(false);

  console.log(result);

  console.log('data:', data);

  useEffect(() => {
    const fetchBankHolidays = async () => {
      try {
        const response = await fetch("https://www.gov.uk/bank-holidays.json");
        
        if (!response.ok) {
          throw new Error("Failed to fetch bank holidays");
        }
        
        const data = await response.json();
        console.log("Bank Holiday Data:", data); // Log the data for testing
      } catch (error) {
        console.error("Error fetching bank holidays:", error);
      }
    };

    fetchBankHolidays();
  }, []); // Empty dependency array ensures this runs only once on mount


  
  const postcode = 'ME14 4QE'; // Use the specified postcode

 
  useEffect(() => {
    const initializeOpenAI = async () => {
      const openaiInstance = new OpenAI({
        dangerouslyAllowBrowser: true,
        apiKey: config.OPENAI_API_KEY,
      });

      setOpenAI(openaiInstance);
    };

    initializeOpenAI();
  }, []);


  const fetchData = async () => {
    try {
        setLoading(true);

        // Define API endpoints with proper error handling for dates
        const sessionsEndpoint = `https://cheekydino.com/api/analytics/sessions?startDate=${startDate}&endDate=${endDate}`;
        const cafeOrdersEndpoint = `https://cheekydino.com/api/analytics/cafeorders?startDate=${startDate}&endDate=${endDate}`;
        const pricelistEndpoint = `https://cheekydino.com/api/analytics/pricelist`;
        const clockinsEndpoint = `https://cheekydino.com/api/analytics/clockins?startDate=${startDate}&endDate=${endDate}`;

        // Fetch data from all endpoints concurrently
        const apiResponses = await Promise.allSettled([
            fetch(sessionsEndpoint),
            fetch(cafeOrdersEndpoint),
            fetch(pricelistEndpoint),
            fetch(clockinsEndpoint)
        ]);

        // Check for errors in the responses
        apiResponses.forEach((response, index) => {
            if (response.status === "rejected") {
                console.error(`API request ${index + 1} failed:`, response.reason);
            } else if (!response.value.ok) {
                console.error(`API request ${index + 1} returned a non-200 status:`, response.value.status);
            }
        });

        // If any API response failed, throw an error
        if (apiResponses.some(response => response.status === "rejected" || !response.value.ok)) {
            throw new Error('One or more API requests failed.');
        }

        // Parse successful API responses as JSON
        const [sessionsData, cafeOrdersData, pricelistData, clockinsData] = await Promise.all(
            apiResponses.map(response => response.value.json())
        );

        // Log API results for debugging
        console.log('Sessions API result:', sessionsData);
        console.log('CafeOrders API result:', cafeOrdersData);
        console.log('Pricelist API result:', pricelistData);
        console.log('Clockins API result:', clockinsData);

        // Prepare final data array
        const finalData = [];

        if (sessionsData.success && cafeOrdersData.success && pricelistData.success && clockinsData.success) {
            finalData.push({
                sessions: sessionsData.data?.data || [],
                cafeOrders: cafeOrdersData.data?.data || [],
                pricelist: pricelistData.data || [],  // Corrected this line to directly access the data array
                clockins: clockinsData.data?.data || []
            });

            console.log('Final Combined Results:', finalData);
            setData(finalData);
            setLoading(false);
            await processData(finalData);
        } else {
            console.error('Failed to fetch data from one or more APIs:', {
                sessionsSuccess: sessionsData.success,
                cafeOrdersSuccess: cafeOrdersData.success,
                pricelistSuccess: pricelistData.success,
                clockinsSuccess: clockinsData.success,
            });
            setLoading(false);
        }
    } catch (error) {
        console.error('Error fetching analytics data:', error);
        setLoading(false);
    }
};

// Process and compute the data
const processData = async (data) => {
  const aggregatedData = {};
  const pricelist = data[0].pricelist || [];  // Extract pricelist for use in calculations

  await Promise.all(data.map(async (dateData) => {
    const { cafeOrders = [], sessions = [], clockins = [] } = dateData;

    if (!Array.isArray(sessions) || sessions.length === 0) return;

    for (const session of sessions) {
      const sessionDate = parseISO(session.Date);
      if (!isValid(sessionDate)) continue;

      const sessionDateKey = format(startOfDay(sessionDate), 'yyyy-MM-dd');
      const sessionDay = format(sessionDate, 'EEEE');  // Get day name, e.g., 'Monday'

      if (!aggregatedData[sessionDateKey]) {
        aggregatedData[sessionDateKey] = {
          date: sessionDateKey,
          totalChildren: 0,
          totalAdults: 0,
          returnVisits: 0,
          totalOrders: 0,
          totalSpent: 0,
          totalCafeSpend: 0,
          sessionData: [],
          clockinCount: 0,
          hotItemCount: {},
          drinkItemCount: {},
          applicablePrices: []  // New field to store all applicable prices for the day
        };
      }

      aggregatedData[sessionDateKey].totalChildren += session.Children || 0;
      aggregatedData[sessionDateKey].totalAdults += session.Adults || 0;
      aggregatedData[sessionDateKey].sessionData.push(session);

      // Filter applicable pricelist items for this session day
      const dailyPricelist = pricelist.filter(item => {
        const days = item.Days.split(',').map(day => day.trim());
        const startDate = item.StartDate ? parseISO(item.StartDate) : null;
        const expiryDate = item.ExpiryDate ? parseISO(item.ExpiryDate) : null;

        return (
          days.includes(sessionDay) &&
          (!startDate || isValid(startDate) && sessionDate >= startDate) &&
          (!expiryDate || isValid(expiryDate) && sessionDate <= expiryDate)
        );
      });

      // Add these applicable prices to the aggregated data for the day
      aggregatedData[sessionDateKey].applicablePrices = dailyPricelist;

      // Sum entrance fees based on a matched pricelist entry
      const entranceFee = dailyPricelist.reduce((sum, item) => sum + parseFloat(item.Price), 0);
      aggregatedData[sessionDateKey].totalSpent += entranceFee;

      // Count clockins for the same day
      aggregatedData[sessionDateKey].clockinCount += clockins.filter(clockin => {
        const clockinDate = parseISO(clockin.Date);
        return isValid(clockinDate) && startOfDay(clockinDate).getTime() === startOfDay(sessionDate).getTime();
      }).length;
    }

    // Process cafe orders...
    cafeOrders.forEach(order => {
      const orderDate = parseISO(order.CreatedDate);
      if (isValid(orderDate)) {
        const orderDateKey = format(startOfDay(orderDate), 'yyyy-MM-dd');
        if (aggregatedData[orderDateKey]) {
          aggregatedData[orderDateKey].totalOrders += 1;
          aggregatedData[orderDateKey].totalCafeSpend += (order.Total || 0);
          aggregatedData[orderDateKey].totalSpent += (order.Total || 0);

          if (order.HotItems) {
            let parsedHotItems;
            try {
              parsedHotItems = JSON.parse(order.HotItems);
            } catch (error) {
              console.error('Failed to parse HotItems:', order.HotItems);
            }

            if (Array.isArray(parsedHotItems)) {
              parsedHotItems.forEach(item => {
                aggregatedData[orderDateKey].hotItemCount[item] =
                  (aggregatedData[orderDateKey].hotItemCount[item] || 0) + 1;
              });
            }
          }

          if (order.DrinkItems) {
            let parsedDrinkItems;
            try {
              parsedDrinkItems = JSON.parse(order.DrinkItems);
            } catch (error) {
              console.error('Failed to parse DrinkItems:', order.DrinkItems);
            }

            if (Array.isArray(parsedDrinkItems)) {
              parsedDrinkItems.forEach(item => {
                aggregatedData[orderDateKey].drinkItemCount[item] =
                  (aggregatedData[orderDateKey].drinkItemCount[item] || 0) + 1;
              });
            }
          }
        }
      }
    });
  }));

  const finalProcessedData = await Promise.all(Object.values(aggregatedData).map(async (entry) => {
    const uniqueCustomers = new Set(entry.sessionData.map(session => session.Email));
    const clv = uniqueCustomers.size > 0 ? entry.totalSpent / uniqueCustomers.size : 0;

    const weatherData = await fetchWeatherData(entry.date);
    const temperatureMax = weatherData ? weatherData.temperatureMax : 0;

    let color = 'gray';
    if (temperatureMax < 10) color = 'blue-100';
    else if (temperatureMax < 15) color = 'yellow-100';
    else if (temperatureMax < 25) color = 'orange-100';
    else color = 'red-100';

    const mostPopularHotItems = Object.keys(entry.hotItemCount).sort((a, b) =>
      entry.hotItemCount[b] - entry.hotItemCount[a]).slice(0, 3);

    const mostPopularDrinkItems = Object.keys(entry.drinkItemCount).sort((a, b) =>
      entry.drinkItemCount[b] - entry.drinkItemCount[a]).slice(0, 3);

    const result = {
      ...entry,
      clv,
      weather: weatherData ? `${weatherData.weatherDescription}, ${temperatureMax}°C` : 'N/A',
      color,
      mostPopularHotItems: mostPopularHotItems.length > 0 ? mostPopularHotItems : ['N/A'],
      mostPopularDrinkItems: mostPopularDrinkItems.length > 0 ? mostPopularDrinkItems : ['N/A']
    };

    return result;
  }));

  console.log('Final Processed Data:', finalProcessedData);

  setFinalData(finalProcessedData);
};







// Fetch weather data with the original date
const fetchWeatherData = async (date) => {
  const lat = '51.271'; 
  const lon = '0.522';

  try {
      const formattedDate = format(new Date(date), 'yyyy-MM-dd'); // Ensure this is a valid date
      const response = await axios.get('https://api.open-meteo.com/v1/forecast', {
          params: {
              latitude: lat,
              longitude: lon,
              daily: 'temperature_2m_max,temperature_2m_min,weathercode',
              start_date: formattedDate, 
              end_date: formattedDate, 
              timezone: 'Europe/London',
          }
      });

      const weatherData = response.data.daily;
      if (weatherData) {
          const temperatureMax = weatherData.temperature_2m_max[0];
          const weatherCode = weatherData.weathercode[0];
          const weatherDescriptions = {
              0: 'Clear sky',
              1: 'Mainly clear',
              2: 'Partly cloudy',
              3: 'Overcast',
              45: 'Fog',
              48: 'Rime fog',
              51: 'Drizzle light',
              53: 'Drizzle moderate',
              55: 'Drizzle dense',
              61: 'Showers light',
              63: 'Showers moderate',
              65: 'Showers heavy',
              71: 'Snow fall light',
              73: 'Snow fall moderate',
              75: 'Snow fall heavy',
              80: 'Rain showers light',
              81: 'Rain showers moderate',
              95: 'Thunderstorms light',
              99: 'Thunderstorms heavy'
          };

          return {
              temperatureMax,
              weatherDescription: weatherDescriptions[weatherCode] || 'N/A'
          };
      }
  } catch (error) {
      console.error(`Error fetching weather data for date ${date}:`, error);
      return null;
  }
};


  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  const handleFilter = () => {
    if (startDate && endDate) {
      fetchData();
    }
  };


  const generateAI = async (data) => {
    console.log('Generating AI report for:', finalData);

    setLoading(true);

    if (!openAI) {
      console.error('OpenAI instance not initialized');
      return;
    }

    try {
      // Format the data for the AI model
      const formattedData = finalData.map(item => ({
        date: item.date,
        weather: item.weather,
        totalAdults: item.totalAdults,
        totalChildren: item.totalChildren,
        returnVisits: item.returnVisits,
        averageTimeSpent: item.averageTimeSpent,
        totalOrders: item.totalOrders,
        totalSpent: item.totalSpent,
        averageSpend: item.totalSpent / (item.totalOrders || 1),  // Prevent division by zero
        mostPopularHotItems: item.mostPopularHotItems,  // Ensure plural 'mostPopularHotItems'
        mostPopularDrinkItems: item.mostPopularDrinkItems,  // Ensure plural 'mostPopularDrinkItems'
        clv: item.clv,
        pricesList: item.applicablePrices.map(priceItem => ({
          Name: priceItem.Name,
          Price: priceItem.Price,
          Category: priceItem.Category,  // Include Category if needed
          Active: priceItem.Active        // Include Active status if needed
        }))
      }));
      
      

      console.log('Formatted Data:', formattedData);

      const messages = [
        { role: 'system', content: 'You are a helpful assistant.' },
        {
          role: 'user',
          content: `I want you to analyze the following data: ${JSON.stringify(formattedData)}. Provide a summary, insights, and any trends you can identify to improve forecasting business and make predictions about the future .`,
        },
      ];

      // Call the OpenAI API
      const response = await openAI.chat.completions.create({
        model: 'gpt-4',
        messages,
      });

      let newMessage = response.choices[0].message.content;

      setResult(newMessage);
    } catch (error) {
      console.error('Error generating AI response:', error);
    } finally {
      setLoading(false);
    }
  };





  const handleSave = async (result) => {
    try {
      const response = await fetch('https://cheekydino.com/api/saveaireport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Name: 'AI Report',
          Content: result,
          Date: new Date().toISOString(),
          AIAnalytics: 1
        }),
      });
      const data = await response.json();
      if (data.success) {
        console.log('Report saved successfully');
        setResult('');
        setSaved(true);
        


      } else {
        console.error('Failed to save report');
      }
    } catch (error) {
      console.error('Error saving report:', error);
    }
  };


  const handleShowGraph = async () => {
    const labels = finalData.map(item => item.date);

    const adults = finalData.map(item => item.totalAdults);
    const children = finalData.map(item => item.totalChildren);

    // get total guests by adding adults and children
    const totalGuests = adults.map((adult, index) => adult + children[index]);



    const temperatures = finalData.map(item => {
      const weather = item.weather;
      const temperature = weather ? Number(weather.split('°')[0].split(',')[1]) : 0;
      return temperature;
    });

    // Prepare data for chart display, combining date and temperature
    const chartData = finalData.map((item, index) => ({
      dateTemperature: `${labels[index]} (${temperatures[index]}°C)`, // X-axis label with date + temperature
      totalGuests: totalGuests[index], // Y-axis value: total guests
    }));

    // Log the processed data for debugging
    console.log('Labels:', labels);
    console.log('Total Guests:', totalGuests);
    console.log('Temperatures:', temperatures);

    // Set the chart data in state and show the graph
    setChartData(chartData);
    setShowGraph(true);
  };

















  const sortedData = finalData.sort((a, b) => new Date(a.date) - new Date(b.date));






  return (
    <>
      {loading && <LoadingComponent />}
      {result && (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8">
            <h2 className="text-lg font-semibold leading-6 text-gray-900">AI Report</h2>
            <p className="mt-2 text-sm text-gray-700">
              {result}
            </p>
          </div>
          <button onClick={() => setResult('')} className="mt-4 ml-4 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Close AI Report
          </button>
          <button onClick={() => handleSave(result)} className="mt-4 inline-flex items-center rounded-md border border-transparent bg-green-500 mr-2 ml-2 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Save AI Report
          </button>
          {saved && (
            <p className="mt-4 text-sm text-green-700">AI Report saved successfully!</p>
          )}
          
        </div>
      )}

      {!loading && !result && (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Analytics</h1>
              <p className="mt-2 text-sm text-gray-700">
                An overview of analytics data for the selected date range.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <label htmlFor="startDate" className="ml-2 block text-sm font-medium leading-6 text-gray-900">
                Start Date
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <label htmlFor="endDate" className="ml-2 block text-sm font-medium leading-6 text-gray-900">
                End Date
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={handleFilter}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Apply Filter
              </button>
            </div>
          </div>

          <button onClick={() => generateAI(data)} className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Generate AI Report
          </button>
          <button onClick={() => handleShowGraph(finalData)} className="mt-4 ml-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Show Graph
          </button>
          {showGraph && (
            <div className="mt-8">
              <button onClick={() => setShowGraph(false)}         className="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Close Graph
              </button>
              <h2 className="text-lg font-semibold leading-6 text-gray-900">Guest Analytics</h2>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dateTemperature" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="totalGuests" fill="#0000ff" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}


<div className="mt-8 flow-root">
  <div className="-my-2 overflow-x-auto">
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow ring-1 ring-gray-900/5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Date
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Weather
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Total Adults
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Total Children
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Return Visits
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Total Orders
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Total Spent
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                CLV
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Most Popular Hot Items
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Most Popular Drink Items
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Staff Clock-ins
              </th>
              <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                Prices
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
          {sortedData.map((item) => (
  <tr key={item.date} className={`bg-${item.color} text-white`}>
    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
      {format(parseISO(item.date), 'EEEE, MMMM d, yyyy')}
</td>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {item.weather}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-black">{item.totalAdults}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-black">{item.totalChildren}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.returnVisits}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.totalOrders}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">£{item.totalSpent.toFixed(2)}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  £{item.clv ? item.clv.toFixed(2) : 'N/A'}
</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {item.mostPopularHotItems.length > 0 ? item.mostPopularHotItems.join(', ') : 'N/A'}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {item.mostPopularDrinkItems.length > 0 ? item.mostPopularDrinkItems.join(', ') : 'N/A'}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.clockinCount}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {item.applicablePrices.map(priceItem => `${priceItem.Name} (£${priceItem.Price})`).join(', ')}
                </td>
              </tr>
              
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
)}
</>
);
}

