import React, { useEffect, useState } from 'react';
import SessionCalenderTill from './sessioncalendertill';
import StaffTill from './StaffTill';
import SessionCalender from './sessionCalender';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { format, isWithinInterval, parseISO } from 'date-fns';

export default function SessionBook() {
  const [children, setChildren] = useState(1);
  const [adults, setAdults] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [childData, setChildData] = useState([{ age: '' }]);
  const [name, setName] = useState("");
  const [staff, setStaff] = useState('');
  const [selectedSession, setSelectedSession] = useState('');
  const [sessions, setSessions] = useState([]);
  const [price, setPrice] = useState(0);
  const [dontRenderForm, setDontRenderForm] = useState(false);
  const [childPrice, setChildPrice] = useState([]);
  const [adultPrice, setAdultPrice] = useState([]);
  const [siblingPrice, setSiblingPrice] = useState([]);
  const [additionalAdultPrice, setAdditionalAdultPrice] = useState([]);
  const [date, setDate] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setNumber] = useState('');

  const user = useContext(AuthContext);
  const Navigate = useNavigate();

  console.log(children, adults, date);

  const handleChildrenChange = (e) => {
    const value = e.target.value;
    setChildren(value);
    setChildData(Array.from({ length: value }, () => ({ name: '', age: '' })));
  };

  useEffect(() => {
    const fetchPrices = async () => {
      if (!date) return; // Do not proceed if no date is selected

      try {
        const response = await fetch('https://cheekydino.com/api/prices', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const prices = data.data;

        // Use the selected date to determine the day of the week
        const selectedDate = parseISO(date);
        const dayOfWeek = format(selectedDate, 'EEEE');
        const currentTime = format(new Date(), 'HH:mm:ss');

        const convertDaysToArray = (daysString) => {
          if (!daysString) return []; // Handle null or undefined
          return daysString.split(',').map(day => day.trim());
        };

        const sessionDataDays = prices.filter((price) => {
          const daysArray = convertDaysToArray(price.Days);
          return (
            price.SetDay === 1 && price.Active === 1 &&
            price.Days &&
            daysArray.includes(dayOfWeek)
          );
        });

        const sessionDataDates = prices.filter((price) => {
          const startDate = price.StartDate ? parseISO(price.StartDate) : null;
          const expiryDate = price.ExpiryDate ? parseISO(price.ExpiryDate) : null;
          return (
            price.SetDay === 0 && price.Active === 1 && 
            price.Category &&
            startDate &&
            expiryDate &&
            isWithinInterval(selectedDate, { start: startDate, end: expiryDate })
          );
        });

        const allSessionData = [...sessionDataDays, ...sessionDataDates];
        
        setChildPrice(allSessionData.filter(price => price.Category === "Children"));
        setAdultPrice(allSessionData.filter(price => price.Category === "Adults"));
        setSiblingPrice(allSessionData.filter(price => price.Category === "Siblings"));
        setAdditionalAdultPrice(allSessionData.filter(price => price.Category === "AdditionalAdults"));

      } catch (error) {
        console.error('Failed to fetch prices', error);
      }
    };

    fetchPrices();
  }, [date]);

  const handleChildAgeChange = (index, value) => {
    console.log(`Child ${index} age changed to ${value}`);
  
    setChildData((prev) =>
      prev.map((data, i) => (i === index ? { ...data, childAge: value } : data))
    );
  
    // Log the updated childData state
    console.log(childData);
  };
  
  const calculatePrice = (childData, adults, children) => {
    let totalPrice = 0;

    // Sum all child prices from childData
    childData.forEach(data => {
      const childPriceValue = parseFloat(data.childAge) || 0; // Get price from childData
      totalPrice += childPriceValue;
    });

    // Calculate additional adult price if there are more adults than children
    const additionalAdults = adults - children;
    if (additionalAdults > 0) {
      const additionalAdultPriceValue = adultPrice.find(price => price.Category === "Adults")?.Price;
      totalPrice += additionalAdults * (parseFloat(additionalAdultPriceValue) || 0);
    }

    return totalPrice;
  };

  const handleSubmit = () => {
    const totalPrice = calculatePrice(childData, parseInt(adults), parseInt(children));
    setPrice(totalPrice); // Set the total price state
    setChildData((prev) => prev.map((data) => ({ ...data, TotalSpent: totalPrice })));
    if (totalPrice !== 0) {
      setSubmitted(true);
    } else {
      alert('Please add the childrens Ages to proceed with your booking.');
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2 border">
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className=" mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl component-title">
                Book Your Session
              </h2>
              <div>
                <h4 className=' component-title text-orange-500 mt-5'>{email}</h4>
              </div>

              {/* Date Selection Component */}
              <div>
                <label htmlFor="date" className="block text-sm font-medium leading-6 component-title mt-2 text-gray-900">
                  Date
                </label>
                <input
                  onChange={(e) => setDate(e.target.value)}
                  id="date"
                  type="date"
                  name="date"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>

              <div>
                <label htmlFor="name" className="block component-title mt-2 text-sm font-medium leading-6 text-gray-900">
                  Adult Name
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  type="text"
                  name="name"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={name}
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm component-title mt-2 font-medium leading-6 text-gray-900">
                  Telephone
                </label>
                <input
                  onChange={(e) => setNumber(e.target.value)}
                  id="number"
                  type='text'
                  name="number"
                  value={telephone}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>

              <div>
                <label htmlFor="adults" className="block text-sm component-title mt-2 font-medium leading-6 text-gray-900">
                  Number of Adults
                </label>
                <input
                  onChange={(e) => setAdults(e.target.value)}
                  id="adults"
                  type="number"
                  name="adults"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={adults}
                />
              </div>

              <div>
                <label htmlFor="children" className="block text-sm component-title mt-2 font-medium leading-6 text-gray-900">
                  Number of Children
                </label>
                <input
                  onChange={handleChildrenChange}
                  id="children"
                  type="number"
                  name="children"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={children}
                />
              </div>

              <div>
                {childData.map((data, index) => (
                  <div key={index} className="space-y-2">
                    <label htmlFor={`child-age-${index}`} className="block text-sm font-medium text-gray-900">
                      Child's Age- Do Not Select Sibling First
                    </label>
                    <select
                      onChange={(e) => handleChildAgeChange(index, e.target.value)}
                      id={`child-age-${index}`}
                      name={`child-age-${index}`}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select an age</option>
                      {childPrice.map((price) => (
                        <option key={price.id} value={price.Price}>
                          {price.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>

              <p className="mt-2 text-sm font-bold text-gray-500">Terms Of Service</p>
<p className="mt-2 text-sm text-gray-900"> Disclaimer for Online Bookings:

  At Cheeky Dino, we prioritize the safety and enjoyment of all our visitors. To maintain a safe and fair environment for everyone, it's essential that accurate information is provided when booking tickets online or using our online services.

  By using our online booking platform, you agree to adhere to our code of practice and transparency. This includes providing truthful and accurate information, especially regarding age for discounted rates.

  Please be advised that intentionally providing incorrect information, such as incorrect ages to receive discounted rates, is strictly prohibited. Inaccurate information provided during online booking may result in refusal of entry or services without refund.

  We reserve the right to verify the accuracy of information provided and to take appropriate action if discrepancies are found. This may include adjusting pricing to reflect the correct age category or refusing entry.

  Thank you for your cooperation in helping us maintain a safe and enjoyable experience for all our guests.

  Please contact us if you have any questions or require further information.

</p>
             
              <p className="mt-2 text-sm text-gray-900">Privacy Policy
              <p className="mt-2 text-sm text-gray-900">Privacy Policy
   Data Security and GDPR Compliance:

  At Cheeky Dino, we take the privacy and security of your personal information seriously. Any information provided during the booking process is saved securely and is used solely for business purposes within Cheeky Dino. We do not share this personal information with any other parties.

  We are committed to complying with the General Data Protection Regulation (GDPR) rules. If you wish for your personal data to be deleted, please submit a written request, and we will promptly delete it from our records. Additionally, you have the right to make a Subject Access Request (SAR) in writing to obtain information about the personal data we hold about you.

  Please rest assured that we adhere to strict data protection protocols to ensure the confidentiality and integrity of your information at all times.

  Thank you for entrusting us with your personal information. If you have any concerns or questions regarding data privacy, please don't hesitate to contact us.
</p>              </p>
              <button
                type="submit"
                onClick={handleSubmit}
                className="mt-8 w-full inline-flex items-center justify-center px-6 py-3 border component-title mt-2 border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-orange-500 md:py-4 md:text-lg md:px-10"
              >
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 border">
        {submitted && (
          <SessionCalender children={children} adults={adults} date={date} childData={childData} telephone={telephone}
            email={email} name={name} price={price} />
        )}
      </div>
    </div>
  );
}




