// index.js (for website deployment)
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppRoutes } from './renderer';
import './index.css';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import '@fontsource/inter/variable.css';
import { AuthProvider } from './authprovider';

// Use BrowserRouter only for the website
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <HashRouter>  {/* Use BrowserRouter for web */}
        <App />
      </HashRouter>
    </AuthProvider>
  </React.StrictMode>
);

// if (window && window.process && window.process.type) {
//   ReactDOM.createRoot(document.querySelector('#app')).render(
//     <React.StrictMode>
//       <AuthProvider>
//         <BrowserRouter>
//           <AppRoutes />
//         </BrowserRouter>
//       </AuthProvider>
//     </React.StrictMode>
//   );
// }
