import React, { useState, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../AuthContext";
import LoadingComponent from "../staffpages/loading";

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const user = useContext(AuthContext);
  const userEmail = user.email;
  const location = useLocation();
  const { party } = location.state || {};  // Add a default empty object

  if (!party) {
    return <div><LoadingComponent/></div>;  // Return a loading state or a default state
  }

  useEffect(() => {
    const fetchMessages = async () => {
      const token = localStorage.getItem('token'); // Replace this with your actual method to get the token

      try {
        const response = await fetch('https://cheekydino.com/api/customerenquiries', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setMessages(data.data); // Adjusted based on your backend response
      } catch (error) {
        console.error(error);
      }
    };

    fetchMessages();

    // fetch messages every 5 seconds

    const interval = setInterval(() => {
      fetchMessages();
    }
    , 5000);

    return () => clearInterval(interval);

    
  }, [userEmail]);

  const sendMessage = async (event) => {
    event.preventDefault();
    const enquiryData = {
      To: "frontdesk@cheekydino.co.uk",
      Content: input,
      From: userEmail,
      Replied: 0,
      Marketing: 0,
      ToMarketing: "not applicable",
      createdAt: new Date().toISOString(),


    };



    const token = localStorage.getItem('token'); // Replace this with your actual method to get the token

    try {
        const response = await fetch('https://cheekydino.com/api/addenquiry', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(enquiryData),
        });

        if (!response.ok) {
            throw new Error('Failed to send message');
        }

        const data = await response.json();
        console.log('Message sent:', data);
window.location.reload()        
setInput('');


    } catch (error) {
        console.error('Error sending message:', error);
    }




  };

  return (
    <div className="flex flex-col h-full bg-gray-100" style={{ height: '90vh' }}>
      <div className="bg-orange-500 text-white p-4 flex justify-between items-center">
        <h1 className="text-lg font-bold">Chat with us</h1>
        <span className="text-sm">{userEmail}</span>
      </div>
      <div className="md:flex p-4 mb-10">
        <img className="w-full md:w-1/2 md:mr-4" src={party.imageSrc} alt={party.imageAlt} />
        <div className="mt-4 md:mt-0 md:w-1/2">
          <h2>{party.name}</h2>
          <p>{party.description}</p>
          <ul>
            {party.highlights.map((highlight, index) => (
              <li key={index}>{highlight}</li>
            ))}
          </ul>
          <p>Price: £{party.price}</p>
        </div>
      </div>
      <div className="flex flex-col h-screen bg-purple-100 mt-10">
        {messages.map((message, index) => (
          <div key={index} className={`p-4 mb-4 rounded-lg mt-5 shadow-lg ${message.From === userEmail ? "bg-green-100 bg-opacity-75 self-center" : "bg-blue-50 bg-opacity-75 self-start"}`} style={{ borderRadius: '20px' }}>
            <div style={{ padding: '10px' }}>
              <div>{message.From}: {message.Content}</div>
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={sendMessage} className="m-2">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          type="text"
          className="w-full p-2 border rounded-lg"
          placeholder="Type your message here..."
          style={{ height: '100px' }}
        />
        <button type="submit" className="w-full p-2 mt-2 bg-indigo-500 text-white rounded-lg">
          Send
        </button>
      </form>
    </div>
  );
};

export default ChatComponent;
