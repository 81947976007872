import { useState, useEffect, useContext } from 'react';
import "./customer.css";
import { AuthContext } from '../../AuthContext';

export default function PartyGuests() {
  const user = useContext(AuthContext);
  const email = user?.email;

  const [partyBookings, setPartyBookings] = useState(null);
  const [guests, setGuests] = useState([]);
  const [addGuest, setAddGuests] = useState(false);
  const [name, setName] = useState('');
  const [emails, setEmail] = useState('');
  const [foodOptions, setFoodOptions] = useState([]);
  const [food, setFood] = useState('');
  const [activate, setActivate] = useState('No');
  const [allergyDetails, setAllergyDetails] = useState('');

  useEffect(() => {
    async function fetchPartyBookings() {
      try {
        const token = localStorage.getItem('token');

        const response = await fetch(`https://cheekydino.com/api/uniquepartybooking/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          const uniqueBookings = data.data.filter((booking, index, self) =>
            index === self.findIndex((t) => (
              t.PartyDate === booking.PartyDate && t.PartyTime === booking.PartyTime
            ))
          );

          if (uniqueBookings.length > 0) {
            setPartyBookings(uniqueBookings[0].id);
            setFoodOptions(uniqueBookings[0].FoodOptionSelected);
          }
        }
      } catch (error) {
        console.error('Failed to fetch party bookings', error);
      }
    }

    fetchPartyBookings();
  }, [email]);

  useEffect(() => {
    if (!partyBookings) return;

    const getGuests = async () => {
      try {
        const response = await fetch(`https://cheekydino.com/api/partyguestsid/${partyBookings}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setGuests(data.data);

      } catch (error) {
        console.error('Failed to fetch party guests', error);
      }
    };

    getGuests();

    const interval = setInterval(() => {
      getGuests();
    }, 5000);

    return () => clearInterval(interval);

  }, [partyBookings]);

  useEffect(() => {
    if (!foodOptions.length) return;

    const getFoodOptions = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/foodoptions', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setFoodOptions(data.data);
        
      } catch (error) {
        console.error('Failed to fetch food options', error);
      }
    };

    getFoodOptions();
  }, [foodOptions]);

  const handleAddGuest = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://cheekydino.com/api/addpartyguest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ChildName: name,
          FoodOption: food.name,
          FoodOptionId: food.id,
          Allergies: activate === 'Yes' ? allergyDetails : 'No',
          ContactInfoEmail: emails,
          guest_id: partyBookings
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      setAddGuests(false);
      setName('');
      setFood('');
      setAllergyDetails('');
      setActivate('No');
      setEmail('');

    } catch (error) {
      console.error('Failed to add party guest:', error);
    }
  };

  const handleDeleteGuest = async (e, guestId) => {
    e.preventDefault();

    console.log('Deleting guest:', guestId);
  
    try {
      const response = await fetch(`https://cheekydino.com/api/deletepartyguest/${guestId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Guest deleted:', data);
  
      // Refresh the guest list after deleting a guest
    } catch (error) {
      console.error('Failed to delete party guest:', error);
    }
  };

  const handleChange = (e) => {
    // Extract the selected value
    const [selectedId, selectedName] = e.target.value.split('|');
    setFood({ id: selectedId, name: selectedName });
};



  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">My Party Guests</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => setAddGuests(true)}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add Guest
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        {addGuest && (
          <div className="mt-8">
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Add Guest for Party ID: {partyBookings}
            </h2>
            <form onSubmit={handleAddGuest} className="mt-4 space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Child's Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Contact Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={emails}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
            <label htmlFor="food" className="block text-sm font-medium leading-6 text-gray-900">
                Food Option
            </label>
            <select
                id="food"
                name="food"
                value={`${food.id || ''}|${food.name || ''}`} // Set value to the combined id and name
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
                <option value="" disabled>
                    Please select
                </option>
                {foodOptions.map((option) => (
                    <option key={option.id} value={`${option.id}|${option.Name}`}>
                        {option.Name}
                    </option>
                ))}
            </select>
        </div>


              <div>
                <label htmlFor="activate" className="block text-sm font-medium text-gray-700">
                  Any Allergies?
                </label>
                <select
                  id="activate"
                  name="activate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={activate}
                  onChange={(e) => setActivate(e.target.value)}
                  required
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>

              {activate === 'Yes' && (
                <div>
                  <label htmlFor="allergyDetails" className="block text-sm font-medium text-gray-700">
                    Allergy Details
                  </label>
                  <input
                    type="text"
                    id="allergyDetails"
                    name="allergyDetails"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={allergyDetails}
                    onChange={(e) => setAllergyDetails(e.target.value)}
                    required={activate === 'Yes'}
                  />
                </div>
              )}

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Add Guest
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Food Option
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Allergies
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {guests.map((person, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.ChildName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.ContactInfoEmail}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.FoodOption}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Allergies}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                    className="text-red-600 hover:text-red-900"
                    onClick={(e) => handleDeleteGuest(e, person.id)}
                  >
                    Delete
                  </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
