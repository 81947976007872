import React, { useState, useEffect } from "react";
import { format, differenceInMinutes, parse } from "date-fns";
import { differenceInSeconds } from "date-fns";

export default function CleanTable() {
  const [sessions, setSessions] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

 
  
  useEffect(() => {
    const getTodaysBookings = async () => {
        try {
          const today = format(new Date(), 'yyyy-MM-dd');
      
          const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
      
          const sessionsData = await response.json();
          const sessions = sessionsData.data;
      
          // Filter the sessions array to only include sessions for today
          const todaysBookings = sessions.filter(
            (session) => session.Arrived === 1 && session.LeftCenter === 1 && session.CleanTable === 1 && session.TableCleaned === 0
          );
      
         
      
      
          // Update sessions state directly within useEffect
          setSessions(todaysBookings);
          console.log('todaysBookings:', todaysBookings);
        } catch (error) {
          console.error('Error getting today\'s bookings:', error);
        }
      };
    
        getTodaysBookings();
    
        setInterval(() => {
            getTodaysBookings();
        }, 5000);
    
        }, []);

  

//   const handleCleanedClick = async (session, event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setLoading(true);
//     try {
//       await DataStore.save(
//         Sessions.copyOf(session, updated => {
//           updated.CleanTable = true;
//           updated.TableCleaned = true;
//         })
//       );


//       fetchSessions();
//       console.log("Session updated successfully");
//       // set a timer to setLoading(false) after 3 seconds
//       setTimeout(() => {
//         setLoading(false);
//         fetchSessions();
//       }, 3000);
//     } catch (error) {
//       console.error("Error updating session", error);
//     }
//   };

const handleCleanedClick = async (session, event) => {
  event.preventDefault();
  event.stopPropagation();

  setLoading(true);

  try {
    // POST request to mark the table as cleaned
    const response = await fetch(`https://cheekydino.com/api/cleantable`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: session.id }), // send id in the body
    });

    if (!response.ok) {
      throw new Error('Failed to mark table as cleaned');
    }

    // Update the sessions state to remove the cleaned table
    const updatedSessions = sessions.filter(s => s.id !== session.id);
    setSessions(updatedSessions);

    // set a timer to setLoading(false) after 3 seconds
    setTimeout(() => {
      setLoading(false);
    }, 3000);

  } catch (error) {
    console.error('Error marking table as cleaned:', error);
  }
};










  
  
  
  

  

  return (
    <div>
     

      


  <div className="grid grid-cols-3 gap-4 mt-5">
    {sessions.map((session) => {
      return (
        <div key={session.id} className={`flex flex-col justify-between gap-y-4 py-5 px-4 rounded-lg bg-purple-500 shadow-lg`}>
          <div className="flex justify-between items-start">
            {
            loading ? (
              <div className="flex items-center justify-center h-20 w-20 rounded-full bg-white">
                <img src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeW1td3QyZzZuNTNrZWI2N2I1NzZ2aGoxbW14eXdiYzRxY3E3Y2RydyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gjNtQ7q4Gd6hcbvQoj/giphy.gif" alt="loading" />
              </div>
            ) : null

            }
            <div className="flex flex-col">
              <div className="flex items-center justify-center h-20 w-20 rounded-full bg-white">
                <p className="text-black font-bold text-xl">{session.Table}</p>
              </div>
            </div>
            <button className="mt-4 bg-white hover:bg-blue-700 text-black font-bold py-2 px-4 rounded" onClick={() => handleCleanedClick(session, event)}>
              Done
            </button>
          </div>
        </div>
      )
    })}
  </div>


    </div>
  );
}
