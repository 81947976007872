import { useState, useEffect } from "react";
import JsxParser from "react-jsx-parser";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";

export default function EditPreview() {
  const [adverts, setAdverts] = useState([]);
  const [selectedAdvert, setSelectedAdvert] = useState(null);
  const [selectedId, setSelectedId] = useState(""); // Initialize as an empty string
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("date");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [callback, setCallback] = useState(false);



  useEffect(() => {
    const getAdverts = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/adverts", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAdverts(data.data);

          // Set selected advert only on the initial load, or when adverts change
          if (!selectedId && data.data.length > 0) {
            const defaultAdvert = data.data[0];
            setSelectedId(defaultAdvert.id); // Set the first advert's id as the default selectedId
            setSelectedAdvert(defaultAdvert);
          }
        } else {
          console.error('Failed to fetch adverts');
        }
      } catch (error) {
        console.error(error);
      }
    };

    getAdverts();

    // call the function every 5 seconds
    const interval = setInterval(() => {
      getAdverts();
    }, 10000); // Increased to 10 seconds to minimize interference

    return () => clearInterval(interval);

  }, [selectedId, callback]);

  const handleSaveAnnouncements = async (event) => {






    event.preventDefault();

    if (selectedAdvert) {
      try {
        const response = await fetch(`https://cheekydino.com/api/addadvert/${selectedAdvert.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            SetDay: selectedOption === 'day' ? 1 : 0,
          StartDate: selectedOption === 'day' ? null : startDate,
          EndDate: selectedOption === 'day' ? null : endDate,
          StartTime: startTime,
          EndTime: endTime,
          Days: selectedOption === 'day' ? selectedDays : null,

          }),

        });

        if (response.ok) {
          const updatedAdvert = await response.json();
          setSelectedAdvert(updatedAdvert); // Update the selected advert with new status
          setCallback(true); // Trigger a re-fetch of the adverts
          setOpen(false); // Close the modal
          console.log('Announcements saved successfully!');
        }
      } catch (error) {
        console.error('Failed to save announcements', error);
      }
    }
  };






  useEffect(() => {
    const getAdverts = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/adverts", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAdverts(data.data);

          // Set selected advert only on the initial load, or when adverts change
          if (!selectedId && data.data.length > 0) {
            const defaultAdvert = data.data[0];
            setSelectedId(defaultAdvert.id); // Set the first advert's id as the default selectedId
            setSelectedAdvert(defaultAdvert);
          }
        } else {
          console.error('Failed to fetch adverts');
        }
      } catch (error) {
        console.error(error);
      }
    };

    getAdverts();

    // call the function every 5 seconds
    const interval = setInterval(() => {
      getAdverts();
    }, 10000); // Increased to 10 seconds to minimize interference

    return () => clearInterval(interval);

  }, [selectedId, callback]);

  const handleSelectChange = (event) => {
    const selectedId = parseInt(event.target.value, 10); 
    setSelectedId(selectedId);

    const selectedAdvert = adverts.find((advert) => advert.id === selectedId);
    setSelectedAdvert(selectedAdvert);
  };


  

  const handleStatusChange = async (field, newStatus) => {
    console.log(`Updating ${field} status to ${newStatus}...`); // Debugging log

    console.log(selectedAdvert);
    if (selectedAdvert) {
        try {
            const response = await fetch(`https://cheekydino.com/api/advert/${selectedAdvert.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ field, newStatus }),
            });
    
            if (response.ok) {
                const updatedAdvert = await response.json();
                console.log(`${field} status updated successfully.`);
                setCallback(true); // Trigger a re-fetch of the adverts
            } else {
                throw new Error(`Failed to update ${field} status`);
            }
        } catch (error) {
            console.error(`There was an error updating the ${field} status!`, error);
        }
    }
};

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://cheekydino.com/api/advert/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setAdverts((prev) => prev.filter((advert) => advert.id !== id));
        setCallback(true); // Trigger a re-fetch of the adverts
        console.log('Advert deleted successfully!');
      } else {
        throw new Error('Failed to delete advert');
      }
    } catch (error) {
      console.error('There was an error deleting the advert!', error);
    }
  };
  

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Adverts</h1>
          <div className="mt-4">
            <label htmlFor="advert-select" className="block text-sm font-medium text-gray-700">Select Advert</label>
            <select
              id="advert-select"
              name="advert-select"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={handleSelectChange}
              value={selectedId} // Bind the value to selectedId
            >
              {adverts.map((advert) => (
                <option key={advert.id} value={advert.id}>
                  {advert.Name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {open && (
        <div className="mt-4">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Edit Times</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
              </div>
              <form onSubmit={handleSaveAnnouncements} className="mt-5 space-y-4">
              

                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900">Set for Day or Date</legend>
                  <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    <div className="flex items-center">
                      <input
                        id="date"
                        name="schedule-option"
                        type="radio"
                        value="date"
                        checked={selectedOption === 'date'}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="date" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Date
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="day"
                        name="schedule-option"
                        type="radio"
                        value="day"
                        checked={selectedOption === 'day'}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="day" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Day
                      </label>
                    </div>
                  </div>
                </fieldset>

                {selectedOption === 'date' ? (
                  <>
                    <div>
                      <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <input
                        onChange={(event) => setStartDate(event.target.value)}
                        type="date"
                        name="startDate"
                        id="startDate"
                        value={startDate}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Start Date"
                      />
                    </div>
                    <div>
                      <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                        End Date
                      </label>
                      <input
                        onChange={(event) => setEndDate(event.target.value)}
                        type="date"
                        name="endDate"
                        id="endDate"
                        value={endDate}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="End Date"
                      />
                    </div>
                    <div>
                      <label htmlFor="startTime" className="block text-sm font-medium text-gray-700">
                        Start Time
                      </label>
                      <input
                        onChange={(event) => setStartTime(event.target.value)}
                        type="time"
                        name="startTime"
                        id="startTime"
                        value={startTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Start Time"
                      />
                    </div>
                    <div>
                      <label htmlFor="endTime" className="block text-sm font-medium text-gray-700">
                        End Time
                      </label>
                      <input
                        onChange={(event) => setEndTime(event.target.value)}
                        type="time"
                        name="endTime"
                        id="endTime"
                        value={endTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="End Time"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Select Days of the Week</label>
                      <div className="mt-2 grid grid-cols-3 gap-2">
                        {daysOfWeek.map((day) => (
                          <div key={day} className="flex items-center">
                            <input
                              id={day}
                              name="days"
                              type="checkbox"
                              value={day}
                              onChange={(event) => {
                                const { checked, value } = event.target;
                                setSelectedDays((prev) =>
                                  checked ? [...prev, value] : prev.filter((day) => day !== value)
                                );
                              }}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor={day} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              {day}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="startTime" className="block text-sm font-medium text-gray-700">
                        Start Time
                      </label>
                      <input
                        onChange={(event) => setStartTime(event.target.value)}
                        type="time"
                        name="startTime"
                        id="startTime"
                        value={startTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Start Time"
                      />
                    </div>
                    <div>
                      <label htmlFor="endTime" className="block text-sm font-medium text-gray-700">
                        End Time
                      </label>
                      <input
                        onChange={(event) => setEndTime(event.target.value)}
                        type="time"
                        name="endTime"
                        id="endTime"
                        value={endTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="End Time"
                      />
                    </div>
                  </>
                )}

                <div className="mt-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md bg-green-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {selectedAdvert && (
        <div className="mt-8">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-blue-700">Preview</h3>
              <button onClick={() => setOpen(true)} className="mt-1 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Edit
              </button>
              <button onClick={() => handleDelete(selectedAdvert.id)} className="mt-1 block w-full rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Delete
              </button>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Preview</dt>
                  <dd className="mt-1 text-sm text-blue-900 sm:col-span-2 sm:mt-0">
                    <div className="w-full max-w-full overflow-hidden">
                      <JsxParser jsx={selectedAdvert.Code} />
                    </div>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedAdvert.Name}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Design</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedAdvert.Design ? "My Own Design" : "AI Generated"}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Active</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {selectedAdvert.Activate ? "Yes" : "No"}
                    <button
                      onClick={() => handleStatusChange('Activate', selectedAdvert.Activate ? 0 : 1)}
                      type="button"
                      className={`ml-4 py-1 px-3 text-sm font-medium text-white rounded-md shadow-sm ${selectedAdvert.Activate ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'}`}
                    >
                      {selectedAdvert.Activate ? 'Deactivate' : 'Activate'}
                    </button>
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
  <dt className="text-sm font-medium text-gray-500">Front Screen</dt>
  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center">
    <button
      onClick={() => handleStatusChange('FrontScreen', selectedAdvert.FrontScreen ? 0 : 1)}
      type="button"
      className={`ml-4 rounded-full p-2 text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${selectedAdvert.FrontScreen ? 'bg-green-500' : 'bg-red-500'}`}
    >
      {selectedAdvert.FrontScreen ? (
        <LockOpenIcon aria-hidden="true" className="h-5 w-5" />
      ) : (
        <LockClosedIcon aria-hidden="true" className="h-5 w-5" />
      )}
    </button>
  </dd>
</div>

<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
  <dt className="text-sm font-medium text-gray-500">Website</dt>
  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center">
    <button
      onClick={() => handleStatusChange('Website', selectedAdvert.Website ? 0 : 1)}
      type="button"
      className={`ml-4 rounded-full p-2 text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${selectedAdvert.Website ? 'bg-green-500' : 'bg-red-500'}`}
    >
      {selectedAdvert.Website ? (
        <LockOpenIcon aria-hidden="true" className="h-5 w-5" />
      ) : (
        <LockClosedIcon aria-hidden="true" className="h-5 w-5" />
      )}
    </button>
  </dd>
</div>
               <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
  <dt className="text-sm font-medium text-gray-500">Campaign Dates</dt>
  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
    {selectedAdvert.StartDate && selectedAdvert.EndDate ? (
      `${format(new Date(selectedAdvert.StartDate), 'yyyy-MM-dd')} - ${format(new Date(selectedAdvert.EndDate), 'yyyy-MM-dd')}`
    ) : (
      ''
    )}
  </dd>
</div>

                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Showing Times</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedAdvert.StartTime} - {selectedAdvert.EndTime}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Days</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedAdvert.Days}</dd>
                </div>
              </dl>
            </div>
            <button className="block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              Download as PDF
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
