import {
    ArrowDownIcon,
    ArrowUpIcon,
    CursorArrowRaysIcon,
    DocumentArrowUpIcon,
    EnvelopeOpenIcon,
    EyeIcon,
    UsersIcon
  } from '@heroicons/react/20/solid';
  import { useEffect, useState } from 'react';
  // import { DataStore, Predicates } from 'aws-amplify';
  // import { Sessions, PartyBooking, StockControl, CafeOrder, ClockIns, } from '../models';
  import { format, isToday, parse } from 'date-fns';
import { ArrowRightCircleIcon, CakeIcon, CalendarDateRangeIcon, ClockIcon, CurrencyPoundIcon, HeartIcon, PlusCircleIcon, ScaleIcon, ShoppingBagIcon, TableCellsIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { id } from 'date-fns/locale';
import { set } from 'lodash';
import TillHistory from './tillhistory';
import BranchSettings from './branchsettingshome';
import Buzzer from './buzzer';
import NextDaySession from './nextdaysession';
import DailyReport from './dailyreport';
import Embed from './embed';


    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
        }

  
 export default function FinancialStats() {
    const [sessions, setSessions] = useState([]);
    const [partyBookings, setPartyBookings] = useState([]);
    const [cafeOrders, setCafeOrders] = useState([]);
    const [staff, setStaff] = useState([]);
    const [show, setShow] = useState(false);
    const [branch, setBranch] = useState(false);
    const [day, setDay] = useState(false);
    const [report, setReport] = useState(false);
    const [labor, setLabor] = useState(false);

 

  useEffect(() => {
    const fetchSessions = async () => {

        const today = format(new Date(), 'yyyy-MM-dd');

        try {
            const sessions = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },

            })

            if (!sessions.ok) {
                throw new Error('Failed to fetch sessions');
            }




            const data = await sessions.json();
            const session = data.data;

            console.log(session);




            setSessions(session);
        } catch (error) {

            console.error('Failed to fetch sessions:', error);
            
        }
     
    };

    fetchSessions(); // Initial fetch

    const intervalId = setInterval(() => {
        fetchSessions();
    }, 5000); // Poll every second

    return () => {

        clearInterval(intervalId); // Cleanup interval on component unmount
    }
    }, []);

    useEffect(() => {

        const fetchPartyBookings = async () => {

            const date = format(new Date(), 'yyyy-MM-dd');

            try {
              const partyResponse = await fetch(`https://cheekydino.com/api/party?date=${date}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
        
              if (!partyResponse.ok) {
                throw new Error('Failed to fetch party data');
              }
        

                const data = await partyResponse.json();
                const partyBooking = data.data;
                setPartyBookings(partyBooking);
            } catch (error) {

                console.error('Failed to fetch party bookings:', error);
            }

        };

        fetchPartyBookings(); // Initial fetch

       
    }, []);


console.log(partyBookings);

    useEffect(() => {

        const fetchCafeOrders = async () => {
            const today = format(new Date(), 'yyyy-MM-dd');
            try {
                const cafeOrders = await fetch(`https://cheekydino.com/api/cafeordersstats?date=${today}`, {

                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await cafeOrders.json();
                const cafeOrder = data.data;
                setCafeOrders(cafeOrder);
            } catch (error) {

                console.error('Failed to fetch cafe orders:', error);
            }

        };

        fetchCafeOrders(); // Initial fetch

        const intervalId = setInterval(() => {

            fetchCafeOrders();
        }, 30000); // Poll every second

        return () => {

            clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, []);


    useEffect(() => {

      const fetchClockIns = async () => {

          try {

              const clockIns = await fetch('https://cheekydino.com/api/clockins', {  
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                  }

              });

              if (!clockIns.ok) {
                  throw new Error('Failed to fetch clock ins');
              }

              const data = await clockIns.json();
              const clockIn = data.data;

              setStaff(clockIn);

          } catch (error) {

              console.error('Failed to fetch clock ins:', error);
          }

      };

      fetchClockIns(); // Initial fetch

      const intervalId = setInterval(() => {
          
            fetchClockIns();
        }
        // poll every 10 minutes
        , 600000);

      return () => {
          
            clearInterval(intervalId); // Cleanup interval on component unmount
        }


        


     

  }, []);





  



if (show) {
    return (
      <TillHistory />
    )
  }

  if (branch) {
    return (
      <BranchSettings />
    )
  }


  if (day) {
    return (
      <NextDaySession />
    )
  }

 



  






  
    // const fetchCafeOrders = async () => {
    //     const cafeOrders = await DataStore.query(CafeOrder);
    //     setCafeOrders(cafeOrders);
    //     };

    // const fetchStaff = async () => {
    //     const staff = await DataStore.query(ClockIns);
    //     setStaff(staff);





    //     };


    // const fetchPartyBookings = async () => {
    //   const partyBookings = await DataStore.query(PartyBooking);
    //   setPartyBookings(partyBookings);
    // };
   




    // work out staff costs 


   


  
    const currentGuests = sessions.filter(
      session => session.Arrived === 1 && session.LeftCenter === 0
    );
    const addGuests = currentGuests.reduce((acc, session) => acc + session.Children + session.Adults, 0);
  
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  
    const yesterdaySessions = sessions.filter(session => session.Date === yesterday);
    const yesterdayGuests = yesterdaySessions.reduce(
      (acc, session) => acc + session.Children + session.Adults,
      0
    );
  
    const guestsChange = addGuests - yesterdayGuests;
  
    const today = format(new Date(), 'yyyy-MM-dd');

    const todaySessions = sessions.filter(session => {
      const sessionDate = parse(session.Date, 'yyyy-MM-dd', new Date());
      return isToday(sessionDate);
    });
    
      const cleanTables = sessions.filter(session => session.CleanTable === 1 && session.TableCleaned === 0);


    
      const todayTotalSpend = todaySessions.reduce((total, session) => total + (session.TotalSpent || 0), 0) 
      + cafeOrders.reduce((total, order) => total + (order.Total || 0), 0);

    const yesterdayTotalSpend = yesterdaySessions.reduce((total, session) => total + (session.TotalSpent || 0), 0);

    const totalSpendChange = todayTotalSpend - yesterdayTotalSpend;
  

    const currentTime = format(new Date(), 'HH:mm');    
    const futureBookings = sessions.filter(session => session.TimeslotFrom > currentTime && session.Date === today && session.Arrived === 0);
    const futureSessionsCount = futureBookings.length;

  
    const occupiedTables = sessions.filter(session => session.Arrived === 1 && session.LeftCenter === 0).length;
    const totalTables = sessions.filter(session => session.Table).length;
  
    const totalSpendAllSessions = sessions.reduce((total, session) => total + (session.TotalSpent || 0), 0);
    const averageSpendPerSession = totalSpendAllSessions / sessions.length;
  
    const partyBookingsTodayCount = partyBookings.filter(booking => booking.PartyDate === today).length;

    const currentOrders = cafeOrders.length;
    const currentOrdersCount = currentOrders.length;

    // yesterdays average spend

    const yesterdaySessionsTotalSpent = yesterdaySessions.reduce((total, session) => total + (session.TotalSpent || 0), 0);
const totalAllSessions = yesterdaySessionsTotalSpent / yesterdaySessions.length;


const variableLabourPercentage = () => {
  const currentTime = format(new Date(), 'HH:mm:ss');

  // Find staff who are currently clocked in
  const staffClockedIn = staff.filter(staff => staff.ClockOut === null);

  // Calculate total labour cost
  const totalLabourCost = staffClockedIn.reduce((acc, staff) => {
    // Create Date objects for clock-in and current time
    const clockInTime = new Date(`1970-01-01T${staff.ClockIn}Z`);
    const clockOutTime = new Date(`1970-01-01T${currentTime}Z`);

    // Calculate hours worked
    const hoursWorked = (clockOutTime - clockInTime) / 1000 / 60 / 60;
    
    // Calculate labour cost
    const labourCost = hoursWorked * staff.HourlyRate;
    return acc + labourCost;

  }, 0);

  // Calculate variable labour percentage
  const variableLabourPercentage = todayTotalSpend > 0 ? (totalLabourCost / todayTotalSpend) * 100 : 0;

  return variableLabourPercentage.toFixed(2); // Return the percentage with 2 decimal places
};













  const highestGrossingTable = sessions.reduce((acc, session) => {
    if (acc[session.Table]) {
      acc[session.Table] += session.TotalSpent;
    } else {
      acc[session.Table] = session.TotalSpent;
    }
    return acc;

  }, {});


  
    const stats = [
      {
        id: 1,
        name: 'Current Session Guests',
        stat: addGuests,
        icon: UsersIcon,
        change: guestsChange,
        changeType: guestsChange >= 0 ? 'increase' : 'decrease'
      },
      {
        id: 2,
        name: 'Total Spend Today',
        stat: `£${todayTotalSpend.toFixed(2)}`,
        icon: CurrencyPoundIcon,
        change: `£${totalSpendChange.toFixed(2)}`,
        changeType: totalSpendChange >= 0 ? 'increase' : 'decrease'
      },
      {
        id: 3,
        name: 'Orders Today',
        stat: cafeOrders.length,
        icon: ShoppingBagIcon,
        change: '', // You can provide a description here if needed
      },
      {
        id: 4,
        name: 'Tables Occupied',
        stat: occupiedTables,
        icon: TableCellsIcon,
        change: '', // You can provide a description here if needed
      },
      {
        id: 5,
        name: 'Clean Tables',
        stat: cleanTables.length,
        icon: CursorArrowRaysIcon,
        change: '', // You can provide a description here if needed
      },
      {
        id: 6,
        name: 'Future Sessions',
        stat: futureSessionsCount,
        icon: ArrowRightCircleIcon,
        change: '', // You can provide a description here if needed
      },
      {
        id: 7,
        name: 'Party Bookings Today',
        stat: partyBookings.length,
        icon: CakeIcon,
        change: '', // You can provide a description here if needed
      },
      {
        id: 8,
        name: 'Average Spend',
        stat: `£${averageSpendPerSession.toFixed(2)}`,
        icon: ScaleIcon,
        change: '', // You can provide a description here if needed
        changeType: totalSpendChange >= 0 ? 'increase' : 'decrease'
      },
      
      {
        id: 9,
        name: 'Variable %',
        stat: `${variableLabourPercentage()}%`,
        icon: UserGroupIcon,
        change: '', // You can provide a description here if needed
        textColor: variableLabourPercentage() > 22 ? 'text-red-600' : 'text-gray-900' // Conditional color
      }
      
      
      
      
     
    ];
  
    const todaysDate = format(new Date(), 'do MMMM yyyy');
  
   
    return (
      <div className="relative">
        {/* Buzzer Component Fixed Position */}
        
        <div className="flex items-center justify-between relative z-10">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{todaysDate}</h3>
          <button onClick={() => setShow(true)}
            type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <HeartIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
          </button>
          <Buzzer className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          />
          <button onClick={() => setBranch(true)}
            type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
          </button>
          <button onClick={() => setDay(true)}
            type="button"
            className="inline-flex ml-2 items-center gap-x-2 rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <CalendarDateRangeIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
          </button>
          <button onClick={() => setReport(true)} type='button' className="inline-flex items-center gap-x-2 rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <DocumentArrowUpIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
          </button>
          


        </div>
        {report && <DailyReport/>}
  
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 relative z-0">
  {stats.map((item) => (
    <div
      key={item.id}
      className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
    >
      <dt>
        <div className="absolute rounded-md bg-indigo-500 p-3">
          <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
      </dt>
      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        {/* Apply dynamic text color for item.stat */}
        <p className={`text-2xl font-semibold ${item.textColor}`}>{item.stat}</p>
        <p
          className={classNames(
            item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
            'ml-2 flex items-baseline text-sm font-semibold'
          )}
        >
          {item.changeType === 'increase' ? (
            <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
          ) : (
            <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
          )}
          <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
          {item.change}
        </p>
      </dd>
    </div>
  ))}
</dl>
      </div>
    );
  }
