import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";



export default function ForgotPassword() {

    const [verifyEmail, setVerifyEmail] = useState('');
    const [code, setCode] = useState(false);
    const [randomCode, setRandomCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [passwordField, SetPasswordField] = useState(false);
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);



    async function handleSubmit() {


        const generateRandomCode = () => {
            const min = 100000; // minimum 6-digit number
            const max = 999999; // maximum 6-digit number
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        const randomCode = generateRandomCode().toString(); // Convert to string



        try {
          const response = await fetch('https://cheekydino.com/api/forgot', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Email: verifyEmail, RandomCode: randomCode }),
          });
      
          if (!response.ok) {
            const errorData = await response.json();
            console.error('Failed to process request:', errorData.error);
            alert(errorData.error || 'Failed to process request');
            return;
          }
      
          const data = await response.json();
          
          // Check if the operation was successful
          if (data.success) {
            setCode(data.RandomCode);  // Store the code
            setIsCodeSent(true);       // Indicate that the code was successfully sent
            alert('Email sent successfully');
          } else {
            alert('Failed to send email');
          }
      
        } catch (error) {
          console.error('Failed to send email:', error);
          alert('Failed to send email');
        }
      }

        async function handleCodeSubmit() {

            if (randomCode === code) {
                SetPasswordField(true)

            } else {

                alert('Invalid code');

            }

        }


        const navigate = useNavigate(); // Corrected `Navigate` usage

  async function handlePasswordSubmit() {
    try {
      const response = await fetch('https://cheekydino.com/api/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Email: verifyEmail, Password: password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to process request:', errorData.error);
        alert(errorData.error || 'Failed to process request');
        return;
      }

      const data = await response.json();

      if (data.message === 'Password updated successfully') {
        alert('Password reset successfully');
        navigate('/login'); // Corrected the function call
      } else {
        alert('Failed to reset password');
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      alert('Failed to reset password');
    }
  }
            // the code is stored in the state variable 'code'
                
      



        


            return (
                <>
                  <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                      <p className="text-base font-semibold leading-7 text-indigo-600">Forgot Your Password</p>
                      <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Support center</h2>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                        Enter the email address you used to create your account, and we will send you a link to reset your password.
                      </p>
                    </div>
                  </div>
              
                  {/* Email Input - Only show if isCodeSent is false */}
                  {!isCodeSent && (
                    <>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-purple-500">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input 
                          onChange={(event) => setVerifyEmail(event.target.value)}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-purple-500 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <button 
                        onClick={handleSubmit}
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Verify Email
                      </button>
                    </>
                  )}
              
                  {/* Code Input - Only show if isCodeSent is true and passwordField is false */}
                  {isCodeSent && !passwordField && (
                    <div>
                      <label htmlFor="code" className="block text-sm font-medium leading-6 text-purple-500">
                        Code
                      </label>
                      <div className="mt-2">
                        <input 
                          onChange={(event) => setRandomCode(event.target.value)}
                          id="code"
                          name="code"
                          type="text"
                          autoComplete="code"
                          required
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-purple-500 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <button 
                        onClick={handleCodeSubmit}
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Verify Code
                      </button>
                    </div>
                  )}
              
              <div>
      {passwordField && (
        <div>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-purple-500">
            Password
          </label>
          <div className="mt-2 relative">
            <input
              onChange={(event) => setPassword(event.target.value)}
              id="password"
              name="password"
              type={isPasswordVisible ? 'text' : 'password'} // Toggle between "text" and "password"
              autoComplete="current-password"
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-purple-500 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
            <button
              type="button"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)} // Toggle visibility
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {isPasswordVisible ? '🙈' : '👁️'} {/* Eye icon changes */}
            </button>
          </div>
          <button
            onClick={handlePasswordSubmit}
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Reset Password
          </button>
        </div>
      )}
    </div>
                </>
              );
              
}   