import { useState, useEffect } from "react";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import RegisterNow from './registernow';
import TillPin from './tillpin';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LogIns() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [people, setPeople] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [reset, setReset] = useState(false);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [addUser, setAddUser] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [staffPin, setStaffPin] = useState(false);
    

  useEffect(() => {
    if (!isAuthenticated) {
      const fetchClockIns = async () => {
        try {
          const response = await fetch('https://cheekydino.com/api/login', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const logins = data.data;

          setPeople(logins);
        } catch (error) {
          console.error('Failed to fetch clock ins:', error);
        }
      };

      fetchClockIns(); // Initial fetch

      const intervalId = setInterval(() => {
        fetchClockIns();
      }, 1000); // Poll every second

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      }
    }
  }, [isAuthenticated]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const correctPassword = '8568'; // Change this to your desired password

    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      setError('Incorrect password');
    }
  };

  const groups = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Staff' },
    { id: 3, name: 'Customer' },
  ];

  const handleEditEmail = async (email) => {
    try {
      const response = await fetch(`https://cheekydino.com/api/login/${selectedPerson.login_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Email: email })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPeople((prevPeople) => 
        prevPeople.map((person) => 
          person.login_id === selectedPerson.login_id ? { ...person, Email: email } : person
        )
      );
      setShowEditModal(false);
    } catch (error) {
      console.error('Failed to update email:', error);
    }
  };

  const handleChangeGroup = async (group) => {
    if (!selectedPerson) {
      console.error('No person selected');
      return;
    }
    
    try {
      const response = await fetch(`https://cheekydino.com/api/logingroup`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login_id: selectedPerson.login_id, Usergroup: group.name })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setPeople((prevPeople) =>
        prevPeople.map((person) =>
          person.login_id === selectedPerson.login_id ? { ...person, Usergroup: group.name } : person
        )
      );
    } catch (error) {
      console.error('Failed to update user group:', error);
    }
  };

  const handleResetPassword = async () => {
    if (password1 !== password2) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`https://cheekydino.com/api/loginpassword/${selectedPerson.login_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Password: password1 })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPeople((prevPeople) =>
        prevPeople.map((person) =>
          person.login_id === selectedPerson.login_id ? { ...person, Password: password1 } : person
        )
      );
      setReset(false);
    } catch (error) {
      console.error('Failed to update password:', error);
    }
  };

  if (staffPin === true) {
    return (
      <TillPin />
    );
    
  }



  

  if (!isAuthenticated) {
    return (
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="/versa.gif"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Management Access Only
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleLoginSubmit} method="POST" className="space-y-6">
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          {error && <p className="mt-4 text-red-600 text-sm text-center">{error}</p>}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
      {errorMessage && <p className="mt-4 text-red-600 text-sm text-center">{errorMessage}</p>}

        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name, title, email, and role.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button onClick={() => 
              setAddUser(true)
            
            }
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button onClick={() => 
              setStaffPin(true)
            
            }
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Staff Pin
            </button>
          </div>
        </div>
        {addUser && (
          <RegisterNow />
        )}
        {reset && selectedPerson && (
          <div className="mx-auto max-w-lg">
            <div>
              <div className="text-center">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-gray-400"
                >
                  <path
                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.712-3.714M32 22a8 8 0 00-16 0v8h16v-8z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">Reset password</h3>
                <div className="mt-6 flex flex-col sm:flex-row sm:justify-center">
                  <div className="w-full sm:max-w-xs sm:flex sm:justify-center">
                    <input
                      type="password"
                      name="password1"
                      id="password1"
                      placeholder="New password"
                      onChange={(e) => setPassword1(e.target.value)}
                      className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="w-full sm:max-w-xs sm:ml-3 mt-3 sm:mt-0 sm:flex sm:justify-center">
                    <input
                      type="password"
                      name="password2"
                      id="password2"
                      placeholder="Confirm password"
                      onChange={(e) => setPassword2(e.target.value)}
                      className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="mt-6 flex flex-col sm:flex-row sm:justify-center">
                  <button
                    type="button"
                    onClick={handleResetPassword}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto"
                  >
                    Reset password
                  </button>
                  <button
                    type="button"
                    onClick={() => setReset(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}







        {showEditModal && selectedPerson && (
          <div className="mx-auto max-w-lg">
            <div>
              <div className="text-center">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-gray-400"
                >
                  <path
                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Edit User</h2>
                <p className="mt-1 text-sm text-gray-500">
                  Update the user's email address or change their user group.
                </p>
              </div>
              <form className="mt-6">
                <label htmlFor="email" className="sr-only">Email address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter an email"
                  value={selectedPerson.Email}
                  onChange={(e) => setSelectedPerson({ ...selectedPerson, Email: e.target.value })}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  type="button"
                  onClick={() => handleEditEmail(selectedPerson.Email)}
                  className="mt-2 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Edit Email Address
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="mt-8 flow-root">
          {error && <p className="text-red-600 text-sm text-center">{error}</p>}
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Login ID
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Usergroup
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Logged In 
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Log In Time 
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {people.map((person) => (
                    <tr key={person.login_id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.login_id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  <Listbox
    value={selectedGroup}
    onChange={(group) => {
      setSelectedGroup(group);
      handleChangeGroup(group);
    }}
  >
    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
      <span className="block truncate">{selectedGroup ? selectedGroup.name : person.Usergroup}</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
      </span>
    </Listbox.Button>
    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
      {groups.map((group) => (
        <Listbox.Option
          key={group.id}
          value={group}
          className={({ active }) =>
            classNames(
              active ? 'text-white bg-indigo-600' : 'text-gray-900',
              'cursor-default select-none relative py-2 pl-3 pr-9'
            )
          }
        >
          {({ selected, active }) => (
            <>
              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                {group.name}
              </span>
              {selected ? (
                <span
                  className={classNames(
                    active ? 'text-white' : 'text-indigo-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4'
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              ) : null}
            </>
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  </Listbox>
</td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.LoggedIn}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.LastLogins}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex justify-between">
                        <button onClick={() => {
                            setSelectedPerson(person);
                            setReset(true);

                            }}

                            
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Reset Password
                        </button>
                        <button
  onClick={() => {
    setSelectedPerson(person);
    setSelectedGroup(groups.find(group => group.name === person.Usergroup));
    setShowEditModal(true);
  }}
  type="button"
  className="block ml-2 rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
>
  Edit
</button>

                        <button
                          onClick={(e) => {
                            const deletePerson = async () => {
                              e.preventDefault();
                              try {
                                const response = await fetch(`https://cheekydino.com/api/login/${person.login_id}`, {
                                  method: 'DELETE',
                                  headers: { 'Content-Type': 'application/json' }
                                });

                                if (!response.ok) {
                                  throw new Error(`HTTP error! status: ${response.status}`);
                                }

                                const data = await response.json();

                                const newPeople = people.filter((p) => p.login_id !== person.login_id);
                                setPeople(newPeople);
                              } catch (error) {
                                console.error('Failed to delete person:', error);
                                setErrorMessage('Failed to delete person as the user has sessions or party bookings associated with this account');
                              }
                            };

                            deletePerson();
                          }}
                          type="button"
                          className="block ml-2 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
