import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

// Define the sound URL
const SOUND_URL = 'sound.mp3';

export default function Example() {
  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState([]);
  const [sound] = useState(new Audio(SOUND_URL));

  useEffect(() => {
    const fetchBuzzer = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/buzzer', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to fetch buzzer data');
        const data = await response.json();

        // Filter messages where Active is 1
        const activeMessages = data.data.filter((msg) => msg.Active === 1);

        console.log('Active messages:', activeMessages);

        // Update state and play sound if there are new messages
        if (activeMessages.length > 0) {
          setMessage(activeMessages);
          sound.play();
        } else {
          setMessage([]);
        }
      } catch (error) {
        console.error('Error fetching buzzer data:', error);
      }
    };

    fetchBuzzer();
    const interval = setInterval(fetchBuzzer, 1000);
    return () => clearInterval(interval);
  }, [sound]);

  const handleAcknowledge = async () => {
    try {
      // Assuming the first message is the one to be acknowledged
      const messageToAcknowledge = message[0];
      await fetch(`https://cheekydino.com/api/buzzer/${messageToAcknowledge.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the acknowledged message from state
      setMessage(message.slice(1));
      setOpen(false); // Close the dialog
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error('Error acknowledging the message:', error);
    }
  };

  return (
    message.length > 0 && (
      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
        <Dialog.Backdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                       {message[0].Staff}
                    </Dialog.Title>

                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            {message[0].Content}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={handleAcknowledge}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Acknowledge
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    )
  );
}
