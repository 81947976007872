import {Routes, Route, Router} from 'react-router-dom';
import Navbar from './Navbar';
import Trex from '../src/customer-pages/party/Trex';
import Character from '../src/customer-pages/party/Character';
import Packages from './customer-pages/party/partyPackages';
import Login from './customer-pages/Login/Login';
import Register from './customer-pages/Login/Register';

import React from 'react';
import StaffNav from './staffpages/staffNav';
import PartyGuests from './customer-pages/customerlogin/PartyGuests';
import TRexCalendar from './customer-pages/party/T-rexCalender';
import ThemedCalendar from './customer-pages/party/themedCalender';
import MyBooking from './customer-pages/customerlogin/mybookings';
import CustomerNav from './customer-pages/customerlogin/customernav';
import Football from './customer-pages/party/Football';
import Kitchen from './staffpages/KitchenHome';
import Till from './staffpages/Till';
import DashBoard from './staffpages/DashBoard';
import SessionBook from './staffpages/customersession';

import SessionLogin from './staffpages/sessiobooklogin'
import SessionBooking from './staffpages/sessiondetails';

import Home from './customer-pages/home';
import CustomerScreen from './staffpages/customerscreen';
import EditHome from './staffpages/edithomenav';
import DiscoParty from './customer-pages/party/discoparty';
import LaserParty from './customer-pages/party/Laserparty';
import LaserPartyCalender from './customer-pages/party/laserpartycalender';
import TeddyParty from './customer-pages/party/Teddyparty';
import PrivateHire from './customer-pages/party/privatehire';
import Settings from './staffpages/settings';
import BookNow from './staffpages/tillbooking';
import BookLater from './staffpages/sessionbooker';
import MakeReservation from './staffpages/makereservation';
import MasterClose from './staffpages/masterclose';
import PreBookTill from './staffpages/PrebookTill';
import MoveTables from './staffpages/movetables';
import MoveTables2 from './staffpages/movetables2';
import PreBookTill2 from './staffpages/PrebookTill2';
import ControlPanel from './staffpages/ControlPanel';
import ShoppingList from './staffpages/ShoppingList';
import StockPdf from './staffpages/stockpdf';
import CustMessages from './customer-pages/contactus';
import EnquiryContact from './customer-pages/enquirycontact';
import MarketingSuite from './staffpages/marketingsuite';
import SecondDisplay from './staffpages/seconddisplay';
import PartyBooking from './staffpages/PartyStaffCalendar';
import Tabs from './staffpages/tabs'
import EditSessionBooker from './staffpages/editsessionbooker';
import TillProducts from './staffpages/tillproducts';
import BuildAMeal from './staffpages/buildameal';
import StockControl from './staffpages/stockcontrol';
import PartyStock from './staffpages/partystock';
import ErrorLog from './staffpages/errorlog';
import Forgot  from './customer-pages/Login/forgot';
import RegisterNow from './customer-pages/Login/registercust';
import PartyLogin from './customer-pages/Login/partylogin';


import { useLocation } from 'react-router-dom';

import { AuthContext } from './AuthContext'; // Import the AuthContext
import { useContext } from 'react';
import CustomerDashboard from './customer-pages/customerlogin/customerdashboard';
import Authentication from './customer-pages/Login/authentication';


export default function App() {
  const user = useContext(AuthContext); // Access the user state from AuthContext
  const location = useLocation(); // Get the current route location

  // Define routes where no navigation bar should appear for Staff/Admin users
  const noNavRoutes = [
    '/kitchen',
    '/till',
    '/dashboard',
    '/edithome',
    '/settings',
    '/booknow',
    '/reservations',
    '/movetable',
    '/tabdrawer',
    '/editsession',
    '/masterclose',
    '/tillproducts',
    '/stockcontrol',
    '/partystock',
    '/controlpanel',
    '/prebooktill',
    '/prebooktill2',
    '/movetables2',
    '/partybooking',
    '/secondscreen',
    '/buildameal',
    '/errorlog',
    '/stockpdf',
    '/shoppinglist',
    '/movetables2',

    // Add any additional staff-specific or admin-specific routes here
  ];

  // Check if the current route is included in the noNavRoutes array
  const hideNavbar = noNavRoutes.includes(location.pathname);


  

  return (
    <>
      {/* Render Navbar or CustomerNav based on user group and hideNavbar condition */}
      {!hideNavbar && (
        user?.usergroup === 'customer' ? <CustomerNav /> : <Navbar />
      )}

      <Routes>
        {/* Customer Routes */}
        {user?.usergroup === 'customer' && (
          <>
            <Route path="/my-booking" element={<MyBooking />} />
            <Route path="/customercontact" element={<CustMessages />} />
            <Route path="/add-guests" element={<PartyGuests />} />
            <Route path="/sessionbookings" element={<SessionBooking />} />
            <Route path="/enquirycontact" element={<EnquiryContact />} />
            <Route path="/customer-dashboard" element={<CustomerDashboard />} />
            <Route path="/session" element={<SessionBook />} />

          </>
        )}

        {/* Staff and Admin Routes */}
        {user && (user.usergroup === 'Staff' || user.usergroup === 'Admin') && (
          <>
            <Route path="/kitchen" element={<Kitchen />} />
            <Route path="/till" element={<Till />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/edithome" element={<EditHome />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/booknow" element={<BookNow />} />
            <Route path="/booklater" element={<BookLater />} />
            <Route path="/reservations" element={<MakeReservation />} />
            <Route path="/movetable" element={<MoveTables />} />
            <Route path="/tabdrawer" element={<Tabs />} />
            <Route path="/editsession" element={<EditSessionBooker />} />
            <Route path="/masterclose" element={<MasterClose />} />
            <Route path="/tillproducts" element={<TillProducts />} />
            <Route path="/stockcontrol" element={<StockControl />} />
            <Route path="/partystock" element={<PartyStock />} />
            <Route path="/controlpanel" element={<ControlPanel />} />
            <Route path="/customerscreen" element={<CustomerScreen />} />
            <Route path="/prebooktill" element={<PreBookTill />} />
            <Route path="/prebooktill2" element={<PreBookTill2 />} />
            <Route path="/stockpdf" element={<StockPdf />} />
            <Route path="/shoppinglist" element={<ShoppingList />} />
            <Route path="/movetables2" element={<MoveTables2 />} />
            <Route path="/partybooking" element={<PartyBooking />} />
            <Route path="/secondscreen" element={<SecondDisplay />} />
            <Route path="/buildameal" element={<BuildAMeal />} />
            <Route path="/errorlog" element={<ErrorLog />} />
          </>
        )}

        {/* Common Routes (accessible to all users) */}
        <Route path="/trexparty" element={<Trex />} />
        <Route path="/disco" element={<DiscoParty />} />
        <Route path="/laser" element={<LaserParty />} />
        <Route path="/laserparty" element={<LaserPartyCalender />} />
        <Route path="/teddy" element={<TeddyParty />} />
        <Route path="/privatehire" element={<PrivateHire />} />
        <Route path="/football" element={<Football />} />
        <Route path="/character" element={<Character />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/sessionlogin" element={<SessionLogin />} />
        <Route path="/registernow" element={<RegisterNow />} />
        <Route path="/partylogin" element={<PartyLogin />} />
        
        <Route path="/register" element={<Register />} />
        <Route path='/auth' element={<Authentication />} />
        <Route path="/trexparty/calendar" element={<TRexCalendar />} />
        <Route path="/themed" element={<ThemedCalendar />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </>
  );
}




