import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forget, setForget] = useState(false);

  const navigate = useNavigate();

  // Get current DateTime in sql format (YYYY-MM-DD HH:MM:SS)

  const LastLogins = new Date().toISOString().slice(0, 19).replace('T', ' ');
  const LoggedIn = 1;


  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      // Send login request to the backend
      const response = await axios.post('https://cheekydino.com/api/login', { email, password });

      console.log(response.data);

      if (response.data.success) {
        // Save JWT to LocalStorage
        const token = response.data.token;
        localStorage.setItem('token', token);

        // Decode JWT to access the usergroup directly
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const usergroup = tokenPayload.usergroup;

        // Navigate based on user group
        if (usergroup === 'customer') {
          navigate('/session');
        } else if (usergroup === 'Admin' || usergroup === 'Staff') {
          navigate('/dashboard');
          window.location.reload();
        }
      } else {
        console.log('Login failed');
      }
    } catch (error) {
      console.error('Failed to login', error);
    }
  };
  
  



 return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-900">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src="dino-logo.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-purple-500">
                Email address
              </label>
              <div className="mt-2">
                <input onChange={(event) => setEmail(event.target.value)}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-purple-500 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-purple-500">
                  Password
                </label>
                <div className="text-sm">
                  <button onClick={()=> setForget(true)}  className="font-semibold text-indigo-400 hover:text-indigo-300">
                    Forgot password?
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <input onChange={(event) => setPassword(event.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-purple-500 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
          <div className="mt-6">
            <button onClick={() => {
              localStorage.removeItem('token');
              navigate('/');
            }
            }
              type="button"
              className="flex w-full justify-center rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            >
              Log Out 
            </button>
          </div>

          <p className="mt-10 text-center text-sm text-gray-400">
            Not a member?{' '}
            <a href="#" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
              Start a 14 day free trial
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
