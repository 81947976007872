import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';

const actions = [
  { title: 'Front Desk', icon: ClockIcon },
  { title: 'Cafe Till', icon: CheckBadgeIcon },
  { title: 'Clean Tables', icon: UsersIcon },
  { title: 'Party Host', icon: BanknotesIcon },
  { title: 'Clean and Hoover Upstairs', icon: ReceiptRefundIcon },
  { title: 'Training', icon: AcademicCapIcon },
];

export default function StaffTask() {
  const [clockIns, setClockIns] = useState([]);
  const [selectedAction, setSelectedAction] = useState('');
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchClockIns = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/clockins');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setClockIns(data.data);
      } catch (error) {
        console.error('Failed to fetch clock ins:', error);
      }
    };

    fetchClockIns();
    const intervalId = setInterval(fetchClockIns, 10000);
    return () => clearInterval(intervalId);
  }, [reload]);

  const handleStaffAssignment = async (clockInId) => {
    if (clockInId && selectedAction) {


// match clockinId to clockins.id and get the StaffName 

const staffName = clockIns.find((clockIn) => clockIn.id === clockInId).StaffName;

      try {
        const response = await fetch(`https://cheekydino.com/api/settask/${clockInId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ StaffRole: selectedAction, StaffName: staffName }),
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        setReload(!reload);
        setSelectedAction(''); // Reset selection after assignment
      } catch (error) {
        console.error('Failed to assign task:', error);
      }
    }
  };

  return (
     <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"> Staff Name</th>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Current Role</th> {/* New Column for StaffRole */}
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Task</th>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Action</th>
          </tr>
        </thead>
        <tbody>
          {clockIns.map((clockIn) => (
            <tr key={clockIn.id} className="hover:bg-gray-100">
             <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{clockIn.StaffName}</td>
             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{clockIn.StaffRole}</td> {/* Display StaffRole */}
             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <select
                  value={selectedAction}
                  onChange={(e) => setSelectedAction(e.target.value)}
                  className="border border-gray-300 p-1 rounded"
                >
                  <option value="" disabled>Select Task</option>
                  {actions.map((action) => (
                    <option key={action.title} value={action.title}>
                      {action.title}
                    </option>
                  ))}
                </select>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <button
                  onClick={() => handleStaffAssignment(clockIn.id)}
                  className="bg-blue-600 text-white py-1 px-2 rounded"
                >
                  Assign
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
      </div>
  );
}
