import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

export default function Example({ reply }) {
  const [open, setOpen] = useState(true);
  const [replies, setReplies] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserEmail = async () => {
      const email = "frontdesk@cheekydino.co.uk";
      setUserEmail(email);
    };
    fetchUserEmail();
  }, [reload]);

  useEffect(() => {
    setReplies(reply);
  }, [reply]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/customermessages', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }

        const data = await response.json();
        const filteredMessages = data.data.filter(
          (message) => (message.From === userEmail && message.To === replies.From) ||
                       (message.From === replies.From && message.To === userEmail)
        );

        if (filteredMessages.length === 0) {
          setMessages(replies);
        } else {
          const sortedMessages = filteredMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          setMessages(sortedMessages);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    const interval = setInterval(() => {
      fetchMessages();
    }, 30000);

    return () => clearInterval(interval);
  }, [replies, userEmail]);

  const handleSendReply = async () => {
    console.log('Sending reply:', newMessage);

    try {
      const sendReply = await fetch(`https://cheekydino.com/api/replied/${replies.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Replied: 1 })
      });

      if (!sendReply.ok) {
        throw new Error('Failed to send reply');
      }

      const data = await sendReply.json();
      console.log(data);

      const sendMessage = await fetch('https://cheekydino.com/api/sendcustomerenquiry', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          To: replies.From,
          Content: newMessage,
          From: 'frontdesk@cheekydino.co.uk',
          Replied: 1,
          createdAt: new Date().toISOString(),
          Marketing: 0,
          ToMarketing: 0,
          login_id: replies.login_id
        })
      });

      if (!sendMessage.ok) {
        throw new Error('Failed to send message');
      }

      const messageData = await sendMessage.json();
      console.log(messageData);

    } catch (error) {
      console.error('Error sending reply:', error);
    }

    setNewMessage('');
    setReload(!reload);
  };

  const handleCloseDialog = () => {
    window.location.reload();
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 overflow-y-auto p-4 space-y-4 rounded-lg bg-gray-900">
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.To === userEmail ? 'justify-end' : 'justify-start'}`}>
            <div className={`max-w-xs p-4 rounded-lg shadow ${message.From === userEmail ? 'bg-blue-500 text-white' : 'bg-green-500 text-white'}`}>
              <p>{message.Content}</p>
              <div className="text-xs text-white-500 mt-2">
                <span className="font-semibold">{message.From}</span> {format(new Date(message.createdAt), 'HH:mm dd/MM/yyyy')}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="p-4 bg-white border-t border-gray-200 flex items-center">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          className="flex-1 p-2 border border-gray-300 rounded-lg mr-4"
        />
        <button
          onClick={handleSendReply}
          className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
        >
          Send
        </button>
      </div>
    </div>
  );
}
