import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Authentication() {
  const location = useLocation();
  const [authCode, setAuthCode] = useState('');
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://cheekydino.com/api/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          RandomCode: authCode,
          Email: email,
        }),
      });

      const data = await response.json();

      if (data.success) {
        // Save JWT to LocalStorage if included in the response
        if (data.token) {
          localStorage.setItem('token', data.token);
        }
        navigate('/');
        window.location.reload();
      } else {
        alert('Invalid code');
      }
    } catch (error) {
      console.error('Failed to verify code', error);
      alert('Failed to verify code');
    }
  };

  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Enter Your Code</h2>
        <div className="mt-2">
          <input
            onChange={(event) => setAuthCode(event.target.value)}
            id="authcode"
            name="authcode"
            type="text"
            value={authCode}
            placeholder="Your Code"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="inline-block w-full px-5 py-3 rounded-lg bg-indigo-600 text-white shadow-lg hover:bg-indigo-700 focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-50"
            onClick={handleSubmit}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
}
