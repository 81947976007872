import { useState, useEffect, useContext } from "react";
import StaffNav from "./staffNav";
import { AuthContext } from "../AuthContext";
import { BookOpenIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";

export default function ChatDashboard() {
    const [messages, setMessages] = useState([]);
    const [userEmail, setUserEmail] = useState("");
    const [newMessage, setNewMessage] = useState("");
    const [online, setOnline] = useState([]);

    const user = useContext(AuthContext);

    useEffect(() => {
        const fetchLogins = async () => {
            try {
                const response = await fetch('https://cheekydino.com/api/currentlogins', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                const data = await response.json();
                let loginData = data.data;

                // Create a categorized object for online users
                const onlineUsers = loginData.reduce((acc, curr) => {
                    const letter = curr.Email.charAt(0).toUpperCase();
                    if (!acc[letter]) {
                        acc[letter] = [];
                    }
                    acc[letter].push({
                        email: curr.Email,
                        name: curr.Email,
                        imageUrl: '/versa.gif' // Placeholder image URL
                    });
                    return acc;
                }, {});

                console.log(onlineUsers);

                setOnline(onlineUsers);
            } catch (error) {
                console.error('Failed to fetch logins:', error);
            }
        };

        fetchLogins(); // Initial fetch

    }, []);

        

    useEffect(() => {
        setUserEmail(user.email);
    }, [user]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await fetch('https://cheekydino.com/api/messages', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                const data = await response.json();
                setMessages(data.data);
            } catch (error) {
                console.error('Failed to fetch messages:', error);
            }
        };

        fetchMessages(); // Initial fetch

        const intervalId = setInterval(() => {
            fetchMessages();
        }, 10000); // Poll every second

        return () => {
            clearInterval(intervalId); // Cleanup interval on component unmount
        };
    }, []);
  const handleSendMessage = async () => {
    if (newMessage.trim() === "") {
        return; // Exit if the message is empty
    }

    const message = {
        content: newMessage,
        email: userEmail,
        createdAt: new Date().toISOString(),
        replied: 0
    };

    try {
        // Send the message
        const responseSend = await fetch('https://cheekydino.com/api/addmessages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        });

        if (!responseSend.ok) {
            throw new Error(`Failed to send message: ${responseSend.statusText}`);
        }

        // Clear the input field
        setNewMessage("");

        // Update the reply status
        const responseUpdate = await fetch('https://cheekydino.com/api/reply', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ replied: 1 })
        });

        if (!responseUpdate.ok) {
            throw new Error(`Failed to update reply status: ${responseUpdate.statusText}`);
        }

    } catch (error) {
        console.error('Error during message handling:', error);
    }
};


    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleReadAll = async () => {
        try {
            const response = await fetch('https://cheekydino.com/api/replyall', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ replied: 1 })
            });

            if (!response.ok) {
                throw new Error(`Failed to update reply status: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error during read all:', error);
        }
    };


    const messagesEndRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom only if there are more than 5 messages when the component mounts
        if (messages.length > 5 && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []); // Empty dependency array ensures this runs only once on mount


    return (
        <>
          
            <div className="flex h-screen bg-gray-900">
                {/* Navigation (Left Side) */}
                <nav aria-label="Online Users" className={`w-64 bg-white text-white h-full ${isNavOpen ? 'block' : 'hidden'} lg:block`}>
                    <div className="h-full overflow-y-auto">
                        {Object.keys(online).sort().map((letter) => (
                            <div key={letter} className="relative">
                                <div className="sticky top-0 z-10 border-y border-b-blue-700 border-t-blue-700 bg-blue-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-300">
                                    <h3 className= "text-blue-700">{letter}</h3>
                                </div>
                                <ul role="list" className="divide-y divide-gray-700">
                                    {online[letter].map((person) => (
                                        <li key={person.email} className="flex gap-x-4 px-3 py-5">
                                            <img alt="" src={person.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-600" />
                                            <div className="min-w-0">
                                                <p className="text-sm font-semibold leading-6 text-blue-700">{person.name}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            
                        ))}
                        
                    </div>
                </nav>

                

                {/* Main Content (Chat Dashboard) */}
                <div className="flex-1 flex flex-col">
            <div className="flex-1 overflow-y-auto p-4 space-y-4 rounded-lg bg-white">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`flex ${message.email === userEmail ? 'justify-end' : 'justify-start'}`}
                    >
                        <div
                            className={`max-w-xs p-4 rounded-lg shadow ${message.email === userEmail ? 'bg-blue-700 text-white' : 'bg-green-500 text-white'}`}
                        >
                            <p>{message.content}</p>
                            <div className="text-xs text-white-500 mt-2">
                                <span className="font-semibold">{message.email}</span> • {message.createdAt}
                            </div>
                        </div>
                    </div>
                ))}
                {/* This empty div will be scrolled into view, ensuring the latest message is visible */}
                <div ref={messagesEndRef} />
            </div>
                {/* This empty div will be scrolled into view, ensuring the latest message is visible */}
                <div className="p-4 bg-white border-t border-gray-200 flex items-center">
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type your message..."
                            className="block w-1/2 mr-5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        <button onClick={handleSendMessage}
        type="button"
        className="rounded-full bg-green-600 mr-2 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <EnvelopeIcon aria-hidden="true" className="h-5 w-5" />
      </button>
      <button onClick={handleReadAll}
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <BookOpenIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        Read All
      </button>

                    </div>
            </div>
                  

                {/* Mobile Navigation (Collapsible) */}
                <div className={`lg:hidden fixed inset-0 bg-gray-800 bg-opacity-75 z-50 ${isNavOpen ? 'block' : 'hidden'}`}>
                    <div className="w-64 h-full bg-gray-800 text-white p-4">
                        <button onClick={toggleNav} className="text-white">Close</button>
                        <div className="h-full overflow-y-auto mt-4">
                            {Object.keys(online).sort().map((letter) => (
                                <div key={letter} className="relative">
                                    <div className="sticky top-0 z-10 border-y border-b-gray-700 border-t-gray-600 bg-gray-700 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-300">
                                        <h3>{letter}</h3>
                                    </div>
                                    <ul role="list" className="divide-y divide-gray-700">
                                        {online[letter].map((person) => (
                                            <li key={person.email} className="flex gap-x-4 px-3 py-5">
                                                <img alt="" src={person.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-600" />
                                                <div className="min-w-0">
                                                    <p className="text-sm font-semibold leading-6 text-gray-300">{person.name}</p>
                                                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}
