import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { startOfDay, isBefore, isToday, parse, isWithinInterval, format, isAfter, addMinutes } from 'date-fns';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';

function MyComponent() {
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();

  const user = useContext(AuthContext);

  const login_id = user.login_id.toString();

  const email = user.email;

  console.log(email);



  useEffect(() => {
    fetchSessions();
  }, []);

  async function fetchSessions() {
   try {
    const token = localStorage.getItem('token');
    
    const response = await fetch(`https://cheekydino.com/api/uniquesessionbooking/${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }

    });

    const data = await response.json();

    if (!response.ok) {

        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    if (data.success) {
      const uniqueBookings = data.data.filter((booking, index, self) =>
        index === self.findIndex((t) => (
          t.id === booking.id && t.id === booking.id
        ))
      );


    setSessions(uniqueBookings)}

    }

    catch (error) {
        console.error('Failed to fetch session bookings', error);
    }
    }




    async function deleteSession(id) {
      try {
        const response = await fetch(`https://cheekydino.com/api/deletesession/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          console.error(`Failed to delete session: ${errorData.error}`);
          return;
        }
    
        const data = await response.json();
        if (data.success || data.message) {
          fetchSessions();  // Refresh the session list or update the UI
        } else {
          console.error('Failed to delete session: Unexpected response format');
        }
      } catch (error) {
        console.error('Failed to delete session:', error);
        alert(`Failed to delete session: ${error.message}`);
      }
    }


  function getSessionStatus(session) {
    const currentDate = startOfDay(new Date());
    const currentTime = parse(format(new Date(), 'HH:mm:ss'), 'HH:mm:ss', new Date());
    const sessionDate = startOfDay(new Date(session.Date));
    const timeslotFrom = parse(session.TimeslotFrom, 'HH:mm:ss', new Date());
    const timeslotTo = parse(session.TimeslotTo, 'HH:mm:ss', new Date());
  
    // Check if the session date is before the current date and the user did not arrive or leave
    if (isBefore(sessionDate, currentDate) && session.Arrived === 0 && session.LeftCenter === 0) {
      return 'missed';
    } else if (isToday(sessionDate)) {
      // If today, check the timeslot
      if (isWithinInterval(currentTime, { start: timeslotFrom, end: timeslotTo })) {
        if (session.Arrived === 0 && isAfter(currentTime, timeslotFrom)) {
          return 'late';
        }
        return 'onTime';
      }
      return 'today';
    }
    return 'notToday';
  }

  // convert to string

  return (
    <div className="flex flex-col items-center mt-5 bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-orange-300 via-orange-400 to-orange-100">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mb-4">
        My Bookings
      </h2>
      <div className='flex justify-between'> 
      <button
        onClick={() => {
          navigate("/session");
        }}
        className="px-4 py-2 bg-white text-black mr-5 rounded-md hover:bg-blue-600 mb-5 animate-pulse"
      >
        Book A New Session
      </button>
      <button onClick={() => navigate('/customercontact')} className="px-4 mb-5 py-2 bg-white text-black rounded-md hover:bg-blue-600 mb-5 animate-pulse"
>
            Contact Cheeky Dino
          </button>
</div>
{[...sessions].reverse().map((session, index) => {
        const status = getSessionStatus(session);
        const color = status === 'onTime' ? 'bg-green-500' : status === 'late' ? 'bg-red-500' : status === 'missed' ? 'bg-gray-500' : 'bg-blue-500';
        const timeslotFrom = format(parse(session.TimeslotFrom, 'HH:mm:ss', new Date()), 'HH:mm');
        const timeslotTo = format(parse(session.TimeslotTo, 'HH:mm:ss', new Date()), 'HH:mm');
        const arrived = session.Arrived === 0 ? 'No' : 'Yes';

        return (
          <div
            key={index}
            className={`border p-4 rounded w-full max-w-md mb-4 shadow-lg border-purple-500 ${color}`}
          >
            <div className="flex justify-between items-center mb-2">
              <span className="text-lg font-semibold leading-6 text-purple-500">
                Booking #{sessions.length - index}
              </span>
              {arrived === 'No' && (
              <button
  className='px-4 py-2 bg-red-500 text-white rounded-md hover:bg-blue-600'
  onClick={() => deleteSession(session.id)}
>
  Cancel Booking
</button>
)}

            </div>
            <div
              className={`p-2 rounded flex justify-center items-center ${
                index === 5 ? "bg-purple-500" : ""
              }`}
            >
              <div className="border-thick border-purple-500 p-2">
                <QRCode value={login_id} size={Math.min(200, window.innerWidth - 100)} />
              </div>
            </div>
            <div className="flex flex-col gap-y-1 mt-2 text-center text-white">
              <div>Name: {session.Name}</div>
              <div>Adults: {session.Adults}</div>
              <div>Children: {session.Children}</div>
              <div>Email: {session.Email}</div>
              <div>Table: {session.Table}</div>
              <div>Total: £{session.EntranceFee}</div>
              <div>
                Timeslot: {timeslotFrom} - {timeslotTo}
              </div>
              <div>Date: {session.Date}</div>
            </div>
            {status === 'today' && isAfter(new Date(), addMinutes(parse(session.TimeslotFrom, 'HH:mm', new Date()), 30)) && (
              <div className="flex justify-center mt-2">
                <p className="text-white text-sm">Dear {session.Name},

We’ve noticed that you have not arrived for your scheduled session on time. To ensure smooth operations and respect the time of all our clients, we kindly ask you to take immediate action.

Please do the following:

Contact the Center: Inform us whether you still intend to attend your session. Your prompt communication is crucial.
Cancel if Necessary: If you are unable to attend, please cancel your booking as soon as possible to allow others the opportunity to book.
Please Note: Due to the busy nature of our center, arriving late may affect the duration of your session. Depending on the schedule and availability, we may not be able to extend your session to the full two hours. You may be required to adhere to your original allotted end time.</p>


              <button onClick={() => navigate('/customercontact')} className="px-4 mb-5 py-2 bg-white text-black rounded-md hover:bg-blue-600 mb-5 animate-pulse"
>
            Contact Cheeky Dino
          </button>
        
              </div>
            )}
            {status === 'missed' && (
              <div className="flex justify-center mt-2">
                <p className="text-white text-sm">Missed Session Notice

Dear {session.Name},

We’ve noticed that you did not attend your scheduled session and we did not receive prior notification of cancellation or a request to reschedule. We understand that unforeseen circumstances can arise, but in order to manage our bookings effectively, we maintain a policy for such situations.

Policy on Missed Sessions: If a session is missed without contacting us or cancelling the booking, we reserve the right to restrict further online bookings. This measure ensures fair access to our services for all clients.

Next Steps: Please reach out to our center at your earliest convenience to discuss your missed session. We are here to assist you in rebooking and to address any concerns you may have.

Thank you for your understanding.
</p>
              </div>
            )
              
            
            }
          </div>
        );
      })}
    </div>
  );
}

export default MyComponent;
