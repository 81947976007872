import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import ConsumerAnalytics from './consumeranayltics';
import { useNavigate } from 'react-router-dom';
// import Marketing from './marketing';
// import SessionBlowout from './sessionblowout';

export default function SessionHistory() {
    const [sessions, setSessions] = useState([]);
    const [allSessions, setAllSessions] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [session, setSession] = useState([]);
    const [back, setBack] = useState(false);
    const [edit, setEdit] = useState(null);
    const [error, setError] = useState(false);


    const navigate = useNavigate();

    // Fetch sessions from the API
    useEffect(() => {
        async function fetchSessions() {
            try {
                const response = await fetch(`https://cheekydino.com/api/sessionshistory?startDate=${startDate}&endDate=${endDate}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch sessions');
                }

                const data = await response.json();
                console.log(data.data);
                setAllSessions(data.data);
                setSessions(data.data);
            } catch (error) {
                console.error('Error fetching sessions:', error);
            }
        }

        // Fetch sessions if startDate or endDate is set
        if (startDate && endDate) {
            fetchSessions();
        }
    }, [startDate, endDate]);

    const handleFilter = () => {
        const filteredSessions = allSessions.filter(session => {
            const sessionDate = new Date(session.Date);

            return (
                (!startDate || sessionDate >= new Date(startDate)) &&
                (!endDate || sessionDate <= new Date(endDate))
            );
        });

        // Sort the sessions by date
        filteredSessions.sort((a, b) => new Date(b.Date) - new Date(a.Date));

        setSessions(filteredSessions);
    };

    const handleEditClick = (session) => {

        console.log(session);

        if (session.Arrived === 0 && session.LeftCenter === 0)
        {
        setEdit(session);
        }
        else {
            setError(true);
        }

    };

    // if (unattend) {
    //     return <SessionBlowout />;
    // }

    const handleSearch = () => {
        const search = searchQuery.toLowerCase();

        const filteredSessions = allSessions.filter(session => {
            if (session) {
                const name = session.Name?.toLowerCase() || '';
                const email = session.Email?.toLowerCase() || '';
                const telephone = session.Telephone?.toLowerCase() || '';

                return name.includes(search) || email.includes(search) || telephone.includes(search);
            }
            return false;
        });

        // Sort the sessions by date
        filteredSessions.sort((a, b) => new Date(b.Date) - new Date(a.Date));

        setSessions(filteredSessions.length ? filteredSessions : ['No results found']);
    };

    const numberOfSessions = sessions.length;

    if (back) {
        navigate('/dashboard', { replace: true });
    }

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://cheekydino.com/api/sessionshistory/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete session');
            }

            const data = await response.json();
            console.log(data.message);

            // Refresh the sessions list
            handleFilter();

        } catch (error) {
            console.error('Error deleting session:', error);
        }
    };

    if (edit) {
        navigate('/editsession', { state: { session: edit } });

        return null;

    }
    

    return (
        <>
            <div className="flex flex-col items-start sm:items-center sm:flex-row sm:justify-between">
                {/* <Marketing /> */}
                <input
                    type="text"
                    placeholder="Search by Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="mt-2 block w-60 rounded-md border-gray-300 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button className="inline-flex ml-2 items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleSearch}>Search</button>

                <label htmlFor="startDate" className="ml-2 block text-sm font-medium leading-6 text-gray-900">
                    Start Date
                </label>
                <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(format(new Date(e.target.value), 'yyyy-MM-dd'))}
                    className="mt-2 block w-48 rounded-md border-gray-300 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <label htmlFor="endDate" className="block ml-2 text-sm font-medium leading-6 text-gray-900">
                    End Date
                </label>
                <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(format(new Date(e.target.value), 'yyyy-MM-dd'))}
                    className="mt-2 block w-48 rounded-md border-gray-300 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button className="inline-flex items-center ml-2 gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleFilter}>Submit</button>
            </div>
            {error && (
                <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error:</strong>
                    <span className="block sm:inline">You cannot edit this session as the customer has already arrived or left the center.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => setError(false)} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <title>Close</title>
                            <path fillRule="evenodd" clipRule="evenodd" d="M14.95 5.05a.75.75 0 0 1 1.06 1.06L11.06 10l4.95 4.95a.75.75 0 1 1-1.06 1.06L10 11.06l-4.95 4.95a.75.75 0 0 1-1.06-1.06L8.94 10 4.05 5.05a.75.75 0 0 1 1.06-1.06L10 8.94l4.95-4.95z" />
                        </svg>
                    </span>
                </div>
            )}

            <div className="flex flex-col items-start sm:flex-row sm:justify-between gap-x-6 py-5">
                <p className='text-sm'>Number of Sessions: {numberOfSessions}</p>
            </div>
       
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
    <thead>
        <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Name</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telephone</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Timeslot</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Table</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Adults</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Children</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Child Ages</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Arrived</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Left Center</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cafe Orders</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Drink Items</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hot Items</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Entrance Fee</th>

            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cafe Total</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Grand Total</th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Staff</th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>

          
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                <span className="sr-only">Edit</span>
            </th>
        </tr>
    </thead>
    <tbody className="divide-y divide-gray-200 bg-white">
        {sessions.map((session, index) => {
            // Ensure CafeOrders is an array
            const cafeOrders = session.CafeOrders || [];
            
            // Initialize variables to hold combined items and totals
            let combinedDrinkItems = [];
            let combinedHotItems = [];
            let cafeTotal = 0;
            
            // Aggregate items and totals from each CafeOrder
            cafeOrders.forEach(order => {
                try {
                    const drinkItems = JSON.parse(order.DrinkItems || "[]");
                    const hotItems = JSON.parse(order.HotItems || "[]");
                    
                    combinedDrinkItems = combinedDrinkItems.concat(drinkItems);
                    combinedHotItems = combinedHotItems.concat(hotItems);
                    
                    cafeTotal += parseFloat(order.Total || 0);
                } catch (error) {
                    console.error('Error parsing DrinkItems or HotItems', error);
                }
            });
            
            // Remove duplicates if necessary (optional)
            combinedDrinkItems = [...new Set(combinedDrinkItems)];
            combinedHotItems = [...new Set(combinedHotItems)];
            
            // Calculate grand total
            const grandTotal = (session.TotalSpent || 0) + cafeTotal;


            let childDataArray = [];
try {
    childDataArray = JSON.parse(session.ChildData);
} catch (error) {
    console.error('Failed to parse ChildData:', error);
}

// Ensure the structure is consistent (e.g., always using "childAge" key)
const normalizedChildDataArray = childDataArray.map(child => {
    return { childAge: child.childAge || child.age };
});
            
            return (
                <tr key={index}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Telephone}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Date}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.TimeslotFrom} to {session.TimeslotTo}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Table}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Adults}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Children}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
    {normalizedChildDataArray.length > 0 ? (
        normalizedChildDataArray.map((child, index) => (
            <p key={index}>{child.childAge}</p>
        ))
    ) : (
        <p>No data</p>
    )}
</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.Arrived ? "Yes": "No"}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{session.LeftCenter ? "Yes": "No"}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {cafeOrders.length} </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {combinedDrinkItems.length > 0 ? (
                            combinedDrinkItems.map((drink, index) => (
                                <p key={index}>{drink}</p>
                            ))
                        ) : (
                            <p>No data</p>
                        )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {combinedHotItems.length > 0 ? (
                            combinedHotItems.map((hot, index) => (
                                <p key={index}>{hot}</p>
                            ))
                        ) : (
                            <p>No data</p>
                        )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-500">£{session.TotalSpent.toFixed(2)}</td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-orange-500">£{cafeTotal.toFixed(2)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">£{grandTotal.toFixed(2)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">{session.Staff}</td>

                    
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button className="text-indigo-600 mr-2 hover:text-indigo-900" onClick={() => handleEditClick(session)}>Edit</button>
                        <button className="text-red-600 hover:text-red-900" onClick={() => handleDelete(session.id)}>Delete</button>
                    </td>
                    
                </tr>
            );
        })}
    </tbody>
</table>




                </div>
            </div>
        </>
    );
}
