import { EyeIcon, PencilIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useEffect } from "react";
import StaffNav from "./staffNav";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import OpenAI from 'openai';
import config from '../openai'; // Adjust the path if necessary
import React from 'react';
import JSXParser from 'react-jsx-parser';
import { format } from "date-fns";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import ReactDOMServer from 'react-dom/server';




// Convert JSX to string









export default function EditHome() {
  const [buildModal, setBuildModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Design");
  const [selectedColor, setSelectedColor] = useState("text-gray-900");
  const [AIcolor, setAIcolor] = useState("text-gray-900");
  const [AIPrompt, setAIPrompt] = useState("");
  const [openAI, setOpenAI] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [aiImage, setAiImage] = useState("");
  const [dataSource, setDataSource] = useState(null);


  
const RenderSavedComponent = ({ savedJSX }) => {
  return <JSXParser jsx={savedJSX} />;
};


  // Initialize OpenAI instance
  useEffect(() => {
      const initializeOpenAI = async () => {
          const openaiInstance = new OpenAI({
              dangerouslyAllowBrowser: true,
              apiKey: config.OPENAI_API_KEY,
          });

          setOpenAI(openaiInstance);
      };

      initializeOpenAI();
  }, []);


    const [paragraph2, setParagraph2] = useState(
    "Paragraph"
    );


  // State for the editable content
  const [heading, setHeading] = useState("Customise This Heading");
  const [paragraph, setParagraph] = useState(
    "Paragraph"
  );

  // State for styles
  const [headingColor, setHeadingColor] = useState("text-blue-700");
  const [paragraphColor, setParagraphColor] = useState("text-gray-600");
  const [bgColor, setBgColor] = useState("bg-white");
  const [bgImageUrl, setBgImageUrl] = useState("");
  const [font, setFont] = useState("sans-serif");
  const [renderedComponent, setRenderedComponent] = useState(null);
  const [prices, setPrices] = useState([]);
  const [showTemplateSelection, setShowTemplateSelection] = useState(true);

  console.log(prices)

  console.log("dataSource", preview);

  
  useEffect(() => {
    const fetchPrices = async () => {
        try {
            const response = await fetch('https://cheekydino.com/api/prices', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setPrices(data.data)
            } else {
                console.error('Failed to fetch prices');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchPrices();


}, []);



  const templates = [
    {
      id: null,
      name: "None",
      code: "",
    },
    {
      id: 1,
      name: "Header Section",
      code: (
        <div
          className={`${bgColor} ${bgImageUrl ? 'bg-cover bg-center' : ''} px-6 py-24 sm:py-32 lg:px-8`}
          style={{ backgroundImage: bgImageUrl ? `url(${bgImageUrl})` : "" }}
        >
          <div className="mx-auto max-w-2xl text-center">
            <h2 className={`text-4xl font-bold tracking-tight sm:text-6xl ${headingColor}`} style={{ fontFamily: font }}>
              {heading}
            </h2>
            <p className={`mt-6 text-lg leading-8 ${paragraphColor}`} style={{ fontFamily: font }}>
              {paragraph}
            </p>
          </div>
        </div>
      ),
    },
    {
        id: 2,
        name: "Feature Section",
        code: (
                <div className="relative">
                  <div className={`relative h-80 overflow-hidden ${bgColor} md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2`}>
  <img
    alt=""
    src={bgImageUrl}
    className="h-full w-full object-cover"
  />
</div>

                  <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
                    <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
                      <h2 className={`text-base font-semibold leading-7 ${headingColor}`} style={{ fontFamily: font }}>
                        {heading}
                      </h2>
                      <p className={`mt-2 text-3xl font-bold tracking-tight sm:text-4xl ${paragraphColor} `} style={{ fontFamily: font }}>
                        {paragraph}
                      </p>
                     
                    </div>
                  </div>
                </div>
              
        ),
    },
    {
        id:3,
        name: "Content Section",
        code: (
            <div className={`${bgColor} py-24 sm:py-32`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div className="lg:pr-4">
                  <div className="relative overflow-hidden rounded-3xl px-6 pb-9 pt-64 sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                    <img
                      alt=""
                      src={bgImageUrl}
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-base leading-7 lg:max-w-lg">
                    <h1
                      className={`mt-2 ${headingColor} text-3xl font-bold tracking-tight sm:text-4xl`}
                      style={{ fontFamily: font }} // Add this line to customize the heading color
                    >
                      {heading}
                    </h1>
                    <div className="max-w-xl">
                      <p
                        className={`mt-6 ${paragraphColor}`} // Add this line to customize the paragraph color
                        style={{ fontFamily: font }} // Add this line to customize the paragraph color
                      >
                        {paragraph}
                      </p>
                      <p
                        className={`mt-8 ${paragraphColor}`} // Add this line to customize the paragraph color
                        style={{ fontFamily: font}} // Add this line to customize the paragraph color
                      >
                        {paragraph2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        ),
    }

                
  ];

  const handleInputChange = (e, type) => {
    const value = e.target.value;
    switch (type) {
      case "heading":
        setHeading(value);
        break;
      case "paragraph":
        setParagraph(value);
        break;
        case "paragraph2":
        setParagraph2(value);
        break;
      case "headingColor":
        setHeadingColor(value);
        break;
      case "paragraphColor":
        setParagraphColor(value);
        break;
      case "bgColor":
        setBgColor(value);
        break;
      case "bgImageUrl":
        setBgImageUrl(value);
        break;
      case "font":
        setFont(value);
        break;
      default:
        break;
    }
  };

  const tailwindColors = [
    // Gray colors
    "text-gray-50", "text-gray-100", "text-gray-200", "text-gray-300", "text-gray-400", "text-gray-500", 
    "text-gray-600", "text-gray-700", "text-gray-800", "text-gray-900", 
    "bg-gray-50", "bg-gray-100", "bg-gray-200", "bg-gray-300", "bg-gray-400", "bg-gray-500", 
    "bg-gray-600", "bg-gray-700", "bg-gray-800", "bg-gray-900",
  
    // Red colors
    "text-red-50", "text-red-100", "text-red-200", "text-red-300", "text-red-400", "text-red-500", 
    "text-red-600", "text-red-700", "text-red-800", "text-red-900", 
    "bg-red-50", "bg-red-100", "bg-red-200", "bg-red-300", "bg-red-400", "bg-red-500", 
    "bg-red-600", "bg-red-700", "bg-red-800", "bg-red-900",
  
    // Yellow colors
    "text-yellow-50", "text-yellow-100", "text-yellow-200", "text-yellow-300", "text-yellow-400", "text-yellow-500", 
    "text-yellow-600", "text-yellow-700", "text-yellow-800", "text-yellow-900", 
    "bg-yellow-50", "bg-yellow-100", "bg-yellow-200", "bg-yellow-300", "bg-yellow-400", "bg-yellow-500", 
    "bg-yellow-600", "bg-yellow-700", "bg-yellow-800", "bg-yellow-900",
  
    // Green colors
    "text-green-50", "text-green-100", "text-green-200", "text-green-300", "text-green-400", "text-green-500", 
    "text-green-600", "text-green-700", "text-green-800", "text-green-900", 
    "bg-green-50", "bg-green-100", "bg-green-200", "bg-green-300", "bg-green-400", "bg-green-500", 
    "bg-green-600", "bg-green-700", "bg-green-800", "bg-green-900",
  
    // Blue colors
    "text-blue-50", "text-blue-100", "text-blue-200", "text-blue-300", "text-blue-400", "text-blue-500", 
    "text-blue-600", "text-blue-700", "text-blue-800", "text-blue-900", 
    "bg-blue-50", "bg-blue-100", "bg-blue-200", "bg-blue-300", "bg-blue-400", "bg-blue-500", 
    "bg-blue-600", "bg-blue-700", "bg-blue-800", "bg-blue-900",
  
    // Indigo colors
    "text-indigo-50", "text-indigo-100", "text-indigo-200", "text-indigo-300", "text-indigo-400", "text-indigo-500", 
    "text-indigo-600", "text-indigo-700", "text-indigo-800", "text-indigo-900", 
    "bg-indigo-50", "bg-indigo-100", "bg-indigo-200", "bg-indigo-300", "bg-indigo-400", "bg-indigo-500", 
    "bg-indigo-600", "bg-indigo-700", "bg-indigo-800", "bg-indigo-900",
  
    // Purple colors
    "text-purple-50", "text-purple-100", "text-purple-200", "text-purple-300", "text-purple-400", "text-purple-500", 
    "text-purple-600", "text-purple-700", "text-purple-800", "text-purple-900", 
    "bg-purple-50", "bg-purple-100", "bg-purple-200", "bg-purple-300", "bg-purple-400", "bg-purple-500", 
    "bg-purple-600", "bg-purple-700", "bg-purple-800", "bg-purple-900",
  
    // Pink colors
    "text-pink-50", "text-pink-100", "text-pink-200", "text-pink-300", "text-pink-400", "text-pink-500", 
    "text-pink-600", "text-pink-700", "text-pink-800", "text-pink-900", 
    "bg-pink-50", "bg-pink-100", "bg-pink-200", "bg-pink-300", "bg-pink-400", "bg-pink-500", 
    "bg-pink-600", "bg-pink-700", "bg-pink-800", "bg-pink-900",
  
    // Orange colors
    "text-orange-50", "text-orange-100", "text-orange-200", "text-orange-300", "text-orange-400", "text-orange-500", 
    "text-orange-600", "text-orange-700", "text-orange-800", "text-orange-900", 
    "bg-orange-50", "bg-orange-100", "bg-orange-200", "bg-orange-300", "bg-orange-400", "bg-orange-500", 
    "bg-orange-600", "bg-orange-700", "bg-orange-800", "bg-orange-900",
  
    // Cyan colors
    "text-cyan-50", "text-cyan-100", "text-cyan-200", "text-cyan-300", "text-cyan-400", "text-cyan-500", 
    "text-cyan-600", "text-cyan-700", "text-cyan-800", "text-cyan-900", 
    "bg-cyan-50", "bg-cyan-100", "bg-cyan-200", "bg-cyan-300", "bg-cyan-400", "bg-cyan-500", 
    "bg-cyan-600", "bg-cyan-700", "bg-cyan-800", "bg-cyan-900",
  
    // Teal colors
    "text-teal-50", "text-teal-100", "text-teal-200", "text-teal-300", "text-teal-400", "text-teal-500", 
    "text-teal-600", "text-teal-700", "text-teal-800", "text-teal-900", 
    "bg-teal-50", "bg-teal-100", "bg-teal-200", "bg-teal-300", "bg-teal-400", "bg-teal-500", 
    "bg-teal-600", "bg-teal-700", "bg-teal-800", "bg-teal-900",
  
    // Lime colors
    "text-lime-50", "text-lime-100", "text-lime-200", "text-lime-300", "text-lime-400", "text-lime-500", 
    "text-lime-600", "text-lime-700", "text-lime-800", "text-lime-900", 
    "bg-lime-50", "bg-lime-100", "bg-lime-200", "bg-lime-300", "bg-lime-400", "bg-lime-500", 
    "bg-lime-600", "bg-lime-700", "bg-lime-800", "bg-lime-900",
  
    // Emerald colors
    "text-emerald-50", "text-emerald-100", "text-emerald-200", "text-emerald-300", "text-emerald-400", "text-emerald-500", 
    "text-emerald-600", "text-emerald-700", "text-emerald-800", "text-emerald-900", 
    "bg-emerald-50", "bg-emerald-100", "bg-emerald-200", "bg-emerald-300", "bg-emerald-400", "bg-emerald-500", 
    "bg-emerald-600", "bg-emerald-700", "bg-emerald-800", "bg-emerald-900",
  
    // Rose colors
    "text-rose-50", "text-rose-100", "text-rose-200", "text-rose-300", "text-rose-400", "text-rose-500", 
    "text-rose-600", "text-rose-700", "text-rose-800", "text-rose-900", 
    "bg-rose-50", "bg-rose-100", "bg-rose-200", "bg-rose-300", "bg-rose-400", "bg-rose-500", 
    "bg-rose-600", "bg-rose-700", "bg-rose-800", "bg-rose-900",
  
    // Fuchsia colors
    "text-fuchsia-50", "text-fuchsia-100", "text-fuchsia-200", "text-fuchsia-300", "text-fuchsia-400", "text-fuchsia-500", 
    "text-fuchsia-600", "text-fuchsia-700", "text-fuchsia-800", "text-fuchsia-900", 
    "bg-fuchsia-50", "bg-fuchsia-100", "bg-fuchsia-200", "bg-fuchsia-300", "bg-fuchsia-400", "bg-fuchsia-500", 
    "bg-fuchsia-600", "bg-fuchsia-700", "bg-fuchsia-800", "bg-fuchsia-900",
  ];

  const handleChoose = (e, type) => {
    const value = e.target.value;
    switch (type) {
      case "selectedOption":
        setSelectedOption(value);
        break;
      default:
        break;
    }
  }

  const handleSelectChange = (e) => {
    const selectedId = parseInt(e.target.value, 10); // Ensure the ID is a number
    console.log('Selected ID:', selectedId);

    // Find the selected price object
    const selectedPrice = prices.find(price => price.id === selectedId);

    console.log('Selected Price:', selectedPrice);

    setDataSource(selectedPrice || null);
};

console.log("dataSource", dataSource);
  
  const handleAIgenerate = async (selectedTemplateId, AIcolor, AIPrompt, aiImage, dataSource) => {
    console.log("AI generate", { AIPrompt, AIcolor, aiImage, dataSource });

    // Find the template object based on the selectedTemplateId
    const selectedTemplate = templates.find(template => template.id === selectedTemplateId);

    if (!selectedTemplate) {
        console.error("Selected template not found!");
        return;
    }

    // Log the template code for debugging
    console.log("Selected Template Code:", selectedTemplate.code);

    // Set loading state to true
    setLoading(true);
    setShowTemplateSelection(false);


    const messages = [
        { role: 'system', content: 'You are a helpful assistant.' },
        {
            role: 'user',
            content: `I am using React JS and Tailwind CSS. I want to generate JSX code for the following content:

Description: ${AIPrompt}
Data Source: ${JSON.stringify(dataSource)}
Template Code: ${selectedTemplate.code}
Color Scheme: ${AIcolor}
Image Link: ${aiImage}

Your task is to:
1. Adapt the template to create a vibrant, engaging advertisement that stands out.
6. Use the data from the Data Source to create a compelling paragraph and title, but do not use it dynamically in the JSX code.
2. Use the provided color scheme creatively, ensuring the design is visually appealing.
3. Integrate the image link appropriately, making sure it enhances the overall design.

4. Incorporate the description into the design in a way that draws attention.
5. Feel free to adjust the layout, add decorative elements, or introduce new styling techniques to make the ad more attractive.
6. Use the data from the Data Source to create a compelling paragraph and title, but do not use it dynamically in the JSX code.
7. Make sure the final design is responsive and visually appealing on different screen sizes.
8. We live in the UK so the Price is in £ and can you format the dates so it shows the day of the week.


Please return only the JSX code snippet that reflects these changes, excluding imports, function definitions, and no buttons, and return statements, only use tailwind css className and use the template provided.`,
        },
    ];

    try {
        // Call the OpenAI API
        const response = await openAI.chat.completions.create({
            model: 'gpt-4',
            messages,
        });

        // Handle response and update chat messages
        console.log(response);
        let newMessage = response.choices[0].message.content;

        // Extract only the JSX content between the first <div> and last </div> tags
        const firstDivIndex = newMessage.indexOf('<div');
        const lastDivIndex = newMessage.lastIndexOf('</div>') + 6; // +6 to include `</div>`

        if (firstDivIndex !== -1 && lastDivIndex !== -1) {
            newMessage = newMessage.slice(firstDivIndex, lastDivIndex);
        }

        // Update state with the JSX string
        setRenderedComponent(<JSXParser jsx={newMessage} />);

        // Update chat messages
        setChatMessages([...chatMessages, { author: 'bot', message: newMessage }]);
    } catch (error) {
        console.error('Error:', error);
    } finally {
        // End loading state
        setLoading(false);
    }
};

  




const handleSavePreview = async () => {
  const saveData = {
      Name: AIPrompt,  // Assuming this is the name for the advert
      Code: renderedComponent.props.jsx, // Saving the JSX string as the code
      Activate: 0,
      Design: 0 // Live flag set to 0
      // Add any other necessary fields here, e.g., StartDate, ExpiryDate
  };

  try {
      const response = await fetch('https://cheekydino.com/api/aipreview', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(saveData),
      });

      if (response.ok) {
          console.log('Advert saved successfully');
          setPreview(true);
          window.location.reload();
          // Handle success (e.g., show a success message or reset the form)
      } else {
          console.error('Failed to save the advert');
      }
  } catch (error) {
      console.error('Error:', error);
  }
};


const templateStrings = [
  {
    id: 1,
    code: `
      <div
        className="\${bgColor} \${bgImageUrl ? 'bg-cover bg-center' : ''} px-6 py-24 sm:py-32 lg:px-8"
        style={{ backgroundImage: bgImageUrl ? \`url(\${bgImageUrl})\` : "" }}
      >
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight sm:text-6xl \${headingColor}" style={{ fontFamily: font }}>
            \${heading}
          </h2>
          <p className="mt-6 text-lg leading-8 \${paragraphColor}" style={{ fontFamily: font }}>
            \${paragraph}
          </p>
        </div>
      </div>
    `,
  },
  {
    id: 2,
    code: `
      <div className="relative">
        <div className="relative h-80 overflow-hidden \${bgColor} md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
          <img
            alt=""
            src="\${bgImageUrl}"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
          <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
            <h2 className="text-base font-semibold leading-7 \${headingColor}" style={{ fontFamily: font }}>
              \${heading}
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl \${paragraphColor}" style={{ fontFamily: font }}>
              \${paragraph}
            </p>
          </div>
        </div>
      </div>
    `,
  },
  {
    id: 3,
    code: `
      <div className="\${bgColor} py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl px-6 pb-9 pt-64 sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                  alt=""
                  src="\${bgImageUrl}"
                  className="absolute inset-0 h-full w-full object-cover"
                />
              </div>
            </div>
            <div>
              <div className="text-base leading-7 lg:max-w-lg">
                <h1 className="mt-2 \${headingColor} text-3xl font-bold tracking-tight sm:text-4xl" style={{ fontFamily: font }}>
                  \${heading}
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6 \${paragraphColor}" style={{ fontFamily: font }}>
                    \${paragraph}
                  </p>
                  <p className="mt-8 \${paragraphColor}" style={{ fontFamily: font }}>
                    \${paragraph2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
  },
];



  










const handleOwnDesign = async () => {
  try {
    const selectedTemplate = templateStrings.find(t => t.id === selectedTemplateId);
    if (!selectedTemplate) throw new Error('Template not found');

    // Replace placeholders with actual values
    const filledTemplate = selectedTemplate.code
      .replace(/\${heading}/g, heading)
      .replace(/\${paragraph}/g, paragraph)
      .replace(/\${paragraph2}/g, paragraph2 || "")
      .replace(/\${headingColor}/g, headingColor)
      .replace(/\${paragraphColor}/g, paragraphColor)
      .replace(/\${bgColor}/g, bgColor)
      .replace(/\${bgImageUrl}/g, bgImageUrl)
      .replace(/\${font}/g, font);

    // Prepare the data to save
    const dataToSave = {
      Code: filledTemplate, // Save the filled template as code
      Activate: 0,
      Name: heading,
      Design: 1, // Live flag set to 0
    };

    // Send the data to the server
    const response = await fetch('https://cheekydino.com/api/ownpreview', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSave),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    console.log('Successfully saved:', result);
    window.location.reload();
    // Handle success feedback
  } catch (error) {
    console.error('Error saving design:', error);
    // Handle error feedback
  }
};



  




return (
  <div className={`grid ${showTemplateSelection ? 'grid-cols-3' : 'grid-cols-2'} gap-4`}>
    {/* Template Selection Section */}
    {showTemplateSelection && (
      <div className="col-span-1 p-4">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900">Select a Template</legend>
          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
            {templates.map((template) => (
              <div key={template.id} className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label htmlFor={`template-${template.id}`} className="select-none font-medium text-gray-900">
                    {template.name}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <input
                    type="radio"
                    id={`template-${template.id}`}
                    name="template"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => setSelectedTemplateId(template.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    )}



    {/* Main Content Section (Design or AI Generate) */}
    <div className={`col-span-${showTemplateSelection ? '2' : '1'} p-4`}>
    {selectedTemplateId ? (
        <>
          <label htmlFor="options" className="block text-sm font-medium leading-6 text-gray-900">
            Choose An Option
          </label>
          <select
            id="options"
            name="options"
            onChange={(e) => handleChoose(e, "selectedOption")}
            defaultValue="Design"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>Design</option>
            <option>Generate AI</option>
          </select>

          {/* Design Option Content */}

          {selectedOption === "Design" && (
            <div className="mt-4">
  <div className="mt-4">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Edit Heading</label>
                      <input
                        type="text"
                        value={heading}
                        onChange={(e) => handleInputChange(e, "heading")}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
  
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Edit Paragraph</label>
                      <textarea
                        value={paragraph}
                        onChange={(e) => handleInputChange(e, "paragraph")}
                        rows="4"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
  
                    {selectedTemplateId === 3 && (
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Edit Paragraph 2</label>
                        <textarea
                          value={paragraph2}
                          onChange={(e) => handleInputChange(e, "paragraph2")}
                          rows="4"
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        />
                      </div>
                    )}
  
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Select Heading Color</label>
                      <select
                        value={headingColor}
                        onChange={(e) => handleInputChange(e, "headingColor")}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      >
                        {tailwindColors.filter(color => color.startsWith('text-')).map((color) => (
                          <option key={color} value={color}>
                            {color}
                          </option>
                        ))}
                      </select>
                    </div>
  
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Select Paragraph Color</label>
                      <select
                        value={paragraphColor}
                        onChange={(e) => handleInputChange(e, "paragraphColor")}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      >
                        {tailwindColors.filter(color => color.startsWith('text-')).map((color) => (
                          <option key={color} value={color}>
                            {color}
                          </option>
                        ))}
                      </select>
                    </div>
  
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Select Background Color</label>
                      <select
                        value={bgColor}
                        onChange={(e) => handleInputChange(e, "bgColor")}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      >
                        {tailwindColors.filter(color => color.startsWith('bg-')).map((color) => (
                          <option key={color} value={color}>
                            {color}
                          </option>
                        ))}
                      </select>
                    </div>
  
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Background Image URL (optional)</label>
                      <input
                        type="text"
                        value={bgImageUrl}
                        onChange={(e) => handleInputChange(e, "bgImageUrl")}
                        placeholder="Enter image URL"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
  
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Select Font Family</label>
                      <select
                        value={font}
                        onChange={(e) => handleInputChange(e, "font")}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="sans-serif">Sans-serif</option>
                        <option value="serif">Serif</option>
                        <option value="monospace">Monospace</option>
                        <option value="cursive">Cursive</option>
                        <option value="fantasy">Fantasy</option>
                      </select>
                    </div>
  
                   
                  </div>              <button
                onClick={() => setPreview(true)|| setShowTemplateSelection(false)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Preview
              </button>
            </div>
          )}

          {/* Generate AI Option Content */}
          {selectedOption === "Generate AI" && (
            <div className="mt-4">
              {/* AI generation inputs and settings */}
              <div className="mt-4">
            <textarea
                onChange={(e) => setAIPrompt(e.target.value)}
                placeholder="Describe your AI-generated content"
                className="block w-full mt-5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <div className="mt-4">
                <textarea
                    onChange={(e) => setAiImage(e.target.value)}
                    placeholder="Paste your image URL"
                    className="block w-full mt-5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </div>
            <div className="mt-4">
                <label htmlFor="dataSource" className="block text-sm font-medium leading-6 text-gray-900">
                    Data Source
                </label>
                <select
                    onChange={handleSelectChange}
                    id="dataSource"
                    name="dataSource"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                    {prices.map((price) => (
                        <option key={price.id} value={price.id}>
                            {price.Name} - {price.Category} - £{price.Price} - {format(new Date(price.StartDate), 'yyyy/MM/dd')} - {format(new Date(price.ExpiryDate), 'yyyy/MM/dd')}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Color Scheme</label>
                <select
                    value={AIcolor}
                    onChange={(e) => setAIcolor(e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    style={{ backgroundColor: AIcolor }}
                >
                    {tailwindColors.map((color) => (
                      
                        <option key={color} value={color}>
                            {color}
                        </option>
                    ))}
                </select>
                <div className={`mt-5 mb-5 w-full h-10 ${AIcolor}`}></div>
            </div>

            {loading ? (
              <div className="flex items-center gap-x-2">
                <Cog6ToothIcon aria-hidden="true" className="-ml-0.5 h-20 w-20 animate-spin" />
                <span className="text-lg font-semibold text-gray-900">Generating AI...</span>
              </div>
                
            ) : (
                <button
                    onClick={() => handleAIgenerate(selectedTemplateId, AIcolor, AIPrompt, aiImage, dataSource)}
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <Cog6ToothIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                    Generate AI
                </button>
            )}
        </div>
            </div>
          )}
        </>
      ) : (
        <p className="text-sm text-gray-900">Please select a template to continue.</p>
      )}
    </div>
             
          {/* Rendered AI Component */}
          {renderedComponent && (
      <div className={`col-span-${showTemplateSelection ? '2' : '1'} mt-4`}>
        {renderedComponent}
        <button
          onClick={handleSavePreview}
          type="button"
          className="inline-flex mr-5 items-center gap-x-2 mt-4 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save as Preview
        </button>
        <button onClick={() => window.location.reload()} className="inline-flex items-center gap-x-2 mt-4 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          Cancel
        </button>
      </div>
    )}

    {/* Preview Section */}
    {preview && selectedTemplateId && (
  <div className={`col-span-${showTemplateSelection ? '2' : '1'} mt-4`}>
    <h2 className="text-lg font-semibold">Preview Section</h2>
    <div>
      {templates.find((t) => t.id === selectedTemplateId)?.code}
    </div>
    <div className="mt-4 flex gap-x-4">
      <button
        onClick={handleOwnDesign}
        type="button"
        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Save as Preview
      </button>
      <button
        onClick={() => window.location.reload()}
        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Cancel
      </button>
    </div>
  </div>
)}

  </div>
);
}
