import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

export default function SessionCalendar() {
  const [occupiedTables, setOccupiedTables] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date()); // State for the current time
  const currentDate = new Date(); // Set the current date automatically

  const timeslots = [
    { start: '09:30', end: '11:30' },
    { start: '10:00', end: '12:00' },
    { start: '10:30', end: '12:30' },
    { start: '11:00', end: '13:00' },
    { start: '11:30', end: '13:30' },
    { start: '12:00', end: '14:00' },
    { start: '12:30', end: '14:30' },
    { start: '13:00', end: '15:00' },
    { start: '13:30', end: '15:30' },
    { start: '14:00', end: '16:00' },
    { start: '14:30', end: '16:30' },
    { start: '15:00', end: '17:00' },
  ];

  useEffect(() => {
    // Update the current time every minute
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer); // Clear the interval on component unmount
  }, []);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${formattedDate}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch availability data');
        }

        const bookingsResponse = await response.json();
        const bookingsForDate = bookingsResponse.data;

        const occupiedTablesData = {};

        timeslots.forEach(timeslot => {
          let occupiedCount = 0;

          bookingsForDate.forEach(booking => {
            if (
              booking.TimeslotFrom < timeslot.end &&
              booking.TimeslotTo > timeslot.start
            ) {
              occupiedCount++;
            }
          });

          occupiedTablesData[timeslot.start] = occupiedCount;
        });

        setOccupiedTables(occupiedTablesData);
      } catch (error) {
        console.error('Failed to fetch availability data', error);
      }
    };

    fetchAvailability();
  }, [currentDate]);

  const getTableColor = numOccupiedTables => {
    if (numOccupiedTables > 0 && numOccupiedTables <= 15) {
      return 'bg-green-500';
    } else if (numOccupiedTables > 15 && numOccupiedTables <= 25) {
      return 'bg-yellow-500';
    } else if (numOccupiedTables > 25 && numOccupiedTables <= 30) {
      return 'bg-orange-500';
    } else if (numOccupiedTables > 30 && numOccupiedTables <= 38) {
      return 'bg-red-500';
    }
  };

  const isCurrentTimeInTimeslot = (start, end) => {
    const now = currentTime;
    const startTime = new Date(currentDate);
    const endTime = new Date(currentDate);

    // Parse start and end times for the timeslot
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    startTime.setHours(startHour, startMinute, 0, 0);
    endTime.setHours(endHour, endMinute, 0, 0);

    return now >= startTime && now < endTime;
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
      <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-2">
        {timeslots.map(timeslot => (
          <div
            key={timeslot.start}
            className={`flex justify-between items-center p-2 rounded-md shadow-sm text-white ${getTableColor(occupiedTables[timeslot.start] || 0)} 
              ${isCurrentTimeInTimeslot(timeslot.start, timeslot.end) ? 'animate-pulse' : ''}`}
          >
            <div className="text-sm font-medium">
              {timeslot.start} - {timeslot.end}
            </div>
            <div className="text-sm">
              {occupiedTables[timeslot.start] || 0}/38
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}
