import React, { useEffect, useState, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { FireIcon, HeartIcon, FaceSmileIcon, FaceFrownIcon, HandThumbUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import OpenAI from 'openai';
import config from '../openai'; // Adjust the path if necessary
import { format } from 'date-fns';
import LoadingComponent from './loading';

const moods = [
    { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
    { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ChatGPTMarketing({ emails }) {
    const [chatMessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [openai, setOpenAI] = useState(null);
    const [selected, setSelected] = useState(moods[5]);
    const [editMode, setEditMode] = useState(false);
    const [editMessage, setEditMessage] = useState('');
    const [mail, setMail] = useState([]);
    const [progress, setProgress] = useState(0); 
    const [prices, setPricelist] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getPricelist = async () => {
            try {
                const response = await fetch('https://cheekydino.com/api/specialoffers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch pricelist');
                }

                const data = await response.json();
                const pricelist = data.data;
                setPricelist(pricelist);
            } catch (error) {
                console.error('Error fetching pricelist:', error);
            }
        };

        getPricelist();
    }, []);

    useEffect(() => {
        setMail(emails);
    }, [emails]);

    useEffect(() => {
        const initializeOpenAI = async () => {
            const openaiInstance = new OpenAI({
                dangerouslyAllowBrowser: true,
                apiKey: config.OPENAI_API_KEY,
            });

            setOpenAI(openaiInstance);
        };

        initializeOpenAI();
    }, []);

    const sendMessage = async () => {
        setLoading(true);
        const messages = [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Cheeky Dino Playcentre - create marketing email but just the body don't include the subject  - ${message}` }
        ];
        try {
            const response = await openai.chat.completions.create({
                model: 'gpt-3.5-turbo',
                messages,
            });
            const newMessage = response.choices[0].message.content;
            const newMessages = [...chatMessages, { author: 'bot', message: newMessage }];
            setChatMessages(newMessages);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const editResponse = (index) => {
        setEditMode(true);
        setEditMessage(chatMessages[index].message);
    };

    const saveEdit = async (index) => {
        let newMessages = [...chatMessages];
        newMessages[index].message = editMessage;
        setChatMessages(newMessages);
        setEditMode(false);
    
        let sentCount = 0;
    
        for (const email of mail) {
            const emailData = {
                email: email,
                content: editMessage 
            };
            
            try {
                const response = await fetch('https://cheekydino.com/api/email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailData),
                });

                if (response.ok) {
                    sentCount++;
                    setProgress((sentCount / mail.length) * 100);
                }
            } catch (error) {
                console.error('Error sending email:', error);
            }

            try {
                const response = await fetch('https://cheekydino.com/api/addenquiry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        To: "Marketing",
                        Content: editMessage,
                        From: 'bookings@cheekydino.co.uk',
                        ToMarketing: email,
                        Replied: 1,
                        Marketing: 1
                    }),

                });

                if (response.ok) {
                    sentCount++;
                    setProgress((sentCount / mail.length) * 100);
                }
            } catch (error) {
                console.error('Error sending email:', error);
            }

            console.log(`${sentCount} emails sent successfully.`);
            navigate('/dashboard');


        }

               
              
    
    };

    const handleSelectChange = (e) => {
        const selectedPrice = prices.find((price) => price.id === e.target.value);

        if (selectedPrice.SetDay === 1) {
            setMessage(`Special offer - ${selectedPrice.Name} - ${selectedPrice.Category} - £${selectedPrice.Price} - ${selectedPrice.Days} days`);
        } else {
            setMessage(`Special offer - ${selectedPrice.Name} - ${selectedPrice.Category} - £${selectedPrice.Price} - ${format(new Date(selectedPrice.StartDate), 'yyyy/MM/dd')} - ${format(new Date(selectedPrice.ExpiryDate), 'yyyy/MM/dd')}`);
        }
    }

    return (
        <div>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => navigate('/')}>Back</button>
            <div className="bg-white px-6 py-24 sm:py-32 lg:px-8" >
                <div className="mx-auto max-w-2xl text-center">
                    <p className="text-base font-semibold leading-7 text-indigo-600">Versasoft AI Marketing Manager</p>
                    <h2 className="mt-2 text-4xl font-bold tracking-tight text-indigo-900 sm:text-6xl">Talk To Versa</h2>
                    {mail.length > 0 && <div className="mt-4">
                        <p> Emails Selected : {mail.length} </p>
                    </div>}

                </div>
            </div>

            <div className='mt-10 mb-10'>
                {loading && <div><LoadingComponent/> </div>}
                {chatMessages.map((chatMessage, index) => (
                    <div key={index} className="p-4 mb-4 bg-indigo-100 text-sm font-semi-bold rounded shadow">
                        <div>{chatMessage.message}</div>
                        {chatMessage.author === 'bot' && <button className="px-4 py-2 mt-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => editResponse(index)}>Edit</button>}
                        {editMode &&
                            <textarea
                                value={editMessage}
                                onChange={(e) => setEditMessage(e.target.value)}
                                className="w-full p-2 mt-2 border rounded mb-5"
                            />
                        }
                        {editMode &&
                            <button
                                onClick={() => saveEdit(index)}
                                className="px-4 py-2 mt-2 bg-green-500 text-white rounded hover:bg-green-600"
                            >
                                Save
                            </button>
                        }
                    </div>
                ))}
            </div>

            <div className="flex items-start space-x-4 mb-10 mt-10">
                <div className="flex-shrink-0">
                    <img
                        className="inline-block h-10 w-10 rounded-full"
                        src='versa.gif'
                        alt=""
                    />
                </div>
                <div className="min-w-0 flex-1">
                    <div className="mt-4">
                        <label htmlFor="dataSource" className="block text-sm font-medium leading-6 text-gray-900">
                            Choose A Price List
                        </label>
                        <div className="mt-2">
                            <select id="dataSource" name="dataSource" onChange={handleSelectChange}>
                                <option>Select A Pricing List</option>
                                {prices.map((price) => (
                                    <option key={price.id} value={price.id}>{price.Name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                            Write Your Marketing Email
                        </label>
                        <div className="mt-2">
                            <textarea
                                rows={4}
                                name="comment"
                                id="comment"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                            />
                        </div>
                    </div>

                    <div className="mt-2 flex justify-end">
                        <button
                            type="button"
                            onClick={sendMessage}
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
