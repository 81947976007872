import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function RestaurantLayout() {
  const [sessions, setSessions] = useState([]);
  const [futureSessions, setFutureSessions] = useState([]);
  const [cleanTables, setCleanTables] = useState([]);
  const [nav, setNav] = useState(false);
  const [hoveredTable, setHoveredTable] = useState(null);
  const [tableData, setTableData] = useState([]);

  const handleTableHover = (table) => {
    setHoveredTable(table);
  };

  const handleTableHoverOut = () => {
    setHoveredTable(null);
  };

  const Navigate = useNavigate();

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/admin');
        if (!response.ok) {
          throw new Error('Failed to fetch admin data');
        }
        const data = await response.json();
        const parsedTableData = JSON.parse(data.data[0].TableData);
        setTableData(parsedTableData);
      } catch (error) {
        console.error('Failed to fetch admin data:', error);
      }
    };

    fetchAdminData();
  }, []);

  const fetchSessions = async () => {
    try {
      const date = new Date();
      const awsDate = format(date, 'yyyy-MM-dd');
      const currentTime = format(date, 'HH:mm');

      const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${awsDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch sessions');
      }

      const sessiondata = await response.json();
      const sessionsData = sessiondata.data;

      const todaysSessions = sessionsData.filter(session =>
        session.Arrived === 1 &&
        session.LeftCenter === 0 &&
        session.Date === awsDate
      );
      setSessions(todaysSessions);

      const futureSessionsData = sessionsData.filter(session => {
        const sessionStartTime = new Date(`1970-01-01T${session.TimeslotFrom}:00`);
        const sessionEndTime = new Date(`1970-01-01T${session.TimeslotTo}:00`);
        const currentTimeDate = new Date(`1970-01-01T${currentTime}:00`);

        return session.Arrived === 0 &&
          session.LeftCenter === 0 &&
          currentTimeDate >= sessionStartTime &&
          currentTimeDate <= sessionEndTime;
      });
      setFutureSessions(futureSessionsData);

      const tablesToBeCleaned = sessionsData.filter(session =>
        session.Arrived === 1 &&
        session.LeftCenter === 1 &&
        session.CleanTable === 1 &&
        session.TableCleaned === 0
      );
      setCleanTables(tablesToBeCleaned);

    } catch (error) {
      console.error('Error fetching or processing sessions:', error);
    }
  };

  useEffect(() => {
    fetchSessions();
    const interval = setInterval(() => {
      fetchSessions();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  if (nav === true) {
    Navigate('/dashboard');
  }

  return (
    <div className="grid grid-cols-8 grid-rows-5 gap-4 p-10 border border-color-white">
      {tableData.map((table) => {
        const session = sessions.find(session => session.Table === table.table);
        const futureSession = futureSessions.find(session => session.Table === table.table);
        const cleanTable = cleanTables.find(session => session.Table === table.table);

        let tableRow = table.location.y + 1;
        let tableCols = `${table.location.x + 1} / span 1`;

        if (tableRow === 2 || tableRow === 4) {
          const tablesInRow = tableData.filter(t => t.location.y === tableRow - 1);
          tablesInRow.reverse();
          tableCols = `${tablesInRow[table.location.x].location.x + 1} / span 1`;
        }

        return (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            key={table.table}
            className={`p-2 ${table.shape === 'square' ? 'w-8 h-8 mt-2 mb-2' : 'w-8 h-8 rounded-full mt-2 mb-2'} 
            ${cleanTable ? 'bg-purple-500 animate-pulse' : futureSession ? 'bg-blue-500' : session ? 'bg-red-500' : 'bg-green-500'}`}
            style={{ gridColumn: tableCols, gridRow: `${tableRow} / span 1`, border: '2px solid gray-300', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)' }}
            onClick={() => setNav(true)}
            onMouseEnter={() => handleTableHover(table.table)}
            onMouseLeave={() => handleTableHoverOut()}
          >
            <div className="table-info">
              <span className="text-white">{table.table}</span>
            </div>
            <div className="timeslot-info">
              {hoveredTable === table.table && (
                <span className="text-xs text-gray-500 block mt-5 mb-2">
                  {session ? `${session.TimeslotFrom}-${session.TimeslotTo} (${session.Name})` : 'Table available'}
                </span>
              )}
            </div>
          </motion.button>
        );
      })}
    </div>
  );
}

export default RestaurantLayout;
