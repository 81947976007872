import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

export default function Online() {
  const [clockedInStaff, setClockedInStaff] = useState([]);

  useEffect(() => {
    const fetchClockedInStaff = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/clockins', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setClockedInStaff(data.data);
      } catch (error) {
        console.error('Failed to fetch clocked in staff:', error);
      }
    };

    fetchClockedInStaff(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchClockedInStaff();
    }, 1000); // Poll every second

    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
    };
  }, []);

  const calculateElapsedTime = (breakStart) => {
    if (!breakStart) {
      return 0;
    }

    const now = new Date();
    const [hours, minutes, seconds, milliseconds] = breakStart.split(/[:.]/); // Split the time string
    const breakStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds, milliseconds);

    const elapsedMilliseconds = now - breakStartTime;
    const elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60));
    return elapsedMinutes;
  };

  console.log(clockedInStaff);

  return (
    <div className="overflow-x-auto md:overflow-hidden">
        {/* Table for larger screens */}
        <table className="min-w-full divide-y divide-purple-300 hidden md:table">
            <thead>
                <tr>
                    <th className="px-4 py-3 border-b border-purple-200 bg-purple-50 text-left text-xs leading-4 font-medium text-purple-500 uppercase tracking-wider">Staff ID</th>
                    <th className="px-4 py-3 border-b border-purple-200 bg-purple-50 text-left text-xs leading-4 font-medium text-purple-500 uppercase tracking-wider">Department</th>
                    <th className="px-4 py-3 border-b border-purple-200 bg-purple-50 text-left text-xs leading-4 font-medium text-purple-500 uppercase tracking-wider">Clock-In Time</th>
                    <th className="px-4 py-3 border-b border-purple-200 bg-purple-50 text-left text-xs leading-4 font-medium text-purple-500 uppercase tracking-wider">Current Task</th>
                    <th className="px-4 py-3 border-b border-purple-200 bg-purple-50 text-left text-xs leading-4 font-medium text-purple-500 uppercase tracking-wider">Break Status</th>
                    <th className="px-4 py-3 border-b border-purple-200 bg-purple-50 text-left text-xs leading-4 font-medium text-purple-500 uppercase tracking-wider">Elapsed Break Time</th>
                </tr>
            </thead>
            <tbody>
                {clockedInStaff.map((staff) => (
                    <tr key={staff.id} className={staff.Break ? "bg-red-100" : "bg-green-100"}>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-purple-200">
                            <Link to="/dashboard" className="text-sm leading-5 font-medium text-purple-900 hover:underline">
                                {staff.StaffName}
                            </Link>
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-purple-200">
                            <p className="text-sm leading-5 text-purple-900">{staff.StaffRole}</p>
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-purple-200">
                            <p className="text-sm leading-5 text-purple-900">{staff.ClockIn.split(':').slice(0, 2).join(':')}</p>
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-purple-200">
                            <p className="text-lg text-green-500 animate-pulse">{staff.StaffRole}</p>
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-purple-200">
                            <p className="text-sm leading-5 text-purple-900">{staff.Break ? "On Break" : "Working"}</p>
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-purple-200">
                            {staff.Break && (
                                <p className="text-sm leading-5 text-purple-900">
                                    {calculateElapsedTime(staff.BreakStart.split(':').slice(0, 2).join(':'))} minutes
                                </p>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

        {/* Card layout for mobile screens */}
        <div className="block md:hidden">
            {clockedInStaff.map((staff) => (
                <div key={staff.id} className={`bg-white rounded-lg shadow-md p-4 mb-4 ${staff.Break ? 'bg-red-100' : 'bg-green-100'}`}>
                    <div className="flex justify-between">
                        <h3 className="text-lg font-medium text-purple-900">
                            <Link to="/dashboard" className="hover:underline">
                                {staff.StaffName}
                            </Link>
                        </h3>
                        <p className="text-sm text-purple-900">{staff.StaffRole}</p>
                    </div>
                    <div className="mt-2">
                        <p className="text-sm text-purple-700">Clock-In: {staff.ClockIn.split(':').slice(0, 2).join(':')}</p>
                        <p className="text-sm text-purple-700">Task: <span className="text-green-500">{staff.StaffRole}</span></p>
                        <p className="text-sm text-purple-700">Status: {staff.Break ? "On Break" : "Working"}</p>
                        {staff.Break && (
                            <p className="text-sm text-purple-700">
                                Elapsed Break Time: {calculateElapsedTime(staff.BreakStart.split(':').slice(0, 2).join(':'))} minutes
                            </p>
                        )}
                    </div>
                </div>
            ))}
        </div>
    </div>
);
}