import React, { useState, useEffect } from "react";
import { format, differenceInMinutes, parse, addMinutes, addDays } from "date-fns";
import { PlusIcon } from '@heroicons/react/20/solid';
import NextDaySession from "./nextdaysession";
import TableSlots from "./tableslots";
import { useNavigate } from "react-router-dom";
import BookingDetails from "./BookingDetails";
import { set } from "lodash";
import MessagesComponent from "./custsendmessage";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

export default function FutureOnlineBookings() {
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [enteredPassword, setEnteredPassword] = useState('');
    const [showPasswordInputs, setShowPasswordInputs] = useState({});
    const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [showComponent, setShowComponent] = useState(false);
    const [date, setDate] = useState('');
    const [showNew, setShowNew] = useState(false);
    const [truth, setTrue] = useState(false);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const navigate = useNavigate();



    console.log('Sessions', sessions);


    useEffect(() => {
        fetchSessions();


        // fetch sessions every 1 second 

        const interval = setInterval(() => {
            fetchSessions();
        }
            , 10000);

        return () => clearInterval(interval);




    }, []);

    const fetchSessions = async () => {
        try {
            const today = format(new Date(), 'yyyy-MM-dd');
        
            const response = await fetch(`https://cheekydino.com/api/sessionbookingsfuture?date=${today}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });

            const sessions = await response.json();
            const sessionsData = sessions.data;

            console.log('Sessions', sessionsData);

            const currentTime = format(new Date(), 'HH:mm:ss');
            const currentTimeMinus30 = format(addMinutes(parse(currentTime, 'HH:mm:ss', new Date()), -30), 'HH:mm:ss');

        

            let futureBookings = sessionsData.filter(session =>
                session.TimeslotFrom > currentTimeMinus30 && session.Arrived === 0
            );

            console.log('Future bookings', futureBookings);

            futureBookings.sort((a, b) => {
                const timeTillSessionA = differenceInMinutes(parse(a.TimeslotFrom, 'HH:mm:ss', new Date()), new Date());
                const timeTillSessionB = differenceInMinutes(parse(b.TimeslotFrom, 'HH:mm:ss', new Date()), new Date());
                return timeTillSessionA - timeTillSessionB;
            });

            setSessions(futureBookings);
        } catch (error) {
            console.error("Error fetching sessions", error);
        }
    };

    const handleArrivedClick = async (session) => {
      // setState 
      setSelectedSession(session)
      setTrue(true)

  }

  console.log('Selected session', selectedSession);

  if (truth === true) {
      return <BookingDetails session={selectedSession} />
  }

    const handleDeleteClick = (session, event) => {
        event.preventDefault();
        event.stopPropagation();

        setSelectedSession(session);
        setShowPasswordInputs(prevState => ({ ...prevState, [session.id]: true }));
    };


    const handleConfirmClick = async () => {
        if (enteredPassword === 'password') {
            try {
                // post request to delete session
                const response = await fetch(`https://cheekydino.com/api/deletesessionbooking/${selectedSession.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to delete session');
                }

                console.log('Session deleted successfully');
                fetchSessions();
            } catch (error) {
                console.error('Error deleting session', error);
            }
            setShowPasswordInputs(prevState => ({ ...prevState, [selectedSession.id]: false }));
            setEnteredPassword('');
        } else {
            window.location.reload();
        }
    };



//     const handleConfirmClick = async () => {
//         if (enteredPassword === 'password') {
//             try {
//                 await DataStore.delete(selectedSession);
//                 console.log('Session deleted successfully');
//                 fetchSessions();
//             } catch (error) {
//                 console.error('Error deleting session', error);
//             }
//             setShowPasswordInputs(prevState => ({ ...prevState, [selectedSession.id]: false }));
//             setEnteredPassword('');
//         } else {
// window.location.reload();        }
//     };

    const handleNextDayClick = () => {
        const tomorrow = format(addDays(new Date(), 1), 'yyyy-MM-dd');
        setStartDate(tomorrow);
        setShowComponent(true);
    };

    const handleSpace = () => {
        const today = format(new Date(), 'yyyy-MM-dd');
        setDate(today);
        setShowNew(true);
    };

    if (showComponent) {
        return <NextDaySession startDate={startDate} />;
    }

    if (showNew) {
        return <TableSlots date={date} />;
    }

    const handleMessageClick = (session, event) => {
        event.preventDefault();
        event.stopPropagation();

        setMessage(session.Email);
        setShowMessage(true);
    }

    if (showMessage) {
        return <MessagesComponent reply={message} />;
    }


    return (
        <div>
            <div className="md:flex md:items-center md:justify-between mb-5">
            <div className="md:flex md:items-center md:justify-between mb-5">
      <div className="min-w-0 flex-1">
      <div className="border-b border-gray-200 bg-blue-50 px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold text-center leading-6 text-gray-900">Future Bookings</h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          
        </div>
        </div>
        </div>
        </div>
        </div>
      <div className="mt-4 flex md:ml-4 md:mt-0">
       
        <button onClick={handleNextDayClick} type="button" className="rounded-full bg-orange-600 ml-3 mr-3 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <CalendarDaysIcon className="h-5 w-5" aria-hidden="true" />
                </button>
               
      </div>
    </div>
           

            {sessions.length === 0 && <p className="text-center mt-5 text-lg">No future bookings</p>}

                <ul role="list" className="divide-y divide-gray-100 mt-5">
                    {sessions.map((session) => {
                        const timeTillSession = differenceInMinutes(parse(session.TimeslotFrom, 'HH:mm:ss', new Date()), new Date());
                        const isLate = timeTillSession < 0;
                        return (
                            <li key={session.id} className="flex mt-5 flex-col justify-between gap-y-4 py-5 px-4 rounded-lg bg-blue-100 shadow-lg">
                                <div className="flex justify-between items-start">
                                    <div className="flex flex-col">
                                        <p className="text-md font-bold leading-6 text-gray-900">{session.Name}</p>
                                        <p className="mt-1 text-md font-semibold leading-5 text-black">{session.Email}</p>
                                        <p className="mt-1 text-md font-semibold leading-5 text-black">{session.Children} Children</p>
                                        <p className="mt-1 text-md font-semibold leading-5 text-black">{session.Adults} Adults</p>
                                        <p className="mt-1 text-md font-semibold leading-5 text-black">{session.Telephone}</p>
                                        <p className="mt-1 text-md font-semibold leading-5 text-black">Booked At Table {session.Table}</p>
                                        <p className="mt-1 text-md font-semibold leading-5 text-black">Total: £ {session.TotalSpent}</p>
                                        {session.ChildData && session.ChildData.length > 0 && (
    <div>
        {session.ChildData.map((child, index) => (
            <p key={index} className="mt-1 text-md font-semibold leading-5 text-black">
                Child Age: {child.childAge}
            </p>
        ))}
    </div>
)}
                                    </div>
                                    <div className={`flex items-center justify-center h-20 w-20 rounded-full ${isLate ? 'bg-red-500 animate-pulse' : 'bg-green-500'}`}>
                                        <span className="text-white">{isLate ? 'Late' : `${timeTillSession} Mins`}</span>
                                    </div>
                                </div>
                                <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleArrivedClick(session)}>
                                    Arrived
                                </button>
                               
                                <button className="mt-4 bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleArrivedClick(session)}>
                                    Edit
                                </button>
                                <button className="mt-4 bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event) => handleDeleteClick(session, event)}>
                                    Cancel
                                </button>
                                {/* <button className="mt-4 bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event) => handleMessageClick(session, event)}>
                                    Send Message
                                </button> */}
                                {showPasswordInputs[session.id] && (
                                    <div>
                                        <input
                                            type="password"
                                            placeholder="Enter password"
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            value={enteredPassword}
                                            onChange={(e) => setEnteredPassword(e.target.value)}
                                        />
                                        <button onClick={handleConfirmClick}>
                                            Confirm
                                        </button>
                                    </div>
                                )}
                                <div className="flex flex-col">
                                    <p className="text-md leading-5 text-gray-500">
                                        Date: {session.Date}
                                    </p>
                                    <p className="mt-1 text-xl font-bold leading-5 text-black">
                                        Timeslot: {session.TimeslotFrom} - {session.TimeslotTo}
                                    </p>
                                    <p className="mt-1 text-md font-semibold leading-5 text-black">
    Time Booked: {format(new Date(session.createdAt), 'dd-MM-yyyy HH:mm:ss')}
</p>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            
        </div>
    );
}
