import { useState, useEffect } from "react";

export default function Pin() {

    const [pin, setPin] = useState("");

    useEffect(() => {

        const getPin = async () => {
            try {
                const response = await fetch(`https://cheekydino.com/api/pin`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setPin(data.data);

            } catch (error) {
                console.error('Error fetching pin:', error);
            }
        };


        getPin();

    }, []);



    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>Pin</h4>
                            </div>
                            <div className="card-body">
                                <p>Pin: {pin}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        


    
    );
}







