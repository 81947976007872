import { useState, useEffect } from "react";
import { format } from "date-fns";

export default function Leave() {
  const [finishedTables, setFinishedTables] = useState([]); // Store table numbers with finished sessions
    const [clockedInStaff, setClockedInStaff] = useState([]); // Store clocked in staff

  useEffect(() => {
    const getTodaysBookings = async () => {
      try {
        const today = format(new Date(), "yyyy-MM-dd");

        const response = await fetch(
          `https://cheekydino.com/api/sessionbookings?date=${today}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          }
        );

        const sessionsData = await response.json();
        const sessions = sessionsData.data;

        // Filter the sessions array to only include sessions for today
        const todaysBookings = sessions.filter(
          (session) => session.Arrived === 1 && session.LeftCenter === 0
        );

        // Get the current time in hh:mm:ss format
        const currentTime = format(new Date(), "HH:mm:ss");

        // Find tables where the session has finished (TimeslotTo is less than currentTime)
        const finished = todaysBookings
          .filter((booking) => currentTime > booking.TimeslotTo)
          .map((booking) => booking.Table);

        // Update state with the table numbers of finished sessions
        setFinishedTables(finished);

      } catch (error) {
        console.error("Error getting today's bookings:", error);
      }
    };

    getTodaysBookings();

    const intervalId = setInterval(() => {
      getTodaysBookings();
    }, 30000); // Refresh bookings every 5 seconds

    // Cleanup function to clear interval
    return () => clearInterval(intervalId);

  }, []);


  


  return (
    <div className="relative">
      {/* Only show ticker if there are finished tables */}
      {finishedTables.length > 0 && (
        <div className="bg-red-500 text-white p-2 rounded-lg overflow-hidden whitespace-nowrap">
          <div className="animate-marquee inline-block">
            Table {finishedTables.join(', ')} session has finished
          </div>
        </div>
      )}

      {/* Inline CSS styles for marquee animation */}
      <style>{`
  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }

  .animate-marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
`}</style>

    </div>
  );
}
