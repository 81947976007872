import { useState, useEffect } from "react";
import { startOfDay, addDays, format } from 'date-fns';
import StaffNav from "./staffNav";

export default function PartyStock() {
    const [parties, setParties] = useState([]);
    const [guests, setGuests] = useState([]);
    const [kitchenData, setKitchenData] = useState([]);
    const [today, setToday] = useState('');
    const [nextWeek, setNextWeek] = useState('');

    useEffect(() => {
        const fetchPartyStock = async () => {
            try {
                // Step 1: Fetch Today's Date and Add 7 Days to It
                const today = startOfDay(new Date());
                const nextWeek = addDays(today, 7);
                const todayFormatted = format(today, 'yyyy-MM-dd');
                const nextWeekFormatted = format(nextWeek, 'yyyy-MM-dd');
                console.log('Step 1 - Dates:', { todayFormatted, nextWeekFormatted });

                setToday(todayFormatted);
                setNextWeek(nextWeekFormatted);

                // Step 2: Fetch All Party Bookings
                const response = await fetch(`https://cheekydino.com/api/allpartybookings`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                const Parties = data.data.filter(booking => booking.PartyDate >= todayFormatted && booking.PartyDate <= nextWeekFormatted);
                console.log('Step 2 - Filtered Parties:', Parties);
                setParties(Parties);

                // Step 3: Extract the IDs of Each Party
                const partyIds = Parties.map(party => party.id);
                console.log('Step 3 - Party IDs:', partyIds);

                // Step 4: Fetch All Guests for Each Party
                const guestsPromises = partyIds.map(async (partyId) => {
                    const guestResponse = await fetch(`https://cheekydino.com/api/partyguestsid/${partyId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!guestResponse.ok) {
                        throw new Error(`Failed to fetch guests for party ID ${partyId}, Status: ${guestResponse.status}`);
                    }

                    const guestData = await guestResponse.json();
                    console.log(`Step 4 - Guests for Party ID ${partyId}:`, guestData);
                    return guestData.data; // Ensure you are extracting the 'data' property from the response
                });

                const allGuests = await Promise.all(guestsPromises);
                const flattenedGuests = allGuests.flat();
                console.log('Step 4 - All Guests:', flattenedGuests);
                setGuests(flattenedGuests);

                // Step 5: Fetch the Kitchen Menu
                const kitchenMenuResponse = await fetch(`https://cheekydino.com/api/partymenu`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!kitchenMenuResponse.ok) {
                    throw new Error(`HTTP error! Status: ${kitchenMenuResponse.status}`);
                }

                const kitchenMenu = await kitchenMenuResponse.json();
                const kitchenMenuData = kitchenMenu.data;
                console.log('Step 5 - Kitchen Menu Data:', kitchenMenuData);

                // Step 6: Match FoodOptionId with Kitchen Menu and Update Stock Levels
                const updatedStockLevels = kitchenMenuData.map(item => {
                    // Ensure item.id and item.StockLevel are correct
                    console.log(`Matching for Kitchen Menu Item ID: ${item.id}`);

                    // Count guests by FoodOptionId
                    const foodOptionCount = flattenedGuests.reduce((acc, guest) => {
                        console.log(`Comparing Guest FoodOptionId: ${guest.FoodOptionId} with Kitchen Item ID: ${item.id}`);
                        if (guest.FoodOptionId === item.id) {
                            return acc + 1;
                        }
                        return acc;
                    }, 0);

                    const initialStockLevel = parseInt(item.StockLevel, 10) || 0;
                    const updatedStock = initialStockLevel - foodOptionCount;

                    console.log(`Step 6 - Item: ${item.Name}, Initial: ${initialStockLevel}, Count: ${foodOptionCount}, Updated: ${updatedStock}`);

                    return {
                        ...item,
                        InitialStockLevel: initialStockLevel,
                        UpdatedStockLevel: updatedStock
                    };
                });

                console.log('Step 6 - Updated Stock Levels:', updatedStockLevels);
                setKitchenData(updatedStockLevels);

            } catch (error) {
                console.error('Failed to fetch party stock', error);
            }
        }

        fetchPartyStock();
    }, []);

    const getStockStatusClass = (initialStockLevel) => {
        if (initialStockLevel < 20) return 'bg-red-500 text-white'; // Red
        if (initialStockLevel < 30) return 'bg-orange-500 text-white'; // Orange
        if (initialStockLevel < 40) return 'bg-yellow-500 text-black'; // Yellow
        return 'bg-green-500 text-white'; // Green
    };

    const totalParties = parties.length;
    const totalGuests = guests.length;

    const stats = [
        { name: 'Total Parties', value: totalParties },
        { name: 'Total Guests', value: totalGuests },
        { name: 'Total Stock Items', value: kitchenData.length },
        { name: 'Total Stock Items Requiring Reorder', value: kitchenData.filter(item => item.InitialStockLevel < 20).length },
    ];

    return (
        <>
        <StaffNav/>
        <div className="p-6">
            {/* Header showing total parties and guests */}
           
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Party Stock</h2>
            <p className="mt-4 text-xl leading-7 text-gray-300">
                Here are the current stock levels for your upcoming parties.
            </p>
            <p className="mt-4 text-xl leading-7 text-gray-300">
            {today} & {nextWeek}
            </p>


         
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
            {/* Kitchen Menu Grid List */}
            <ul role="list" className="divide-y divide-gray-100">
                {kitchenData.map((item) => (
                    <li
                        key={item.id}
                        className={`flex justify-between mt-5 p-4 rounded-lg mt-5 shadow-md gap-x-6 py-5 ${getStockStatusClass(item.InitialStockLevel)}`}
                    >
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{item.Name}</p>
                            <p className="mt-1 text-sm text-semi-bold leading-5 text-black">
                                Current Stock Level: {item.InitialStockLevel}
                            </p>
                            <p className="mt-1 text-sm text-semi-bold leading-5 text-black">
                                Stock Level After Party: {item.UpdatedStockLevel}
                            </p>
                            {item.InitialStockLevel < 20 && (
                                <p className="mt-2 text-xs font-semibold text-white">Reorder Stock</p>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        </>
    )
}
