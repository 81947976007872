import React, { useState, useEffect } from 'react';
import { format, addHours, parseISO, isWithinInterval } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Modal2 from './modal2';

export default function Arrival({ session }) {
  const [arrival, setArrival] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addGuests, setAddGuests] = useState(false);
  const [adults, setAdults] = useState(session.Adults);
  const [children, setChildren] = useState(session.Children);
  const [childData, setChildData] = useState(session.ChildData || [{ age: '' }]);
  const [totalSpent, setTotalSpent] = useState(session.TotalSpent);
  const [childPrice, setChildPrice] = useState([]);
  const [adultPrice, setAdultPrice] = useState([]);
  const [additionalAdultPrice, setAdditionalAdultPrice] = useState([]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/prices', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const prices = data.data;

        const today = new Date();
        const dayOfWeek = format(today, 'EEEE');
        const currentTime = format(today, 'HH:mm:ss');

        const convertDaysToArray = (daysString) => {
          if (!daysString) return [];
          return daysString.split(',').map(day => day.trim());
        };

        const sessionDataDays = prices.filter((price) => {
          const daysArray = convertDaysToArray(price.Days);
          return (
            price.SetDay === 1 &&
            price.Active === 1 &&
            price.Days &&
            daysArray.includes(dayOfWeek) &&
            price.StartTime <= currentTime &&
            price.EndTime >= currentTime
          );
        });

        const sessionDataDates = prices.filter((price) => {
          const startDate = price.StartDate ? parseISO(price.StartDate) : null;
          const expiryDate = price.ExpiryDate ? parseISO(price.ExpiryDate) : null;
          return (
            price.SetDay === 0 &&
            price.Active === 1 &&
            price.Category &&
            startDate &&
            expiryDate &&
            isWithinInterval(today, { start: startDate, end: expiryDate }) &&
            price.StartTime <= currentTime &&
            price.EndTime >= currentTime
          );
        });

        const allSessionData = [...sessionDataDays, ...sessionDataDates];

        setChildPrice(allSessionData.filter(price => price.Category === "Children"));
        setAdultPrice(allSessionData.filter(price => price.Category === "Adults"));
        setAdditionalAdultPrice(allSessionData.filter(price => price.Category === "AdditionalAdults"));

      } catch (error) {
        console.error('Failed to fetch prices', error);
      }
    };

    fetchPrices();
  }, []);

  const calculatePrice = (childData, adults, children) => {
    let totalPrice = 0;

    childData.forEach(data => {
      const childPriceValue = parseFloat(data.age) || 0;
      totalPrice += childPriceValue;
    });

    const additionalAdults = adults - children;
    if (additionalAdults > 0) {
      const additionalAdultPriceValue = adultPrice.find(price => price.Category === "Adults")?.Price;
      totalPrice += additionalAdults * (parseFloat(additionalAdultPriceValue) || 0);
    }

    return totalPrice;
  };

  useEffect(() => {
    if (addGuests) {
      setTotalSpent(calculatePrice(childData, adults, children));
    } else {
      setTotalSpent(session.TotalSpent);
    }
  }, [addGuests, adults, children, childData]);

  const navigate = useNavigate();

  const handleAdultsChange = (value) => {
    setAdults(Math.max(0, value));
  };

  const handleChildrenChange = (value) => {
    setChildren(Math.max(0, value));
    setChildData(Array.from({ length: value }, () => ({ age: '' })));
  };

  const handleChildAgeChange = (index, value) => {
    setChildData((prev) =>
      prev.map((data, i) => (i === index ? { ...data, age: value } : data))
    );
  };

  const confirmArrival = async () => {
    const currentTime = new Date();
    const formattedTime = format(currentTime, 'HH:mm:ss');
    const arrivalTime = format(currentTime, 'HH:mm:ss');
    const twoHoursLater = addHours(currentTime, 2);
    const formattedTwoHoursLater = format(twoHoursLater, 'HH:mm:ss');
    const today = format(currentTime, 'yyyy-MM-dd');

    try {
      const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch sessions');
      }

      const allSessions = await response.json();

      const conflictingSessions = allSessions.data.filter(
        (s) =>
          s.Table === session.Table &&
          s.TimeslotFrom < formattedTwoHoursLater &&
          s.TimeslotTo > formattedTime &&
          s.Date === session.Date &&
          s.id !== session.id
      );

      if (conflictingSessions.length > 0) {
        setShowModal(true);
      } else {
        try {
          const updatedSession = {
            id: session.id,
            Table: session.Table,
            Date: session.Date,
            Arrived: 1,
            TimeArrived: arrivalTime,
            LeftCenter: 0,
            TimeslotFrom: formattedTime,
            TimeslotTo: formattedTwoHoursLater,
            Adults: adults,
            Children: children,
            EntranceFee: totalSpent,
            
            TotalSpent: totalSpent,
            Email: session.Email,
            Name: session.Name,
            ChildData: childData,
          };

          const postResponse = await fetch('https://cheekydino.com/api/addsessionbooking', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSession),
          });

          if (!postResponse.ok) {
            throw new Error('Failed to save session');
          }

          setArrival(true);
          console.log('Session saved successfully!');
        } catch (error) {
          console.error('Error saving session:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
  };

  if (arrival === true) {
    navigate('/prebooktill', {
      state: {
        order: '2 Hour Session',
        total: totalSpent,
        table: session.Table,
        ChildName: session.Name,
      },
    });
  }

  const guests = adults + children;

  if (showModal) {
    return <Modal2 session={session}></Modal2>;
  }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Session Details</h3>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.Name}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Number of Guests</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{guests}</dd>
          </div>
          <button
            onClick={() => setAddGuests(true)}
            type="button"
            className="rounded-md mr-2 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adjust Guests
          </button>
        </dl>
      </div>
      {addGuests && (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <div className="flex flex-col">
            <label htmlFor="adults" className="text-sm font-medium text-gray-900">
              Adults
            </label>
            <input
              id="adults"
              type="number"
              value={adults}
              onChange={(e) => handleAdultsChange(e.target.value)}
              className="mt-1 block w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="children" className="text-sm font-medium text-gray-900">
              Children
            </label>
            <input
              id="children"
              type="number"
              value={children}
              onChange={(e) => handleChildrenChange(e.target.value)}
              className="mt-1 block w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            onClick={() => setAddGuests(false)}
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Cancel
          </button>
        </div>
      )}
      {addGuests && children > 0 && (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          {childData.map((data, index) => (
            <div key={index} className="space-y-2">
              <label htmlFor={`child-age-${index}`} className="block text-sm font-medium text-gray-900">
                Child's Age- Do Not Select Sibling First
              </label>
              <select
                onChange={(e) => handleChildAgeChange(index, e.target.value)}
                id={`child-age-${index}`}
                name={`child-age-${index}`}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select an age</option>
                {childPrice.map((price) => (
                  <option key={price.id} value={price.Price}>
                    {price.Name}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      )}
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Session Id</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.id}</dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Date</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.Date}</dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">TimeSlot From</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.TimeslotFrom}</dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">TimeSlot To</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.TimeslotTo}</dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Email address</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.Email}</dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Table</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{session.Table}</dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Child Ages</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {session.ChildData && session.ChildData.length > 0 && (
            <div>
              {session.ChildData.map((child, index) => (
                <p key={index} className="mt-1 text-md font-semibold leading-5 text-black">
                  Child Age: {child.age}
                </p>
              ))}
            </div>
          )}
        </dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Total To Pay Now</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">£{totalSpent}</dd>
      </div>
      <button
        onClick={confirmArrival}
        type="button"
        className="rounded-full bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Confirm Arrival
      </button>
    </div>
  );
}
