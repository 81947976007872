import { useEffect, useState } from "react";
import {format} from 'date-fns';

const ukBankHolidays = [
  { name: "New Year's Day", date: "January 1" },
  { name: "Good Friday", date: "variable" },
  { name: "Easter Monday", date: "variable" },
  { name: "Early May Bank Holiday", date: "First Monday of May" },
  { name: "Spring Bank Holiday", date: "Last Monday of May" },
  { name: "Battle of the Boyne (Orangemen's Day)", date: "July 12" },
  { name: "Summer Bank Holiday", date: "First Monday of August" },
  { name: "Summer Bank Holiday", date: "Last Monday of August" },
  { name: "St Andrew's Day", date: "November 30" },
  { name: "Christmas Day", date: "December 25" },
  { name: "Boxing Day", date: "December 26" }
];

export default function ClosedDates() {
  const [closedDateModalOpen, setClosedDateModalOpen] = useState(false);
  const [closedDates, setClosedDates] = useState([]);
  const [closedDate, setClosedDate] = useState('');
  const [notes, setNotes] = useState('');
  const [holiday, setHoliday] = useState('');
  const [bankHoliday, setBankHoliday] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchClosedDates = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/closed', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("ClosedDates data from API:", data);
        setClosedDates(data.data);
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchClosedDates();
  }, [reload]);

  const handleAddClosedDate = async () => {
    if (closedDate) {
      const updatedDates = [...closedDates, { Date: closedDate, Notes: notes, Holiday: holiday }];
      setClosedDates(updatedDates);
      setClosedDateModalOpen(false);

      try {
        const response = await fetch('https://cheekydino.com/api/addclose', {
          method: 'POST', // Use PUT to update existing data
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ClosedDate: closedDate, Notes: notes, Holiday: holiday }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('Closed date saved successfully.');
        setReload(!reload); // Toggle reload to refresh the closed dates
        setClosedDateModalOpen(false);
      } catch (error) {
        console.error('There was an error saving the closed date!', error);
      }
    }
  };

  const checkHoliday = (date) => {
    // Simplify date format to match with UK Bank Holidays
    const formattedDate = new Date(date).toLocaleDateString('en-GB'); // "dd/mm/yyyy"
    const matchingHoliday = ukBankHolidays.find(holiday =>
      new Date(holiday.date).toLocaleDateString('en-GB') === formattedDate
    );
    return matchingHoliday ? matchingHoliday.name : '';
  };

  const formatDate = (date) => {
    return format(parseISO(date), 'yyyy-MM-dd');
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Closed Dates</h1>
          <p className="mt-2 text-sm text-gray-700">
            Add or remove dates that your business will be closed.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex justify-between">
          <button
            onClick={() => setClosedDateModalOpen(true)}
            type="button"
            className="block rounded-md bg-green-600 px-3 py-2 ml-5 mr-5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          >
            Add Closed Date
          </button>
          <button
            onClick={() => setBankHoliday(true)}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            View UK Bank Holidays
          </button>
        </div>
      </div>

      {closedDateModalOpen && (
        <div className="mt-10 flex flex-col lg:flex-row">
          <div className="lg:w-1/2 lg:pr-4">
            <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
              Add Closed Date
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => {
                  setClosedDate(e.target.value);
                  setHoliday(checkHoliday(e.target.value)); // Check if the selected date is a UK Bank Holiday
                }}
                id="date"
                name="date"
                type="date"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <label htmlFor="notes" className="block mt-4 text-sm font-medium leading-6 text-gray-900">
              Notes
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setNotes(e.target.value)}
                id="notes"
                name="notes"
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <button
              onClick={handleAddClosedDate}
              type="button"
              className="mt-4 block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Save Closed Date
            </button>
          </div>

          {bankHoliday && (
            <div className="lg:w-1/2 lg:pl-4 mt-10 lg:mt-0">
              <h1 className="text-base font-semibold leading-6 text-gray-900">UK Bank Holidays</h1>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5">
                {ukBankHolidays.map((holiday) => (
                  <div
                    key={holiday.name}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="min-w-0 flex-1">
                      <span aria-hidden="true" className="absolute inset-0" />
                      <p className="text-sm font-medium text-gray-900">{holiday.name}</p>
                      <p className="truncate text-sm text-gray-500">{holiday.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

{bankHoliday && (
            <div className="lg:w-1/2 lg:pl-4 mt-10 lg:mt-0">
              <h1 className="text-base font-semibold leading-6 text-gray-900">UK Bank Holidays</h1>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5">
                {ukBankHolidays.map((holiday) => (
                  <div
                    key={holiday.name}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="min-w-0 flex-1">
                      <span aria-hidden="true" className="absolute inset-0" />
                      <p className="text-sm font-medium text-gray-900">{holiday.name}</p>
                      <p className="truncate text-sm text-gray-500">{holiday.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-10">
        {closedDates.map((date, index) => (
          <div
            key={index}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-blue-50 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="min-w-0 flex-1">
              <span aria-hidden="true" className="absolute inset-0" />
              <p className="text-sm font-medium text-gray-900">Closed on</p>
              <p className="truncate text-sm text-purple-500">           {format(new Date(date.ClosedDate), 'yyyy-MM-dd')}
              </p>
              <p className="truncate text-sm text-gray-500">{date.Notes}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
