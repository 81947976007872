import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import TableSelect from './TableSelect';
import { format, addHours, set } from 'date-fns';
import SessionBooker from './sessionbooker';
import StaffCalenderParty from './PartyStaffCalendar';
import StaffTill from './StaffTill';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Full from './full';
import { subMinutes, parseISO } from 'date-fns';
import StaffNav from './staffNav';
import OccupiedTables from './occupiedtables';



// check if running in electron environment

let ipcRenderer = null;
if (window && window.process && window.process.type) {
  ipcRenderer = window.require('electron').ipcRenderer;
}





export default function SessionBook() {
  const [children, setChildren] = useState(0);
  const [adults, setAdults] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [date, setDate] = useState("");
  const [name, setName] = useState("")
  const [age, setAge] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [availableTables, setAvailableTables] = useState([]);
  const [truee, setTrue] = useState(false)
  const [details, setDetails] = useState({})
  const [staff, setStaff] = useState("")
  const [previousName, setPreviousName] = useState(name);
  const [previousEmail, setPreviousEmail] = useState(email);
  const [previousNumber, setPreviousNumber] = useState(number);
  const [previousChildren, setPreviousChildren] = useState(children);
  const [previousAdults, setPreviousAdults] = useState(adults);
  const [previousDate, setPreviousDate] = useState(date);
  const [previousAge, setPreviousAge] = useState(age);
  const [previousStaff, setPreviousStaff] = useState(staff);
  const [sessions, setSessions] = useState([]);
const [selectedSession, setSelectedSession] = useState(null);
const [menu, setMenu] = useState(false);
const [alert, setAlert] = useState(false);
const [tableData, setTableData] = useState([]);
const [registration, setRegistration] = useState("");
const [previousRegistration, setPreviousRegistration] = useState(registration);
const [childPrice, setChildPrice] = useState([]);
const [adultPrice, setAdultPrice] = useState([]);
const [siblingPrice, setSiblingPrice] = useState([]);
const [additionalAdultPrice, setAdditionalAdultPrice] = useState([]);
const [isPinCorrect, setIsPinCorrect] = useState(false);
const [showWarning, setShowWarning] = useState(false);


const inputRef = useRef(null); // Create a ref for the input




console.log(email)


  const navigate = useNavigate();

 
  

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/admin');
        const data = await response.json();
        const parsedTableData = JSON.parse(data.data[0].TableData); // JSON.parse here
        setTableData(parsedTableData);
      } catch (error) {
        console.error('Error fetching admin data:', error);
      }
    };

    fetchAdminData();
  }, []);
   


const [childData, setChildData] = useState(Array.from({ length: 1 }, () => ({ childAge: '' }))); // Initialize with 1 child



  console.log(childData)


  const handleBack = () => {
    setName(previousName);
    setEmail(previousEmail);
    setNumber(previousNumber);
    setChildren(0);
    setAdults(0);
    setDate(previousDate);
    setAge(previousAge);
    setStaff(previousStaff);
    setTrue(false);
    setRegistration(previousRegistration)

    setChildData(Array.from({ length: 0 }, () => ({ childAge: '' }))); // Set to 0 children



  }



  
console.log(childData)

  const handleTableSelect = (selectedTables) => {
    // do nothing
  };


  const handleChildrenChange = (e) => {
    const value = e.target.value;
    setChildren(value);
    setPreviousChildren(value);

    // Reset childData and childAges when children change
    setChildData(Array.from({ length: 0 }, () => ({ childAge: '' }))); // Reset childData
  };
  

  useEffect(() => {
    // Update childData when the number of children or adults changes
    setChildData(
      Array.from({ length: children }, () => ({ childAge: '' }))
    );
  }, [children, adults]); // Trigger the effect when children or adults change
  
  
  console.log(children, adults, date)

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/prices', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const prices = data.data;

        // Get today's date and time
        const today = new Date();
        const dayOfWeek = format(today, 'EEEE');
        const currentTime = format(today, 'HH:mm:ss');

        // Helper function to convert days string to an array
        const convertDaysToArray = (daysString) => {
          if (!daysString) return []; // Handle null or undefined
          return daysString.split(',').map(day => day.trim());
        };

        // Filtering based on SetDay === 1 and Days field
        const sessionDataDays = prices.filter((price) => {
          const daysArray = convertDaysToArray(price.Days);
          return (
            price.SetDay === 1 && price.Active === 1 &&
            price.Days &&
            daysArray.includes(dayOfWeek) &&
            price.StartTime <= currentTime &&
            price.EndTime >= currentTime
          );
        });

        // Filtering based on SetDay === 0, StartDate, and ExpiryDate
        const sessionDataDates = prices.filter((price) => {
          const startDate = price.StartDate ? parseISO(price.StartDate) : null;
          const expiryDate = price.ExpiryDate ? parseISO(price.ExpiryDate) : null;
          return (
            price.SetDay === 0 && price.Active === 1 && 
            price.Category &&
            startDate &&
            expiryDate &&
            isWithinInterval(today, { start: startDate, end: expiryDate }) &&
            price.StartTime <= currentTime &&
            price.EndTime >= currentTime
          );
        });

        // Combine and set the prices based on categories
        const allSessionData = [...sessionDataDays, ...sessionDataDates];
        
        setChildPrice(allSessionData.filter(price => price.Category === "Children"));
        setAdultPrice(allSessionData.filter(price => price.Category === "Adults"));
        setSiblingPrice(allSessionData.filter(price => price.Category === "Siblings"));
        setAdditionalAdultPrice(allSessionData.filter(price => price.Category === "AdditionalAdults"));

      } catch (error) {
        console.error('Failed to fetch prices', error);
      }
    };

    fetchPrices();
  }, []);



console.log(childPrice, adultPrice, siblingPrice, additionalAdultPrice)



      



const handleNowSubmit = async () => {
  // Helper function to calculate total price
  const calculatePrice = (childData, adults, children) => {
    let totalPrice = 0;

    // Sum all child prices from childData
    childData.forEach(data => {
      const childPriceValue = parseFloat(data.childAge) || 0; // Get price from childData
      totalPrice += childPriceValue;
    });

    // Calculate additional adult price if there are more adults than children
    const additionalAdults = adults - children;
    if (additionalAdults > 0) {
      const additionalAdultPriceValue = adultPrice.find(price => price.Category === "Adults")?.Price;
      totalPrice += additionalAdults * (parseFloat(additionalAdultPriceValue) || 0);
    }

    return totalPrice;
  };

  const today = new Date();
  const nowString = format(today, 'HH:mm');
  const dateString = format(today, 'yyyy-MM-dd');
  const twoHoursLater = addHours(today, 2);
  const twoHoursLaterString = format(twoHoursLater, 'HH:mm');

  const nowDate = format(new Date(), 'yyyy-MM-dd');


  try {
    const bookings = await fetch(`https://cheekydino.com/api/sessionbookings?date=${nowDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },

    });

    if (!bookings.ok) {
      throw new Error(`HTTP error! Status: ${bookings.status}`);
    }


    const bookingData = await bookings.json();
    const bookingsToday = bookingData.data

    console.log('Bookings today:', bookingsToday);

    const guests = children + adults;

    const availableTablesForTimeslot = tableData.filter(table => {
      const isBooked = bookingsToday.some(booking => {
        const { TimeslotFrom, TimeslotTo, Table, LeftCenter, Arrived } = booking;
        const bookingStartTime = new Date(`1970-01-01T${TimeslotFrom}`);
        const tenMinsBeforeBooking = subMinutes(bookingStartTime, 10);
        const currentTime = new Date();
    
        console.log('Booking:', booking);
    
        // First, check if the booking has Arrived and not LeftCenter, which overrides timeslot checks.
        const hasArrivedButNotLeft = Arrived === 1 && LeftCenter === 0;
    
        // If not in override state, apply timeslot constraints.
        const withinTimeslot = 
          currentTime >= tenMinsBeforeBooking &&
          TimeslotTo > format(currentTime, 'HH:mm:ss');
    
        return (
          Table === table.table &&
          (hasArrivedButNotLeft || withinTimeslot)
        );
      });
    
      return !isBooked;
    });
    
    

    console.log(availableTablesForTimeslot);

    const totalPrice = calculatePrice(childData, adults, children);

    if (totalPrice > 0) {
      setAvailableTables(availableTablesForTimeslot);
      setDetails({
        Name: name,
        Email: email,
        Number: number,
        Children: children,
        Adults: adults,
        TimeSlotFrom: nowString,
        TimeSlotTo: twoHoursLaterString,
        Telephone: number,
        Total: totalPrice,
        Staff: staff,
        CarReg: registration,
        ChildData: childData,
      });
      setTrue(true);
    } else {
      setAlert(true);
    }
  } catch (error) {
    console.error('Error fetching bookings:', error);
  }
};


  
  
  
    
  const handleChildAgeChange = (index, value) => {
    console.log(`Child ${index} age changed to ${value}`);
  
    setChildData((prev) =>
      prev.map((data, i) => (i === index ? { ...data, childAge: value } : data))
    );
  
    // Log the updated childData state
    console.log(childData);
  };
  

  const handleSelectedChange = (selectedStaff) => {
    console.log('Selected staff member:', selectedStaff);
    setStaff(selectedStaff.StaffName);

    // Assuming you want to show the rest of the component only when a valid staff is selected
    if (selectedStaff.StaffId === 'Barry@Cheekydino.co.uk' || selectedStaff.TillPin) {
      setIsPinCorrect(true); // Grant access if it's Barry or if TillPin exists
    }
  };



  useEffect(() => {
    // Automatically focus on the input when isPinCorrect changes
    if (inputRef.current && isPinCorrect !== false) {
      inputRef.current.focus();
    }
  }, [isPinCorrect]); // Dependency on isPinCorrect to re-focus the input on its change

 

  const AutoFill = () => {
    const session = sessions.find(session => session.id === selectedSession);
    if (session) {
      setName(session.Name);
      console.log(session.Name)
setNumber(session.Number);

      setChildren(session.Children);
      setChildData(Array.from({ length: session.Children }, () => ({ age: '' })));

      console.log(session.Children)
      setAdults(session.Adults);
      console.log(session.Adults)
    }

  }

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };



  
  const validateEmail = () => {
    if (email.trim() === '') {
      setShowWarning(true); // Show warning message if empty
      inputRef.current.focus(); // Refocus on the input
    } else {
      setShowWarning(false); // Hide warning if filled
    }
  };

  


  

  // filter sessions for now or later using TimeSlotTo and TimeSlotFrom and Table 
  return (
    <>
      <StaffTill onSelectChange={handleSelectedChange} />
      <OccupiedTables />

      {isPinCorrect && (
        <div>
          {/* Alert Message */}
          {alert && (
            <div className="rounded-md bg-yellow-50 p-4 flex items-center space-x-2">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              <div>
                <h3 className="text-sm font-medium text-yellow-800">Price Cannot Be £0.00</h3>
                <button
                  onClick={() => setAlert(false) || setChildren(0)}
                  type="button"
                  className="mt-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white hover:bg-indigo-500"
                >
                  Okay
                </button>
              </div>
            </div>
          )}

          {/* Main Layout for Form and TableSelect */}
          <div className="flex flex-row bg-white space-x-4">
            {/* Left Side - Form */}
            <div className="w-1/2 p-6 border">
              {/* Back Button */}
              <motion.button
                onClick={() => navigate('/dashboard')}
                className="mb-4 p-2 border rounded-md bg-red-500 text-white hover:bg-red-900"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Back
              </motion.button>

              {/* Form Content */}
              <div>
                <label htmlFor="staff" className="block text-sm font-medium text-gray-900">
                  {staff}
                </label>

                {/* Email Field */}
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                    Email
                  </label>
                  <input
                    ref={inputRef}
                    id="email"
                    type="text"
                    name="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={validateEmail}
                    className={`mt-1 block w-full py-2 px-3 border ${
                      showWarning ? 'border-red-500' : 'border-gray-300'
                    } bg-white rounded-md shadow-sm focus:outline-none sm:text-sm`}
                  />
                  {showWarning && (
                    <p className="text-red-500 text-sm mt-1">Please complete the email field.</p>
                  )}
                </div>

                {/* Name Field */}
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                    Adult Name
                  </label>
                  <input
                    onChange={(e) => setName(e.target.value) || setPreviousName(e.target.value)}
                    id="name"
                    type="text"
                    name="name"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                    defaultValue={name}
                    autoComplete="on"
                  />
                </div>

                {/* Phone Number Field */}
                <div>
                  <label htmlFor="number" className="block text-sm font-medium text-gray-900">
                    Telephone
                  </label>
                  <input
                    onChange={(e) => setNumber(e.target.value) || setPreviousNumber(e.target.value)}
                    id="number"
                    type="text"
                    name="number"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                    value={number}
                    autoComplete="on"
                  />
                </div>

                {/* Car Registration Field */}
                <div>
                  <label htmlFor="number" className="block text-sm font-medium text-gray-900">
                    Car Registration
                  </label>
                  <input
                    onChange={(e) => setRegistration(e.target.value) || setPreviousRegistration(e.target.value)}
                    id="reg"
                    type="text"
                    name="number"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                    value={registration}
                    autoComplete="on"
                  />
                </div>

                {/* Adults and Children Fields */}
                <div>
                  <label htmlFor="adults" className="block text-sm font-medium text-gray-900">
                    Number of Adults
                  </label>
                  <input
                    onChange={(e) => setAdults(e.target.value) || setPreviousAdults(e.target.value)}
                    id="adults"
                    type="number"
                    name="adults"
                    min={0}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                    value={adults}
                    autoComplete="on"
                  />
                </div>

                <div>
                  <label htmlFor="children" className="block text-sm font-medium text-gray-900">
                    Number of Children
                  </label>
                  <input
                    onChange={handleChildrenChange}
                    id="children"
                    type="number"
                    name="children"
                    min={0}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                    value={children}
                    autoComplete="on"
                  />
                </div>

                {/* Child Age Selection */}
                <div>
                  {childData.map((data, index) => (
                    <div key={index} className="space-y-2">
                      <label htmlFor={`child-age-${index}`} className="block text-sm font-medium text-gray-900">
                        Child's Age - Do Not Select Sibling First
                      </label>
                      <select
                        onChange={(e) => handleChildAgeChange(index, e.target.value)}
                        id={`child-age-${index}`}
                        name={`child-age-${index}`}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                      >
                        <option value="">Select an age</option>
                        {childPrice.map((price) => (
                          <option key={price.id} value={price.Price}>
                            {price.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>

                {/* Submit Button */}
                <motion.button
                  type="submit"
                  onClick={handleNowSubmit}
                  className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Book
                </motion.button>
              </div>
            </div>

            {/* Right Side - TableSelect, conditional rendering */}
            <motion.div
              className="w-1/2 p-6 border"
              initial="hidden"
              animate="visible"
              variants={variants}
            >
              {truee && (
                <TableSelect
                  availableTables={availableTables}
                  details={details}
                  onSelect={handleTableSelect}
                  handleBack={() => {
                    handleBack();
                    setTrue(false); // Hide TableSelect on back
                  }}
                />
              )}
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
}
