import { useEffect, useState } from "react";
import { format, parseISO, isWithinInterval, addDays, startOfToday } from "date-fns";
import Holiday from "./holiday";
import BookShifts from "./bookshifts";

// Helper function to format time from HH:mm:ss to HH:mm
const formatTime = (time) => time.slice(0, 5);

// Helper function to check if the shift is a day off
const isDayOff = (startTime, endTime) => startTime === '00:00:00' && endTime === '00:00:00';

export default function Shifts() {
    const [shifts, setShifts] = useState([]);
    const [showNext7Days, setShowNext7Days] = useState(true);
    const [dateRange, setDateRange] = useState([]);
    const [holiday, setHoliday] = useState(false);
    const [book, setBook] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null); // Track expanded rows
    const [password, setPassword] = useState('');
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [selectedShift, setSelectedShift] = useState(null);
    const [newStartTime, setNewStartTime] = useState('');
    const [newEndTime, setNewEndTime] = useState('');

    useEffect(() => {
        const fetchShifts = async () => {
            try {
                const response = await fetch('https://cheekydino.com/api/shifts', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch shifts');
                }
                
                const data = await response.json();

                const responseStaff = await fetch('https://cheekydino.com/api/staff', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!responseStaff.ok) {
                    throw new Error('Failed to fetch staff');
                }

                const dataStaff = await responseStaff.json();

                const shiftsData = data.data.map((shift) => {
                    const staff = dataStaff.data.find((staff) => staff.id === shift.StaffId);
                    return { ...shift, staff };
                });

                console.log(shiftsData);

                setShifts(shiftsData);
                updateDateRange(shiftsData);

            } catch (error) {
                console.error('Error fetching shifts:', error);
            }
        };

        fetchShifts();
        const interval = setInterval(() => fetchShifts(), 1000);

        return () => clearInterval(interval);
    }, []);

    const updateDateRange = (shifts) => {
        const today = startOfToday();
        const endOfPeriod = showNext7Days ? addDays(today, 7) : today;

        const dates = shifts
            .map(shift => parseISO(shift.ShiftDate))
            .filter(date => isWithinInterval(date, { start: today, end: endOfPeriod }))
            .reduce((acc, date) => {
                const formattedDate = format(date, 'dd/MM/yyyy');
                if (!acc.includes(formattedDate)) acc.push(formattedDate);
                return acc;
            }, []);

        setDateRange(dates);
    };

    const filterShifts = () => {
        const today = startOfToday();
        const endOfPeriod = showNext7Days ? addDays(today, 7) : today;

        return shifts.filter(shift => isWithinInterval(parseISO(shift.ShiftDate), { start: today, end: endOfPeriod }));
    };

    const toggleDateRange = () => {
        setShowNext7Days(!showNext7Days);
        updateDateRange(shifts);
    };

    const groupedShifts = () => {
        const shiftsByDateAndStaff = {};
        
        filterShifts().forEach(shift => {
            const staffName = shift.staff.Name;
            const role = shift.staff.Role;
            const shiftDate = format(parseISO(shift.ShiftDate), 'dd/MM/yyyy');
            
            // Check if the shift is marked as Sick or Holiday
            const isSick = shift.Sick === 1;
            const isHoliday = shift.Holiday === 1;
            
            // Set timeRange to reflect Sick, Holiday, or actual shift times
            const timeRange = isHoliday
                ? 'Holiday'
                : isSick
                ? 'Sick'
                : (isDayOff(shift.StartTime, shift.EndTime)
                    ? 'Day Off'
                    : `${formatTime(shift.StartTime)} - ${formatTime(shift.EndTime)}`);
            
            // Initialize entry for staff if not present
            if (!shiftsByDateAndStaff[staffName]) {
                shiftsByDateAndStaff[staffName] = {};
            }
            
            // Store shift details by date, including Role, Sick, and Holiday status
            shiftsByDateAndStaff[staffName][shiftDate] = {
                timeRange,
                id: shift.id,
                Sick: shift.Sick,
                Holiday: shift.Holiday,
                Role: role
            };
        });
    
        return shiftsByDateAndStaff;
    };
    
    
    

    const shiftsGrouped = groupedShifts();

    const handleRowToggle = (shiftId) => {
        setExpandedRow(expandedRow === shiftId ? null : shiftId);
        setPassword('');
        setPasswordVerified(false);
        setNewStartTime('');
        setNewEndTime('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        const correctPassword = '8568'; // Replace with your desired password
        if (password === correctPassword) {
            setPasswordVerified(true);
        } else {
        }
    };

    const handleDeleteShift = async (shiftId) => {
        if (window.confirm('Are you sure you want to delete this shift?')) {
            try {
                const response = await fetch(`https://cheekydino.com/api/shifts/delete/${shiftId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to delete shift');
                }

                // Refresh shifts after deletion
                const updatedShifts = shifts.filter(shift => shift.id !== shiftId);
                setShifts(updatedShifts);
                setExpandedRow(null);
                setPassword('');
                setPasswordVerified(false);

            } catch (error) {
                console.error('Error deleting shift:', error);
            }
        }
    };

    const handleSickShift = async (shiftId) => {

        const response = await fetch(`https://cheekydino.com/api/shifts/sick/${shiftId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to mark shift as sick');
        }

        // Refresh shifts after marking as sick

       setPassword('');
setExpandedRow(null);
setPasswordVerified(false);



       
       




    };

    const handleChangeShift = async (shiftId) => {
        if (!newStartTime || !newEndTime) {
            return;
        }

        try {
            const response = await fetch(`https://cheekydino.com/api/shifts/edit/${shiftId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ StartTime: newStartTime, EndTime: newEndTime  })
            });

            if (!response.ok) {
                throw new Error('Failed to change shift');
            }

            // Refresh shifts after changing
            const updatedShifts = shifts.map(shift => {
                if (shift.id === shiftId) {
                    return { ...shift, StartTime: newStartTime, EndTime: newEndTime };
                }
                return shift;
            });
            setShifts(updatedShifts);
            // Reset the input fields
            setNewStartTime('');
            setNewEndTime('');
            setExpandedRow(null); // Collapse row after updating
            setPassword('');
            setPasswordVerified(false);
        } catch (error) {
            console.error('Error changing shift:', error);
        }
    };

    if (holiday) {
        return <Holiday />;
    } 

    if (book) {
        return <BookShifts />;
    }


    // for each day show the amount of roles that are available for each date using the role property

   


    const roleCountsByDate = dateRange.reduce((acc, date) => {
        acc[date] = {}; // Initialize an object for each date
    
        Object.values(shiftsGrouped).forEach(staffShifts => {
            const shiftDetails = staffShifts[date];
            if (shiftDetails) {
                const role = shiftDetails.Role;
                acc[date][role] = (acc[date][role] || 0) + 1; // Increment the count for each role
            }
        });
    
        return acc;
    }, {});

    console.log(roleCountsByDate);


    const allRoles = Array.from(new Set(
        Object.values(roleCountsByDate).flatMap(roleCounts => Object.keys(roleCounts))
    ));
    
    // Step 2: Sort dates in ascending order
    const sortedDates = Object.keys(roleCountsByDate).sort((a, b) => new Date(a) - new Date(b));
    

    return (
        <div className="p-4">
            <button onClick={toggleDateRange} className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                {showNext7Days ? 'Show All Shifts' : 'Show Next 7 Days'}
            </button>
            <button onClick={() => setHoliday(true)} className="ml-5 rounded bg-green-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Holiday Request
            </button>
            <button onClick={() => setBook(true)} className="ml-5 rounded bg-green-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Book Shifts
            </button>
            <div className="overflow-x-auto">

       

                <table className="min-w-full divide-y divide-gray-300 bg-white">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-indigo-500">Staff Name</th>
                            {dateRange.map((date, index) => (
                                <th scope="col" key={index} className="py-3.5 px-4 text-left text-sm font-semibold text-purple-600">{date}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {Object.keys(shiftsGrouped).map(staffName => (
    <tr key={staffName}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-500">
            {staffName} - {shiftsGrouped[staffName][Object.keys(shiftsGrouped[staffName])[0]].Role}
        </td>
        
        {/* Sort dates in ascending order and map over them */}
        {dateRange.sort((a, b) => new Date(a) - new Date(b)).map(date => {
            const shiftDetails = shiftsGrouped[staffName][date];
            
            return (
                <td key={date} className={`relative whitespace-nowrap px-8 py-8 text-sm text-gray-500 ${expandedRow === shiftDetails?.id ? 'bg-gray-100' : ''}`}>
                    {shiftDetails ? (
                                                <>
                                                    <div className="flex items-center">
                                                        <span>{shiftDetails.timeRange || 'No Shift'}</span>
                                                        
                                                        <button
                                                            onClick={() => handleRowToggle(shiftDetails.id)}
                                                            className="ml-2 text-blue-500 underline"
                                                        >
                                                            Options
                                                        </button>
                                                    </div>
                                                    {expandedRow === shiftDetails.id && (
                                                        <div className="mt-2 transition-all mr-10 duration-300 ease-in-out">
                                                            {!passwordVerified ? (
                                                                <form onSubmit={handlePasswordSubmit}>
                                                                    <input
                                                                        type="password"
                                                                        placeholder="Enter Password"
                                                                        value={password}
                                                                        onChange={handlePasswordChange}
                                                                        className="p-2 border rounded w-full"
                                                                        required
                                                                    />
                                                                    <button type="submit" className="mt-2 bg-green-500 text-white rounded px-2 py-1">
                                                                        Submit
                                                                    </button>
                                                                </form>
                                                            ) : (
                                                                <div className="p-2">
                                                                    <div className="mb-2">
                                                                        <input
                                                                            type="time"
                                                                            placeholder="Start Time"
                                                                            value={newStartTime}
                                                                            onChange={(e) => setNewStartTime(e.target.value)}
                                                                            className="border p-1 rounded"
                                                                        />
                                                                        <input
                                                                            type="time"
                                                                            placeholder="End Time"
                                                                            value={newEndTime}
                                                                            onChange={(e) => setNewEndTime(e.target.value)}
                                                                            className="border p-1 rounded ml-2"
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        onClick={() => handleDeleteShift(shiftDetails.id)}
                                                                        className="block text-red-500 hover:underline"
                                                                    >
                                                                        Delete Shift
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleSickShift(shiftDetails.id)}
                                                                        className="block text-orange-500 hover:underline"
                                                                    >
                                                                        Mark Sick
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleChangeShift(shiftDetails.id)}
                                                                        className="block text-blue-500 hover:underline"
                                                                    >
                                                                        Change Shift
                                                                    </button>
                                                                    <button onClick={() => handleRowToggle(null)} className="mt-2 text-gray-500 hover:underline">
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            ) : 'No Shift'}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
        <span className="text-sm font-semibold text-gray-500 mb-2">Role Availability:</span>
        <table className="min-w-full border-collapse border border-gray-300 mt-2">
            <thead>
                <tr>
                    <th className="px-4 py-2 border border-gray-300 text-left text-sm font-semibold text-gray-500">Role</th>
                    {sortedDates.map(date => (
                        <th key={date} className="px-4 py-2 border border-gray-300 text-left text-sm font-semibold text-gray-500">
                            {date}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {/* Display each role as a row, ensuring every unique role is represented */}
                {allRoles.map(role => (
                    <tr key={role}>
                        <td className="px-4 py-2 border border-gray-300 text-sm font-semibold text-gray-500">
                            {role}
                        </td>
                        {sortedDates.map(date => (
                            <td key={`${date}-${role}`} className="px-4 py-2 border border-gray-300 text-sm font-semibold text-indigo-500">
                                {roleCountsByDate[date][role] || 0}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>

        </div>
        
    );
}
