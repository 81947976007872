import React, { useState, useEffect } from 'react';
import { Howl } from 'howler';
import { useNavigate } from 'react-router-dom';
import '../customer-pages/customerfont.css';

export default function Orders() {
  const [completedOrders, setCompletedOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const prevOrdersRef = React.useRef([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await fetch("https://cheekydino.com/api/cafeorders", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Filter orders based on conditions
        const newPendingOrders = data.data.filter(order => order.Completed === 0 && order.Delivered === 0);
        const newCompletedOrders = data.data.filter(order => order.Completed === 1 && order.Delivered === 0);

        // Sort orders by most recent first (assuming CreatedTime is a valid timestamp field)
        newPendingOrders.sort((a, b) => new Date(b.CreatedTime) - new Date(a.CreatedTime));
        newCompletedOrders.sort((a, b) => new Date(b.CreatedTime) - new Date(a.CreatedTime));

        // Check if any order changed from Completed 0 to Completed 1
        const newlyCompletedOrders = newCompletedOrders.filter(order =>
          !prevOrdersRef.current.some(prevOrder => prevOrder.id === order.id && prevOrder.Completed === 1)
        );

        if (newlyCompletedOrders.length > 0) {
          playNotificationSound();
        }

        // Update prevOrdersRef to current orders
        prevOrdersRef.current = data.data;

        setPendingOrders(newPendingOrders);
        setCompletedOrders(newCompletedOrders);
      } catch (error) {
        console.error('There was an error fetching orders!', error);
      }
    }

    // Initial fetch
    fetchOrders();

    // Polling for new orders every 5 seconds
    const interval = setInterval(fetchOrders, 5000);

    return () => clearInterval(interval);
  }, []);

  // Function to play notification sound
  const playNotificationSound = () => {
    const sound = new Howl({
      src: [`${process.env.PUBLIC_URL}/message.mp3`],
      volume: 0.5, // Adjust volume as needed
    });

    sound.play();
  };

  const toggleHotItems = (orderIndex, isCompleted) => {
    if (isCompleted) {
      const updatedOrders = [...completedOrders];
      updatedOrders[orderIndex].showHotItems = !updatedOrders[orderIndex].showHotItems;
      setCompletedOrders(updatedOrders);
    } else {
      const updatedOrders = [...pendingOrders];
      updatedOrders[orderIndex].showHotItems = !updatedOrders[orderIndex].showHotItems;
      setPendingOrders(updatedOrders);
    }
  };

  const fetchOrders = async () => {
    // Your fetchOrders function definition here
  };

  const Deliver = async (order) => {
    try {
      const response = await fetch(`https://cheekydino.com/api/cafeordersdeliver`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: order, Delivered: 1 }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Order delivered successfully!', data);
        // Fetch the updated orders
        fetchOrders();
      } else {
        console.error('There was an error!', data.error);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <div>
      <div>
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className=" text-4x1 font-semibold leading-6 text-orange-500 component-title">Live Orders</h3>
        </div>
      
    </div>
    <div className='mt-10'>

      
          <h3 className="text-base font-semibold leading-6 text-blue-700 component-title">Preparing</h3>
        
        
    </div>       
     <ul role="list" className="divide-y divide-gray-100 mt-5">
          {pendingOrders.map((order, index) => (
            <li 
              key={order.id} 
              className="flex justify-between gap-x-6 py-5 rounded-lg shadow-md bg-green-500" 
              
            >
              <div className="flex min-w-0 gap-x-4 items-center">
                <div className="h-12 w-12 ml-2 flex items-center justify-center rounded-full bg-white shadow-sm">
                  <span className="text-lg font-bold text-black">{order.TableNum}</span>
                </div>
                <div className="min-w-0 flex-auto">
                  {order.showHotItems && (
                    <ol className="mt-1 text-xs leading-5 text-white">
                      {order.HotItems && JSON.parse(order.HotItems).map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ol>
                  )}
                  <button
                    onClick={() => toggleHotItems(index, false)}
                    className="mt-2 bg-gray-200 text-gray-800 text-xs py-1 px-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                  >
                    {order.showHotItems ? 'Hide Hot Items' : 'Show Hot Items'}
                  </button>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <a href={`mailto:${order.email}`} className="relative truncate hover:underline">
                      {order.Email}
                    </a>
                  </p>
                </div>
              </div>
              <div className="hidden sm:flex sm:flex-col sm:items-end mr-2">
                <p className="text-xs leading-5 text-white font-bold">
                  OT: {order.CreatedTime}
                </p>
               
                <p className="text-xs leading-5 text-white font-bold animate-pulse">
                  Status: Cooking
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div>
      
          <h3 className="text-base font-semibold mt-5 leading-6 text-green-500 component-title">Ready</h3>
        
   
        <ul role="list" className="divide-y divide-gray-100">
          {completedOrders.map((order, index) => (
            <li 
              key={order.id} 
              className="flex justify-between gap-x-6 py-5 rounded-lg shadow-md bg-red-500" 
              
            >
              <div className="flex min-w-0 gap-x-4 items-center mt-5">
                <div className="h-12 w-12 ml-2 flex items-center justify-center rounded-full bg-white shadow-sm">
                  <span className="text-lg font-bold text-black">{order.TableNum}</span>
                </div>
                <div className="min-w-0 flex-auto">
                  {order.showHotItems && (
                    <ol className="mt-1 text-xs leading-5 text-white">
                      {order.HotItems && JSON.parse(order.HotItems).map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ol>
                  )}
                  <button
                    onClick={() => toggleHotItems(index, true)}
                    className="mt-2 bg-gray-200 text-gray-800 text-xs py-1 px-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                  >
                    {order.showHotItems ? 'Hide Hot Items' : 'Show Hot Items'}
                  </button>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <a href={`mailto:${order.email}`} className="relative truncate hover:underline">
                      {order.Email}
                    </a>
                  </p>
                </div>
              </div>
              <div className="hidden sm:flex sm:flex-col sm:items-end mr-2">
                <p className="text-xs leading-5 text-white font-bold">
                  OT: {order.CreatedTime}
                </p>
                
                <p className="text-xs leading-5 text-white font-bold animate-pulse">
                  Status: Ready for Delivery
                </p>
                <button
                  onClick={() => Deliver(order.id)}
                  className="mt-2 bg-green-500 text-white text-xs py-1 px-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                >
                  Deliver
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
