import { BellAlertIcon } from '@heroicons/react/20/solid';
import React, { useState, useEffect } from 'react';

export default function Buzzer() {
  const [showAlert, setShowAlert] = useState(false);
  const [clockedInStaff, setClockedInStaff] = useState([]);
  const [from, setFrom] = useState('Front Desk');
  const [to, setTo] = useState('Front Desk');
  const [staff, setStaff] = useState('');

  useEffect(() => {
    const fetchClockIn = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/clockins', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to fetch clocked-in staff');
        const data = await response.json();
        setClockedInStaff(data.data);
      } catch (error) {
        console.error('Error fetching clocked-in staff:', error);
      }
    };
    fetchClockIn();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const postData = {
      From: from,
      To: to,
      Staff: staff,
      Content: `Can ${staff} please come to ${to}!`,
      Active: 1, // Ensure this field is included
    };

    try {
      const response = await fetch('https://cheekydino.com/api/buzzer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      
      // Clear the form and hide the alert
      setFrom('Front Desk');
      setTo('Front Desk');
      setStaff('');
      setShowAlert(false);
    } catch (error) {
      console.error('Error submitting the buzzer request:', error);
    }
  };

  return (
    <>
      {/* Button to trigger the alert */}
      <button
        onClick={() => setShowAlert(true)}
        type="button"
        className="rounded-full bg-red-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <BellAlertIcon aria-hidden="true" className="h-5 w-5" />
      </button>

      {/* Alert Modal */}
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <div>
              <label htmlFor="from" className="block text-sm font-medium leading-6 text-gray-900">
                From
              </label>
              <select
                id="from"
                name="from"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option>Front Desk</option>
                <option>Cafe</option>
                <option>Party Room</option>
                <option>Staff Room</option>
                <option>Office</option>
                <option>Kitchen</option>
              </select>
            </div>
            <div className="mt-4">
              <label htmlFor="to" className="block text-sm font-medium leading-6 text-gray-900">
                To
              </label>
              <select
                id="to"
                name="to"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option>Front Desk</option>
                <option>Cafe</option>
                <option>Party Room</option>
                <option>Staff Room</option>
                <option>Office</option>
                <option>Kitchen</option>
              </select>
            </div>
            <div className="mt-4">
              <label htmlFor="staff" className="block text-sm font-medium leading-6 text-gray-900">
                Staff
              </label>
              <select
                id="staff"
                name="staff"
                value={staff}
                onChange={(e) => setStaff(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Staff</option>
                {clockedInStaff.map((person) => (
                  <option key={person.id} value={person.StaffName}>
                    {person.StaffName}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 sm:text-sm"
              >
                <BellAlertIcon aria-hidden="true" className="h-5 w-5" />
                Call
              </button>
              <button
                type="button"
                onClick={() => setShowAlert(false)}
                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-base font-medium text-gray-900 shadow-sm ring-1 ring-gray-900 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
