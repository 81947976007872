import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { parse, format, isBefore, isToday, addHours } from 'date-fns';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import LoadingComponent from './loading';

export default function SessionCalender({ date, children, adults, childData, email, telephone, name, staff, price }) {
  const navigate = useNavigate();
  const [freeTablesPerTimeslot, setFreeTablesPerTimeslot] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeslotsObject, setTimeslotsObject] = useState(null);

  const user = useContext(AuthContext);

  let dateObj = new Date(date);
  let formattedDate = `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

  useEffect(() => {
    const fetchAvailability = async () => {
      console.log('Fetching availability data for date:', date);
  
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayIndex = new Date(date).getDay();
      const dayName = daysOfWeek[dayIndex];
  
      try {
        const adminResponse = await fetch('https://cheekydino.com/api/admin');
        if (!adminResponse.ok) {
          throw new Error('Failed to fetch admin data');
        }
        const responseData = await adminResponse.json();
        const adminData = responseData.data[0]; // Access the first element of the array
  
        console.log('Admin data:', adminData);
  
        const timeslotsJSON = adminData[dayName];
        if (!timeslotsJSON) {
          throw new Error('Timeslots data not found for the specified day');
        }
        let timeslots = JSON.parse(timeslotsJSON).sessions;
        if (!timeslots) {
          throw new Error('Timeslots data not found in the parsed JSON');
        }
        console.log('Timeslots data:', timeslots);
  
        // Get the current date and time
        const currentDate = new Date();
        const currentDateString = currentDate.toISOString().split('T')[0]; // Format date as yyyy-mm-dd
        const currentTimeString = currentDate.toTimeString().split(' ')[0].substring(0, 5); // Format time as hh:mm
  
        // If the selected date is today, filter timeslots based on the current time
        if (date === currentDateString) {
          timeslots = timeslots.filter((timeslot) => {
            return timeslot.start > currentTimeString;
          });
        }
  
        console.log('Filtered Timeslots:', timeslots);
  
        const tableData = JSON.parse(adminData.TableData);
        if (!tableData) {
          throw new Error('Table data not found');
        }
        console.log('Table data:', tableData);
  
        setTimeslotsObject(timeslots);
        setTableData(tableData);
      } catch (error) {
        console.error('Error fetching availability:', error);
      }
    };
  
    fetchAvailability();
  }, [date]);
  
  
  
  
  
  useEffect(() => {
    if (tableData && timeslotsObject && timeslotsObject.length > 0) {
      const processTableData = async () => {
        try {
          const allBookingsResponse = await fetch(`https://cheekydino.com/api/sessionbookings?date=${date}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (!allBookingsResponse.ok) {
            throw new Error('Failed to fetch bookings data');
          }
  
          const responseJson = await allBookingsResponse.json();
          if (!responseJson.success) {
            throw new Error('Failed to fetch bookings data: ' + responseJson.message);
          }
  
          const bookingsData = responseJson.data;
          console.log('Bookings data:', bookingsData);
  
          const totalGuests = Number(children) + Number(adults);
  
          const freeTablesPerTimeslot = timeslotsObject.map(timeslot => {
            const startTime = parse(timeslot.start, 'HH:mm', new Date());
            const endTime = parse(timeslot.end, 'HH:mm', new Date());
  
            let freeTables = tableData.filter(table => {
              if ([40, 41, 42].includes(table.table)) {
                return false;
              }
              const isTableBooked = bookingsData.some(booking => {
                const bookingStart = parse(booking.TimeslotFrom, 'HH:mm:ss', new Date());
                const bookingEnd = parse(booking.TimeslotTo, 'HH:mm:ss', new Date());
                
                // Check if the current timeslot starts within the duration of the booking
                const startsDuringBooking = startTime >= bookingStart && startTime < bookingEnd;
                
                const isBooked = startsDuringBooking && booking.Table === table.table;
                
                if (isBooked) {
                  console.log('Bookings found for table:', table.table, 'timeslot:', timeslot);
                }
                
                return isBooked;
              });
              
              
              
              
                
              

                return !isTableBooked;
            });

              
            let tablesMatchingCapacity = freeTables.filter(table => table.capacity >= totalGuests);
  
            if (tablesMatchingCapacity.length === 0) {
              freeTables.sort((a, b) => b.capacity - a.capacity);
            } else {
              freeTables = tablesMatchingCapacity;
            }
  
            let recommendedTables = [];
            let remainingGuests = totalGuests;
            for (let i = 0; i < freeTables.length && remainingGuests > 0; i++) {
              recommendedTables.push(freeTables[i].table);
              remainingGuests -= freeTables[i].capacity;
            }
  
            return {
              timeslot: {
                start: format(startTime, 'HH:mm'),
                end: format(endTime, 'HH:mm')
              },
              freeTables: freeTables.length,
              recommendedTables
            };
          });
  
          setFreeTablesPerTimeslot(freeTablesPerTimeslot);
        } catch (error) {
          console.error('Error fetching bookings:', error);
        }
      };
  
      processTableData();
    } else {
      console.log('Table data or timeslotsObject is not set');
    }
  }, [tableData, timeslotsObject, date, children, adults]);
  
  
  
  async function handleBook(item) {
    setLoading(true);

    try {
      const createUniqueID = () => {
        return Math.random().toString(36).substr(2, 9);
      };

      const bookingID = createUniqueID();

      const bookingData = {
        id: bookingID,
        Name: name,
        Date: date,
        TimeslotFrom: item.timeslot.start,
        TimeslotTo: item.timeslot.end,
        Table: item.recommendedTables[0],
        Email: user.email,
        Adults: Number(adults),
        Children: Number(children),
        Arrived: false,
        LeftCenter: false,
        CustomerbookingID: bookingID,
        ExtraTables: item.recommendedTables.length > 2 ? item.recommendedTables[1].table : null,
        Prepaid: false,
        ChildData: childData,
        Telephone: telephone,
        TotalSpent: price,
        Staff: staff,
        EntranceFee: price,
        createdAt: new Date().toISOString(),
      };

      const emailData = {
        email: email,
        name: name,
        date: date,
        timeslot: `${item.timeslot.start} - ${item.timeslot.end}`,
        table: item.recommendedTables[0],
        telephone: telephone,
        adults: adults,
        children: children,
        bookingID: bookingID,
        totalSpent: price,
        childData: childData,
      };

      // Send email data
      const emailResponse = await fetch('https://ebaedr0fmd.execute-api.eu-west-2.amazonaws.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (!emailResponse.ok) {
        throw new Error('Failed to send email data');
      }

      const token = localStorage.getItem('token');

      // Post booking information to the API
      const bookingResponse = await fetch('https://cheekydino.com/api/postsession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bookingData),
      });

      if (!bookingResponse.ok) {
        throw new Error('Failed to save booking data');
      }

      const today = format(new Date(), 'yyyy-MM-dd');

      if ( date === today ) {

      const message = {
        content: `You have a new session booking for today at ${item.timeslot.start} . Please check the future sessions page for more information.`, 
        email: email,
        createdAt: new Date().toISOString(),
        replied: 0
    };

        // Send the message
        const responseSend = await fetch('https://cheekydino.com/api/addmessages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        });

        if (!responseSend.ok) {
            throw new Error(`Failed to send message: ${responseSend.statusText}`);
        }

        // Clear the input field

    }


     

      console.log('Booking data posted successfully:', bookingData);

      // Redirect to /sessionbooking page or set a completed state
      setCompleted(true);
    } catch (error) {
      console.error('Error during booking:', error);
    } finally {
      setLoading(false);
    }
  }

  if (completed) {
    navigate('/sessionbookings');
  }

  return (
    <motion.div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
      <button onClick={() => window.location.reload()} className="mt-4 w-1/2 mb-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-blue-600">Back</button>
      <p className="text-lg font-semibold leading-6 text-gray-900 component-title">{formattedDate}</p>
      <p className="text-center font-bold component-title">Select A Timeslot</p>
      <p className="text-center font-bold component-title mt-2">Party Size: {Number(adults) + Number(children)}</p>
      <p className="text-center font-bold component-title mt-2">Name: {name}</p>
      <p className="text-center font-bold component-title mt-2">Total Spent: £{price.toFixed(2)}</p>

      {loading ? (
        <div>
          <LoadingComponent />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          {freeTablesPerTimeslot.map((item, index) => (
            <motion.div key={index} className="border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-200">
              <p className={`text-sm font-semibold leading-6 ${item.freeTables > 0 ? 'text-green-600' : 'text-red-600'}`}>
                Timeslot: {item.timeslot.start} - {item.timeslot.end}
              </p>
              <p className={`text-sm font-semibold leading-6 ${item.freeTables > 0 ? 'text-green-600' : 'text-red-600'}`}>
                Free Tables: {item.freeTables}
              </p>
              <div className="relative pt-1">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                  <div style={{ width: `${(1 - item.freeTables / tableData.length) * 100}%` }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${item.freeTables > 0 ? 'bg-green-500' : 'bg-red-500'}`}></div>
                </div>
              </div>
              <button onClick={() => handleBook(item)} className="mt-4 w-full px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-blue-600">Pay Later</button>
            </motion.div>
          ))}
        </div>
      )}
    </motion.div>
  );
}
