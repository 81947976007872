import { useState, useEffect } from "react";
import StaffTill from "./StaffTill";

export default function Request() {
  const [staff, setStaff] = useState('');
  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const [details, setDetails] = useState([]);
  const [holidayRequest, setHolidayRequest] = useState(false);
  
  // State variables for holiday request form fields
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [reason, setReason] = useState('');
  const [holiday, setHoliday] = useState([]);

  // Fetch staff details based on the selected staff member
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/staff');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        const filteredStaff = data.data.filter(staffMember => staffMember.id === staff);
        setDetails(filteredStaff);
      } catch (error) {
        console.error('Failed to fetch staff:', error);
      }
    };

    if (staff) fetchStaff();
  }, [staff]);

  // Handle staff selection change and pin verification
  const handleSelectedChange = (selectedStaff) => {
    setStaff(selectedStaff.StaffId);
    setIsPinCorrect(
      selectedStaff.TillPin || selectedStaff.StaffId === 'Barry@Cheekydino.co.uk'
    );
  };

  // Submit holiday request form data to the API
  const handleSubmit = async (e) => {
    e.preventDefault();

    const holidayData = {
      from: fromDate,
      to: toDate,
      reason,
      staffId: staff
    };

    try {
      const response = await fetch('https://cheekydino.com/api/holidayrequests', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(holidayData),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await response.json();

      // Reset form fields and close request form after successful submission
      setFromDate('');
      setToDate('');
      setReason('');
      setHolidayRequest(false);
    } catch (error) {
      console.error('Failed to submit holiday request:', error);
    }
  };

  // Fetch holiday data based on the selected staff member
  useEffect(() => {
    const fetchHoliday = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/holiday', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        const filteredHoliday = data.data.filter(holiday => holiday.StaffId === staff);
        setHoliday(filteredHoliday);
      } catch (error) {
        console.error('Failed to fetch holiday:', error);
      }
    };

    fetchHoliday();
  }, [staff]);

  return (
    <>
      <StaffTill onSelectChange={handleSelectedChange} />

      {isPinCorrect && (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold text-gray-900">Your Details</h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                onClick={() => setHolidayRequest(true)}
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Make Holiday Request
              </button>
            </div>
          </div>

          {holidayRequest && (
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Holiday Request</h3>
              <form onSubmit={handleSubmit}>
                {/* Holiday Request Form Fields */}
                <div>
                  <label htmlFor="from" className="block text-sm font-medium text-gray-900">From</label>
                  <div className="mt-2">
                    <input
                      id="from"
                      name="from"
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="to" className="block text-sm font-medium text-gray-900">To</label>
                  <div className="mt-2">
                    <input
                      id="to"
                      name="to"
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="reason" className="block text-sm font-medium text-gray-900">Reason</label>
                  <div className="mt-2">
                    <textarea
                      id="reason"
                      name="reason"
                      rows="4"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    ></textarea>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* Holiday Table */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-gray-900">Holiday Table</h2>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">From</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">To</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Reason</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Approved</th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {holiday.map(person => (
                      <tr key={person.id}>
                        <td className="py-4 text-sm font-medium text-gray-900">{person.FromDate}</td>
                        <td className="px-3 text-sm text-gray-500">{person.ToDate}</td>
                        <td className="px-3 text-sm text-gray-500">{person.Notes}</td>
<td className="px-3 text-sm text-gray-500">
  {person.Approved === 1 ? "Yes" : "No"}
</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Details Table */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold text-gray-900">Details Table</h2>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Role</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Hourly Rate</th>
                      <th className="px-3 text-left text-sm font-semibold text-gray-900">Till Pin</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {details.map(detail => (
                      <tr key={detail.id}>
                        <td className="py-4 text-sm font-medium text-gray-900">{detail.Name}</td>
                        <td className="px-3 text-sm text-gray-500">{detail.Role}</td>
                        <td className="px-3 text-sm text-gray-500">{detail.HourlyRate}</td>
                        <td className="px-3 text-sm text-gray-500">{detail.TillPin}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
