import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import '../customerfont.css';
import AdultFoodOptions from './AdultFoodOptions';
import { AuthContext } from '../../AuthContext';
import { useContext } from 'react';
import { set } from 'date-fns';
import PartyGuests from './PartyGuests';

export default function MyBooking() {
  

    const [partyBookings, setPartyBookings] = useState([]);

  const user = useContext(AuthContext);

  const email = user.email;

  console.log(email);

  const navigate = useNavigate();

  const [guests, setGuests] = useState(false);


  useEffect(() => {
    async function fetchPartyBookings() {
      try {
        // Get the JWT token from your user context or local storage
        const token = localStorage.getItem('token'); // Replace this with your actual method to get the token

        const response = await fetch(`https://cheekydino.com/api/uniquepartybooking/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
            console.log(data);
            // if both are party bookings are the same, then only display one

            const uniqueBookings = data.data.filter((booking, index, self) =>
              index === self.findIndex((t) => (
                t.PartyDate === booking.PartyDate && t.PartyTime === booking.PartyTime
              ))
            );


            setPartyBookings(uniqueBookings);
        }
      } catch (error) {
        console.error('Failed to fetch party bookings', error);
      }
    }

    // Call the function to fetch party bookings
    fetchPartyBookings();
  }, []); // Empty dependency array means this effect runs once on mount

  
console.log(partyBookings);
//   const deposit = total * 0.5;
//   let formattedNum = deposit.toFixed(2);

  // if (adultFood === true) {
  //     return (
  //         <AdultFoodOptions selectedParty={id} />
  //     )
  // }

  console.log(partyBookings);

  async function payDeposit() {
      setModalVisible(true);
  }

  if (guests === true) {

    return (
      <PartyGuests partyBookings = {partyBookings}  />
    )
    
  }


 return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-orange-100 overflow-hidden shadow sm:rounded-lg"
    >
    <div className="w-full max-w-md px-4 py-6 sm:px-6">
      <h3 className="text-base font-semibold leading-7 text-gray-900 text-center component-title">
        My Booking
      </h3>
      <p className="w-full text-center font-bold">Party Booking</p>
     
      {partyBookings.map((booking, index) => (
        <div key={index} className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-lg leading-6 font-medium text-gray-900 component-title"> My Party {index + 1}</h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Details of the party booking.</p>
          </div>
          <div className="px-4 py-5 sm:p-6 grid grid-cols-2 gap-4">
            <div>
              <dt className="text-sm font-medium text-gray-500">Full Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{booking.ChildName}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Party Type</dt>
              <dd className="mt-1 text-sm text-gray-900">{booking.PartyType}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Party Date</dt>
              <dd className="mt-1 text-sm text-gray-900">{new Date(booking.PartyDate).toLocaleDateString()}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Party Time</dt>
              <dd className="mt-1 text-sm text-gray-900">{booking.PartyTime}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Number of Guests</dt>
              <dd className="mt-1 text-sm text-gray-900">{booking.NoOfChildren}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Total</dt>
              <dd className="mt-1 text-sm text-gray-900">£{booking.Total}</dd>
            </div>
            <p className='w-full text-center font-bold animate-pulse'>If the party deposit is not paid within 24 hours of your booking, your party will be not be honoured, please contact Cheeky Dino on 01622670055</p>
      <div className="w-full max-w-md px-4 py-6 sm:px-6 flex justify-center space-x-4">
        <button
          onClick={() => {
            setGuests(true)
          }}
          className="rounded-full bg-green-500 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
        >
          Add Guests
        </button>
      </div>
          </div>
        </div>
      ))}
    </div>
  </div>

    );
}