import { useState, useEffect } from "react";
import { ArrowLongLeftIcon, ArrowLongRightIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { useNavigate } from "react-router-dom";
import StaffNav from "./staffNav";
import ClosedDates from "./closeddates";

export default function OpeningTime() {
  const navigate = useNavigate();
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [times, setTimes] = useState(
    daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: { openingTime: "09:30", closingTime: "17:30" } }), {})
  );
  const [sessionLength, setSessionLength] = useState("02:00");
  const [sessions, setSessions] = useState(
    daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: [] }), {})
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const [updatedDay, setUpdatedDay] = useState("");
  const [closedDates, setClosedDates] = useState(false);

  useEffect(() => {
    const fetchOpeningTimes = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/admin', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const defaultSlots = JSON.parse(data.data[0].DefaultSlots);

        const fetchedTimes = {};
        const fetchedSessions = {};

        daysOfWeek.forEach(day => {
          const dayData = defaultSlots[day];
          if (dayData) {
            fetchedTimes[day] = {
              openingTime: dayData.openingTime,
              closingTime: dayData.closingTime,
            };
            fetchedSessions[day] = dayData.sessions || [];
          } else {
            fetchedTimes[day] = {
              openingTime: "09:30",
              closingTime: "17:30",
            };
            fetchedSessions[day] = [];
          }
        });

        setTimes(fetchedTimes);
        setSessions(fetchedSessions);
        setSessionLength(defaultSlots.sessionLength || "02:00");
      } catch (error) {
        console.error('Error fetching opening times', error);
      }
    };

    fetchOpeningTimes();
  }, []);

  useEffect(() => {
    const sessionLengthDate = new Date(`1970-01-01T${sessionLength}Z`);
    const sessionLengthMinutes = sessionLengthDate.getUTCHours() * 60 + sessionLengthDate.getUTCMinutes();
    const sessionInterval = 30; // Set session interval to 30 minutes
  
    const newSessions = {};
    for (const day of daysOfWeek) {
      const openingTimeDate = new Date(`1970-01-01T${times[day].openingTime}:00Z`);
      const closingTimeDate = new Date(`1970-01-01T${times[day].closingTime}:00Z`);
  
      const daySessions = [];
      let currentTime = openingTimeDate;
  
      // Generate sessions, incrementing by 30 minutes but setting session length according to sessionLengthMinutes
      while (currentTime < closingTimeDate) {
        const endTime = new Date(currentTime.getTime() + sessionLengthMinutes * 60000);
        if (endTime > closingTimeDate) {
          break;
        }
        daySessions.push({
          start: currentTime.toISOString().substr(11, 5),
          end: endTime.toISOString().substr(11, 5),
        });
        // Increment start time by 30 minutes for the next session
        currentTime = new Date(currentTime.getTime() + sessionInterval * 60000);
      }
  
      newSessions[day] = daySessions;
    }
  
    setSessions(newSessions);
  }, [times, sessionLength]);

  
  const handleSubmitAsDefault = async () => {
    try {
      const combinedData = {};
  
      daysOfWeek.forEach(day => {
        combinedData[day] = {
          openingTime: times[day].openingTime,
          closingTime: times[day].closingTime,
          sessions: sessions[day], // Ensure sessions are included correctly
        };
      });
  
      combinedData.sessionLength = sessionLength;  // Include session length if needed
  
      // Save to the backend
      const saveResponse = await fetch('https://cheekydino.com/api/defaultslots', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ DefaultSlots: combinedData }),
      });
  
      if (!saveResponse.ok) {
        throw new Error('Network response was not ok');
      }
  
      console.log('DefaultSlots data saved successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error fetching or saving DefaultSlots data', error);
    }
  };
  
  
  
  
  

  const handleChangeForNextWeek = async (day) => {
    try {
      const dayData = {
        openingTime: times[day].openingTime,
        closingTime: times[day].closingTime,
        sessions: sessions[day],
      };

      const response = await fetch('https://cheekydino.com/api/daydata/admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ DefaultSlots: JSON.stringify({ [day]: dayData }) }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      console.log(`Changes for ${day} saved successfully for next week`);
      setUpdatedDay(day);
      setMessage(`New time saved for ${day}`);
    } catch (error) {
      console.error(`Error saving changes for ${day} for next week`, error);
    }
  };

  const colors = ['bg-red-600 hover:bg-red-700', 'bg-yellow-600 hover:bg-yellow-700', 'bg-green-600 hover:bg-green-700', 'bg-blue-600 hover:bg-blue-700', 'bg-orange-600 hover:bg-indigo-700', 'bg-purple-600 hover:bg-purple-700', 'bg-pink-600 hover:bg-pink-700'];

  const handleTimeChange = (day, type, value) => {
    setTimes(prevTimes => ({
      ...prevTimes,
      [day]: {
        ...prevTimes[day],
        [type]: value
      }
    }));
  };

  const handleClosedClick = async (day) => {
   
  
    // Update the local state to reflect the day as closed
    setTimes(prevTimes => ({
      ...prevTimes,
      [day]: closedTimes
    }));
  
    try {
      // Prepare the data to be sent, with the day as the key
      const dataToSend = {
        day
      };
  
      // Save the closed day to the database
      const response = await fetch('https://cheekydino.com/api/closed', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save closed day in the database');
      }
  
      console.log(`${day} marked as closed successfully`);
      setUpdatedDay(day);
      setMessage(`${day} has been marked as closed`);
    } catch (error) {
      console.error(`Error saving closed status for ${day}`, error);
    }
  };

  if (closedDates) {
    return (
      <ClosedDates />
    );
    
  }
  


  return (
    <>
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
          <div className="-mt-px flex w-0 flex-1">
            <button
              onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
              className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              Previous
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {daysOfWeek.map((day, index) => (
              <button
                key={day}
                onClick={() => setCurrentPage(index + 1)}
                className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          
          <div className="-mt-px flex w-0 flex-1 justify-end">
            <button
              onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, daysOfWeek.length))}
              className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              Next
              <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </nav>
      {message && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">{message}</h3>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    onClick={() => setMessage("")}
                    type="button"
                    className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
  
      <div className="flex">
        {/* Left-hand side: Input for opening times, closing times, and day buttons */}
        <div className="w-1/2">
          <button
            onClick={handleSubmitAsDefault}
            className="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white ml-5 mb-5 font-semibold py-2 px-4 rounded-lg shadow-md"
          >
            Save as Default
          </button>
  
          {daysOfWeek.map((day, index) => (
            <button
              key={day}
              onClick={() => handleChangeForNextWeek(day)}
              className={`mt-4 ${colors[index % colors.length]} flex justify-between text-white ml-5 mb-5 font-semibold py-2 px-4 rounded-lg shadow-md`}
            >
              Change for {day}
            </button>
          ))}
        </div>
  
        {/* Right-hand side: Session display */}
        <div className="w-1/2">
          {daysOfWeek.map((day, index) => (
            <div key={day} style={{ display: currentPage === index + 1 ? 'block' : 'none' }}>
              <label htmlFor={`${day}-opening-time`} className="block text-sm ml-2 font-medium leading-6 text-gray-900">
                {day} Opening Time
              </label>
              <div className="mt-2">
                <input
                  type="time"
                  name={`${day}-opening-time`}
                  id={`${day}-opening-time`}
                  className="block w-1/2 rounded-md border-0 ml-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={times[day]?.openingTime || "09:30"}
                  onChange={(e) => handleTimeChange(day, 'openingTime', e.target.value)}
                />
              </div>
              <label htmlFor={`${day}-closing-time`} className="block text-sm ml-2 font-medium leading-6 text-gray-900">
                {day} Closing Time
              </label>
              <div className="mt-2">
                <input
                  type="time"
                  name={`${day}-closing-time`}
                  id={`${day}-closing-time`}
                  className="block w-1/2 rounded-md border-0 ml-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={times[day]?.closingTime || "17:30"}
                  onChange={(e) => handleTimeChange(day, 'closingTime', e.target.value)}
                />
              </div>
  
              <button onClick={() => handleClosedClick(day)} 
                className="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white ml-5 mb-5 font-semibold py-2 px-4 rounded-lg shadow-md">
                Closed on {day}s?
              </button>
  
              {/* Dynamic Session Display */}
              <div className="mt-6 ml-5">
                <h4 className="font-semibold text-lg mb-3">{day} Sessions</h4>
                <ul>
                  {sessions[day]?.map((session, idx) => (
                    <li key={idx} className="mb-2 text-sm font-medium text-gray-700">
                      {session.start} - {session.end}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
  
}
