import React, { useEffect, useState } from 'react';
import SessionCalenderTill from './sessioncalendertill';
import StaffTill from './StaffTill';
import { format, isWithinInterval, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import SessionEditCalenderTill from './sessioneditcalendertill';


export default function SessionBook() {
  const [children, setChildren] = useState(1);
  const [adults, setAdults] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [childData, setChildData] = useState([{ age: '' }]);
  const [name, setName] = useState('');
  const [staff, setStaff] = useState('');
  const [selectedSession, setSelectedSession] = useState('');
  const [sessions, setSessions] = useState([]);
  const [date, setDate] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setNumber] = useState('');
  const [price, setPrice] = useState(0);
  const [menu, setMenu] = useState(false);
  const [previousChildren, setPreviousChildren] = useState(1);
  const [childPrice, setChildPrice] = useState([]);
  const [adultPrice, setAdultPrice] = useState([]);
  const [siblingPrice, setSiblingPrice] = useState([]);
  const [additionalAdultPrice, setAdditionalAdultPrice] = useState([]);
    const [ID, setID] = useState('');

  const navigate = useNavigate();

  // use location to get the query string

   const location = useLocation();


   useEffect(() => {

   const { session } = location.state;

    setID(session.id);

    }, [location]);



console.log(ID);





  const handleMenu = () => {
    setMenu(true);
  };

  if (menu === true) {
    navigate('/dashboard');
  }

  // Fetch prices based on the selected date
  useEffect(() => {
    const fetchPrices = async () => {
      if (!date) return; // Do not proceed if no date is selected

      try {
        const response = await fetch('https://cheekydino.com/api/prices', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const prices = data.data;

        // Use the selected date to determine the day of the week
        const selectedDate = parseISO(date);
        const dayOfWeek = format(selectedDate, 'EEEE');
        const currentTime = format(new Date(), 'HH:mm:ss');

        const convertDaysToArray = (daysString) => {
          if (!daysString) return []; // Handle null or undefined
          return daysString.split(',').map(day => day.trim());
        };

        const sessionDataDays = prices.filter((price) => {
          const daysArray = convertDaysToArray(price.Days);
          return (
            price.SetDay === 1 && price.Active === 1 &&
            price.Days &&
            daysArray.includes(dayOfWeek)
          );
        });

        const sessionDataDates = prices.filter((price) => {
          const startDate = price.StartDate ? parseISO(price.StartDate) : null;
          const expiryDate = price.ExpiryDate ? parseISO(price.ExpiryDate) : null;
          return (
            price.SetDay === 0 && price.Active === 1 && 
            price.Category &&
            startDate &&
            expiryDate &&
            isWithinInterval(selectedDate, { start: startDate, end: expiryDate })
          );
        });

        const allSessionData = [...sessionDataDays, ...sessionDataDates];
        
        setChildPrice(allSessionData.filter(price => price.Category === "Children"));
        setAdultPrice(allSessionData.filter(price => price.Category === "Adults"));
        setSiblingPrice(allSessionData.filter(price => price.Category === "Siblings"));
        setAdditionalAdultPrice(allSessionData.filter(price => price.Category === "AdditionalAdults"));

      } catch (error) {
        console.error('Failed to fetch prices', error);
      }
    };

    fetchPrices();
  }, [date]);

  const handleChildrenChange = (e) => {
    const value = e.target.value;
    setChildren(value);
    setPreviousChildren(value);
  
    setChildData(Array.from({ length: value }, () => ({ age: '' })));
  };
  
  const handleChildAgeChange = (index, value) => {
    setChildData((prev) =>
      prev.map((data, i) => (i === index ? { ...data, childAge: value } : data))
    );
  };

  const handleSubmit = () => {
    const calculatePrice = (childData, adults, children) => {
      let totalPrice = 0;

      childData.forEach(data => {
        const childPriceValue = parseFloat(data.childAge) || 0;
        totalPrice += childPriceValue;
      });

      const additionalAdults = adults - children;
      if (additionalAdults > 0) {
        const additionalAdultPriceValue = adultPrice.find(price => price.Category === "Adults")?.Price;
        totalPrice += additionalAdults * (parseFloat(additionalAdultPriceValue) || 0);
      }

      return totalPrice;
    };
  
    const totalPrice = calculatePrice(childData, parseInt(adults), parseInt(children));
    setPrice(totalPrice); 
    setChildData((prev) => prev.map((data) => ({ ...data, TotalSpent: totalPrice })));
    setSubmitted(true);
  };

  const handleSelectedChange = (value) => {
    setStaff(value.StaffId);
  };

  return (
    <div className="flex bg-white">
      <div className="w-1/2 p-6 border">
        <motion.button
          onClick={handleMenu}
          className="top-4 left-4 p-2 border rounded-md bg-red-500 text-white hover:bg-red-900"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Back
        </motion.button>
        <div>
          <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
            Date
          </label>
          <input
            onChange={(e) => setDate(e.target.value)}
            id="date"
            type="date"
            name="date"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={date}
          />
        </div>
        <div className="mb-2">
          <StaffTill onSelectChange={handleSelectedChange} />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-900">
            Email
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            type="text"
            name="email"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            title="Please enter a valid email address"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-900">
            Adult Name
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            id="name"
            type="text"
            name="name"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            defaultValue={name}
          />
        </div>

        <div>
          <label htmlFor="number" className="block text-sm font-medium text-gray-900">
            Telephone
          </label>
          <input
            onChange={(e) => setNumber(e.target.value)}
            id="number"
            type="text"
            name="number"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={telephone}
          />
        </div>

        <div>
          <label htmlFor="adults" className="block text-sm font-medium text-gray-900">
            Number of Adults
          </label>
          <input
            onChange={(e) => setAdults(e.target.value)}
            id="adults"
            type="number"
            name="adults"
            min={0}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={adults}
          />
        </div>

        <div>
          <label htmlFor="children" className="block text-sm font-medium text-gray-900">
            Number of Children
          </label>
          <input
            onChange={handleChildrenChange}
            id="children"
            type="number"
            name="children"
            min={0}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={children}
          />
        </div>

        <div>
          {childData.map((data, index) => (
            <div key={index} className="space-y-2">
              <label htmlFor={`child-age-${index}`} className="block text-sm font-medium text-gray-900">
                Child's Age - Do Not Select Sibling First
              </label>
              <select
                onChange={(e) => handleChildAgeChange(index, e.target.value)}
                id={`child-age-${index}`}
                name={`child-age-${index}`}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select an age</option>
                {childPrice.map((price) => (
                  <option key={price.id} value={price.Price}>
                    {price.Name}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="mt-8 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
        >
          Book
        </button>
      </div>

      <div className="w-1/2 border">
        {submitted && (
          <SessionEditCalenderTill children={children} staff={staff} adults={adults} date={date} childData={childData} email={email} telephone={telephone} name={name} price={price} id = {ID} />
        )}
      </div>
    </div>
  );
}
