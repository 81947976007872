import React, { useState, useEffect } from 'react';
import OpenAI from 'openai';
import config from '../openai'; // Adjust the path if necessary
import { CogIcon } from '@heroicons/react/20/solid';
import LoadingComponent from './loading';
import StaffNav from './staffNav';

function App() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [populate, setPopulate] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  // Initialize OpenAI with your API key
  const openai = new OpenAI({
    dangerouslyAllowBrowser: true,
    apiKey: config.OPENAI_API_KEY,
  });

  let ipcRenderer = false;

  // Check if code is running in Electron
  if (window && window.process && window.process.type) {
    ipcRenderer = window.require('electron').ipcRenderer;
  }

  useEffect(() => {
    if (ipcRenderer) {
      ipcRenderer.on('file-content', async (event, jsonString) => {
        const textContent = JSON.parse(jsonString);
        const lines = textContent.items.map(item => item.str);

        setLoading(true);

        // Define the stock control schema
        const stockControlSchema = {
          Name: "String",
          Weight: "Int",
          Quantity: "Int",
          Price: "Float",
          PreVAT: "Float",
          Supplier: "String",
          VAT: "Float",
          CurrentStockLevel: "Int",
          Cases: "Int",
          ProductId: "String",
        };

        try {
          const chatCompletion = await openai.chat.completions.create({
            model: 'gpt-3.5-turbo',
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              { role: 'user', content: lines.join(' ') + ' ' + JSON.stringify(stockControlSchema) },
              {
                role: 'user',
                content: "Please return each product's information in JSON format using the provided schema fields. Please start the response with [ and end with ]."
              }
            ],
          });

          setApiResponse(chatCompletion.choices[0].message.content);
          setPopulate(true);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      });

      return () => {
        ipcRenderer.removeAllListeners('file-content');
      };
    }
  }, [ipcRenderer]);

  useEffect(() => {
    if (apiResponse) {
      crossReferenceDatabaseAndPopulateTable();
      setPopulate(true);
    }
  }, [apiResponse]);

  const crossReferenceDatabaseAndPopulateTable = async () => {
    const startIndex = apiResponse.indexOf('[');
    const endIndex = apiResponse.lastIndexOf(']') + 1;

    const jsonString = apiResponse.slice(startIndex, endIndex);
    const parsedData = JSON.parse(jsonString);

    try {
      const response = await fetch('https://cheekydino.com/api/stockcontrol', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      const allStockItems = data.data;

      const newTableData = await Promise.all(parsedData.map(async product => {
        const matchingStockItems = allStockItems.filter(item => item.ProductId === product.ProductId);

        const existsInDatabase = matchingStockItems.length > 0;
        const databasePrice = existsInDatabase ? matchingStockItems[0].Price : null;

        return {
          ...product,
          existsInDatabase,
          databasePrice,
        };
      }));

      setTableData(newTableData);
      setPopulate(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setPopulate(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setFileName(file.name);
  };

  const handleSubmit = () => {
    setLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = function(event) {
        if (ipcRenderer) {
          ipcRenderer.send('file-uploaded', event.target.result);
        } else {
          const buffer = new Uint8Array(event.target.result);
          pdfParse(buffer).then(function(data) {
            setData(data.text);
          });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleUpdate = async (item) => {
    if (item.existsInDatabase) {
      const itemId = item.ProductId;

      try {
        // Make a PUT request to update the existing item
        const response = await fetch(`https://cheekydino.com/api/stockpdf/${itemId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Price: item.Price,
            Quantity: item.Quantity,
            Weight: item.Weight,
            Cases: item.Cases,
            CurrentStockLevel: item.Weight !== 0 ? item.Weight : item.Quantity,
          }),
        });

        const updatedItem = await response.json();
console.log("updatedItem", updatedItem);
        const updatedTableData = tableData.map(i => i.ProductId === updatedItem.ProductId ? updatedItem : i);
        setTableData(updatedTableData);
      } catch (error) {
        console.error('Error updating item:', error);
      }
    } else {
      try {


        // destruture item object to remove existsInDatabase and databasePrice

        const postItem = {
            Name: item.Name,
            Weight: item.Weight,
            Quantity: item.Quantity,
            Price: item.Price,
            PreVAT: item.PreVAT,
            Supplier: item.Supplier,
            VAT: item.VAT,
            CurrentStockLevel: item.Weight !== 0 ? item.Weight : item.Quantity,
            Cases: item.Cases,
            ProductId: item.ProductId,

        }




        // Make a POST request to add the new item
        const response = await fetch('https://cheekydino.com/stockpdf', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postItem),
        });

        const newItem = await response.json();
        const updatedTableData = tableData.map(i => i.ProductId === item.ProductId ? newItem : i);
        setTableData(updatedTableData);
      } catch (error) {
        console.error('Error adding new item:', error);
      }
    }
  };

  return (
    <>
    <StaffNav />
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-fuchsia-400 via-blue-100 to-purple-300 px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-5">AI Assisted Invoice Reading</h2>
          {populate && <p className="text-2xl animate-pulse text-indigo-500">Populating table</p>}
          {loading && <LoadingComponent />}

          <div className='flex justify-center gap-2'>
            <input 
              type="file" 
              accept=".pdf" 
              onChange={handleFileChange} 
              className="hidden" 
              id="file-upload"
            />
            <label htmlFor="file-upload" className="inline-flex items-center gap-x-2 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Select PDF
            </label>
            <button onClick={handleSubmit}
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Generate <CogIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
            {fileName && <p className="text-gray-700">Selected file: {fileName}</p>}
          </div>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Code
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Description
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Exists in Database
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Price
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Database Price
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Quantity
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Weight
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {item.ProductId}
                    </td>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {item.Name}
                    </td>
                    <td className="py-4 px-3 text-sm text-gray-500">
                      {item.existsInDatabase ? 'Yes' : 'No'}
                    </td>
                    <td className="py-4 px-3 text-sm text-gray-500">
                      {item.Price}
                    </td>
                    <td className="py-4 px-3 text-sm text-gray-500">
                      {item.databasePrice}
                    </td>
                    <td className="py-4 px-3 text-sm text-gray-500">
                      {item.Quantity}
                    </td>
                    <td className="py-4 px-3 text-sm text-gray-500">
                      {item.Weight}
                    </td>
                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => handleUpdate(item)}
                        className="inline-flex items-center gap-x-2 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {item.existsInDatabase ? 'Update' : 'Add'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
