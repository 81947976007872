import { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CurrencyPoundIcon,
  PencilIcon,
  QrCodeIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftRightIcon,
  BellIcon,
  CheckIcon,
  BoltIcon,
  CakeIcon,
  TableCellsIcon,
  HeartIcon,
  SunIcon,
  StarIcon,
  LinkIcon,
  TvIcon,
  ClockIcon,
  LightBulbIcon,
  CogIcon,
  KeyIcon,
  ClipboardDocumentListIcon

} from '@heroicons/react/24/outline'
import CleanTable from './orderprogress'
import Tables from './tables'
import { Link } from 'react-router-dom'
import LiveChat from './livechat'
import {ListBulletIcon, PlusIcon } from '@heroicons/react/20/solid'
import FutureTables from './futureonlinebookings'
import PartyHistory from './partyhistory'
import StaffHome from './staffhome'
import Stats from './stats'
import CustomerEnquires from './customercontact'
import ReportsHome from './reportshome'
import Weather from './weatherdata'
import BranchSettings from './branchsettingshome'
import Buzzer from './audiochat'
import CreateBuzzer from './buzzer'
import { AnimatePresence, motion } from 'framer-motion'


const initialTabs = [
  { name: 'Stats', component: <Stats />, icon: <ListBulletIcon /> },
  { name: 'Tables', component: <Tables />, icon: <ListBulletIcon /> },
  { name: 'Live Orders', component: <CleanTable />, icon: <ChatBubbleBottomCenterIcon /> },
  { name: 'Chat', component: <LiveChat />, icon: <DocumentDuplicateIcon />, hasUnreadMessages: false },
  { name: 'Customer Chat', component: <CustomerEnquires />, icon: <DocumentDuplicateIcon />, hasUnreadMessages: false },
  { name: 'Parties', component: <PartyHistory />, icon: <CalendarIcon /> },
  { name: 'Staff', component: <StaffHome />, icon: <UsersIcon /> },
  { name: 'Reports', component: <ReportsHome />, icon: <ChartPieIcon /> },
  { name: 'Settings', component: <BranchSettings />, icon: <FolderIcon /> },
];





const navigation = [
  { name: 'Home', href: '/dashboard', icon: HomeIcon, current: false},
  { name: 'Till', href: '/till', icon: CurrencyPoundIcon, current: true },
  { name: 'Make a Booking', href: '/reservations', icon: PencilIcon, current: false },
 
  { name: 'Control Panel', href: '/controlpanel', icon: BoltIcon, current: false },
  { name: 'Kitchen', href: '/kitchen', icon: CakeIcon, current: false },
  { name: 'Campaigns', href: '/edithome', icon: PlusIcon, current: false },
  { name: 'Customer Screen', href: '/customerscreen', icon: TvIcon, current: false },
  
  { name: 'Shopping List', href: '/shoppinglist', icon: ClipboardDocumentListIcon, current: false },
  { name: 'Stock Control', href: '/settings', icon: CogIcon, current: false },
  { name: 'Master Close', href: '/masterclose', icon: KeyIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [messages, setMessages] = useState([])
  const [tabs, setTabs] = useState(initialTabs);





  // const tabs = [
  //   { name: 'Stats', component: <Stats />, icon: <ListBulletIcon /> },

  //   { name: 'Live Tables', component: <Tables />, icon: <ListBulletIcon /> },
  //   { name: 'Future Bookings', component: <FutureTables />, icon: <ChatBubbleBottomCenterIcon /> },
  //   { name: 'Chat', component: <LiveChat />, icon: <DocumentDuplicateIcon /> },
  //   { name: 'Customer Chat', component: <CustomerEnquires />, icon: <DocumentDuplicateIcon /> },
  //   { name: 'Parties', component: <PartyHistory />, icon: <CalendarIcon /> },
  //   {name: 'Staff', component: <StaffHome />, icon: <UsersIcon />},
  //   { name: 'Reports', component: <ReportsHome />, icon: <ChartPieIcon /> },
  //   { name: 'Settings', component: <BranchSettings />, icon: <FolderIcon /> },

  
  
  // ];



  const [currentTab, setCurrentTab] = useState(tabs[0].name);

  const renderComponent = () => {
    const currentTabData = tabs.find((tab) => tab.name === currentTab);
    return currentTabData ? currentTabData.component : null;
  };



  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/messages', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
  
        const customerChatResponse = await fetch('https://cheekydino.com/api/customerchat', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
  
        const data = await response.json();
        const customerChatData = await customerChatResponse.json();
  
        // Filter messages and customer chat data
        const filteredData = data.data.filter((message) => message.replied === 0);
        const filteredCustomerChatData = customerChatData.data.filter((message) => message.Replied === 0);
  
        setMessages(filteredData);
  
        // Update tabs with unread messages info
        const updatedTabs = initialTabs.map((tab) => {
          if (tab.name === 'Chat') {
            return { ...tab, hasUnreadMessages: filteredData.length > 0 };
          }
          if (tab.name === 'Customer Chat') {
            return { ...tab, hasUnreadMessages: filteredCustomerChatData.length > 0 };
          }
          return tab;
        });
  
        setTabs(updatedTabs);
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };
  
    fetchMessages(); // Initial fetch
  
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 1000); // Poll every second
  
    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
    };
  }, []);

  
  



  return (
    <>
      <div>
        <Dialog open={sidebarOpen} onClose={() => setSidebarOpen(false)} className="relative z-50 lg:hidden">
          <div className="fixed inset-0 bg-purple-500/80 transition-opacity duration-300 ease-linear" aria-hidden="true" />
          <Transition 
            as="div"
            className="fixed inset-0 flex"
            enter="transition-transform ease-in-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            show={sidebarOpen} // Add this line

          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 transform bg-white px-6 pb-2 ring-1 ring-white/10">
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </button>
              </div>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="versa.gif"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="-mx-2 flex-1 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-white text-purple-900'
                              : 'text-blue-700 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-white lg:pb-4">
          <div className="flex h-16 shrink-0 items-center justify-center">
            <img
              alt="Your Company"
              src="versa.gif"
              className="h-8 w-auto"
            />
          </div>
          <nav className="mt-8">
            <ul role="list" className="flex flex-col items-center space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-indigo-500 text-white' : 'text-blue-700 hover:bg-indigo-500 hover:text-white',
                      'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6',
                    )}
                  >
                    <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                    <span className="sr-only">{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-blue-300 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-400 lg:hidden">
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <img
              alt=""
              src="versa.gif"
              className="h-8 w-8 rounded-full bg-gray-800"
            />
          </a>
        </div>
  

        <main className="lg:pl-20">

        <div className="xl:pl-96">
  <div>
    <div className="sm:hidden sticky-top">
      <label htmlFor="tabs" className="sr-only">
        Select a tab
      </label>
      <select
        id="tabs"
        name="tabs"
        value={currentTab}
        onChange={(e) => setCurrentTab(e.target.value)}
        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 bg-white"
      >
        {tabs.map((tab) => (
          <option key={tab.name}>{tab.name}</option>
        ))}
      </select>
    </div>
    <div className="hidden sm:block">
    <nav aria-label="Tabs" className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
  {tabs.map((tab, tabIdx) => (
    <motion.button
      key={tab.name}
      onClick={() => setCurrentTab(tab.name)}
      aria-current={tab.name === currentTab ? 'page' : undefined}
      className={classNames(
        tab.name === currentTab
          ? 'text-gray-900'
          : tab.hasUnreadMessages
          ? 'text-blue-700 bg-blue-100'
          : 'text-blue-700 hover:text-white hover:bg-indigo-500',
        tabIdx === 0 ? 'rounded-l-lg' : '',
        tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
        'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-2 text-center text-sm font-medium hover:bg-blue-700 focus:z-10',
      )}
      whileTap={{ scale: 0.95 }} // Button tap animation
      whileHover={{ scale: 1.05 }} // Button hover animation
      animate={
        tab.hasUnreadMessages && tab.name === 'Chat'
          ? {
              backgroundColor: ['#ffffff', '#0023bc', '#ffffff'], // Blue flashing effect
              scale: [1, 1.05, 1], // Expanding effect
            }
          : tab.hasUnreadMessages && tab.name === 'Customer Chat'
          ? {
              backgroundColor: ['#ffffff', '#6a0dad', '#ffffff'], // Purple flashing effect
              scale: [1, 1.05, 1], // Expanding effect
            }
          : {}
      }
      transition={{
        backgroundColor: {
          duration: 1.5, // Slower flash for Chat
          repeat: tab.hasUnreadMessages && tab.name === 'Chat' ? Infinity : 0,
        },
        scale: {
          duration: 1.5, // Slower expand for Chat
          repeat: tab.hasUnreadMessages && tab.name === 'Chat' ? Infinity : 0,
        },
        ...(tab.name === 'Customer Chat' && {
          backgroundColor: {
            duration: 3.0, // Slower flash for Customer Chat
            repeat: tab.hasUnreadMessages && tab.name === 'Customer Chat' ? Infinity : 0,
          },
          scale: {
            duration: 3.0, // Slower expand for Customer Chat
            repeat: tab.hasUnreadMessages && tab.name === 'Customer Chat' ? Infinity : 0,
          },
        }),
      }}
    >
      <span className="ml-2">{tab.name}</span>
      {tab.hasUnreadMessages && (
        <span className="absolute top-0 right-0 block w-2.5 h-2.5 rounded-full bg-blue-700" />
      )}
    </motion.button>
  ))}
</nav>

    </div>
  </div>
  <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
    <AnimatePresence>
      {renderComponent() && (
        <motion.div
          key={currentTab}
          initial={{ opacity: 0, scale: 0.9 }} // Initial state
          animate={{ opacity: 1, scale: 1 }} // Animate to this state
          exit={{ opacity: 0, scale: 0.9 }} // Exit state
          transition={{ duration: 0.3 }} // Transition duration
        >
          {renderComponent()}
        </motion.div>
      )}
    </AnimatePresence>
  </div>
</div>

    </main>

        <aside className="fixed inset-y-0 left-20 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
         <CleanTable />

        </aside>
      </div>
      <div className="fixed top-4 right-4 z-50">
        <Buzzer />
      </div>
    </>
  )
}
