import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function MoveTables() {
  const [sessions, setSessions] = useState([]);
  const [futureSessions, setFutureSessions] = useState([]); // New state for future sessions
  const [cleanTables, setCleanTables] = useState([]); // New state for tables to be cleaned
  const [nav, setNav] = useState(false);
  const [hoveredTable, setHoveredTable] = useState(null);
  const [tableData, setTableData] = useState([]);


  console.log(futureSessions);

  useEffect(() => {
    const fetchAdminData = async () => {

        try {
            const response = await fetch("https://cheekydino.com/api/admin", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });

                if (!response.ok) {
                throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const parsedTableData = JSON.parse(data.data[0].TableData); // JSON.parse here
                setTableData(parsedTableData);

            
        } catch (error) {

            console.error('There was an error!', error);
            
        }
      
    }

    fetchAdminData();

    // fetch 
  }, []);

  const handleTableHover = (table) => {
    setHoveredTable(table);
  };

  const handleTableHoverOut = () => {
    setHoveredTable(null);
  };

  const state = useLocation();
  const locationState = state.state.moveTable;

  useEffect(() => {
    const fetchSessions = async () => {
      const today = format(new Date(), 'yyyy-MM-dd');
  
      try {
        const response = await fetch(`https://cheekydino.com/api/sessionbookings?date=${today}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch sessions');
        }
  
        const data = await response.json();
        const sessionsData = data.data;
  
        const todaysSessions = sessionsData.filter(
          session => session.Arrived === 1 && session.LeftCenter === 0
        );
  
        const currentTime = new Date(`1970-01-01T${format(new Date(), 'HH:mm')}:00`);
        const futureSessionsData = sessionsData.filter(session => {
          const sessionStartTime = new Date(`1970-01-01T${session.TimeslotFrom}:00`);
          const sessionEndTime = new Date(`1970-01-01T${session.TimeslotTo}:00`);
          return (
            session.Date === today &&
            session.Arrived === 1 &&
            session.LeftCenter === 0 &&
            currentTime >= sessionStartTime &&
            currentTime <= sessionEndTime
          );
        });
  
        const cleanTableSessions = sessionsData.filter(
          session =>
            session.Date === today &&
            session.Arrived === 1 &&
            session.LeftCenter === 1 &&
            session.CleanTable === 1 &&
            session.TableCleaned === 0
        );
  
        setSessions(todaysSessions);
        setFutureSessions(futureSessionsData);
        setCleanTables(cleanTableSessions);
        console.log(cleanTableSessions);
        console.log(futureSessionsData);
        console.log(todaysSessions);
      } catch (error) {
        console.error('Failed to fetch sessions:', error);
      }
    };
  
    fetchSessions();
  }, []);

    const navigate = useNavigate();

    const handleTableClick = async (table) => {

try {
    const response = await fetch(`https://cheekydino.com/api/updatetable`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            table: table,
            id: locationState.id,
        }),
        });

        if (!response.ok) {
        throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data);
        navigate('/dashboard');


    
} catch (error) {
    console.error('There was an error!', error);
    
}      
    };

    const getTableColor = (session) => {
        const currentTime = format(new Date(), 'HH:mm');
        const sessionStartTime = session.TimeslotFrom.split('-')[0].trim();
        const sessionEndTime = session.TimeslotFrom.split('-')[1].trim();

        if (session.CleanTable === true && session.TableCleaned === false) {
        return 'bg-purple-500 animate-pulse';
        } else if (currentTime >= sessionStartTime && currentTime <= sessionEndTime) {
        return 'bg-blue-500';
        } else {
        return 'bg-red-500';
        }
    };

    return (
        <div className='flex'>
        <div className='w-1/2 flex flex-col items-center justify-center bg-gray-100'>
            <h3 className='text-2xl font-bold mb-5'>Details</h3>
            <p> Name: {locationState.name}</p>
            <p> Table: {locationState.number}</p>
            <p> Guests: {locationState.guests}</p>
            <p> TimeSlot: {locationState.TimeslotFrom} to {locationState.TimeSlotTo}</p>
            <p>Arrived: {locationState.timeArrived}</p>
        </div>
        
        <div className='w-1/2'>
            <div className="grid grid-cols-8 grid-rows-5 gap-4 p-10 border border-color-black" style={{ boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)' }}>
            {tableData.map((table) => {
                const session = sessions.find(session => session.Table === table.table);
                const futureSession = futureSessions.find(session => session.Table === table.table); // Check if the table is in the futureSessions list
                const cleanTable = cleanTables.find(session => session.Table === table.table); // Check if the table is in the cleanTables list

                let tableRow = table.location.y + 1;
                let tableCols = `${table.location.x + 1} / span 1`;

                if (tableRow === 2 || tableRow === 4) {
                const tablesInRow = tableData.filter(t => t.location.y === tableRow - 1);
                tablesInRow.reverse();
                tableCols = `${tablesInRow[table.location.x].location.x + 1} / span 1`;
                }

                const sessionName = session ? session.Name : '';
                const sessionTimeslot = session ? session.TimeslotFrom : '';
                const sessionTimeslotTo = session ? session.TimeslotTo : '';
                const sessionEmail = session ? session.Email : '';

                return (
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    key={table.table}
                    className={`p-2 ${table.shape === 'square' ? 'w-8 h-8 mt-2 mb-2' : 'w-8 h-8 rounded-full mt-2 mb-2'} 
                    ${cleanTable ? 'bg-purple-500 animate-pulse' : futureSession ? 'bg-blue-500' : session ? 'bg-red-500' : 'bg-green-500'}`}
                    style={{ gridColumn: tableCols, gridRow: `${tableRow} / span 1`, border: '2px solid gray-300', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)' }}
                    onClick={() => handleTableClick(table.table)}
                    onMouseEnter={() => handleTableHover(table.table)}
                    onMouseLeave={() => handleTableHoverOut()}
                >
                    <div className="table-info">
                    <span className="text-white">{table.table}</span>
                    </div>
                    <div className="timeslot-info">
                    {hoveredTable === table.table && (
                        <span className="text-xs text-gray-500 block mt-5 mb-2">
                        {session ? `${session.TimeslotFrom}-${session.TimeslotTo} (${session.Name})` : 'Table available'}
                        </span>
                    )}
                    </div>
                </motion.button>
                );
            })}
            </div>
        </div>
        </div>
    );
    }

    export default MoveTables;
