import { useState, useEffect } from 'react';
import { format, set } from 'date-fns';



export default function PriceList() {
  const [pricesModalOpen, setPricesModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('Children');
  const [activate, setActivate] = useState('No');
  const [expiry, setExpiry] = useState('No');
  const [expiryDate, setExpiryDate] = useState('');
  const [people, setPeople] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [nameedit, setNameEdit] = useState('');
  const [priceedit, setPriceEdit] = useState('');
  const [categoryedit, setCategoryedit] = useState('Children');
  const [activateedit, setActivateedit] = useState('No');
  const [expiryedit, setExpiryedit] = useState('No');
  const [expiryDateedit, setExpiryDateedit] = useState('');
  const [startDateedit, setStartDateedit] = useState('');
  const [reset, setReset] = useState(false);
  const [specialOffers, setSpecialOffers] = useState([]);
  const [kitchenSpecialOffer, setKitchenSpecialOffer] = useState('');
  const [selectedOption, setSelectedOption] = useState('date');
  const [selectedDays, setSelectedDays] = useState([]);
  const [time, setTime] = useState('Yes');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');





    useEffect(() => {
    const fetchPrices = async () => {
        try {
            const response = await fetch('https://cheekydino.com/api/prices', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setPeople(data.data)
            } else {
                console.error('Failed to fetch prices');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchPrices();

    // call fetchPrices every 5 seconds

    const intervalId = setInterval(() => {
        fetchPrices();
    }, 10000); // Poll every second

    return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
    };
}, []);

useEffect(() => {
   

      const fetchSpecialOffers = async () => {
        try {
            const response = await fetch('https://cheekydino.com/api/menuitems', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                // filter from alpahbetical order
                data.data.sort((a, b) => a.Name.localeCompare(b.Name));
                
                
                setSpecialOffers(data.data)
            } else {
                console.error('Failed to fetch special offers');
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    fetchSpecialOffers();


}, []);

    

const handleEditPrices = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch(`https://cheekydino.com/api/editprices/${selectedPerson.Name}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Name: nameedit,
        Price: priceedit,
        Category: categoryedit,
        Active: activateedit === 'Yes' ? 1 : 0,
        Expiry: expiryedit === 'Yes' ? 1 : 0,
        StartDate: expiryedit === 'Yes' && selectedOption === 'date' ? startDateedit : null,
        ExpiryDate: expiryedit === 'Yes' && selectedOption === 'date' ? expiryDateedit : null,
        Days: expiryedit === 'Yes' && selectedOption === 'day' ? selectedDays : [], // Ensure this is an array
        SetDay: expiryedit === 'Yes' && selectedOption === 'day' ? 1 : 0,
        StartTime: time === 'Yes' ? startTime : null,
        EndTime: time === 'Yes' ? endTime : null,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      setSelectedPerson(null);
    } else {
      console.error('Failed to save prices');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};






const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];




const handleSavePrices = async (e) => {
  e.preventDefault();

  // Split the value to extract the id and name
  const [kitchenId, kitchenName] = kitchenSpecialOffer ? kitchenSpecialOffer.split('|') : [null, null];

  const data = {
    Name: name,
    Price: price,
    Category: category,
    Active: activate === 'Yes' ? 1 : 0,
    Expiry: expiry === 'Yes' ? 1 : 0,
    StartDate: expiry === 'Yes' && selectedOption === 'date' ? startDate : null,
    ExpiryDate: expiry === 'Yes' && selectedOption === 'date' ? expiryDate : null,
    Days: expiry === 'Yes' && selectedOption === 'day' ? selectedDays : [], // Ensure this is an array
    SetDay: expiry === 'Yes' && selectedOption === 'day' ? 1 : 0,
    StartTime: time === 'Yes' ? startTime : null,
    EndTime: time === 'Yes' ? endTime : null,
    KitchenItem: category === 'Special Offer' ? kitchenName : null, // Store the name
    KitchenItemId: category === 'Special Offer' ? kitchenId : null, // Store the id
    PreviousPrice: category === 'Special Offer' ? price : null,
  };

  try {
    const response = await fetch('https://cheekydino.com/api/prices', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const result = await response.json();
      console.log(result);
      setPricesModalOpen(false);
    } else {
      console.error('Failed to save prices');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};



  return (
    <>
 {selectedPerson && (
  <div className="mx-auto max-w-md sm:max-w-3xl">
    <div>
      <div className="text-center">
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 48 48"
          aria-hidden="true"
          className="mx-auto h-12 w-12 text-gray-400"
        >
          <path
            d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Set Prices</h2>
      </div>
      <form onSubmit={handleEditPrices} className="mt-6 space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
            Name - {selectedPerson.Name}
          </label>
          <input
            onChange={(e) => setNameEdit(e.target.value)}
            id="name"
            name="name"
            type="text"
            value={nameedit}
            placeholder="Under 2s"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
            Price - £{selectedPerson.Price}
          </label>
          <input
            onChange={(e) => setPriceEdit(e.target.value)}
            id="price"
            name="price"
            type="number"
            value={priceedit}
            placeholder="10.00"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
            Category - {selectedPerson.Category}
          </label>
          <select
            id="category"
            name="category"
            value={categoryedit}
            onChange={(e) => setCategoryedit(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>Adults</option>
            <option>Children</option>
            <option>Sibling</option>
            <option>Additional Adults</option>
            <option>Event</option>
          </select>
        </div>

        <div>
          <label htmlFor="activate" className="block text-sm font-medium leading-6 text-gray-900">
            Activate - {selectedPerson.Active}
          </label>
          <select
            id="activate"
            name="activate"
            value={activateedit}
            onChange={(e) => setActivateedit(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>

        <div>
          <label htmlFor="expiry" className="block text-sm font-medium leading-6 text-gray-900">
            Set Expiry Date or Day - {selectedPerson.Expiry}
          </label>
          <select
            id="expiry"
            name="expiry"
            value={expiryedit}
            onChange={(e) => setExpiryedit(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>No</option>
            <option>Yes</option>
          </select>
        </div>

        {expiryedit === 'Yes' && (
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">Set for Day or Date</legend>
            <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  id="date"
                  name="schedule-option"
                  type="radio"
                  value="date"
                  checked={selectedOption === 'date'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="date" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Date
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="day"
                  name="schedule-option"
                  type="radio"
                  value="day"
                  checked={selectedOption === 'day'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="day" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Day
                </label>
              </div>
            </div>
          </fieldset>
        )}

        {expiryedit === 'Yes' && selectedOption === 'date' && (
          <div>
            <div className="mt-2">
              <label htmlFor="startDate" className="block text-sm font-medium leading-6 text-gray-900">
                Start Date - {selectedPerson.StartDate}
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDateedit}
                onChange={(e) => setStartDateedit(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="mt-2">
              <label htmlFor="expiryDate" className="block text-sm font-medium leading-6 text-gray-900">
                Expiry Date - {selectedPerson.ExpiryDate}
              </label>
              <input
                type="date"
                id="expiryDate"
                name="expiryDate"
                value={expiryDateedit}
                onChange={(e) => setExpiryDateedit(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}

{expiryedit === 'Yes' && selectedOption === 'day' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Select Days of the Week</label>
            <div className="mt-2 grid grid-cols-3 gap-2">
              {daysOfWeek.map((day) => (
                <div key={day} className="flex items-center">
                  <input
                    id={day}
                    name="days"
                    type="checkbox"
                    value={day}
                    onChange={(event) => {
                      const { checked, value } = event.target;
                      setSelectedDays((prev) =>
                        checked ? [...prev, value] : prev.filter((day) => day !== value)
                      );
                    }}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor={day} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {day}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {expiry === 'Yes' && (
          <fieldset className="mt-6">
            <legend className="text-sm font-semibold leading-6 text-gray-900">Set Time (Only Applicable For Special Offers)</legend>
            <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  id="time-yes"
                  name="time-option"
                  type="radio"
                  value="Yes"
                  checked={time === 'Yes'}
                  onChange={(e) => setTime(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="time-yes" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="time-no"
                  name="time-option"
                  type="radio"
                  value="No"
                  checked={time === 'No'}
                  onChange={(e) => setTime(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="time-no" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  No
                </label>
              </div>
            </div>
          </fieldset>
        )}

        {time === 'Yes' && (
          <div>
            <div className="mt-2">
              <label htmlFor="startTime" className="block text-sm font-medium leading-6 text-gray-900">
                Start Time
              </label>
              <input
                type="time"
                id="startTime"
                name="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="mt-2">
              <label htmlFor="endTime" className="block text-sm font-medium leading-6 text-gray-900">
                End Time
              </label>
              <input
                type="time"
                id="endTime"
                name="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}

        <div className="mt-6 flex justify-end gap-x-6">
          <button type="submit" className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">
            Update Prices
          </button>
         
        </div>
      </form>
    </div>
  </div>
)}


      
    
{pricesModalOpen && (
  <div className="mx-auto max-w-md sm:max-w-3xl">
    <div>
      <div className="text-center">
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 48 48"
          aria-hidden="true"
          className="mx-auto h-12 w-12 text-gray-400"
        >
          <path
            d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Set Session Prices</h2>
      </div>
      <form onSubmit={handleSavePrices} className="mt-6 space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
            Name
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            id="name"
            name="name"
            type="text"
            value={name}
            placeholder="Under 2s"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
            Price
          </label>
          <input
            onChange={(e) => setPrice(e.target.value)}
            id="price"
            name="price"
            type="number"
            value={price}
            placeholder="10.00"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
            Category
          </label>
          <select
            id="category"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>Adults</option>
            <option>Children</option>
            <option>Event</option>
            <option>Additional Adults</option>
            <option>Special Offer</option>
          </select>
        </div>

        {category === 'Special Offer' && (
  <div>
    <label htmlFor="specialOffer" className="block text-sm font-medium leading-6 text-gray-900">
      Special Offer
    </label>
    <p className="text-sm text-gray-500">You can use this price to advertise in the Campaign Centre</p>
    <select
      id="specialOffer"
      name="specialOffer"
      value={kitchenSpecialOffer}
      onChange={(e) => setKitchenSpecialOffer(e.target.value)}
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      {specialOffers.map((offer) => (
        <option key={offer.id} value={`${offer.id}|${offer.Name}`}>
          {offer.Name} - £{offer.Price}
        </option>
      ))}
    </select>
  </div>
)}

        <div>
          <label htmlFor="activate" className="block text-sm font-medium leading-6 text-gray-900">
            Activate
          </label>
          <select
            id="activate"
            name="activate"
            value={activate}
            onChange={(e) => setActivate(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>

        <div>
          <label htmlFor="expiry" className="block text-sm font-medium leading-6 text-gray-900">
            Set Expiry Day or Date
          </label>
          <select
            id="expiry"
            name="expiry"
            value={expiry}
            onChange={(e) => setExpiry(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>No</option>
            <option>Yes</option>
          </select>
        </div>

        {expiry === 'Yes' && (
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">Set for Day or Date</legend>
            <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  id="date"
                  name="schedule-option"
                  type="radio"
                  value="date"
                  checked={selectedOption === 'date'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="date" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Date
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="day"
                  name="schedule-option"
                  type="radio"
                  value="day"
                  checked={selectedOption === 'day'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="day" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Day
                </label>
              </div>
            </div>
          </fieldset>
        )}

        {expiry === 'Yes' && selectedOption === 'date' && (
          <div>
            <div className="mt-2">
              <label htmlFor="startDate" className="block text-sm font-medium leading-6 text-gray-900">
                Start Date
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="mt-2">
              <label htmlFor="expiryDate" className="block text-sm font-medium leading-6 text-gray-900">
                Expiry Date
              </label>
              <input
                type="date"
                id="expiryDate"
                name="expiryDate"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}

        {expiry === 'Yes' && selectedOption === 'day' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Select Days of the Week</label>
            <div className="mt-2 grid grid-cols-3 gap-2">
              {daysOfWeek.map((day) => (
                <div key={day} className="flex items-center">
                  <input
                    id={day}
                    name="days"
                    type="checkbox"
                    value={day}
                    onChange={(event) => {
                      const { checked, value } = event.target;
                      setSelectedDays((prev) =>
                        checked ? [...prev, value] : prev.filter((day) => day !== value)
                      );
                    }}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor={day} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {day}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {expiry === 'Yes' && (
          <fieldset className="mt-6">
            <legend className="text-sm font-semibold leading-6 text-gray-900">Set Time (Only Applicable For Special Offers)</legend>
            <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  id="time-yes"
                  name="time-option"
                  type="radio"
                  value="Yes"
                  checked={time === 'Yes'}
                  onChange={(e) => setTime(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="time-yes" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  Yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="time-no"
                  name="time-option"
                  type="radio"
                  value="No"
                  checked={time === 'No'}
                  onChange={(e) => setTime(e.target.value)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="time-no" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  No
                </label>
              </div>
            </div>
          </fieldset>
        )}

        {time === 'Yes' && (
          <div>
            <div className="mt-2">
              <label htmlFor="startTime" className="block text-sm font-medium leading-6 text-gray-900">
                Start Time
              </label>
              <input
                type="time"
                id="startTime"
                name="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="mt-2">
              <label htmlFor="endTime" className="block text-sm font-medium leading-6 text-gray-900">
                End Time
              </label>
              <input
                type="time"
                id="endTime"
                name="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}

        <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
          <button
            type="submit"
            className="block w-full rounded-md bg-purple-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save Prices
          </button>
        </div>
      </form>
    </div>
  </div>
)}

      <div className="px-4 sm:px-6 lg:px-8 mt-10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Session Prices</h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              onClick={() => setPricesModalOpen(true)}
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Price
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Price
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Category
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Active
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Expiry
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Start Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Expiry Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Days
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.Name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">£{person.Price}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Category}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Active === 1 ? 'Yes' : 'No'}</td>



                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Expiry}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {person.StartDate ? format(new Date(person.StartDate), 'yyyy-MM-dd') : ''}
</td>
<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {person.ExpiryDate ? format(new Date(person.ExpiryDate), 'yyyy-MM-dd') : ''}
</td>
<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {person.Days}
</td>



                      <td className="relative flex justify-between whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                       
                        <button onClick={() => {
                            const updatePerson = async () => {
                                try {
                                    const response = await fetch(`https://cheekydino.com/api/pricesactive/${person.Name}`, {
                                        method: 'PUT',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                            Active: person.Active === 1 ? 0 : 1,
                                        }),
                                    });

                                    if (response.ok) {
                                        const data = await response.json();
                                        console.log(data);
                                    } else {
                                        console.error('Failed to update person');
                                    }
                                } catch (error) {
                                    console.error('Error:', error);
                                }
                            };

                            updatePerson();

                        }

                        }
                         type="button"
className={`block rounded-md mr-3 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${person.Active === 1 ? 'bg-red-500 hover:bg-green-400 focus-visible:outline-red-600' : 'bg-green-500 hover:bg-red-400 focus-visible:outline-green-600'}`}
>
  {person.Active === 1 ? 'Deactivate' : 'Activate'}

                        </button>

                      <button onClick={() => {
                            setSelectedPerson(person);

                            }}

                            
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Edit
                        </button>
                        <button onClick={() => {
    const deletePerson = async () => {
        try {
            console.log('button clicked');
            const response = await fetch(`https://cheekydino.com/api/prices/${person.Name}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
            } else {
                console.error('Failed to delete person');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    deletePerson();
}}
type="button"
className="block rounded-md ml-3 bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
>
    Delete
</button>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
