import React, { useEffect, useState } from 'react';
import {format} from 'date-fns';

const PopulationTable = () => {
  const [populationData, setPopulationData] = useState({ cols: [], rows: [] });
  const [reportData, setReportData] = useState(null);

  // Fetch daily AI reports
  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/reportai', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },

        });


        if (!response.ok) {
          throw new Error('Failed to fetch report data');
        }


        const data = await response.json();
        console.log('Report data:', data);
        const filteredData = data.data.filter((report) => report.AIAnalytics === 1);
        setReportData(filteredData);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchReportData();
  }, []);

  // Fetch population data
  useEffect(() => {
    const fetchPopulationData = async () => {
      const jsonData = {
        cols: [
          { id: "col0", label: "YEAR", type: "string" },
          { id: "col1", label: "TOWN", type: "string" },
          { id: "col2", label: "AGES", type: "string" },
          { id: "col4", label: "VALUE", type: "number" }
        ],
        rows: [
          { c: [{ v: "2021" }, { v: "Maidstone" }, { v: "Total" }, { v: 175782 }] },
          { c: [{ v: "2021" }, { v: "Maidstone" }, { v: "Aged 4 years and under" }, { v: 10390 }] },
          { c: [{ v: "2021" }, { v: "Maidstone" }, { v: "Aged 5 to 9 years" }, { v: 10874 }] }
        ]
      };
      setPopulationData(jsonData);
    };

    fetchPopulationData();
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-8">Population and AI Report Data</h1>
      
      {/* Population Data Table */}
      <div className="flow-root mb-8">
        <h2 className="text-xl font-semibold mb-4">Population Data for Maidstone</h2>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {populationData.cols.map((col) => (
                      <th
                        key={col.id}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {col.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {populationData.rows.map((row, index) => (
                    <tr key={index}>
                      {row.c.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6"
                        >
                          {cell.v}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* AI Report Data Table */}
      {reportData && (
        <div className="flow-root">
          <h2 className="text-xl font-semibold mb-4">AI Daily Reports</h2>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/3" // Larger width for Content column
                      >
                        Content
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {reportData.map((report, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {format(new Date(report.Date), 'dd/MM/yy')} {/* Format the date here */}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {report.Name}
                        </td>
                        <td className="whitespace-pre-wrap px-3 py-4 text-sm text-gray-500 w-2/3">
                          {report.Content}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopulationTable;
