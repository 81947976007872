import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import LoadingComponent from "./loading";

export default function ClosingTime() {
    const navigate = useNavigate();
    const [openingTime, setOpeningTime] = useState(null);
    const [closingTime, setClosingTime] = useState(null);
    const [close, setClose] = useState(false);
    const [closeTime, setCloseTime] = useState('');
    const [sessionLength, setSessionLength] = useState(null);
    const [sessions, setSessions] = useState([]); // Define the sessions state

    // Get today's date and the corresponding day of the week
    const date = new Date();
    const day = format(date, 'EEEE');

    // Fetch admin data when the component mounts
    useEffect(() => {
        fetchAdminData();
    }, []);

    const fetchAdminData = async () => {
        try {
            const response = await fetch(`https://cheekydino.com/api/admin/open/${day}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch admin data');
            }

            const data = await response.json();
            const dayData = data.data[0];
            const dayKey = Object.keys(dayData)[0];
            const parsedDayData = JSON.parse(dayData[dayKey]);

            setOpeningTime(parsedDayData.openingTime);
            setClosingTime(parsedDayData.closingTime);
            setSessionLength(parsedDayData.sessionLength); // Assuming this is stored in your database
            setSessions(parsedDayData.sessions); // Store the existing sessions

        } catch (error) {
            console.error('Error fetching admin data:', error);
        }
    };

    const handleSubmit = async () => {
        try {
            // Convert the new closing time to a Date object
            const newClosingTimeDate = new Date(`1970-01-01T${closeTime}:00Z`);
            
            // Calculate the session length from the first session in the existing sessions array
            if (sessions.length === 0) {
                throw new Error('No sessions available to calculate the length');
            }
    
            const firstSession = sessions[0];
            const sessionStart = new Date(`1970-01-01T${firstSession.start}:00Z`);
            const sessionEnd = new Date(`1970-01-01T${firstSession.end}:00Z`);
            const sessionLengthMinutes = (sessionEnd - sessionStart) / 60000;
    
            // Generate new session slots
            const newSessions = [];
            let currentSessionStart = new Date(`1970-01-01T${openingTime}:00Z`);
    
            while (currentSessionStart < newClosingTimeDate) {
                const currentSessionEnd = new Date(currentSessionStart.getTime() + sessionLengthMinutes * 60000);
    
                // Only add the session if it ends before the new closing time
                if (currentSessionEnd <= newClosingTimeDate) {
                    newSessions.push({
                        start: currentSessionStart.toISOString().substr(11, 5),
                        end: currentSessionEnd.toISOString().substr(11, 5),
                    });
                }
    
                // Increment the start time for the next session
                currentSessionStart = new Date(currentSessionStart.getTime() + 30 * 60000); // Every 30 minutes
            }
    
            // Prepare the updated data to send to the server
            const updatedDayData = {
                openingTime: openingTime,
                closingTime: closeTime,
                sessions: newSessions,
            };
    
            // Send the updated data to the server
            const updateResponse = await fetch(`https://cheekydino.com/api/admin/open/${day}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedDayData), // Send the data directly
            });
    
            if (!updateResponse.ok) {
                throw new Error('Failed to update closing time and sessions');
            }
    
            console.log(`Updated closing time and sessions for ${day}`);
            fetchAdminData(); // Optionally refresh data
    
        } catch (error) {
            console.error('Error updating closing time and sessions:', error);
        }
    };
    

    return (
        <div className="flex justify-between mt-2 mb-2">
            {openingTime && closingTime ? (
                <>
                    <h2 className="font-bold text-sm text-green-500">Opening Time: {openingTime}</h2>
                    <h2 className="font-bold text-sm text-blue-700 mr-3">Closing Time: {closingTime}</h2>
                    <button onClick={() => setClose(true)}
                        type="button"
                        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <ClockIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                    {close && (
                        <div>
                            <label htmlFor="time" className="block text-sm font-medium leading-6 text-gray-900">
                                Close Time
                            </label>
                            <div className="mt-2">
                                <input onChange={(e) => setCloseTime(e.target.value)}
                                    id="time"
                                    name="time"
                                    type="time"
                                    value={closeTime}
                                    placeholder="17:00"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className="mt-4">
                                <button type="submit" className="bg-indigo-600 text-white rounded-md p-2" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="font-bold text-black"><LoadingComponent/></div>
            )}
        </div>
    );
}
