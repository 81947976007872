import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

export default function Financial() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [financialData, setFinancialData] = useState({
    totalCafeOrders: 0,
    totalSessions: 0,
    totalClockins: 0,
    totalPartyBookings: 0,
    grandTotal: 0
  });

  const handleFilter = () => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`https://cheekydino.com/api/financials?startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        console.log('Failed to fetch data from API');
        return;
      }

      const data = await response.json();
      setFinancialData(data.data);
    } catch (error) {
      console.error('Error fetching financial data:', error);
    }
  };

  useEffect(() => {
    // Fetch data on initial load if startDate and endDate are set
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  const calculateGrandTotal = () => {
    const { totalCafeOrders, totalSessions, totalClockins, totalPartyBookings } = financialData;
    return (totalCafeOrders + totalSessions + totalPartyBookings) - totalClockins;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Financial Overview</h1>
          <p className="mt-2 text-sm text-gray-700">
            Summary of financials, including cafe orders, sessions, clock-ins, and party bookings.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <label htmlFor="startDate" className="ml-2 block text-sm font-medium leading-6 text-gray-900">
            Start Date
          </label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={startDate}
            onChange={(e) => setStartDate(format(new Date(e.target.value), 'yyyy-MM-dd'))}
            className="mt-2 block w-48 rounded-md border-gray-300 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <label htmlFor="endDate" className="block ml-2 text-sm font-medium leading-6 text-gray-900">
            End Date
          </label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={endDate}
            onChange={(e) => setEndDate(format(new Date(e.target.value), 'yyyy-MM-dd'))}
            className="mt-2 block w-48 rounded-md border-gray-300 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <button
            className="inline-flex mt-2 items-center ml-2 gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleFilter}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Total Cafe Orders
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Total Sessions
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Total Party Bookings
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Wages (Clockins)
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Grand Total
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <span className="text-green-600">£{financialData.totalCafeOrders.toFixed(2)}</span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="text-green-600">£{financialData.totalSessions.toFixed(2)}</span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="text-green-600">£{financialData.totalPartyBookings.toFixed(2)}</span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="text-red-600">-£{financialData.totalClockins.toFixed(2)}</span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="text-gray-900">£{calculateGrandTotal().toFixed(2)}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
