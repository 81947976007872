import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import Embed from './embed'; // Import Embed component

function ClockInTable() {
  const [clockIns, setClockIns] = useState([]);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      const fetchClockIns = async () => {
        try {
          const response = await fetch('https://cheekydino.com/api/allclockins', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setClockIns(data.data);
        } catch (error) {
          console.error('Failed to fetch clock ins:', error);
        }
      };

      fetchClockIns(); // Initial fetch

      const intervalId = setInterval(() => {
        fetchClockIns();
      }, 1000); // Poll every second

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      }
    }
  }, [isAuthenticated]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const correctPassword = '8568'; // Change this to your desired password

    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      setError('Incorrect password');
    }
  };

  const filteredClockIns = clockIns.filter((person) => {
    const date = new Date(person.Date);
    const formattedDate = format(date, 'yyyy-MM-dd');
    const isWithinDateRange = (!startDate || formattedDate >= startDate) && (!endDate || formattedDate <= endDate);
    const isSelectedStaff = !selectedStaff || person.StaffName === selectedStaff;
    return isWithinDateRange && isSelectedStaff;
  });

  const uniqueStaffNames = [...new Set(clockIns.map(person => person.StaffName))];

  const summaryData = filteredClockIns.reduce((summary, person) => {
    if (person.ClockOut) {
      const clockInTime = new Date(`1970-01-01T${person.ClockIn}Z`);
      const clockOutTime = new Date(`1970-01-01T${person.ClockOut}Z`);
      const breakStart = person.BreakStart ? new Date(`1970-01-01T${person.BreakStart}Z`) : null;
      const breakEnd = person.BreakEnd ? new Date(`1970-01-01T${person.BreakEnd}Z`) : null;

      let hoursWorked = Math.abs(clockOutTime - clockInTime) / 36e5;
      if (breakStart && breakEnd) {
        const breakTime = Math.abs(breakEnd - breakStart) / 36e5;
        hoursWorked -= breakTime;
      }

      if (!summary[person.StaffName]) {
        summary[person.StaffName] = {
          StaffName: person.StaffName,
          TotalHours: 0,
          TotalPay: 0,
        };
      }

      summary[person.StaffName].TotalHours += hoursWorked;
      summary[person.StaffName].TotalPay += person.TotalPay;
    }
    return summary;
  }, {});

  const summaryArray = Object.values(summaryData);

  if (!isAuthenticated) {
    return (
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="/versa.gif"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Management Access Only
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleLoginSubmit} method="POST" className="space-y-6">
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          {error && <p className="mt-4 text-red-600 text-sm text-center">{error}</p>}
        </div>
      </div>
    );
  }

 


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mt-5 mb-5">
      <Embed />
      </div>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Staff Information</h1>
          <p className="mt-2 text-sm text-gray-700">
            View and manage staff clock-in information.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CSVLink data={filteredClockIns} headers={[
            { label: "Name", key: "StaffName" },
            { label: "Date", key: "Date" },
            { label: "Clock In", key: "ClockIn" },
            { label: "Clock Out", key: "ClockOut" },
            { label: "Break Start", key: "BreakStart" },
            { label: "Break End", key: "BreakEnd" },
            { label: "Total Pay", key: "TotalPay" },
            { label: "Late", key: "Late" }
          ]}>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Download
            </button>
          </CSVLink>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Date Range
                </label>
                <div className="flex">
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <span className="mx-2 mt-2 text-gray-500">to</span>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Select Staff
                </label>
                <select
                  value={selectedStaff}
                  onChange={(e) => setSelectedStaff(e.target.value)}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">All</option>
                  {uniqueStaffNames.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Clock In
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Clock Out
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Break Start
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Break End
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total Pay
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Late
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredClockIns.map((person) => (
                    <tr key={person.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {person.StaffName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.Date}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.ClockIn}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.ClockOut || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.BreakStart || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.BreakEnd || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.TotalPay}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.Late}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <h2 className="text-lg font-medium text-gray-900">Summary</h2>
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total Hours Worked
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total Pay
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {summaryArray.map((summary) => (
              <tr key={summary.StaffName}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {summary.StaffName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {summary.TotalHours.toFixed(2)} hours
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  £{summary.TotalPay.toFixed(2)}
                </td>

              </tr>
              
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClockInTable;
