import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Online from './online'; // Assuming Online component is in the same directory
import ClockIn from './staffactions'; // Assuming ClockIn component is in the same directory
import ClockInData from './clockindata'; // Assuming ClockInData component is in the same directory
import Tasks from './stafftasks'; // Assuming Tasks component is in the same directory
import StaffDetails from './staffdetails'; // Assuming StaffDetails component is in the same directory
import BookShifts from './bookshifts'; // Assuming BookShifts component is in the same directory
import Shifts from './shifts'; // Assuming Shifts component is in the same directory

const MyAccount = () => <Shifts/>;
const Company = () =>  <ClockIn />; // Import ClockIn component
const TeamMembers = () => <Online />; // Default to Online component
const Billing = () => <ClockInData/>;
const Task = () => <Tasks/>;
const Details = () => <StaffDetails/>;

const tabs = [
  { name: 'Shifts', component: MyAccount, current: false },
  { name: 'Clock In', component: Company, current: false },
  { name: 'Online Staff', component: TeamMembers, current: true },
  {name: 'Tasks', component: Task, current: false}, // Set this as the default tab
  { name: 'Clock In Data', component: Billing, current: false },
  { name: 'Staff Details', component: Details, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [currentTab, setCurrentTab] = useState(tabs.find((tab) => tab.current));

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to update the selected tab */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={currentTab.name}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) =>
            setCurrentTab(tabs.find((tab) => tab.name === e.target.value))
          }
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setCurrentTab(tab)}
              className={classNames(
                tab.name === currentTab.name
                  ? 'bg-gray-100 text-gray-700'
                  : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {React.createElement(currentTab.component)}
      </div>
    </div>
  );
}
