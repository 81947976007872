import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";



const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [userEmail, setUserEmail] = useState("");

    const user = useContext(AuthContext);


    useEffect(() => {
        setUserEmail(user.email);
    }, [user]);








useEffect(() => {
    const fetchMessages = async () => {
      const token = localStorage.getItem('token'); // Replace this with your actual method to get the token

      try {
        const response = await fetch('https://cheekydino.com/api/customerenquiries', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setMessages(data.data); // Adjusted based on your backend response
      } catch (error) {
        console.error(error);
      }
    };



    fetchMessages();

// fetch messages every 5 seconds

    const interval = setInterval(() => {
      fetchMessages();
    }
    , 5000);

    return () => clearInterval(interval);

    

  }, [userEmail]);


   
  const sendMessage = async (event) => {
    event.preventDefault();
    const enquiryData = {
        To: "frontdesk@cheekydino.co.uk",
        Content: input,
        From: userEmail,
        Replied: 0,
        Marketing: 0,
        ToMarketing: "not applicable",
        createdAt: new Date().toISOString,
  
    };

    const token = localStorage.getItem('token'); // Replace this with your actual method to get the token

    try {
        const response = await fetch('https://cheekydino.com/api/addenquiry', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(enquiryData)
        });
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error(error);
      }

    setInput("");
  };

  return (
      <div className="flex flex-col h-full bg-gray-100" style={{height: '90vh'}}>
        <div className="bg-orange-500 text-white p-4 flex justify-between items-center">
          <h1 className="text-lg font-bold">Chat with us</h1>
          <span className="text-sm">{userEmail}</span>
        </div>
        <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>

        <div className="flex flex-col h-screen bg-purple-50 mt-10">
  {messages.map((message, index) => (
    <div key={index} className={`p-4 mb-4 rounded-lg mt-5 shadow-lg ${message.From === userEmail ? "bg-green-100 bg-opacity-75 self-center" : "bg-blue-50 bg-opacity-75 self-start"}`} style={{borderRadius: '20px'}}>
      <div style={{padding: '10px'}}>
        <div>{message.From}: {message.Content}</div>
      </div>
    </div>
  ))}
</div>
        </div>
        <form onSubmit={sendMessage} className="m-2">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            type="text"
            className="w-full p-2 border rounded-lg"
            placeholder="Type your message here..."
            style={{height: '100px'}}
          />
          <button type="submit" className="w-full p-2 mt-2 bg-indigo-500 text-white rounded-lg">
            Send
          </button>
        </form>
      </div>
    );
    
};


export default ChatComponent;
