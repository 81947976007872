import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function LoadingComponent() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      {/* Background GIF */}
      <div
        className="absolute inset-0 bg-cover bg-center z-40"
        style={{ backgroundImage: "url('/loading.gif')" }}
      ></div>
      
      {/* Loading Spinner */}
      <div className="z-50 flex flex-col items-center">
        <Cog8ToothIcon className="h-24 w-24 text-white animate-spin mb-4" />
        <p className="text-white text-xl animate-pulse">Loading...</p>
      </div>
    </div>
  );
}
