import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import StaffNav from "./staffNav";
import { addDays, isWithinInterval, parseISO } from "date-fns";
import {generateAIReport} from "./aireporttoday";
import LoadingComponent from "./loading";



// check if the program is running in electron






export default function MasterClose() {
  const [masterClose, setMasterClose] = useState(null); // State to hold masterclose status
  const [partyBookings, setPartyBookings] = useState([]); // State to hold party bookings
  const [sentEmails, setSentEmails] = useState([]); // State to hold sent emails
  const [loading, setLoading] = useState(false); // State to hold loading status

  // Get the print job from the Electron context
  



  // Fetch masterclose status on component mount
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch("https://cheekydino.com/api/close", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },

        });


        const data = await response.json();
        console.log(data.data[0]);  
        setMasterClose(data.data[0].MasterClose); // Assume the response has a masterclose field
      } catch (error) {
        console.log(error);
      }
    };

    fetchStatus();
  }, []);

  // Handle the master close action
  const handleClose = async () => {
    const date = format(new Date(), "yyyy-MM-dd");
    const time = format(new Date(), "HH:mm:ss");
  
    try {
      setLoading(true); // Show loading spinner or text
      await generateAIReport(); // Call the AI report function
  
      // Fetch the default slot data for today
      const fetchDefaultSlotForToday = async () => {
        try {
          const adminResponse = await fetch(`https://cheekydino.com/api/admin`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
  
          if (!adminResponse.ok) {
            throw new Error("Failed to fetch admin data");
          }
  
          const adminData = await adminResponse.json();
          const defaultSlotsString = adminData.data[0].DefaultSlots;
          console.log("Fetched default slots:", defaultSlotsString);
  
          const defaultSlots = JSON.parse(defaultSlotsString);
          const getToday = () => {
            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const today = new Date();
            return daysOfWeek[today.getDay()];
          };
  
          const today = getToday();
          console.log("Today is:", today);
  
          if (!defaultSlots[today]) {
            console.error(`No data found for ${today}`);
            return null;
          }
  
          const dayData = defaultSlots[today];
          console.log("Day data for today:", dayData);
  
          if (dayData && dayData.length > 0) {
            const dayKey = Object.keys(dayData[0])[0];
            const jsonData = dayData[0][dayKey];
            const parsedDayData = JSON.parse(jsonData);
  
            console.log("Today's Data:", parsedDayData);
  
            const response = await fetch(`https://cheekydino.com/api/amdindate`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                day: today,
                parsedDayData: parsedDayData,
              }),
            });
  
            if (response.ok) {
              console.log("Master Close");
              setMasterClose(1); // Update the master close state
            } else {
              throw new Error("Failed to close master");
            }
  
            return parsedDayData;
          } else {
            console.error(`No data found for ${today}`);
            return null;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      // Call the function to fetch today's data
      const todaysSlotData = await fetchDefaultSlotForToday();
      if (todaysSlotData) {
        console.log("Opening Time:", todaysSlotData.openingTime);
        console.log("Closing Time:", todaysSlotData.closingTime);
        console.log("Sessions:", todaysSlotData.sessions);
      }
  
      // Trigger AI report generation and show loading
  
      // After report and close process, call the master close endpoint
      const response = await fetch(`https://cheekydino.com/api/masterclose/${date}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          TimeLeft: time,
        }),
      });
  
      if (response.ok) {
        console.log("Master Close completed");
        setMasterClose(1); // Set master close state
      } else {
        throw new Error("Failed to close master");
      }
  
    } catch (error) {
      console.error("Error closing:", error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  

  // Handle the master reopen action

  const handleOpen = async () => {
    const today = new Date();
    const futureDate = addDays(today, 9);

    try {
        // Fetch all party bookings
        const partyResponse = await fetch(`https://cheekydino.com/api/allpartybookings`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!partyResponse.ok) {
            throw new Error("Failed to fetch party bookings");
        }

        const partyData = await partyResponse.json();
        const partyBookingsData = partyData.data;

        // Filter bookings that are within 9 days in the future or less and have an amount paid of 0
        const futureBookings = partyBookingsData.filter((booking) => {
            const bookingDate = parseISO(booking.PartyDate);
            return (
                booking.AmountPaid === 0 && 
                isWithinInterval(bookingDate, { start: today, end: futureDate })
            );
        });

        // Log the valid party bookings to the console
        console.log("Party bookings within 9 days with amount paid as 0:", futureBookings);

        setPartyBookings(futureBookings);


        

        // Make a PUT request to open the master close
        const masterCloseResponse = await fetch("https://cheekydino.com/api/masterclose/open", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (masterCloseResponse.ok) {
            console.log("Master Open");
            setMasterClose(0); // Set status to 0 (open)
        } else {
            throw new Error("Failed to open master close");
        }
    } catch (error) {
        console.log("Error in handleOpen:", error);
    }
};

useEffect(() => {
  if (partyBookings.length > 0) {
      const sendEmail = async (booking) => {
          try {
              // Format PartyDate to a more readable format
              const formattedDate = format(parseISO(booking.PartyDate), "dd/MM/yyyy");

              // Send email request
              const response = await fetch("https://cheekydino.com/api/partyemail", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                      Email: booking.Email,
                      Name: booking.ChildName,
                      Total: booking.Total,
                      Date: formattedDate,
                      Time: booking.PartyTime,
                      PartyType: booking.PartyType,
                  }),
              });

              if (response.ok) {
                  console.log("Email sent for booking:", booking);

                  // Add this booking to the list of sent emails
                  setSentEmails((prev) => [...prev, booking.id]); // Assuming `booking.id` is unique
              } else {
                  throw new Error("Failed to send email");
              }
          } catch (error) {
              console.log("Error sending email for booking:", booking, error);
          }
      };

      // Send emails only for bookings that haven't been emailed yet
      partyBookings.forEach((booking) => {
          if (!sentEmails.includes(booking.id)) {
              sendEmail(booking);
          }
      });
  }
}, [partyBookings, sentEmails]); // Dependency on `sentEmails` to avoid re-sending


  return (
    <>
    {loading && <LoadingComponent />} {/* Show loading component when loading */}
      <StaffNav />
      <div
        style={{
          backgroundColor: masterClose === 1 ? 'black' : 'white',
          minHeight: '100vh',
          color: masterClose === 1 ? 'white' : 'black', // Adjust text color for readability
        }}
      >        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-blue-500 sm:text-4xl">
              Master Close
              <br />
            </h2>
           
            <div className="mt-10 flex items-center justify-center gap-x-6">
              {masterClose === 1 ? (
                <div className="rounded-md bg-gray-700-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm">
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                The master close is currently on. Please click the button below to switch on the system.
              </p>
                <button
                  type="button"
                  onClick={handleOpen}
                  className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Turn On
                </button>
              </div>
              ) : (
                <div className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm">
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                Please click the button below to reset for the next day.
              </p>
                <button
                  type="button"
                  onClick={handleClose}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Shut Down
                </button>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
