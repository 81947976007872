import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Online from './online'; 
import ClockIn from './staffactions'; 
import ClockInData from './clockindata'; 
import Tasks from './stafftasks'; 
import StaffDetails from './staffdetails'; 
import BookShifts from './bookshifts'; 
import EditHome from './edithome';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, Bars3Icon, HomeIcon, CurrencyPoundIcon, PencilIcon, CheckIcon, BoltIcon, CakeIcon, PlusIcon, TvIcon, CogIcon, KeyIcon } from '@heroicons/react/24/outline';
import EditPreview from './editpreview';
import FrontPagePreview from './frontpagepreview';


const MyAccount = () => <EditHome />;
const Company = () => <EditPreview />;
const FrontPage = () => <FrontPagePreview />;


const tabs = [
  { name: 'Build An Advert', component: MyAccount, current: false },
  { name: 'View Adverts', component: Company, current: false },
  { name: 'Front Page Preview', component: FrontPage, current: true },
 
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigation = [
  { name: 'Home', href: '/dashboard', icon: HomeIcon, current: false },
  { name: 'Till', href: '/till', icon: CurrencyPoundIcon, current: true },
  { name: 'Make a Booking', href: '/reservations', icon: PencilIcon, current: false },
  { name: 'Control Panel', href: '/controlpanel', icon: BoltIcon, current: false },
  { name: 'Kitchen', href: '/kitchen', icon: CakeIcon, current: false },
  { name: 'Campaigns', href: '/edithome', icon: PlusIcon, current: false },
  { name: 'Customer Screen', href: '/customerscreen', icon: TvIcon, current: false },
  { name: 'Settings', href: '/settings', icon: CogIcon, current: false },
  { name: 'Master Close', href: '/masterclose', icon: KeyIcon, current: false },
];

export default function Example() {
  const [currentTab, setCurrentTab] = useState(tabs.find((tab) => tab.current));
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Dialog open={sidebarOpen} onClose={() => setSidebarOpen(false)} className="relative z-50 lg:hidden">
        <div className="fixed inset-0 bg-purple-500/80 transition-opacity duration-300 ease-linear" aria-hidden="true" />
        <Transition
          as="div"
          className="fixed inset-0 flex"
          enter="transition-transform ease-in-out duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={sidebarOpen}
        >
          <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 transform bg-white px-6 pb-2 ring-1 ring-white/10">
            <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
              <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
              </button>
            </div>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto">
              <div className="flex h-16 shrink-0 items-center">
                <img alt="Your Company" src="versa.gif" className="h-8 w-auto" />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="-mx-2 flex-1 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-blue-700 text-white' : 'text-gray-900 hover:bg-gray-800 hover:text-white',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                        )}
                      >
                        <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </Dialog.Panel>
        </Transition>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-white lg:pb-4">
        <div className="flex h-16 shrink-0 items-center justify-center">
          <img alt="Your Company" src="versa.gif" className="h-8 w-auto" />
        </div>
        <nav className="mt-8">
          <ul role="list" className="flex flex-col items-center space-y-1">
            {navigation.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-indigo-500 text-white' : 'text-blue-700 hover:bg-indigo-500 hover:text-white',
                    'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6',
                  )}
                >
                  <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                  <span className="sr-only">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-blue-300 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-400 lg:hidden">
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <img alt="" src="versa.gif" className="h-8 w-8 rounded-full bg-gray-800" />
        </a>
      </div>

      <main className="lg:pl-20">
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">Select a tab</label>
            <select
              id="tabs"
              name="tabs"
              defaultValue={currentTab.name}
              className="block w-full rounded-md border-gray-300 mt-10 focus:border-indigo-500 focus:ring-indigo-500"
              onChange={(e) => setCurrentTab(tabs.find((tab) => tab.name === e.target.value))}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav aria-label="Tabs" className="flex space-x-4">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setCurrentTab(tab)}
                  className={classNames(
                    tab.name === currentTab.name
                      ? 'bg-gray-100 text-gray-700'
                      : 'text-gray-500 hover:text-gray-700',
                    'rounded-md px-3 py-2 text-sm font-medium'
                  )}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
          <div className="mt-4">
            {React.createElement(currentTab.component)}
          </div>
        </div>
      </main>
    </div>
  );
}
