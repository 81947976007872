import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ArrowRightCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import MessagesComponent from './custmessages';
import NewMessage from './blankmessage';
import { useNavigate } from 'react-router-dom';
import ChatGPTMarketing from './chatgptmarketing';

export default function CustomerChat() {
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [renderMessages, setRenderMessages] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [renderNewMessage, setRenderNewMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState({});
  const [emails, setEmails] = useState([]);
  const [reload, setReload] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [marketingEmails, setMarketingEmails] = useState([]);

  useEffect(() => {
    const fetchUserEmail = async () => {
      const userEmail = "frontdesk@cheekydino.co.uk";
      setUserEmail(userEmail);
      setLoading(false);
    };

    fetchUserEmail();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://cheekydino.com/api/customermessages', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }

        const data = await response.json();
        const custData = data.data;

        console.log(custData);

        console.log(userEmail);

        // Filter messages by user email
       


        setMessages(custData);
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userEmail) {
      fetchMessages();
      const interval = setInterval(() => {
        fetchMessages();
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [userEmail, reload]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetch('https://cheekydino.com/api/customeremails', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch sessions');
        }

        const data = await response.json();
        const emailData = data.data;
        const emailSet = new Set();
        const latestEmails = [];

        for (const email of emailData) {
          if (!emailSet.has(email.Email)) {
            emailSet.add(email.Email);
            latestEmails.push(email);
          }
        }

        setEmails(latestEmails);
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    fetchSessions();
  }, []);

  const handleSendMessage = () => {
    const message = {
      To: selectedEmail,
      From: 'frontdesk@cheekydino.co.uk',
      Content: '',
      Replied: 0,
      createdAt: new Date().toISOString(),
      Marketing: 0,
      ToMarketing: 'not applicable',
    };
    setReply(message);
    setRenderNewMessage(true);
  };

  const handleMessage = (person) => {
    setRenderMessages(true);
    setReply(person);
  };

  const handleDelete = async (person) => {
    try {

      const response = await fetch(`https://cheekydino.com/api/customermessages/${person.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete message');
      }

      const data = await response.json();
      console.log(data);

      setReload(true)
      
      
    } catch (error) {

      console.error('Error deleting message:', error);
      
    }
  };

  const getBackgroundColorClass = (from) => {
    return from === 'bookings@cheekydino.co.uk' ? 'bg-green-200' : '';
  };

  if (renderMessages) {
    return <MessagesComponent reply={reply} />;
  }

  if (renderNewMessage) {
    return <NewMessage reply={reply} />;
  }

  if (marketing) {
   return <ChatGPTMarketing emails={marketingEmails} />
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {loading && <div><h1>Loading...</h1></div>}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 mt-10 mb-10">Customer Enquiries</h1>
        </div>
        <div>
          <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">Select Email</label>
          <select
            onChange={(e) => setSelectedEmail(e.target.value)}
            id="location"
            name="location"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue=""
          >
            <option value="" disabled>Select an email</option>
            {emails.map((email, index) => (
              <option key={index} value={email.Email}>{email.Email}</option>
            ))}
          </select>
          <button className="rounded-md mt-10 mr-10 bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => setMarketing(true)|| setMarketingEmails(emails)}>Marketing Suite</button>
          {selectedEmail && (
            <button className="rounded-md mt-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleSendMessage}>Send Message</button>
          )}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">From</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">To</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Sent Time</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Content</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"><span className="sr-only">Reply</span></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {messages.map((person, index) => (
                  <tr key={index} className={getBackgroundColorClass(person.From)}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img className="h-11 w-11 rounded-full" src="dino-logo.png" alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{person.From}</div>
                          <div className="mt-1 text-gray-500">{person.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.To}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{format(new Date(person.createdAt), 'dd/MM/yyyy HH:mm')}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {person.Replied ? (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Replied</span>
                      ) : (
                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Not Replied</span>
                      )}
                    </td>
                    <td className="px-3 py-5 text-sm text-gray-500 max-w-xs whitespace-pre-line">{person.Content}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button onClick={() => handleDelete(person)} className="text-red-600 hover:text-indigo-900 mr-5">Delete<span className="sr-only"></span></button>
                      <button onClick={() => handleMessage(person)} className="text-indigo-600 hover:text-indigo-900">Show<span className="sr-only"></span></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
